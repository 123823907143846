import { IconName } from 'components/icon';
import { themeColors } from 'theme/themeColors';

type Props = {
  disabled?: boolean;
  iconName: IconName | undefined;
};

export const styles = ({ disabled, iconName }: Props) => ({
  tags: {
    '& > .MuiChip-root': {
      marginRight: '10px',
      marginBottom: '10px',
      paddingRight: '12px',
    },
  },
  tag: (isError = false) => ({
    padding: '0 16px 1px 16px',
    borderRadius: '1px',
    backgroundColor: isError ? themeColors.red20 : themeColors.grey20,
    height: 48,
    '& svg': {
      cursor: 'pointer',
    },
    '& > .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: '8px',
      fontSize: 16,
    },
  }),
  deleteIcon: {
    '& .MuiChip-deleteIcon': {
      width: 'unset',
      height: 'unset',
      fill: disabled ? themeColors.grey120 : themeColors.purplePrimary,
      cursor: disabled ? 'default' : 'pointer',
      ...(iconName === 'tooltip' ? { marginLeft: 0 } : {}),
    },
  },
});
