import { themeColors } from 'theme/themeColors';

export const expandableStyles = {
  list: {
    '& .MuiMenu-list': {
      padding: 0,
      boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.2)',
      '& > :not(:last-child)': {
        borderBottom: '2px solid ' + themeColors.purple20,
      },
    },
  },
  menuItem: ({ disabled }: { disabled: boolean }) => ({
    color: themeColors.purplePrimary,
    backgroundColor: themeColors.white,
    fontFamily: 'Proxima Nova Bold',
    padding: 0,
    fontSize: 16,
    '&:not(:last-child)': {
      borderBottom: `2px solid ${themeColors.grey20}`,
    },
    ...(disabled
      ? {
          '&:hover': {
            background: themeColors.white,
          },
          '&:focus': {
            background: themeColors.white,
          },
        }
      : {
          '&:hover': {
            textDecoration: 'none !important',
            background: themeColors.purple20,
            color: themeColors.purpleHover + '!important',
          },
          '&:focus': {
            background: themeColors.purple20,
            color: themeColors.purpleHover + '!important',
          },
        }),
  }),
  menuItemContainer: {
    color: themeColors.purplePrimary,
    '&:hover': {
      textDecoration: 'none !important',
      color: themeColors.purpleHover + '!important',
    },
    '&:focus': {
      color: themeColors.purpleHover + '!important',
    },
    '& .MuiAccordionSummary-root': {
      padding: '18px 15px 18px 20px',
      minHeight: 0,
      fontFamily: 'Proxima Nova Bold',
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
      '& .MuiList-root': {
        width: '100%',
        padding: 0,
        '& > *': {
          borderBottom: 'none',
        },
        '& .menu-link': {
          paddingLeft: '30px',
        },
      },
    },
  },
  link: {
    textDecoration: 'none !important',
    color: 'inherit',
    padding: '16.5px 15px',
    width: '100%',
    '&:hover': {
      textDecoration: 'none !important',
      color: 'inherit',
    },
  },
  linkDisabled: {
    opacity: '.38',
    pointerEvents: 'none',
    width: 'auto',
    paddingRight: '8px',
  },
};
