import { IntlShape } from 'react-intl';

import {
  UserUpdatePasswordFormData,
  ValidationError,
  ValidationErrorType,
} from './types';

export const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~`! @#$%^&*()_\-+=:;"'<,>.?/\\|{}[\]]).{8,32}$/;

export function getValidationErrors(
  { password, repeatPassword, termsOfUse }: UserUpdatePasswordFormData,
  intl: IntlShape
): Array<ValidationError> {
  const submitErrors: Array<ValidationError> = [];
  const baseMessage = 'users.activate_user.validation';

  if (!password) {
    submitErrors.push({
      type: ValidationErrorType.password,
      message: intl.formatMessage({ id: `${baseMessage}.password.required` }),
    });
  }
  if (!repeatPassword) {
    submitErrors.push({
      type: ValidationErrorType.repeatPassword,
      message: intl.formatMessage({ id: `${baseMessage}.repeatPassword.required` }),
    });
  }
  if (!termsOfUse) {
    submitErrors.push({
      type: ValidationErrorType.termsOfUse,
      message: intl.formatMessage({ id: `${baseMessage}.termsOfUse.required` }),
    });
  }
  if (password !== repeatPassword) {
    submitErrors.push({
      type: ValidationErrorType.repeatPassword,
      message: intl.formatMessage({ id: `${baseMessage}.passwords_match` }),
    });
  }
  if (!passwordRegex.test(password)) {
    submitErrors.push({
      type: ValidationErrorType.password,
      message: intl.formatMessage({ id: `${baseMessage}.password_criteria` }),
    });
  }

  return submitErrors;
}
