import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';

import { TEST_ID } from 'components/constants';
import { Icon } from 'components/icon';
import { Menu } from 'components/menu';
import { OffsetContainer } from 'components/offset-container';
import { getMenuItems } from 'utils/menuItems';

import { styles } from './styles';

import { useAbility } from '../acl/AclProvider';

const Header: React.FC = () => {
  const ability = useAbility();
  const menuItems = useMemo(() => getMenuItems(ability), [ability]);

  return (
    <OffsetContainer>
      <Box sx={styles.headerLayout}>
        <Box
          data-testid={TEST_ID.appHeaderLogo}
          display="flex"
          alignItems="center"
          height={107}
        >
          <NavLink to="/" style={styles.headerText}>
            <Icon name="dpg-header-logo" width={210} />
          </NavLink>
        </Box>
        <Menu menuItems={menuItems} />
      </Box>
    </OffsetContainer>
  );
};

export { Header };
