import React from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';

import { TEST_ID } from 'components/constants';
import { Icon } from 'components/icon';
import { OffsetContainer } from 'components/offset-container';
import { appRoutes } from 'routes/appRoutes';
import { theme } from 'theme';

import { HelpLinks } from './help-links';
import { styles } from './styles';

import { LOGO_WIDTH } from '../footer/constants';

const FooterSmall: React.FC = () => (
  <Box component="footer" sx={styles.root} data-testid={TEST_ID.appFooter}>
    <OffsetContainer>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Link to={appRoutes.main()} data-testid={TEST_ID.appFooterLogo}>
            <Icon
              name="dpg-footer-logo"
              width={LOGO_WIDTH}
              color={theme.palette.common.white}
            />
          </Link>
        </Grid>
        <Grid item xs={12} sm={6}>
          <HelpLinks />
        </Grid>
      </Grid>
    </OffsetContainer>
  </Box>
);

export { FooterSmall };
