export const styles = {
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  grid: {
    backgroundColor: '#fff',
    padding: '25px 30px',
    boxShadow: '0px 2px 4px 0px rgba(163, 172, 178, 0.4)',
    borderRadius: '3px',
  },
  gridManageItem: {
    display: 'flex',
    position: 'relative',
  },
  separator: {
    position: 'relative',
    width: 2,
    height: 20,
    background: 'rgba(152, 162, 168, 0.3)',
    left: 5,
    top: 10,
    marginLeft: '8px',
    marginRight: '15px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  gridItem: {
    marginTop: '100px',
  },
  searchHeader: {
    width: 'fit-content',
    float: 'right',
  },
};
