import React from 'react';

import Box from '@mui/material/Box';
import { default as MuiDialog } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Icon } from 'components/icon';
import { themeColors } from 'theme/themeColors';

import { CreateAgencyForm } from './CreateAgencyForm';
import { EditAgencyForm } from './EditAgencyForm';
import { styles } from './styles';

import { Agency } from '../../api/agencies/types';

export interface DialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  agencyToEdit?: Agency;
}

const ManageAgencyDialog: React.FC<DialogProps> = ({ open, setOpen, agencyToEdit }) => {
  return (
    <MuiDialog open={open} maxWidth={false}>
      <DialogContent>
        <Box>
          <Icon
            name="close"
            width={24}
            height={24}
            color={themeColors.purplePrimary}
            sx={styles.closeButton}
            onClick={() => setOpen(false)}
          />
        </Box>
        {agencyToEdit ? (
          <EditAgencyForm open={open} setOpen={setOpen} agencyToEdit={agencyToEdit} />
        ) : (
          <CreateAgencyForm open={open} setOpen={setOpen} />
        )}
      </DialogContent>
    </MuiDialog>
  );
};

export { ManageAgencyDialog };
