import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';

import { Divider } from '@mui/material';

import { editDeal } from '../../api/deals';
import { Deal, EditDealData } from '../../api/deals/types';
import { queryKeys } from '../../api/queryKeys';
import { NotificationMessage, useNotification } from '../../notifications';
import { DealFormItem } from '../deal-form-item';
import { Dialog, DialogContainer, DialogFooter, DialogTitle } from '../dialog2';
import { RowWithTitle } from '../elements/row-with-title';
import { TextField } from '../form/fields/text-field/TextField';

export type Props = {
  deal: Deal;
  triggerEl: React.ReactElement;
};

export const DealDetailEditDialog: React.FC<Props> = ({ deal, triggerEl }) => {
  const intl = useIntl();
  const [wipDeal, setWIPDeal] = useState(deal);
  const { mutate } = useEditDeal();

  useEffect(() => {
    setWIPDeal(deal);
  }, [deal]);

  return (
    <Dialog triggerEl={triggerEl}>
      {({ onDialogClose }) => (
        <DialogContainer
          header={
            <DialogTitle
              headerLabel="deal.detail.editForm.dialog.title"
              handleClose={() => {
                setWIPDeal(deal);
                onDialogClose();
              }}
            />
          }
          footer={
            <DialogFooter
              handleSubmit={() => {
                mutate(wipDeal);
                onDialogClose();
              }}
              handleCancel={() => {
                setWIPDeal(deal);
                onDialogClose();
              }}
            />
          }
        >
          <DealFormItem
            deal={wipDeal}
            canEditAgency={false}
            canComment={false}
            onChange={(d: Deal) => setWIPDeal(d)}
          />
          <Divider />
          <RowWithTitle
            title={intl.formatMessage({ id: 'deal.detail.props.dealName' })}
            mt={3}
            mb={3}
          >
            <TextField
              fullWidth
              multiline={true}
              maxRows={4}
              value={wipDeal?.dealName ?? ''}
              onChange={(e) => {
                setWIPDeal({
                  ...wipDeal,
                  dealName: e.target.value,
                });
              }}
            />
          </RowWithTitle>
        </DialogContainer>
      )}
    </Dialog>
  );
};

const useEditDeal = () => {
  const cache = useQueryClient();
  const sendNotification = useNotification();

  return useMutation<void, AxiosError, EditDealData>(editDeal, {
    onSuccess: (_, data) => {
      cache.invalidateQueries([queryKeys.getDeal, data.id]);

      sendNotification({
        type: 'success',
        message: <NotificationMessage id={'deal.detail.editForm.success'} />,
      });
    },
    onError: () => {
      sendNotification({
        type: 'error',
        message: <NotificationMessage id={'deal.detail.editForm.error'} />,
      });
    },
  });
};
