import { User } from '../../../api/users/types';

export function buildAgencyUserCriteria(user?: User) {
  const userAgencies = user?.agencies?.map(({ id }) => id) ?? [];

  return {
    agencies: { $elemMatch: { id: { $in: userAgencies } } },
  };
}

export function buildAgenciesCriteria(user?: User) {
  const userAgencies = user?.agencies?.map(({ id }) => id) ?? [];

  return {
    id: { $in: userAgencies },
  };
}

export function buildRoleCriteria(roles: string[]) {
  return {
    roles: { $elemMatch: { $in: roles } },
  };
}
