export const styles = {
  root: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      width: '100%',
    },
    '& .MuiFormControlLabel-label': {
      display: 'contents',
    },
  },
};
