import { theme } from '../../../theme';
import { themeColors } from '../../../theme/themeColors';

export const styles = {
  item: {
    padding: 0,
    maxWidth: '24px',
  },
  bulkActionsHeader: {
    backgroundColor: themeColors.white,
    height: '45px',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&.active': {
      backgroundColor: themeColors.purplePrimary,
      '& svg path:not(:first-of-type), & svg path:last-child': {
        fill: themeColors.white,
      },
    },
  },
  bulkActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  verticalDivider: {
    backgroundColor: themeColors.purple60,
    width: '1px',
    height: '100%',
  },
  countText: {
    color: themeColors.white,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  bulkButton: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    color: themeColors.white,
    background: 'transparent',
    fontSize: 16,
    fontFamily: 'Proxima Nova Semibold',
    '&:focus, &:active, &:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  toggleFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',

    '&:not(:hover) > .MuiIconButton-root': {
      visibility: 'hidden',
    },
  },
  dealEditableCellText: {
    textOverflow: 'clip',
    overflow: 'hidden',
  },
};
