export const styles = {
  box: {
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    width: '100%',
    marginTop: '32px',
  },
  root: {
    flex: '0 1 auto',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  dropdown: {
    flex: '0 1 auto',
    marginLeft: 'auto',
  },
  text: {
    fontSize: '14px',
    marginRight: '30px',
  },
};
