export const styles = {
  textValueEllipsis: {
    width: '100%',
    alignSelf: 'flex-end',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoMessage: {
    backgroundColor: '#fff',
    padding: '16px',
    borderRadius: '3px',
    boxShadow: '1px 2px 9px #757575',
  },
  popover: {
    marginTop: '16px',
  },
  iconButton: {
    padding: 0,
    maxWidth: '24px',
    marginRight: '8px',
  },
};
