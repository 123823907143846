import en from './messages/en.json';
import { Locales, TranslationsMap } from './types';

const defaultLanguage = Locales.EN;

const translations: TranslationsMap = {
  en,
};

export { translations, defaultLanguage };
