import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/queryKeys';
import { updateUserActivation } from 'api/users';
import { getFullName } from 'features/users/utils';
import { NotificationMessage, useNotification } from 'notifications';

import { UserUpdateActivationRequest } from '../types';

export const useManageUserActivation = () => {
  const cache = useQueryClient();
  const sendNotification = useNotification({});

  return useMutation<void, Record<string, unknown>, UserUpdateActivationRequest>(
    updateUserActivation,
    {
      onSuccess: (_, data) => {
        cache.invalidateQueries([queryKeys.getUsers]);
        cache.invalidateQueries([queryKeys.getUser, data.user.id]);

        sendNotification({
          type: 'success',
          message: (
            <NotificationMessage
              id={`user.manage.activation.${
                data.active ? 'activate' : 'deactivate'
              }.message`}
              values={[{ key: 'username', value: getFullName(data.user) }]}
            />
          ),
        });
      },
    }
  );
};
