export const asObjects = (objects: unknown[]): Record<string, unknown>[] => {
  return objects as Record<string, unknown>[];
};

export function copyObjectProperties<T extends AnyObject, K extends keyof T>(
  obj: T,
  properties: K[]
): Pick<T, K> {
  return Object.fromEntries(
    Object.entries(obj).filter(([k]) => properties.includes(k as K))
  ) as Pick<T, K>;
}
