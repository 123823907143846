import React, { useCallback, useMemo } from 'react';

import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Typography from '@mui/material/Typography';
import { SystemStyleObject } from '@mui/system';

import { Icon, IconName } from 'components/icon';
import { themeColors } from 'theme/themeColors';
import { convertSxToThemeType } from 'utils';

import { styles } from './styles';

export interface CardItemProps {
  title: string;
  description: string | React.ReactElement;
  action?: {
    icon?: IconName;
    testId?: string;
    buttonTitle?: string | React.ReactElement;
    handler?: () => void;
    renderHook?: (target: React.ReactElement) => React.ReactElement;
    disabled?: boolean;
  };
}

interface Props {
  items: CardItemProps[];
}

export const CardList: React.FC<Props> = ({ items }) => {
  const iconButtonElement = useCallback(
    (
      type: IconName,
      sx: SystemStyleObject<Theme>[],
      handler?: () => void,
      testId?: string
    ) => {
      return (
        <IconButton
          edge="end"
          aria-label={type}
          sx={sx}
          data-testid={testId}
          size="large"
          onClick={handler}
        >
          <Icon
            name={type}
            width={24}
            height={24}
            sx={{
              '& path': {
                fill: themeColors.purplePrimary,
              },
            }}
          />
        </IconButton>
      );
    },
    []
  );

  const buttonElement = useCallback(
    (
      title: string | React.ReactElement,
      sx: SystemStyleObject<Theme>[],
      handler?: () => void,
      testId?: string
    ) => (
      <Link
        component="button"
        sx={sx}
        color="primary"
        data-testid={testId}
        onClick={handler}
      >
        {title}
      </Link>
    ),
    []
  );

  const buildAction = (action: CardItemProps['action']) => {
    if (!action) {
      return null;
    }
    const { icon, handler, buttonTitle, renderHook, testId, disabled } = action;

    const buttonSx = convertSxToThemeType([
      styles.button,
      disabled ? styles.disabled : {},
    ]);
    const renderWrapper = renderHook ? renderHook : defaultRenderHook;

    return (
      <ListItemSecondaryAction>
        {renderWrapper(
          icon
            ? iconButtonElement(icon, buttonSx, handler, testId)
            : buttonElement(
                buttonTitle as string | React.ReactElement,
                buttonSx,
                handler,
                testId
              )
        )}
      </ListItemSecondaryAction>
    );
  };

  const defaultRenderHook = (target: React.ReactElement) => target;
  const itemsElement = useMemo(() => {
    const lastItemIndex = items.length - 1;

    return items.map(({ title, description, action }, index) => {
      return (
        <React.Fragment key={title}>
          <ListItem>
            <Box sx={styles.infoRow}>
              <Typography variant="h4" mb={1}>
                {title}
              </Typography>
              <Typography variant="body1" sx={styles.infoDesc}>
                {description}
              </Typography>
            </Box>
            {buildAction(action)}
          </ListItem>
          {index !== lastItemIndex && (
            <Divider
              component="li"
              sx={{
                height: '2px',
                background: themeColors.grey20,
                borderColor: themeColors.grey20,
              }}
            />
          )}
        </React.Fragment>
      );
    });
  }, [items, iconButtonElement, buttonElement]);

  return (
    <Card sx={styles.root}>
      <CardContent>
        <List>{itemsElement}</List>
      </CardContent>
    </Card>
  );
};
