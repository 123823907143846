import { AbilityBuilder } from '@casl/ability';

import { User } from 'api/users/types';

import {
  buildAgenciesCriteria,
  buildAgencyUserCriteria,
  buildRoleCriteria,
} from './utils';

import { Roles } from '../../../utils/keycloak/Keycloak';
import { Actions, Permissions } from '../constants';

export const dpgUserRole = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  can: AbilityBuilder<any>['can'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cannot: AbilityBuilder<any>['cannot'],
  user?: User
): void => {
  const agencyUserCriteria = buildAgencyUserCriteria(user),
    agenciesCriteria = buildAgenciesCriteria(user),
    clientOnlyCriteria = buildRoleCriteria([Roles.ClientAdmin, Roles.ClientUser]);

  can(Actions.View, Permissions.Users);
  can(Actions.Create, Permissions.Users, {
    ...agencyUserCriteria,
    ...clientOnlyCriteria,
  });
  can(Actions.Manage, Permissions.Users);

  can(Actions.View, Permissions.UserInfo, agencyUserCriteria);
  can(Actions.Manage, Permissions.UserInfo, agencyUserCriteria);

  can(Actions.Import, Permissions.Deal, agenciesCriteria);

  can(Actions.View, Permissions.DealId);
  can(Actions.Manage, Permissions.DealId);
  can(Actions.Create, Permissions.DealId);

  can(Actions.Manage, Permissions.DealName);

  can(Actions.Manage, Permissions.Roles, {
    ...agencyUserCriteria,
    ...clientOnlyCriteria,
  });

  cannot(Actions.Manage, Permissions.Activation);

  cannot(Actions.Manage, Permissions.Agencies);
  can(Actions.View, Permissions.Agencies, agenciesCriteria);
  can(Actions.Manage, Permissions.Agencies, agenciesCriteria);
  cannot(Actions.Create, Permissions.Agencies);

  can(Actions.View, Permissions.AgencyDealManagers);

  can(Actions.Manage, Permissions.UserAgencySettings, agencyUserCriteria);
};
