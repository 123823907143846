import React, { createContext } from 'react';

import { Ability } from '@casl/ability';
import { useAbility as useAbilityCasl } from '@casl/react';

import { buildAbilityFor } from './utils';

import { ReactFCC } from '../../react-util';
import { useKeycloak, useUser } from '../../utils/keycloak/KeycloakProvider';

const AbilityContext = createContext<Ability>(undefined!);

export const AclProvider: ReactFCC = ({ children }) => {
  const keycloak = useKeycloak();
  const user = useUser();

  const ability = buildAbilityFor(keycloak, user);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export function useAbility() {
  return useAbilityCasl(AbilityContext);
}
