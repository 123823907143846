import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Box, IconButton, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';

import { Icon } from 'components/icon';

import { styles } from './styles';

import { Deal, State } from '../../../api/deals/types';
import { appRoutes } from '../../../routes/appRoutes';

interface Props {
  selectedDeals: Array<Deal>;
}

const GridBulkActions = ({ selectedDeals }: Props): JSX.Element => {
  const draftDeals = selectedDeals.filter((d) => d.state === State.Draft);
  const internalDeals = selectedDeals.filter((d) => !d.external);
  const isAllDraft = draftDeals.length === selectedDeals.length;
  const isAllInternal = internalDeals.length === selectedDeals.length;
  const intl = useIntl();

  return (
    <>
      <Typography sx={styles.countText}>
        <FormattedMessage
          id="deals.overview.table.selected"
          values={{ count: selectedDeals.length }}
        />
      </Typography>
      <Box component="div" sx={styles.verticalDivider} />
      {draftDeals.length > 0 && (
        <Tooltip
          title={
            isAllDraft
              ? ''
              : intl.formatMessage({
                  id: 'deals.overview.table.bulkEdit.onlyDraftTooltip',
                })
          }
        >
          <IconButton
            sx={styles.bulkButton}
            component={Link}
            to={appRoutes.editDeals(
              {},
              {
                ids: draftDeals.map((d) => d.id).join(','),
              }
            )}
          >
            <Icon name="edit" width={24} height={24} />
            <FormattedMessage id="deals.overview.table.bulkEdit" />
          </IconButton>
        </Tooltip>
      )}
      {internalDeals.length > 0 && (
        <Tooltip
          title={
            isAllInternal
              ? ''
              : intl.formatMessage({
                  id: 'deals.overview.table.bulkDuplicate.onlyInternalTooltip',
                })
          }
        >
          <IconButton
            sx={styles.bulkButton}
            component={Link}
            to={{
              pathname: appRoutes.createDeals(),
              state: { deals: internalDeals },
            }}
          >
            <Icon name="duplicate" width={24} height={24} />
            <FormattedMessage id="deals.overview.table.bulkDuplicate" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export { GridBulkActions };
