import React from 'react';
import { Route as ReactRoute, RouteProps } from 'react-router-dom';

import { DEFAULT_TITLE_PREFIX, useDocumentTitle } from '../utils/hooks';

export type Props = RouteProps & {
  title: string;
};

export const Route = ({ title, ...props }: Props) => {
  useDocumentTitle(`${DEFAULT_TITLE_PREFIX} - ${title}`);

  return <ReactRoute {...props} exact={true} />;
};
