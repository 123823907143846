import { keyframes } from '@emotion/react';

import { themeColors } from 'theme/themeColors';

export const getAnimation = (ratio = 1) => keyframes`
  0% { transform: matrix(1,0,0,1,0,0); }
  10% { transform: matrix(1,0,0,1.5,0,0); }
  30% { transform: matrix(1,0,0,2.5,0,${ratio * 100}); }
  50% { transform: matrix(1,0,0,1,0,${ratio * 170}); }
  80% { transform: matrix(1,0,0,3,0,0); }
  100% { transform: matrix(1,0,0,1,0,0); }
`;

export const getStyles = (width: number) => ({
  box: {
    width: '100%',
    height: '100%',
    pl: '0.5%',
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'space-between',
    transform: 'rotate(180deg) scaleX(-1)',
    '& div': {
      width: '19%',
      height: '25%',
      transformOrigin: 'top',
    },
  },
  purple: {
    backgroundColor: themeColors.purplePrimary,
    animation: `${getAnimation(width / 300)} 1s ease-in infinite`,
    animationDelay: '-0.4s',
  },
  rose: {
    animation: `${getAnimation(width / 300)} 1s ease-in infinite`,
    backgroundColor: themeColors.pink,
    animationDelay: '-0.3s',
  },
  orangered: {
    animation: `${getAnimation(width / 300)} 1s ease-in infinite`,
    backgroundColor: themeColors.red,
    animationDelay: '-0.2s',
  },
  gold: {
    animation: `${getAnimation(width / 300)} 1s ease-in infinite`,
    backgroundColor: themeColors.yellowPrimary,
    animationDelay: '-0.1s',
  },
});
