import React, { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

interface Props {
  value: string | undefined | JSX.Element | Element[];
  variant?: 'body1' | 'subtitle1';
  padding?: boolean;
  width?: string;
}

export const ReadOnlyText: React.FC<Props> = ({
  value,
  variant = 'body1',
  padding = true,
  width = '100%',
}) => {
  return (
    <Box
      width={width}
      sx={{
        overflow: 'hidden',
      }}
    >
      <Typography
        variant={variant}
        sx={{
          paddingTop: padding ? '14px' : 0,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {value as ReactNode}
      </Typography>
    </Box>
  );
};
