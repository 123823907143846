import React from 'react';
import ReactDOMClient from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root');

ReactDOMClient.createRoot(root as HTMLElement).render(<App />);

// eslint-disable-next-line no-console
reportWebVitals((...params) => console.log('Web Vitals:', ...params));
