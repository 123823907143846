import React from 'react';
import { useIntl } from 'react-intl';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { themeColors } from 'theme/themeColors';

import { getSectionsCount } from './utils';

import { TEST_ID } from '../../constants';
import { RootCategories, TargetingValueItem } from '../../types';
import { getSelectedLeafItemsCount } from '../../utils';

type Props = {
  value: TargetingValueItem[];
  formFieldMappedValues: TargetingValueItem[];
  setScreen(v: string[]): void;
};

const MainScreen: React.FC<Props> = ({ setScreen, formFieldMappedValues, value }) => {
  const intl = useIntl();

  const clickHandler = (i: string) => () => setScreen([i]);

  return (
    <Box>
      {Object.keys(RootCategories).map((i) => {
        const item = value.filter((item: TargetingValueItem) => item.id === i)?.[0];

        return (
          <Box
            key={i}
            p={1}
            mt={1}
            display="flex"
            alignItems="center"
            bgcolor={themeColors.grey20}
            style={{ cursor: 'pointer' }}
            data-testid={TEST_ID.mainItems}
            onClick={clickHandler(i)}
          >
            <Box ml={1} display="flex" alignItems="center">
              <Typography variant="body1">
                {intl.formatMessage({
                  id: `deal.targeting.dialog.category.${i}`,
                })}
              </Typography>
              <Typography>
                <Box ml={1} color={themeColors.grey120} component="span">
                  {value.length > 0 &&
                    item &&
                    `(${getSelectedLeafItemsCount(item).length}/${
                      getSectionsCount(formFieldMappedValues)[i]
                    })`}
                </Box>
              </Typography>
            </Box>

            <Box display="flex" style={{ marginLeft: 'auto', marginRight: 0 }}>
              <ChevronRightIcon htmlColor={themeColors.grey120} />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export { MainScreen };
