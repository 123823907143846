import { AxiosError } from 'axios';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { TextFieldProps } from '@mui/material/TextField';

import { editDealId } from 'api/deals';
import { Deal, EditDealIdData } from 'api/deals/types';
import { NotificationMessage, useNotification } from 'notifications';

import { queryKeys } from '../../api/queryKeys';
import { ConfirmableTextField } from '../confirmable-text-field/ConfirmableTextField';

interface Props extends TextFieldProps<'standard'> {
  deal: Deal;
  onComplete?: () => void;
  alwaysShowCancel?: boolean;
}

export const DealIdField: React.FC<Props> = ({
  deal,
  onComplete,
  alwaysShowCancel,
  ...props
}) => {
  const { mutate } = useUpdateDealId();

  return (
    <ConfirmableTextField
      fullWidth
      sx={{ width: '100%' }}
      {...props}
      value={deal.dealId ?? ''}
      alwaysShowCancel={alwaysShowCancel}
      onUpdate={(dealId) => {
        mutate({ id: deal.id, dealId });
        onComplete?.();
      }}
      onCancel={() => onComplete?.()}
    />
  );
};

const useUpdateDealId = () => {
  const cache = useQueryClient();
  const sendNotification = useNotification({});

  return useMutation<void, AxiosError, EditDealIdData>(editDealId, {
    onSuccess: (_, data) => {
      cache.invalidateQueries([queryKeys.getDeal, data.id]);
      cache.invalidateQueries([queryKeys.getDeals]);

      sendNotification({
        type: 'success',
        message: <NotificationMessage id="deal.detail.props.dealId.message" />,
      });
    },
  });
};
