import { RootCategories, TargetingValueItem } from '../../types';
import { getLeafItemsCount } from '../../utils';

export const getSectionsCount = (
  formFieldMappedValues: TargetingValueItem[]
): Record<string, number> => {
  return Object.fromEntries(
    Object.keys(RootCategories).map((cat) => [
      cat,
      getLeafItemsCount(formFieldMappedValues.filter((item) => item.id === cat)[0]),
    ])
  );
};
