import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import { useUpdateUser } from 'api/users/hooks';
import { User } from 'api/users/types';
import { ContainerWithTitle } from 'components/elements/container-with-title';
import { MultiSelect } from 'components/multi-select/MultiSelect';

import { AddBuyerOrAdvertiserButton } from '../../../../../components/manage-agency-dialog/AddBuyerOrAdvertiserButton';
import { UpdateUserFormWrapper } from '../update-user-form-wrapper';

interface Props {
  user: User;
  onCancel: () => void;
}

export const EditAdvertisers: React.FC<Props> = ({ user, onCancel }) => {
  const mutation = useUpdateUser();
  const intl = useIntl();
  const [advertisers, setAdvertisers] = useState(user.advertisers ?? []);
  const allAdvertisers = Array.from(
    new Set(user.agencies.flatMap((a) => a.advertisers ?? []))
  );

  return (
    <Box>
      <UpdateUserFormWrapper
        mutation={mutation}
        onCancel={onCancel}
        onSave={() => {
          mutation.mutate(
            {
              user,
              updates: {
                advertisers,
              },
            },
            {
              onSuccess: () => {
                onCancel();
              },
            }
          );
        }}
      >
        <Box mt={3}>
          <ContainerWithTitle
            title={intl.formatMessage({ id: 'user.manage.set_advertisers.label' })}
            mt={0}
            mb={3}
            infoModalContent={
              <Box width={400}>
                <FormattedMessage id="user.manage.set_advertisers.description" />
              </Box>
            }
          >
            <MultiSelect
              options={allAdvertisers.map((a) => ({ label: a.name, value: a }))}
              selected={advertisers}
              onChange={(selectedAdvertisers) => {
                setAdvertisers(selectedAdvertisers);
              }}
            />
          </ContainerWithTitle>

          <AddBuyerOrAdvertiserButton user={user} />
        </Box>
      </UpdateUserFormWrapper>
    </Box>
  );
};
