import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, SxProps } from '@mui/material';

import { StatusIcon } from 'components/status-label/status-icon/StatusIcon';

import { State } from '../../api/deals/types';
import { themeColors } from '../../theme/themeColors';

interface Props {
  state: State;
  sx?: SxProps;
}

function mapStateToColor(state: State): string {
  switch (state) {
    case State.Active:
      return themeColors.greenPrimary;
    case State.Draft:
      return themeColors.greyPrimary;
    case State.Requested:
    case State.WaitingForFeedback:
      return themeColors.yellowPrimary;
    case State.Error:
      return themeColors.redPrimary;
    default:
      return themeColors.greyPrimary;
  }
}

function mapStateToLabelId(state: State): string {
  return `deals.states.${state.toLowerCase()}`;
}

export const StatusLabel: React.FC<Props> = ({ state, sx = {} }) => {
  return (
    <Box display="flex" alignItems="center" width="100%" sx={sx}>
      <StatusIcon color={mapStateToColor(state)} />
      <Box overflow="hidden" textOverflow="ellipsis" component="span">
        <FormattedMessage id={mapStateToLabelId(state)} />
      </Box>
    </Box>
  );
};
