import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Typography } from '@mui/material';

import { styles } from './styles';

import { User } from '../../../api/users/types';
import { RowWithTitle } from '../../elements/row-with-title';
import { MultiSelect } from '../../multi-select/MultiSelect';

export type Props = {
  user: User;
  onChange(user: User): void;
};

export const AgentUserPreferencesFields: React.FC<Props> = ({ user, onChange }) => {
  const intl = useIntl();
  const buyerOptions = user.agencies?.flatMap((a) => a.buyers ?? []) ?? [];
  const advertiserOptions = user.agencies?.flatMap((a) => a.advertisers ?? []) ?? [];

  return (
    <>
      <RowWithTitle
        title={intl.formatMessage({ id: 'user.form.preferences.buyers' })}
        mt={3}
        mb={3}
      >
        {!!user.agencies && buyerOptions.length === 0 ? (
          <Typography sx={styles.textValue}>
            <FormattedMessage id="user.form.preferences.noBuyers" />
          </Typography>
        ) : (
          <MultiSelect
            options={buyerOptions?.map((buyer) => ({
              label: `${buyer.dsp} - ${buyer.seat}`,
              value: buyer,
            }))}
            selected={user.buyers ?? []}
            onChange={(selectedBuyers) => {
              onChange({
                ...user,
                buyers: selectedBuyers,
              });
            }}
          />
        )}
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'user.form.preferences.advertisers' })}
        mt={3}
        mb={3}
      >
        {!!user.agencies && advertiserOptions.length === 0 ? (
          <Typography sx={styles.textValue}>
            <FormattedMessage id="user.form.preferences.noAdvertisers" />
          </Typography>
        ) : (
          <MultiSelect
            options={advertiserOptions.map((a) => ({ label: a.name, value: a }))}
            selected={user.advertisers ?? []}
            onChange={(selectedAdvertisers) => {
              onChange({
                ...user,
                advertisers: selectedAdvertisers,
              });
            }}
          />
        )}
      </RowWithTitle>
    </>
  );
};
