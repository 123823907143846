import { theme } from '../../theme';
import { themeColors } from '../../theme/themeColors';

export const styles = {
  title: {
    color: themeColors.greyPrimary,
  },
  textValue: {
    width: '100%',
    alignSelf: 'flex-end',
    fontWeight: 'bold',
  },
  textValueNormal: {
    width: '100%',
    alignSelf: 'flex-end',
  },
  dividerSpaceBottom: {
    marginBottom: theme.spacing(3),
  },
  fullWidth: {
    width: '100%',
  },
  formActionButton: {
    marginRight: theme.spacing(3),
  },
  button: {
    background: 'none',
    color: '#5b2a7a',
    fontFamily: 'Proxima Nova Regular',
    fontSize: 16,
    textDecoration: 'none',
    '&:hover, &:active': {
      color: theme.palette.primary.main,
      textDecoration: 'underlined',
    },
    float: 'right',
    marginTop: '12px',
  },
  padding: {
    paddingTop: '15px',
  },
};
