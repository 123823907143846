import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import { TEST_ID } from 'components/constants';
import { themeColors } from 'theme/themeColors';

import { DialogContext } from './Dialog';
import { ModalVariants } from './types';

interface Props {
  submitButtonLabel?: string;
  submitButtonColor?: 'primary' | 'secondary';
  submitTestId?: string;
  disabled?: boolean;
  cancelButtonLabel?: string;
  cancelTestId?: string;
  bottomDivider?: boolean;

  handleSubmit(): void;

  handleCancel?(): void;
}

const DialogFooter: React.FC<Props> = ({
  submitButtonLabel = 'button.save',
  submitButtonColor = 'primary',
  submitTestId = TEST_ID.submitButton,
  disabled = false,
  cancelButtonLabel = 'button.cancel',
  cancelTestId = TEST_ID.cancelButton,
  bottomDivider = false,
  handleSubmit,
  handleCancel,
}) => {
  const { mode } = useContext(DialogContext);

  return (
    <>
      <hr
        style={{
          margin: ModalVariants.primary === mode ? '0 32px' : '0 24px',
          border: `1px solid ${themeColors.grey20}`,
          visibility: bottomDivider ? 'hidden' : 'visible',
        }}
      />

      <DialogActions
        sx={{
          padding: ModalVariants.primary === mode ? '18px 32px 32px' : '8px 24px 16px',
          display: 'block',
          '&:before': {
            content: '""',
            display: 'block',
            height: '6px',
            ...(bottomDivider && {
              position: 'relative',
              bottom: ModalVariants.primary === mode ? '18px' : '10px',
              margin: ModalVariants.primary === mode ? '0 -32px' : '0 -24px',
              boxShadow: `0 -4px 6px 0 ${themeColors.dropShadow}`,
              zIndex: 1500,
            }),
          },
        }}
      >
        <Box
          sx={{
            ...(ModalVariants.secondary === mode && {
              display: 'flex',
              flexDirection: 'row-reverse',
            }),
          }}
        >
          <Button
            disableRipple
            variant={ModalVariants.primary === mode ? 'contained' : 'text'}
            color={submitButtonColor}
            type="submit"
            disabled={disabled}
            sx={{
              ...(ModalVariants.primary === mode && { minWidth: '125px' }),
              ...(ModalVariants.secondary === mode && {
                padding: '0 0 0 12px',
                lineHeight: 1.2,
                height: 'fit-content',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }),
            }}
            data-testid={submitTestId}
            onClick={handleSubmit}
          >
            <FormattedMessage id={submitButtonLabel} />
          </Button>

          <Button
            disableRipple
            variant="text"
            sx={{
              ...(ModalVariants.primary === mode && { mx: 2.5, px: 1.5 }),
              ...(ModalVariants.secondary === mode && {
                padding: '0 12px 0 12px',
                lineHeight: 1.2,
                height: 'fit-content',
              }),
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            data-testid={cancelTestId}
            onClick={handleCancel}
          >
            <FormattedMessage id={cancelButtonLabel} />
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export { DialogFooter };
