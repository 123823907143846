import { themeColors } from 'theme/themeColors';

export const styles = {
  ml: {
    marginLeft: '8px',
  },
  mr: {
    marginRight: '8px',
  },
  pageBtn: {
    minWidth: '10px',
    width: 'fit-content',
    padding: '0 8px',
    '&:hover': {
      color: themeColors.purpleHover,
    },
  },
  pages: {
    fontFamily: 'Proxima Nova Semibold',
    fontSize: 16,
    color: themeColors.purplePrimary,
    fontWeight: 600,
    '&:hover': {
      color: themeColors.purpleHover,
    },
  },
  selected: {
    color: themeColors.black,
    cursor: 'auto',
    pointerEvents: 'none',
    padding: '0 8px',
  },
};
