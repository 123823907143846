import { Inventory } from '../../../api/deals/types';
import { ProductSpecs } from '../../../static/productSpecs';

const ProductSpecChannelGroups = {
  HighImpact: [
    ProductSpecs.Superheader,
    ProductSpecs.Billboard,
    ProductSpecs.HalfpageAd,
    ProductSpecs.BillboardAndHalfpageAd,
    ProductSpecs.Interscroller,
    ProductSpecs.Interstitial,
    ProductSpecs.MobilePortrait,
  ],
  Native: [ProductSpecs.NativePost],
  OutstreamVideo: [ProductSpecs.OutstreamVideo, ProductSpecs.FramedOutstreamVideo],
  SmallIABFormats: [ProductSpecs.IABStandard, ProductSpecs.IABMobileSmall],
};

export const INVENTORY_CHANNELS: Array<{
  name: string;
  description: string;
  supportedFormats?: ProductSpecs[];
  channelIds: Array<{ id: number; formats?: ProductSpecs[] }>;
}> = [
  {
    name: 'ADR News Media & indebuurt.nl',
    description:
      'DPG Media: ad.nl, bd.nl, bndestem.nl, destentor.nl, ed.nl, gelderlander.nl, indebuurt.nl, pzc.nl, tubantia.nl',
    channelIds: [{ id: 76261 }, { id: 76213 }],
  },
  {
    name: 'Automotive',
    description:
      "DPG Media: autoweek.nl, section 'auto' van ADR News Media en nu.nl, autotrack.nl, autowereld.nl, gaspedaal.nl",
    channelIds: [{ id: 51953 }, { id: 76835 }, { id: 51952 }, { id: 76851 }],
  },
  {
    name: 'Business & finance',
    description: `DPG Media: deondernemer.nl, section 'economie' on DPG Media - Nieuws, Intermediair.nl\nPublishing partners: linda.nl/geld, rtlnieuws.nl`,
    channelIds: [{ id: 51968 }, { id: 76836 }, { id: 76852 }, { id: 51954 }],
  },
  {
    name: 'Entertainment',
    description:
      "DPG Media: story.nl, totaaltv.nl, veronicasuperguide.nl, section 'media & cultuur'/'Entertainment' on DPG Media - News\nPublishing partners: 538.nl, gids.tv, goeievraag.nl section 'entertainment, kijk.nl, radio10.nl, radioveronica.nl, rtlboulevard.nl, rtlnieuws.nl, show.nl, skyradio.nl, televizier.nl, top40.nl, top40hitdossier.nl, tvgids.nl",
    channelIds: [{ id: 61928 }, { id: 76837 }, { id: 61927 }, { id: 76853 }],
  },
  {
    name: 'Fashion & beauty',
    description:
      "DPG Media: section 'mode & beauty' on libelle.nl & margriet.nl, nouveau.nl\nPublishing partners: goeievraag.nl sections 'winkelen' and 'beauty en verzorging', linda.nl, vogue.nl ",
    channelIds: [{ id: 75949 }, { id: 76838 }, { id: 51958 }, { id: 76854 }],
  },
  {
    name: 'Food',
    description:
      "DPG Media: section ‘Cooking & Food’ of ADR News Media, section 'Food and drink' of nu.nl and indebuurt.nl , section 'food' on libelle.nl, section 'eten' on margriet.nl\nPublishing partners: 24kitchen.nl, goeievraag.nl section 'food&drink', linda.nl",
    channelIds: [{ id: 51970 }, { id: 76839 }, { id: 51957 }, { id: 76855 }],
  },
  {
    name: 'Health',
    description:
      "DPG Media: section 'feeling good' on libelle.nl, section 'health' on margriet.nl & nu.nl, section healh on ADR, section 'health' on nouveau.nl\nPublishing partners: goeievraag.nl section 'health', linda.nl/health",
    channelIds: [{ id: 51972 }, { id: 76840 }, { id: 51959 }, { id: 76856 }],
  },
  {
    name: 'High Viewability',
    description:
      'You only target positions with an average viewability of 80% or higher.',
    channelIds: [
      { id: 77675, formats: ProductSpecChannelGroups.HighImpact },
      { id: 77676, formats: ProductSpecChannelGroups.HighImpact },
      { id: 77677, formats: ProductSpecChannelGroups.HighImpact },
      { id: 77678, formats: ProductSpecChannelGroups.HighImpact },

      { id: 81511, formats: ProductSpecChannelGroups.Native },
      { id: 81512, formats: ProductSpecChannelGroups.Native },
      { id: 81513, formats: ProductSpecChannelGroups.Native },
      { id: 81514, formats: ProductSpecChannelGroups.Native },

      { id: 81408, formats: ProductSpecChannelGroups.OutstreamVideo },
      { id: 81409, formats: ProductSpecChannelGroups.OutstreamVideo },

      { id: 77637, formats: ProductSpecChannelGroups.SmallIABFormats },
      { id: 76850, formats: ProductSpecChannelGroups.SmallIABFormats },
      { id: 77636, formats: ProductSpecChannelGroups.SmallIABFormats },
      { id: 76865, formats: ProductSpecChannelGroups.SmallIABFormats },
    ],
    supportedFormats: [
      ...ProductSpecChannelGroups.HighImpact,
      ...ProductSpecChannelGroups.Native,
      ...ProductSpecChannelGroups.OutstreamVideo,
      ...ProductSpecChannelGroups.SmallIABFormats,
    ],
  },
  {
    name: 'Home deco',
    description:
      "DPG Media: vtwonen, section 'wonen' on channel Women and DPG Media - Nieuws, Indebuurt.nl section 'wonen'\nPublishing partners: goeievraag.nl section 'wonen', linda.nl/wonen",
    channelIds: [{ id: 51973 }, { id: 76841 }, { id: 51964 }, { id: 76857 }],
  },
  {
    name: 'Men',
    description: 'DPG Media: autoweek.nl, sportnieuws.nl, nusport.nl',
    channelIds: [{ id: 51971 }, { id: 76842 }, { id: 51962 }, { id: 76858 }],
  },
  {
    name: 'News',
    description:
      'DPG Media: ad.nl, bd.nl, bndestem.nl, destentor.nl, ed.nl, gelderlander.nl, indebuurt.nl, nu.nl, parool.nl, pzc.nl, trouw.nl, tubantia.nl, volkskrant.nl\nPublishing partners: hartvannederland.nl, linda.nl/news, rtlnieuws.nl',
    channelIds: [{ id: 76859 }, { id: 76843 }, { id: 76844 }, { id: 75946 }],
  },
  {
    name: 'Parenting',
    description:
      "DPG Media: oudersvannu.nl\nPublishing partners: goeievraag.nl section 'parents&children', kekmama.nl, linda.nl/parenting, mamamagazine.nl, mamaplaats.nl",
    channelIds: [{ id: 51956 }, { id: 76860 }, { id: 75948 }, { id: 76845 }],
  },
  {
    name: 'Radio & music',
    description:
      'Publishing partners: 538.nl, radio10.nl,radioveronica.nl, skyradio.nl, top40.nl, top40hitdossier.nl',
    channelIds: [{ id: 79622 }],
  },
  {
    name: 'Sport',
    description:
      "DPG Media: section ‘sport’ of ADR News Media, nu.nl, volkskrant.nl and parool.nl, sportnieuws.nl, nusport.nl\nPublishing partners: goeievraag.nl section 'sport', rtlnieuws.nl",
    channelIds: [{ id: 76861 }, { id: 51969 }, { id: 76846 }, { id: 51961 }],
  },
  {
    name: 'Tech',
    description:
      "DPG Media: section 'tech' on DPG Media - News\nPublishing partner: rtlnieuws.nl",
    channelIds: [{ id: 76862 }, { id: 51967 }, { id: 76847 }, { id: 51951 }],
  },
  {
    name: 'Travel & weather',
    description:
      "DPG Media: section 'holiday' on nu.nl, section 'travel' on ADR, libelle.nl, margriet.nl\nPublishing partners: goeievraag.nl sections 'holiday' and 'weather', linda.nl/travel, section 'weather' on startpagina.nl, weer.nl",
    channelIds: [{ id: 76863 }, { id: 51974 }, { id: 76848 }, { id: 51963 }],
  },
  {
    name: 'TV & Film',
    description:
      "DPG Media: nu.nl section 'tvgids', totaaltv.nl, veronicasuperguide.nl\nPublishing partners: gids.tv, kijk.nl, televizier.nl, tvgids.nl",
    channelIds: [{ id: 77996 }, { id: 77997 }, { id: 77998 }, { id: 77999 }],
  },
  {
    name: 'Vertical video',
    description: 'Outstream video',
    channelIds: [{ id: 78592 }, { id: 81783 }],
    supportedFormats: [ProductSpecs.VerticalOutstreamVideo],
  },
  {
    name: 'VTP News Media',
    description: 'DPG Media: volkskrant.nl, trouw.nl, parool.nl,',
    channelIds: [{ id: 76262 }, { id: 75837 }],
  },
  {
    name: 'Women',
    description:
      'DPG Media: flaironline.nl, libelle.nl, margriet.nl, nouveau.nl, forum.viva.nl\nPublishing partners: linda.nl, vogue.nl',
    channelIds: [{ id: 76864 }, { id: 51965 }, { id: 76849 }, { id: 51938 }],
  },
];

export const INVENTORY_SITES_MAX = 2;
export const INVENTORY_SITES: string[] = [
  'ad.nl',
  'ariadneathome.nl',
  'autotrack.nl',
  'autoweek.nl',
  'autowereld.nl',
  'bndestem.nl',
  'bd.nl',
  'deondernemer.nl',
  'destentor.nl',
  'eigenhuiseninterieur.nl',
  'ed.nl',
  'flair.nl',
  'gaspedaal.nl',
  'gelderlander.nl',
  'goeievraag.nl',
  'hartvannederland.nl',
  'huislijn.nl',
  'indebuurt.nl',
  'intermediair.nl',
  'kekmama.nl',
  'kijk.nl',
  'libelle.nl',
  'linda.nl',
  'mamaplaats.nl',
  'margriet.nl',
  'nouveau.nl',
  'nu.nl',
  'oudersvannu.nl',
  'parool.nl',
  'pzc.nl',
  'reclamefolder.nl',
  'scholieren.com',
  'shownieuws.nl',
  'startpagina.nl',
  'story.nl',
  'veronicasuperguide.nl',
  'televizier.nl',
  'top40.nl',
  'totaaltv.nl',
  'trouw.nl',
  'tubantia.nl',
  'tvgids.nl',
  'vandaaginside.nl',
  'forum.viva.nl',
  'volkskrant.nl',
  'vtwonen.nl',
  '24kitchen.nl',
  '538.nl',
  'mamamagazine.nl',
  'radio10.nl',
  'rtlboulevard.nl',
  'rtlnieuws.nl',
  'schoolbank.nl',
  'skyradio.nl',
  'sportnieuws.nl',
  'vogue.nl',
].sort();

export const RON_DPG_INVENTORY: Inventory = {
  type: 'channel',
  value: 'All DPG Media inventory',
  channelIds: [61971, 52316],
};

export const RON_PPN_INVENTORY: Inventory = {
  type: 'channel',
  value: 'All Publishing partner network inventory',
  channelIds: [76834, 62028],
};
