import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/queryKeys';
import { updateUserEmail } from 'api/users';
import { NotificationMessage, useNotification } from 'notifications';

import { UserUpdateEmailRequest } from '../types';

export const useUpdateUserEmail = () => {
  const cache = useQueryClient();
  const sendNotification = useNotification({});

  return useMutation<void, AxiosError, UserUpdateEmailRequest>(updateUserEmail, {
    onSuccess: (_, data) => {
      cache.invalidateQueries([queryKeys.getUsers]);
      cache.invalidateQueries([queryKeys.getUser, data.user.id]);

      sendNotification({
        type: 'success',
        message: (
          <NotificationMessage
            id="user.manage.update_email.message"
            values={[{ key: 'email', value: data.email }]}
          />
        ),
      });
    },
  });
};
