import * as React from 'react';
import { useMemo } from 'react';

import { PaginationRenderItemParams } from '@mui/lab/Pagination/Pagination';
import { SxProps, Typography } from '@mui/material';

import { Button } from 'components/button';
import { Icon, IconName } from 'components/icon';
import { themeColors } from 'theme/themeColors';

import { styles } from './styles';

interface Props {
  params: PaginationRenderItemParams;
  smallNavButton?: boolean;
}

interface NavButtonProps {
  labelId?: string;
  startIconName?: IconName;
  endIconName?: IconName;
  sx?: SxProps;
}

interface ItemProps {
  sx: SxProps;
  content: string | number | null;
}

export const PageItem: React.FC<Props> = ({ params, smallNavButton = false }) => {
  const { type, disabled, onClick, page, selected } = params;

  const isPrevious = type === 'previous';

  const navButtonProps: NavButtonProps = isPrevious
    ? {
        startIconName: 'arrow-left',
        labelId: 'button.pagination.previous',
      }
    : {
        endIconName: 'arrow-right',
        labelId: 'button.pagination.next',
      };

  const smallNavButtonIcon: React.ReactNode = useMemo(
    () => (
      <Icon
        name={isPrevious ? 'arrow-left' : 'arrow-right'}
        width={24}
        height={24}
        color={themeColors.purplePrimary}
      />
    ),
    [isPrevious]
  );

  const ItemRenderer: React.FC<ItemProps> = ({ sx, content }) => {
    return (
      <Button
        variant="text"
        className={type}
        sx={styles.pageBtn}
        onClick={onClick as () => void}
      >
        <Typography variant="h4" sx={sx}>
          {content}
        </Typography>
      </Button>
    );
  };

  if (type === 'previous' || type === 'next') {
    return (
      <Button
        variant="outlined"
        className={type}
        disabled={disabled}
        sx={styles.btnMargin(isPrevious, smallNavButton)}
        onClick={onClick as () => void}
        {...(smallNavButton ? { children: smallNavButtonIcon } : navButtonProps)}
      />
    );
  }

  if (type === 'end-ellipsis' || type === 'start-ellipsis') {
    return (
      <Typography variant="h4" sx={styles.pages}>
        {'...'}
      </Typography>
    );
  }

  if (selected && type === 'page') {
    return (
      <Typography variant="h4" sx={styles.selected}>
        {page}
      </Typography>
    );
  }

  return <ItemRenderer sx={styles.pages} content={page} />;
};
