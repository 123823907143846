import { themeColors } from 'theme/themeColors';

export const styles = {
  formControl: {
    minWidth: 120,

    '& .MuiSelect-icon': {
      fill: themeColors.black,
    },
  },
  select: {
    width: '225px',
    borderRadius: '3px',
    height: '44px',
  },
};
