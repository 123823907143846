import { RootCategories } from '../../components/deal-form-item/targeting-field/types';

export enum State {
  Active = 'ACTIVE',
  Requested = 'REQUESTED',
  Error = 'ERROR',
  WaitingForFeedback = 'WAITING',
  Draft = 'DRAFT',
}

export type Deal = Optional<DealAspects> & {
  id: string;
  dealId?: string;
  dealName?: string;
  state: State;
  creationDate: number;
  external?: boolean;
  ownedBy?: string;
};

export type Device = 'desktop' | 'tablet' | 'mobile';

export type Environment = 'web' | 'app';

export type DealAspects = {
  agency: string;
  advertiser: string;
  buyer: Buyer;
  inventory?: Inventory[] | null;
  targeting?: Targeting | null;
  format: string;
  devices: Device[];
  environments: Environment[];
  floorPrice?: number;
  comments?: Comment[];
  ssp?: string;
};

export type Inventory = InventoryChannel | InventorySite;

export type InventoryChannel = {
  type: 'channel';
  value: string;
  channelIds: number[];
};

export type InventorySite = {
  type: 'site';
  value: string;
};

export type Buyer = {
  dsp: string;
  seat: string;
};

export type Comment = {
  creationDate: string;
  writtenBy: { id: string; firstName: string; lastName: string };
  message: string;
};

export interface GetDealsResult {
  deals: Array<Deal>;
  meta: {
    totalElements: number;
  };
}

export type CreateDealData = Optional<DealAspects> & { ownedBy: string };

export type EditDealData = Optional<DealAspects> & {
  id: Deal['id'];
  dealName?: Deal['dealName'];
};

export type EditDealIdData = Optional<DealAspects> & {
  id: Deal['id'];
  dealId: Deal['dealId'];
};

export type ProductSpec = {
  name: string;
  format: string;
  devices: Device[];
  environments: Environment[];
  priceFloor: number;
};

export type Email = `${string}@${string}`;

export type Targeting = {
  [key in RootCategories]?: TargetingItem[];
};

export type TargetingItem = {
  id: string;
  label: string;
  children?: TargetingItem[];
  xandrSegmentCode?: string;
  xandrIdNl?: number;
  xandrIdBe?: number;
};
