import { theme } from 'theme';

export const styles = {
  hideButton: {
    paddingLeft: '12px',
    marginRight: 0,
    marginBottom: '2px',
    color: theme.palette.grey[600],
  },
  hideButtonText: {
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.grey[600],
  },
};
