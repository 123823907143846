import React from 'react';

import { SvgIcon } from '@mui/material';

type Props = {
  color: string;
  sx?: React.CSSProperties;
};
const StatusIcon: React.FC<Props> = ({ color, sx = {} }) => (
  <SvgIcon sx={sx}>
    <circle fill={color} cx="12" cy="12" r="4" />
  </SvgIcon>
);

export { StatusIcon };
