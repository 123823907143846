import { theme } from '../../theme';

export const styles = {
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
};
