import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { default as MuiDialog } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Icon } from 'components/icon';
import { themeColors } from 'theme/themeColors';

import { styles } from './styles';

import { Agency } from '../../api/agencies/types';
import { User } from '../../api/users/types';
import { RequestAdvertisersBuyers } from '../../features/users/manage-user/components/request-advertisers-buyers/RequestAdvertisersBuyers';
import { theme } from '../../theme';
import { LoadingOverlay } from '../loading-overlay';

export interface DialogProps {
  user: User;
  open: boolean;
  setOpen: (open: boolean) => void;
  agencyToEdit?: Agency;
}

const AddBuyerOrAdvertiserDialog: React.FC<DialogProps> = ({ user, open, setOpen }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <MuiDialog open={open} maxWidth={false}>
      <DialogContent>
        {isSubmitting && (
          <Backdrop
            open={true}
            style={{
              zIndex: theme.zIndex.drawer + 1,
            }}
          >
            <LoadingOverlay />
          </Backdrop>
        )}
        <Typography variant="h2" sx={styles.title}>
          <FormattedMessage id="user.manage.requestAdvertisersBuyers.dialog.title" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id="user.manage.requestAdvertisersBuyers.dialog.description" />
        </Typography>
        <Box>
          <Icon
            name="close"
            width={24}
            height={24}
            color={themeColors.purplePrimary}
            sx={styles.closeButton}
            onClick={() => setOpen(false)}
          />
        </Box>
        <RequestAdvertisersBuyers
          user={user}
          setOpen={setOpen}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
        />
      </DialogContent>
    </MuiDialog>
  );
};

export { AddBuyerOrAdvertiserDialog };
