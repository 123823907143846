import React, { useState } from 'react';
import { useQuery } from 'react-query';

import Backdrop from '@mui/material/Backdrop';

import { Agency, GetAgenciesResult } from 'api/agencies/types';
import { User } from 'api/users/types';

import { getAgencies } from '../../api/agencies';
import { queryKeys } from '../../api/queryKeys';
import { theme } from '../../theme';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { Button } from '../button';
import { SelectField } from '../form/fields/select-field';
import { LoadingOverlay } from '../loading-overlay';
import { ManageAgencyDialog } from '../manage-agency-dialog/ManageAgencyDialog';

export type Props = {
  user: User;
  onChange(agencies: Agency[]): void;
};

export const AgencySelect: React.FC<Props> = ({ user, onChange }) => {
  const [isCreatingAgency, setIsCreatingAgency] = useState(false);
  const ability = useAbility();
  const { data: agencies, isLoading } = useQuery<GetAgenciesResult>(
    queryKeys.getAgencies,
    getAgencies
  );

  if (isLoading) {
    return (
      <Backdrop
        open={true}
        style={{
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <LoadingOverlay />
      </Backdrop>
    );
  }

  return (
    <>
      <SelectField
        displayEmpty
        fullWidth
        multiple={true}
        value={user.agencies ?? []}
        options={
          agencies?.agencies.map((a) => ({
            value: a,
            label: a.name,
          })) ?? []
        }
        variant="outlined"
        onChange={(e) => {
          onChange(e.target.value as Agency[]);
        }}
      />
      {ability.can(Actions.Create, Permissions.Agencies) && (
        <>
          <Button
            labelId="agency.form.createButton.label"
            endIconName="arrow-right"
            color="secondary"
            sx={{ marginTop: '24px' }}
            onClick={() => setIsCreatingAgency(true)}
          />
          {isCreatingAgency && (
            <ManageAgencyDialog open={isCreatingAgency} setOpen={setIsCreatingAgency} />
          )}
        </>
      )}
    </>
  );
};
