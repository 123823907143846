import * as React from 'react';

import useScrollTrigger from '@mui/material/useScrollTrigger';

interface Props {
  targetRef: React.MutableRefObject<HTMLDivElement | undefined>;
  children: React.ReactElement;
}

export const HeaderScroll: React.FC<Props> = ({ children, targetRef }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: targetRef?.current,
  });

  return React.cloneElement(children, {
    topDivider: trigger,
  });
};
