import React, { useEffect, useRef, useState } from 'react';

import { Box, Tooltip } from '@mui/material';

import { styles } from './styles';

import { ReactFCC } from '../../react-util';

interface Props {
  value: string | React.ReactElement;
  computedWidth?: number;
}

export const TextOverflowTooltip: ReactFCC<Props> = ({
  value,
  computedWidth,
  children,
}) => {
  const [isOveflowed, setIsOverflowed] = useState(false);
  const boxRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setIsOverflowed(
      Number(boxRef.current?.scrollWidth) > Number(boxRef.current?.clientWidth)
    );
  }, [computedWidth]);

  return (
    <Tooltip title={value} disableHoverListener={!isOveflowed}>
      <Box ref={boxRef} sx={styles.box}>
        {children || value}
      </Box>
    </Tooltip>
  );
};
