export enum Reasons {
  escapeKeyDown = 'escapeKeyDown',
  backdropClick = 'backdropClick',
}

export type Reason = keyof typeof Reasons;

export enum ModalVariants {
  primary = 'primary',
  secondary = 'secondary',
}

export type ModalVariant = keyof typeof ModalVariants;
