import { themeColors } from '../../../theme/themeColors';

export const styles = {
  title: {
    color: themeColors.greyPrimary,
  },
  fullWidth: {
    width: '100%',
  },
  textValue: {
    width: '100%',
    alignSelf: 'flex-end',
  },
  box: {
    display: 'flex',
  },
};
