import React, { MouseEventHandler } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { themeColors } from 'theme/themeColors';

interface Props {
  handleClose: MouseEventHandler<HTMLButtonElement>;
}

export const DialogCloseIcon: React.FC<Props> = ({ handleClose }) => (
  <IconButton
    disableRipple
    size="small"
    sx={{
      p: 0,
      width: '24px',
      height: '24px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    }}
    onClick={handleClose}
  >
    <CloseIcon
      color="primary"
      sx={{
        fontSize: '24px',
        '&:hover': {
          color: themeColors.purpleHover,
        },
      }}
    />
  </IconButton>
);
