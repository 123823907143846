import React from 'react';
import { useIntl } from 'react-intl';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { themeColors } from 'theme/themeColors';

import { TEST_ID } from '../../constants';
import {
  DetailedTargetingItem,
  Keyword,
  RestrictedRelation,
  TargetingValue,
  TargetingValueItem,
} from '../../types';
import { TargetingItem } from '../targeting-item/TargetingItem';

type Props = {
  formData: TargetingValueItem[];
  data: DetailedTargetingItem[];
  name: string;
  type: RestrictedRelation;
  categoryLabel?: string;
  setScreen(v: (value: string[]) => string[]): void;
  onChange(value: TargetingValue & { keywords?: Keyword[] }): void;
};

const CategoryScreen: React.FC<Props> = ({
  formData,
  data,
  type,
  name,
  setScreen,
  categoryLabel,
  onChange,
}) => {
  const intl = useIntl();
  const backClickHandler = () => setScreen((v) => v.slice(0, -1));
  const nextClickHandler = (i: DetailedTargetingItem) => () =>
    i.children && setScreen((value: string[]) => [...value, i.id]);
  const selectOne = data?.every((c) => c.exclusive) ?? false;

  return (
    <Box>
      <Box
        p={1}
        mt={1}
        display="flex"
        alignItems="center"
        bgcolor={themeColors.grey30}
        style={{ cursor: 'pointer' }}
        data-testid={TEST_ID.backItem}
        onClick={backClickHandler}
      >
        <Box display="flex">
          <ChevronLeftIcon htmlColor={themeColors.grey120} />
        </Box>
        <Box ml={1}>
          <Typography variant="body1">{categoryLabel}</Typography>
        </Box>
      </Box>
      <Box my={3}>
        {data?.map((i) => (
          <TargetingItem
            key={i.id}
            baseName={name}
            type={type}
            config={i}
            formData={formData}
            selectOne={selectOne}
            onClick={nextClickHandler(i)}
            onChange={onChange}
          />
        ))}
      </Box>
    </Box>
  );
};

export { CategoryScreen };
