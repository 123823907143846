export const styles = {
  wrapper: {
    position: 'relative',
  },
  optional: {
    position: 'absolute',
    right: 0,
    fontSize: 12,
  },
  infoModal: {
    cursor: 'pointer',
  },
  descriptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '3px',
    marginBottom: '15px',
  },
};
