import { useState } from 'react';

import { DEFAULT_PER_PAGE_VALUE } from '../show-per-page/constants';

export const usePagination = () => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PER_PAGE_VALUE);

  const handlePageChange = (page: number) => setPage(page);
  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(1);
  };

  return {
    page,
    pageSize,
    onPageChange: handlePageChange,
    onPageSizeChange: handlePageSizeChange,
  };
};
