import React from 'react';

import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { default as MuiDialog } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Icon } from 'components/icon';
import { theme } from 'theme';
import { themeColors } from 'theme/themeColors';

export interface DialogProps {
  triggerEl: React.ReactElement;
  children: React.ReactElement;
  contentPaddingX?: number;
  contentPaddingY?: number;
  contentPaddingBottom?: number;
  disableBackdropClick?: boolean;
  withCrossIcon?: boolean;
  sx?: SxProps;
}

const Dialog: React.FC<DialogProps> = ({
  triggerEl,
  children,
  sx,
  contentPaddingX = 7,
  contentPaddingY = 6,
  contentPaddingBottom,
  withCrossIcon = false,
  disableBackdropClick = true,
}) => {
  const [open, setOpen] = React.useState(false);
  contentPaddingBottom = contentPaddingBottom ?? contentPaddingY;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: React.MouseEvent, reason: string) => {
    if (disableBackdropClick && reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <>
      {React.cloneElement(triggerEl, {
        onClick: () => {
          triggerEl.props.onClick?.();
          handleOpen();
        },
      })}
      <MuiDialog open={open} maxWidth={false} sx={sx} onClose={handleClose}>
        <DialogContent>
          <Box
            paddingY={contentPaddingY}
            paddingX={contentPaddingX}
            pb={contentPaddingBottom}
          >
            {React.cloneElement(children, { onDialogClose: handleClose })}
            {withCrossIcon && (
              <Icon
                name="close"
                width={24}
                height={24}
                color={themeColors.purplePrimary}
                sx={{
                  position: 'absolute',
                  top: theme.spacing(contentPaddingY),
                  right: theme.spacing(contentPaddingX),
                  cursor: 'pointer',
                }}
                onClick={() => setOpen(false)}
              />
            )}
          </Box>
        </DialogContent>
      </MuiDialog>
    </>
  );
};

export { Dialog };
