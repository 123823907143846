import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import { Email, User } from 'api/users/types';
import { ContainerWithTitle } from 'components/elements/container-with-title';
import { TextField } from 'components/form/fields/text-field/TextField';
import { SectionContent } from 'components/section-content';

import { TEST_ID } from './constants';

import { useUpdateUserEmail } from '../../../../../api/users/hooks';
import { UpdateUserFormWrapper } from '../update-user-form-wrapper';

interface Props {
  user: User;
  onCancel: () => void;
}

export const EditEmail: React.FC<Props> = ({ user, onCancel }) => {
  const intl = useIntl();
  const mutation = useUpdateUserEmail();
  const [email, setEmail] = useState('');

  return (
    <Box>
      <UpdateUserFormWrapper
        mutation={mutation}
        onSave={() => {
          mutation.mutate(
            {
              user,
              email: email as Email,
            },
            {
              onSuccess: () => {
                onCancel();
              },
            }
          );
        }}
        onCancel={onCancel}
      >
        <SectionContent size="lg">
          <ContainerWithTitle
            title={intl.formatMessage({ id: 'user.manage.new.email.label' })}
          >
            <TextField
              fullWidth
              name="email"
              data-testid={TEST_ID.email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </ContainerWithTitle>
        </SectionContent>
      </UpdateUserFormWrapper>
    </Box>
  );
};
