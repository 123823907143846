import { themeColors } from '../../theme/themeColors';

export const styles = {
  title: {
    color: themeColors.greyPrimary,
  },
  background: {
    backgroundColor: themeColors.grey20,
    padding: '8px',
    borderRadius: '0px 3px 3px 0px',
    borderLeft: `2px solid ${themeColors.purplePrimary}`,
  },
};
