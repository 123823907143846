import React from 'react';
import { FormattedMessage } from 'react-intl';

import Typography from '@mui/material/Typography';

interface Props {
  totalId: string;
  searchField: string;
  total: number;
  counterTestId?: string;
}

const TableTitle = ({
  counterTestId,
  totalId,
  searchField,
  total,
}: Props): JSX.Element => {
  return (
    <Typography gutterBottom variant="h3" data-testid={counterTestId}>
      {!searchField.length ? (
        <FormattedMessage id={totalId} values={{ total }} />
      ) : (
        <FormattedMessage id="search.total" values={{ total, searchField }} />
      )}
    </Typography>
  );
};

export { TableTitle };
