import { theme } from '../../theme';
import { CONTENT_MAX_WIDTH } from '../content-layout/constants';

export const styles = {
  root: {
    maxWidth: CONTENT_MAX_WIDTH,
    margin: '0 auto',
    width: '100%',
    padding: '0 48px',
    [theme.breakpoints.down('lg')]: {
      padding: '0 24px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },
};
