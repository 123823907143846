import React from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import InputAdornment from '@mui/material/InputAdornment';
import TextFieldBase, { TextFieldProps } from '@mui/material/TextField';

import { convertSxToThemeType } from 'utils';

import { styles } from './styles';

const TextField: React.FC<TextFieldProps> = (props) => {
  const { InputProps, ...rest } = props;

  const updatedProps: TextFieldProps = {
    variant: 'outlined',
    InputProps: InputProps || {
      sx: convertSxToThemeType([styles.root]),
      endAdornment: (
        <InputAdornment position="start">
          <ErrorIcon sx={styles.errorIcon} />
        </InputAdornment>
      ),
    },
    ...rest,
  } as TextFieldProps;

  return <TextFieldBase {...updatedProps} />;
};

export { TextField };
