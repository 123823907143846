import React from 'react';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';
import { SxProps } from '@mui/material';

import { themeColors } from 'theme/themeColors';

interface Props {
  sx?: SxProps;
  isIconHoverAble?: boolean;
}

export const StyledLink = styled(Link)<Props>(({ sx = {}, isIconHoverAble = true }) => ({
  color: themeColors.purplePrimary,
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Proxima Nova Bold',
  fontSize: 14,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    color: themeColors.purpleHover,
    '& svg': {
      fill: themeColors.purpleHover,
      ...(isIconHoverAble && {
        '& g': {
          fill: themeColors.purpleHover,
        },
      }),
    },
  },
  ...(sx as React.CSSProperties),
}));
