import React from 'react';

import { Divider, FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { DealAspects } from 'api/deals/types';

import { INVENTORY_CHANNELS } from './constants';
import { styles } from './styles';

export type Props = {
  inventory: DealAspects['inventory'];
  onChange(inventory: DealAspects['inventory']): void;
};

export const ChannelSelect: React.FC<Props> = ({ inventory, onChange }) => {
  return (
    <>
      {INVENTORY_CHANNELS.map(({ name, description, channelIds }, idx) => {
        const checked = inventory?.some((i) => i.value === name) ?? false;

        return (
          <React.Fragment key={name}>
            <FormControlLabel
              sx={styles.channelCheckbox}
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onChange([
                        ...(inventory ?? []),
                        {
                          type: 'channel',
                          value: name,
                          channelIds: channelIds.map((d) => d.id),
                        },
                      ]);
                    } else {
                      onChange(inventory?.filter((i) => i.value !== name) ?? []);
                    }
                  }}
                />
              }
              label={
                <>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ whiteSpace: 'pre-wrap' }}
                  >
                    {description}
                  </Typography>
                </>
              }
            />
            {idx !== INVENTORY_CHANNELS.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </>
  );
};
