import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { convertSxToThemeType } from 'utils';

import { styles } from './styles';

import { ReactFCC } from '../../../react-util';
import { InfoModal } from '../../info-modal';

interface Props {
  title?: React.ReactNode;
  mb?: number;
  mt?: number;
  ml?: number;
  infoIconSx?: SxProps;
  testId?: string;
  infoModalContent?: ReactElement;
  width?: number;
  paddingIndent?: boolean;
  sx?: SxProps;
  typographySx?: SxProps;
  optional?: boolean;
}

const RowWithTitle: ReactFCC<Props> = ({
  children,
  title,
  testId,
  mt = 4,
  mb = 4,
  ml,
  infoIconSx,
  infoModalContent,
  paddingIndent = true,
  width = 280,
  sx = {},
  typographySx = {},
  optional = false,
  ...rest
}) => {
  const wrapperClass = convertSxToThemeType([styles.wrapper, sx] as Array<SxProps>);

  const optionalLabel = (
    <Typography variant="subtitle1">
      <FormattedMessage id="optional.label" />
    </Typography>
  );

  return (
    <Box {...rest} mt={mt} mb={mb} ml={ml} data-testid={testId} sx={wrapperClass}>
      <Box width={width} sx={styles.titleWrapper}>
        <Typography
          variant="h3"
          color="textPrimary"
          sx={convertSxToThemeType([
            !paddingIndent && styles.flex,
            paddingIndent && styles.padding,
            typographySx,
          ] as Array<SxProps>)}
        >
          {title}
        </Typography>
        {optional && optionalLabel}
        {infoModalContent && (
          <Box
            ml={1}
            sx={convertSxToThemeType([styles.infoModal, infoIconSx] as Array<SxProps>)}
          >
            <InfoModal>{infoModalContent}</InfoModal>
          </Box>
        )}
      </Box>

      {children}
    </Box>
  );
};

export { RowWithTitle };
