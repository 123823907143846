import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ValueLabelContainer from './ValueLabelContainer';

interface Props {
  filterValue: {
    include: string[];
    exclude: string[];
  };
  alwaysShowInclude?: boolean;
  noneLabel?: string;
  disabled?: boolean;
}

const FilterValueLabel: React.FC<Props> = ({
  filterValue,
  alwaysShowInclude = false,
  noneLabel = 'deal.form.item.targeting.default',
  disabled,
}) => (
  <Box style={{ display: 'flex', flexDirection: 'column' }}>
    {!filterValue.include.length && !filterValue.exclude.length && (
      <Typography color={disabled ? 'textSecondary' : 'textPrimary'}>
        <FormattedMessage id={noneLabel} />
      </Typography>
    )}

    {filterValue.include.length > 0 &&
      (filterValue.exclude.length > 0 || alwaysShowInclude ? (
        <Box style={{ display: 'flex' }}>
          <ValueLabelContainer>
            <FormattedMessage
              id="deal.form.item.targeting.filters.action"
              values={{
                action: (
                  <b>
                    <FormattedMessage id="deal.form.item.targeting.filters.include" />
                  </b>
                ),
                value: filterValue.include[0],
              }}
            />
          </ValueLabelContainer>
          {filterValue.include[1] && (
            <Typography component="span" style={{ marginLeft: '5px' }}>
              {filterValue.include[1]}
            </Typography>
          )}
        </Box>
      ) : (
        <Box style={{ display: 'flex' }}>
          <ValueLabelContainer>{filterValue.include[0]}</ValueLabelContainer>
          {filterValue.include[1] && (
            <Typography component="span" style={{ marginLeft: '5px' }}>
              {filterValue.include[1]}
            </Typography>
          )}
        </Box>
      ))}

    {filterValue.exclude.length > 0 && (
      <Box style={{ display: 'flex' }}>
        <ValueLabelContainer>
          <FormattedMessage
            id="deal.form.item.targeting.filters.action"
            values={{
              action: (
                <b>
                  <FormattedMessage id="deal.form.item.targeting.filters.exclude" />
                </b>
              ),
              value: filterValue.exclude[0],
            }}
          />
        </ValueLabelContainer>
        {filterValue.exclude[1] && (
          <Typography component="span" style={{ marginLeft: '5px' }}>
            {filterValue.exclude[1]}
          </Typography>
        )}
      </Box>
    )}
  </Box>
);

export { FilterValueLabel };
