import { Deal, Inventory, TargetingItem } from '../../api/deals/types';

export function canAutomaticallyCreateDealId(deal: Deal): boolean {
  if (deal.inventory?.some((i) => !isSupportedInventory(i))) return false;

  if (
    deal.targeting &&
    !Object.values(deal.targeting).every((t) => t.every(hasTargetingIdentifiers))
  )
    return false;

  return true;
}

function hasTargetingIdentifiers(item: TargetingItem) {
  if (!item.children) {
    return item.xandrIdNl || item.xandrSegmentCode;
  }

  return item.children!.every(hasTargetingIdentifiers);
}

function isSupportedInventory(inventory: Inventory): boolean {
  if (inventory.type === 'channel') {
    return !!inventory.channelIds?.length;
  }

  return true;
}
