import { AbilityBuilder } from '@casl/ability';

import {
  buildAgenciesCriteria,
  buildAgencyUserCriteria,
  buildRoleCriteria,
} from './utils';

import { User } from '../../../api/users/types';
import { Roles } from '../../../utils/keycloak/Keycloak';
import { Actions, Permissions } from '../constants';

export const clientAdminRole = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  can: AbilityBuilder<any>['can'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cannot: AbilityBuilder<any>['cannot'],
  user?: User
): void => {
  const agencyUserCriteria = buildAgencyUserCriteria(user),
    agenciesCriteria = buildAgenciesCriteria(user),
    clientOnlyCriteria = buildRoleCriteria([Roles.ClientAdmin, Roles.ClientUser]),
    clientAgencyOnly = {
      ...agencyUserCriteria,
      ...clientOnlyCriteria,
    };

  can(Actions.View, Permissions.Users, clientAgencyOnly);
  can(Actions.Create, Permissions.Users, clientAgencyOnly);

  can(Actions.View, Permissions.UserInfo, { id: user?.id });
  can(Actions.View, Permissions.UserInfo, clientAgencyOnly);
  can(Actions.Manage, Permissions.UserInfo, { id: user?.id });
  can(Actions.Manage, Permissions.UserInfo, clientAgencyOnly);

  cannot(Actions.Manage, Permissions.DealId);
  can(Actions.View, Permissions.DealId);

  can(Actions.Manage, Permissions.Roles, clientAgencyOnly);

  cannot(Actions.Manage, Permissions.Agencies);
  cannot(Actions.Create, Permissions.Agencies);
  can(Actions.View, Permissions.Agencies, agenciesCriteria);

  cannot(Actions.View, Permissions.AgencyDealManagers);

  can(Actions.Manage, Permissions.UserAgencySettings, clientAgencyOnly);
};
