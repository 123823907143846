import { themeColors } from 'theme/themeColors';

export const styles = ({
  notClickable,
  disableHeaderOverflow,
  selectAllCheckboxHidden,
}: {
  notClickable?: boolean;
  disableHeaderOverflow?: boolean;
  selectAllCheckboxHidden?: boolean;
}) => ({
  root: {
    width: '100%',
    border: 'none',
    color: themeColors.black,
    fontFamily: 'Proxima Nova Regular',
    '& a': {
      color: themeColors.black,
      textDecoration: 'none',
      overflow: 'hidden',
    },
    '& .row-inactive': {
      color: themeColors.grey120,
      '& .MuiTypography-root': {
        color: themeColors.grey120,
      },
    },
    '& .MuiDataGrid-row': {
      '&:nth-of-type(even)': {
        backgroundColor: themeColors.grey20,
      },
      '&.disabled-row': {
        pointerEvents: 'none',
        opacity: '.4',
      },
      '&.unselectable-row': {
        '& .MuiDataGrid-cellCheckbox .MuiCheckbox-root': {
          display: 'none !important',
        },
      },
      '&:not(.unselectable-row)': {
        '&:hover, &.Mui-selected, &.Mui-selected:hover': {
          backgroundColor: notClickable ? 'unset' : themeColors.purple20,
          cursor: notClickable ? 'auto' : 'pointer',
          '&:nth-of-type(even)': {
            backgroundColor: notClickable ? themeColors.grey20 : themeColors.purple20,
          },
        },
      },
    },
    '& .MuiDataGrid-columnHeader': {
      outline: 'none !important',
      '&:hover': {
        '& .MuiDataGrid-columnSeparator--resizable': {
          display: 'flex',
          color: '#c9c9c9',
        },
      },
      ...(selectAllCheckboxHidden
        ? {
            '&.MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              display: 'none !important',
            },
          }
        : {}),
    },
    '& .MuiDataGrid-main, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeaderTitleContainer':
      {
        overflow: disableHeaderOverflow ? 'initial' : 'hidden',
      },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: themeColors.grey120,
      fontWeight: 700,
      fontSize: 14,
    },
    '& .MuiDataGrid-virtualScroller': {
      minHeight: '460px', // No rows overlay size
    },
    '& .MuiDataGrid-overlayWrapperInner': {
      height: 'auto !important',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-cell': {
      border: 'none',
      outline: 'none !important',
    },
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-colCell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-colCell-draggable': {
      justifyContent: 'space-between',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderColor: themeColors.grey20,
    },
    '& .MuiFormControlLabel-root': {
      cursor: notClickable ? 'default' : 'pointer',
    },
    '.boldCell': {
      fontWeight: 'bold',
    },
  },
  pagination: {
    width: 'fit-content',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& .MuiButtonBase-root': {
      color: themeColors.purple80,
      fontWeight: 'bold',
    },
  },
});
