import React from 'react';

import { SvgIcon } from '@mui/material';

import { themeColors } from 'theme/themeColors';

const AscendingIcon: React.FC = () => (
  <SvgIcon>
    <path d="M7 11l4-4 4 4z" fill={themeColors.pink} />
    <path d="M7 13l4 4 4-4z" fill={themeColors.grey120} />
  </SvgIcon>
);

export { AscendingIcon };
