import { createAppRoutes } from 'utils/create-app-routes';

export const API_ENDPOINTS = {
  deals: '/deals',
  deal: '/deal/:id',
  dealId: '/deal/:id/set-deal-id',
  dealCreate: '/deal',
  dealComment: '/deal/:id/comment',
  dealSendToXandr: '/deal/:id/send-to-xandr',
  dealsRequest: '/deals/request',
  dealsImport: '/deals/import',

  users: '/users',
  user: '/user/:id',
  userCreate: '/user',
  userEdit: '/user/:id',
  userChangeEmail: '/user/:id/change-email',
  userResetPassword: '/user/:id/reset-password',
  userSetRole: '/user/:id/set-role',
  userActivate: '/user/:id/activate',
  userDeactivate: '/user/:id/deactivate',
  userActivateToken: '/user/activate',
  validateEmail: '/user/validate-email',
  userResendActivationToken: '/user/:id/resend-activation',
  userSetDefaultAgency: '/user/:id/set-default-agency',

  agencies: '/agencies',
  agencyCreate: '/agency',
  agencyEdit: '/agency/:id',
  requestAgencyAdvertisersBuyers: '/agency/:id/request-buyers-advertisers',
};

export const apiEndpoints = createAppRoutes(API_ENDPOINTS);
