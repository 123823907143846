import { Agency, GetAgenciesResult } from './types';

import { Api } from '../api';
import { Buyer } from '../deals/types';
import { apiEndpoints } from '../endpoints';

// TODO handle agencies server side
export async function getAgencies(): Promise<GetAgenciesResult> {
  const { data } = await Api.get<GetAgenciesResult>(apiEndpoints.agencies());

  return data;
}

export async function createAgency(agency: Agency): Promise<Agency> {
  const { data } = await Api.post<Agency>(apiEndpoints.agencyCreate(), agency);

  return data;
}

export async function editAgency(agency: Agency): Promise<void> {
  await Api.post(apiEndpoints.agencyEdit({ id: agency.id }), agency);
}

export function filterAgencies(
  agenciesResult: GetAgenciesResult,
  searchValue?: string
): GetAgenciesResult {
  const searchEntries = searchValue?.split(',').map((t) => t.trim()) ?? [];
  const filteredAgencies = agenciesResult.agencies.filter((agency) =>
    searchFilter(agency, searchEntries)
  );

  return {
    ...agenciesResult,
    agencies: filteredAgencies,
    meta: {
      ...agenciesResult.meta,
      totalElements: filteredAgencies.length,
    },
  };
}

function searchFilter(agency: Agency, search: string[]) {
  if (search.length === 0) {
    return true;
  }

  return search.some(
    (s) =>
      agency.name.toLowerCase().includes(s.toLowerCase()) ||
      agency.salesForceId?.toLowerCase().includes(s.toLowerCase())
  );
}

export async function requestAdvertisersAndBuyers(
  agency: Agency,
  buyers?: Buyer[],
  advertisers?: string[]
) {
  await Api.post(apiEndpoints.requestAgencyAdvertisersBuyers({ id: agency.id }), {
    buyers,
    advertisers,
  });
}
