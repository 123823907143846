import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { styles } from 'components/deals-table-layout/styles';
import { styles as tableStyles } from 'components/table/common-styles';

import { AgencyActions } from './components/AgencyActions';
import { Props } from './types';

import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { Button } from '../button';
import { TableTitle } from '../deals-table-layout';
import { NoRowsOverlay } from '../deals-table-layout/components/NoRowsOverlay';
import { TableLayoutTitle } from '../deals-table-layout/components/TableLayoutTitle';
import { LoadingOverlay } from '../loading-overlay';
import { ManageAgencyDialog } from '../manage-agency-dialog/ManageAgencyDialog';
import { AscendingIcon } from '../sort-icon/ascending-icon/AscendingIcon';
import { DescendingIcon } from '../sort-icon/descending-icon/DescendingIcon';
import { UnsortedIcon } from '../sort-icon/unsorted-icon/UnsortedIcon';
import { Pagination } from '../table/pagination/Pagination';

const AgenciesTableLayout: React.FC<Props> = ({
  data,
  isLoading,
  SearchInput,
  searchValue,
  page,
  pageSize,
  onPageSizeChange,
  onPageChange,
  sortModel,
  onSortModelChange,
}): JSX.Element => {
  const [isCreatingAgency, setIsCreatingAgency] = useState(false);
  const sxStyles = styles({
    notClickable: false,
    disableHeaderOverflow: false,
    selectAllCheckboxHidden: false,
  });
  const intl = useIntl();
  const ability = useAbility();
  const columns: Array<GridColDef> = [
    {
      field: 'name',
      minWidth: 200,
      headerName: intl.formatMessage({ id: 'agencies.overview.column.name' }),
      valueFormatter: ({ value }) => value ?? '-',
    },
    {
      field: 'salesForceId',
      headerName: intl.formatMessage({ id: 'agencies.overview.column.salesForce' }),
      flex: 2,
      valueFormatter: ({ value }) => value ?? '-',
    },
    {
      field: 'actions',
      headerName: intl.formatMessage({ id: 'deals.overview.column.actions' }),
      minWidth: 120,
      type: 'actions',
      getActions: ({ row }) => [<AgencyActions agency={row} />],
    },
  ];

  return (
    <>
      <TableLayoutTitle
        titleId="agencies.overview.title"
        descriptionId={
          ability.can(Actions.Manage, Permissions.Agencies)
            ? 'agencies.overview.description.edit'
            : 'agencies.overview.description.view'
        }
      />
      {ability.can(Actions.Create, Permissions.Agencies) && (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button
              endIconName="arrow-right"
              labelId="agencies.overview.button.new"
              onClick={() => setIsCreatingAgency(true)}
            />
          </Grid>
        </Grid>
      )}
      {isCreatingAgency && (
        <ManageAgencyDialog open={isCreatingAgency} setOpen={setIsCreatingAgency} />
      )}
      <Box mt={4} sx={tableStyles.grid}>
        <TableTitle
          totalId="agencies.overview.table.title"
          searchField={searchValue}
          total={data?.meta.totalElements ?? 0}
        />
        <Grid item sx={tableStyles.searchHeader}>
          {SearchInput}
        </Grid>
        <DataGrid
          autoHeight
          rows={data?.agencies ?? []}
          columns={columns}
          loading={isLoading}
          sx={sxStyles.root}
          rowHeight={72}
          columnHeaderHeight={60}
          hideFooter={true}
          disableColumnMenu={true}
          slots={{
            columnSortedDescendingIcon: DescendingIcon,
            columnSortedAscendingIcon: AscendingIcon,
            columnUnsortedIcon: UnsortedIcon,
            loadingOverlay: LoadingOverlay,
            noRowsOverlay: () => (
              <NoRowsOverlay
                title="agencies.overview.noRows.title"
                description="agencies.overview.noRows.description"
              />
            ),
          }}
          rowCount={data?.meta?.totalElements ?? 0}
          paginationModel={{ page: page - 1, pageSize }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'desc' }],
            },
          }}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
        />
        <Pagination
          page={page}
          size={pageSize}
          itemsLength={data?.meta.totalElements ?? 0}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  );
};

export { AgenciesTableLayout };
