import React from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { CircularProgress } from '@mui/material';

import { AgentUserPreferencesFields } from './AgentUserPreferencesFields';

import { getAgencies } from '../../../api/agencies';
import { GetAgenciesResult } from '../../../api/agencies/types';
import { queryKeys } from '../../../api/queryKeys';
import { User } from '../../../api/users/types';
import { getUserRole, Roles } from '../../../utils/keycloak/Keycloak';
import { AgencyField } from '../../agency-field/AgencyField';
import { RowWithTitle } from '../../elements/row-with-title';

export type Props = {
  user: User;
  onChange(user: User): void;
};

export const UserPreferencesForm: React.FC<Props> = ({ user, onChange }) => {
  const intl = useIntl();
  const { data, isLoading } = useQuery<GetAgenciesResult>(
    queryKeys.getAgencies,
    getAgencies
  );

  return (
    <>
      <RowWithTitle
        title={intl.formatMessage({ id: 'user.form.preferences.agency' })}
        mt={3}
        mb={3}
      >
        {isLoading ? (
          <CircularProgress
            color="error"
            sx={{
              marginLeft: '16px',
              marginBottom: '16px',
              width: '25px !important',
              height: '25px !important',
            }}
          />
        ) : (
          <AgencyField user={user} agencies={data?.agencies ?? []} onChange={onChange} />
        )}
      </RowWithTitle>
      {getUserRole(user) === Roles.ClientUser && (
        <AgentUserPreferencesFields user={user} onChange={onChange} />
      )}
    </>
  );
};
