import debounce from 'lodash/debounce';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import { Box, SxProps } from '@mui/material';
import TextField from '@mui/material/TextField';

import { Icon } from 'components/icon';
import {
  ANIMATION_TIMEOUT,
  DEBOUNCE_TIMEOUT,
  MIN_SEARCH_LENGTH,
} from 'components/table/constants';
import { convertSxToThemeType } from 'utils';

import { CrossButton, styles } from './styles';

interface Props {
  placeholder?: string;

  setSearchValue(searchValue: string): void;

  setCachedValue?(searchValue: string): void;
}

export const AnimatedSearchInput: React.FC<Props> = ({
  setSearchValue,
  setCachedValue = () => null,
  placeholder = 'Search',
}) => {
  let animationTimeout: ReturnType<typeof setTimeout>;
  const [value, setValue] = useState<string>('');
  const [isOpenAnimate, setIsOpenAnimate] = useState<boolean>(false);
  const [sx, setSx] = useState<SxProps>(styles.openWithAnimation);
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);

  const onSearchInputBlur = () => {
    if (!value) {
      clearTimeout(animationTimeout);

      setSx(styles.closeWithAnimation);

      animationTimeout = setTimeout(() => {
        setIsOpenAnimate(false);
        setSx(styles.openWithAnimation);
      }, ANIMATION_TIMEOUT);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandler = useCallback(
    debounce((searchValue, oldValue) => {
      const validatedValue = searchValue.length >= MIN_SEARCH_LENGTH ? searchValue : '';
      setSearchValue(validatedValue);
      setCachedValue(oldValue);
    }, DEBOUNCE_TIMEOUT),
    []
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    debounceHandler(event.target.value, value);
    setValue(event.target.value);
  };

  const resetValue = () => {
    setValue('');
    debounceHandler('', value);
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Box component="div" sx={styles.searchBarContainer}>
      {isOpenAnimate && (
        <Box>
          <TextField
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            inputRef={inputRef}
            sx={convertSxToThemeType([styles.searchInput, sx])}
            value={value}
            variant="outlined"
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onSearchInputBlur}
          />
          {value && <CrossButton onClick={resetValue} />}
        </Box>
      )}
      {!isOpenAnimate && (
        <Box sx={styles.searchIcon}>
          <Icon
            name="nav-search"
            width={24}
            height={24}
            onClick={() => setIsOpenAnimate(true)}
          />
        </Box>
      )}
    </Box>
  );
};
