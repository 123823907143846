import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/queryKeys';
import { NotificationMessage, useNotification } from 'notifications';

import { getFullName } from '../../../features/users/utils';
import { updateUserRole } from '../index';
import { UserUpdateRoleRequest } from '../types';

export const useUpdateUserRole = () => {
  const cache = useQueryClient();
  const sendNotification = useNotification({});

  return useMutation<void, AxiosError, UserUpdateRoleRequest>(updateUserRole, {
    onSuccess: (_, data) => {
      cache.invalidateQueries([queryKeys.getUsers]);
      cache.invalidateQueries([queryKeys.getUser, data.user.id]);

      sendNotification({
        type: 'success',
        message: (
          <NotificationMessage
            id="user.manage.updated.message"
            values={[{ key: 'username', value: getFullName(data.user) }]}
          />
        ),
      });
    },
  });
};
