import React from 'react';
import { LinkProps } from 'react-router-dom';

import { SxProps } from '@mui/material';

import { Icon } from 'components/icon';
import { themeColors } from 'theme/themeColors';

import { StyledLink } from './styles';

interface Props extends LinkProps {
  withIcon?: boolean;
  icon?: React.ReactElement;
  sx?: SxProps;
  isIconHoverAble?: boolean;
}

export const Link: React.FC<Props> = ({ children, withIcon, icon, ...rest }) => {
  const linkIcon = icon || (
    <Icon name="arrow-right" width={20} height={20} color={themeColors.purplePrimary} />
  );

  return (
    <StyledLink {...rest}>
      {children}
      {withIcon && linkIcon}
    </StyledLink>
  );
};
