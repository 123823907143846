import { Relation, RestrictedRelation, TargetingValueItem } from '../../types';

export const buildTargetingValue = (
  name: string,
  prevValue: TargetingValueItem[],
  valueToSet: RestrictedRelation
) => {
  const updateItem = (
    names: string[],
    value?: TargetingValueItem[]
  ): TargetingValueItem[] | undefined => {
    const name = names[0];

    if (names.length <= 1) {
      const isExclusive =
        value?.some((item) => item.id === name && item.exclusive) ?? false;

      return value?.map((item) => {
        if (item.id === name) {
          return toggleItem(item, valueToSet);
        }

        if (isExclusive) {
          return {
            ...item,
            relation: undefined,
            children: deselectAllChildren(item.children),
          };
        }

        return { ...item };
      });
    }

    return value?.map((item) => {
      if (item.id === name) {
        const nextName = names.slice(1);

        return {
          ...item,
          children: updateItem(nextName, item.children),
        };
      }

      return { ...item };
    });
  };

  const names = name.split('.');
  const updatedItems = updateItem([...names], prevValue);

  return updateParent([...names], updatedItems);
};

const toggleItem = (
  item: TargetingValueItem,
  valueToSet: Relation
): TargetingValueItem => {
  const relation = item.relation !== valueToSet ? valueToSet : undefined;

  return {
    ...item,
    relation,
    children: relation
      ? selectAllChildren(valueToSet, item.children)
      : deselectAllChildren(item.children),
  };
};

const validateStatus = (
  names: string[],
  data?: TargetingValueItem[]
): TargetingValueItem[] | undefined => {
  const name = names[0];
  return data?.map((i) => {
    if (i.id === name) {
      if (names.length <= 1) {
        if (!i?.children) {
          return { ...i };
        }
        const isUnselected = !i.children?.filter((ch) => ch.relation).length;
        const isIncluded =
          i.children?.filter((ch) => ch.relation === Relation.INCLUDED).length ===
          i.children?.length;
        const isExcluded =
          i.children?.filter((ch) => ch.relation === Relation.EXCLUDED).length ===
          i.children?.length;
        const isIntermediate = !isUnselected && !isExcluded && !isIncluded;
        let relation: Relation | undefined;
        if (isIncluded) {
          relation = Relation.INCLUDED;
        }
        if (isExcluded) {
          relation = Relation.EXCLUDED;
        }
        if (isIntermediate) {
          relation = Relation.INTERMEDIATE;
        }
        return { ...i, relation };
      } else {
        return {
          ...i,
          children: validateStatus(names.slice(1), i.children),
        };
      }
    }
    return { ...i };
  });
};

const updateParent = (
  names: string[],
  data?: TargetingValueItem[]
): TargetingValueItem[] | undefined => {
  let updatedData = data;
  let counter = names.slice(0, -1);
  while (counter.length) {
    updatedData = validateStatus(counter, updatedData);
    counter = counter.slice(0, -1);
  }

  return updatedData;
};

export const deselectAllChildren = (
  data?: TargetingValueItem[]
): TargetingValueItem[] | undefined =>
  data?.map((i) => {
    const { relation, ...item } = i;
    return {
      ...item,
      children: deselectAllChildren(item.children),
    };
  });

export const selectAllChildren = (
  value: Relation,
  data?: TargetingValueItem[]
): TargetingValueItem[] | undefined =>
  data?.map((i) => ({
    ...i,
    relation: value,
    children: selectAllChildren(value, i.children),
  }));

export const isIntermediateDisabled = (
  type: Relation,
  data?: TargetingValueItem[]
): boolean =>
  !!data?.find((i) => {
    if (i.relation && i.relation !== Relation.INTERMEDIATE && i.relation !== type) {
      return true;
    } else if (i.children) {
      return isIntermediateDisabled(type, i.children);
    }
    return false;
  });
