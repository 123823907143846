export const TEST_ID = {
  appFooter: 'appFooter',
  appFooterLogo: 'appFooterLogo',
  appFooterContacts: 'appFooterContacts',
  appFooterHelpLinks: 'appFooterHelpLinks',
  appFooterFollowUs: 'appFooterFollowUs',
  appFooterFollowUsLinks: 'appFooterFollowUsLinks',
  appFooterContactsAddress: 'appFooterContactsAddress',
  sectionTitle: 'sectionTitle',
  emptyState: 'emptyState',
  topNavigation: 'topNavigation',
  externalLink: 'externalLink',
  icon: 'icon',
  appContent: 'appContent',
  actionIcon: 'actionIcon',
  appHeaderLogo: 'appHeaderLogo',
  logout: 'logout',
  login: 'login',
  nextButton: 'nextButton',
  sectionContent: 'sectionContent',
  cancelButton: 'cancelButton',
  solidButton: 'solidButton',
  showMoreButton: 'showMoreButton',
  passwordField: 'passwordField',
  hideButton: 'hideButton',
  loader: 'loader',
  submitButton: 'submitButton',
  tagContainer: 'tagContainer',
  closeIcon: 'closeIcon',
  loaderContainer: 'loaderContainer',
  breadcrumbs: 'breadcrumbs',
  saveButton: 'saveButton',
  pureButton: 'pureButton',
};
