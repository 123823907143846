import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { DealAspects } from 'api/deals/types';

import { InventoryDialogContent } from './InventoryDialogContent';
import { styles } from './styles';

import { Dialog, DialogContainer, DialogFooter, DialogTitle } from '../../dialog2';
import { ModalVariants } from '../../dialog2/types';
import { RowWithTitle } from '../../elements/row-with-title';
import { Icon } from '../../icon';

export type Props = {
  inventory?: DealAspects['inventory'] | null;
  onChange(inventory: DealAspects['inventory']): void;
};

export const InventoryField: React.FC<Props> = ({ inventory, onChange }) => {
  const intl = useIntl();
  const [currentInv, setCurrentInv] = useState(inventory ?? []);

  return (
    <RowWithTitle
      title={intl.formatMessage({ id: 'deal.form.item.inventory' })}
      mt={3}
      mb={3}
    >
      <Grid container justifyContent="flex-start" alignItems="center">
        <Typography variant="body1">
          {inventory?.length ? (
            inventory!.map(({ value }) => value).join(', ')
          ) : (
            <FormattedMessage id="deal.form.item.inventory.default" />
          )}
        </Typography>
        <Dialog
          mode={ModalVariants.secondary}
          triggerEl={
            <IconButton
              aria-label="more"
              aria-haspopup="true"
              sx={styles.editIcon}
              size="large"
            >
              <Icon name="edit" width={24} height={24} />
            </IconButton>
          }
        >
          {({ onDialogClose }) => {
            const handleSubmit = () => {
              onChange(currentInv.length > 0 ? currentInv : null);
              onDialogClose();
            };
            const handleClose = () => {
              setCurrentInv(inventory ?? []);
              onDialogClose();
            };

            return (
              <DialogContainer
                header={
                  <DialogTitle
                    headerLabel="deal.inventory.dialog.title"
                    subHeaderLabel="deal.inventory.dialog.description"
                    handleClose={handleClose}
                  />
                }
                footer={
                  <DialogFooter handleSubmit={handleSubmit} handleCancel={handleClose} />
                }
              >
                <InventoryDialogContent
                  inventory={currentInv}
                  onChange={(inv) => setCurrentInv(inv ?? [])}
                />
              </DialogContainer>
            );
          }}
        </Dialog>
      </Grid>
    </RowWithTitle>
  );
};
