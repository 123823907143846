import React from 'react';
import { useIntl } from 'react-intl';

import { CircularProgress } from '@mui/material';

import { useManageUserActivation } from 'api/users/hooks';
import { User } from 'api/users/types';
import { ConfirmationDialog } from 'components/confirmation-dialog';
import { getFullName } from 'features/users/utils';

interface Props {
  triggerEl: React.ReactElement;
  user: User;
}

export const ManageUserActivationDialog: React.FC<Props> = ({ triggerEl, user }) => {
  const intl = useIntl();
  const { mutate, isLoading } = useManageUserActivation();
  const confirmDeactivationHandler = () => {
    mutate({
      user,
      active: !user.active,
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <ConfirmationDialog
      triggerEl={triggerEl}
      title={intl.formatMessage({
        id: `user.manage.activation.${user.active ? 'deactivate' : 'activate'}.title`,
      })}
      description={intl.formatMessage(
        {
          id: `user.manage.activation.${
            user.active ? 'deactivate' : 'activate'
          }.description`,
        },
        { username: getFullName(user) }
      )}
      onConfirm={confirmDeactivationHandler}
    />
  );
};
