import React, { useCallback, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import { ShowAllButton } from 'components/elements/show-all-button';
import { Icon, IconName } from 'components/icon';
import { themeColors } from 'theme/themeColors';
import { convertSxToThemeType } from 'utils';

import { TAGS_NUMBER } from './constants';
import { styles } from './styles';
import { TagLabel } from './TagLabel';

import { Option } from '../../../select-field/SelectField';

export interface TagType extends Option {
  infoContent?: React.ReactElement;
  state?: string;
}

interface Props {
  isAllOptionSelected: boolean;
  selectAllLabel: string;
  onTagDelete: (index: number) => void;
  tags: TagType[];
  disabled?: boolean;
  iconName?: IconName;
  tagLabelRenderer?: (props: unknown) => React.ReactElement;
  sx?: SxProps;
}

export const Tags: React.FC<Props> = ({
  isAllOptionSelected,
  selectAllLabel,
  tags,
  onTagDelete,
  disabled,
  iconName,
  tagLabelRenderer,
  sx,
}) => {
  const sxStyles = styles({ disabled: !!disabled, iconName });
  const [showAll, setShowAll] = useState(false);
  const onDelete = useCallback(
    (index: number) => () => {
      if (!disabled) {
        onTagDelete(index);
      }
    },
    [onTagDelete, disabled]
  );

  const totalTagsCount = tags?.length || 0;
  const hiddenTagsCount = totalTagsCount > TAGS_NUMBER ? totalTagsCount - TAGS_NUMBER : 0;
  const clickHandler = useCallback(() => setShowAll((prev) => !prev), [setShowAll]);

  const tagsElements = useMemo(
    () =>
      tags?.map((option: TagType, index: number) => {
        const isErrorState = option.state === 'error';
        return (
          <Chip
            key={option.value as string}
            label={
              tagLabelRenderer ? (
                tagLabelRenderer({
                  label: option.label,
                  infoContent: option.infoContent,
                  iconName,
                  option,
                })
              ) : (
                <TagLabel
                  label={option.label}
                  infoContent={option.infoContent}
                  iconName={iconName}
                />
              )
            }
            sx={convertSxToThemeType([
              sxStyles.tag(isErrorState),
              sxStyles.deleteIcon,
              !!sx && sx,
            ])}
            deleteIcon={
              <CloseIcon
                width={24}
                height={24}
                sx={{
                  color: themeColors.purplePrimary + '!important',
                }}
              />
            }
            onDelete={onDelete(index)}
          />
        );
      }) || [],
    [tags, tagLabelRenderer, iconName, sxStyles, sx, onDelete]
  );

  const croppedTagsElements = showAll ? tagsElements : tagsElements.slice(0, TAGS_NUMBER);

  return (
    <Box sx={sxStyles.tags}>
      {isAllOptionSelected ? (
        <Chip
          label={selectAllLabel}
          sx={convertSxToThemeType([sxStyles.tag(), sxStyles.deleteIcon])}
          deleteIcon={<Icon name="close" height={24} />}
          onDelete={onDelete(-1)}
        />
      ) : (
        croppedTagsElements
      )}
      {!!hiddenTagsCount && !isAllOptionSelected && (
        <ShowAllButton
          showMore={!showAll}
          count={totalTagsCount}
          clickHandler={clickHandler}
        />
      )}
    </Box>
  );
};
