import { SnackbarProvider } from 'notistack';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { ErrorBoundary } from 'components/error-boundary/ErrorBoundary';
import { AppProvider } from 'context';
import { defaultLanguage, translations } from 'localization';
import { Routes } from 'routes/Routes';
import { theme } from 'theme';

import { AclProvider } from './components/acl/AclProvider';
import AppErrorHandler from './components/app-error-handler/AppErrorHandler';
import { KeycloakProvider } from './utils/keycloak/KeycloakProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <KeycloakProvider>
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={defaultLanguage} messages={translations[defaultLanguage]}>
          <AclProvider>
            <SnackbarProvider
              maxSnack={8}
              autoHideDuration={1000000}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Router>
                <CssBaseline>
                  <ThemeProvider theme={theme}>
                    <AppProvider>
                      <AppErrorHandler>
                        <ErrorBoundary>
                          <Routes />
                        </ErrorBoundary>
                      </AppErrorHandler>
                    </AppProvider>
                  </ThemeProvider>
                </CssBaseline>
              </Router>
            </SnackbarProvider>
          </AclProvider>
        </IntlProvider>
      </QueryClientProvider>
    </KeycloakProvider>
  );
};

export default App;
