export const styles = {
  container: {
    position: 'relative',
  },
  input: {
    '& input': {
      // because input props don't go on the input…
      paddingRight: '48px',
    },
  },
  actions: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    whiteSpace: 'nowrap',
  },
};
