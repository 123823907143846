import Keycloak from 'keycloak-js';

import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';

import { clientAdminRole } from './roles/clientadmin';
import { defaultRole } from './roles/default';
import { dpgAdminRole } from './roles/dpgadmin';
import { dpgUserRole } from './roles/dpguser';

import { User } from '../../api/users/types';
import { Roles } from '../../utils/keycloak/Keycloak';

export const AppAbility = Ability as AbilityClass<Ability>;

export const defineRulesFor = (keycloak: Keycloak, user?: User) => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  if (keycloak.hasRealmRole(Roles.DPGAdmin)) {
    dpgAdminRole(can, cannot);
  } else if (keycloak.hasRealmRole(Roles.DPGUser)) {
    dpgUserRole(can, cannot, user);
  } else if (keycloak.hasRealmRole(Roles.ClientAdmin)) {
    clientAdminRole(can, cannot, user);
  } else {
    defaultRole(can, cannot, user);
  }

  return rules;
};

export const buildAbilityFor = (keycloak: Keycloak, user?: User): Ability => {
  return new AppAbility(defineRulesFor(keycloak, user), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    detectSubjectType: (object) => object!.type,
  });
};
