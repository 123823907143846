import { theme } from '../../../theme';
import { themeColors } from '../../../theme/themeColors';

export const styles = {
  editIcon: {
    '& path': {
      fill: themeColors.purplePrimary,
    },
    width: 48,
    height: 48,
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.text.primary,
      opacity: 0.6,
    },
  },
  channelCheckbox: {
    margin: `${theme.spacing(2)} 0`,
  },
};
