import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import { AddBuyerOrAdvertiserDialog } from './AddBuyerOrAdvertiserDialog';

import { User } from '../../api/users/types';
import { Button } from '../button';

export interface DialogProps {
  user: User;
}

const AddBuyerOrAdvertiserButton: React.FC<DialogProps> = ({ user }) => {
  const [isAddingBuyersOrAdvertisers, setIsAddingBuyersOrAdvertisers] = useState(false);
  const intl = useIntl();

  return (
    <>
      <Box>
        <Button
          variant="text"
          color="primary"
          onClick={() => setIsAddingBuyersOrAdvertisers(true)}
        >
          {intl.formatMessage({
            id: 'user.manage.requestAdvertisersBuyers',
          })}
        </Button>
      </Box>
      {isAddingBuyersOrAdvertisers && (
        <AddBuyerOrAdvertiserDialog
          user={user!}
          open={isAddingBuyersOrAdvertisers}
          setOpen={setIsAddingBuyersOrAdvertisers}
        />
      )}
    </>
  );
};

export { AddBuyerOrAdvertiserButton };
