type Params = Record<string, string | number>;
type QueryParams = Record<string, string>;
type Route = Record<string, string>;
type RouteFn = (params?: Params, queryParams?: QueryParams) => string;

const getRouter = (path: string, params: Params, queryParams: QueryParams) => {
  const baseUrl = Object.keys(params).reduce((endpoint: string, key: string) => {
    return endpoint.replace(':' + key, params[key] as string);
  }, path);

  if (Object.entries(queryParams).length === 0) {
    return baseUrl;
  }

  const searchParams = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  return `${baseUrl}?${searchParams.toString()}`;
};

export const createAppRoutes = <T extends Route, K extends keyof T>(
  endpoints: T,
  defaults: Params = {}
): Record<K, RouteFn> => {
  return Object.keys(endpoints).reduce(
    (apis: Record<string, RouteFn>, pathKey: string) => {
      apis[pathKey] = (params: Params = {}, queryParams: QueryParams = {}) =>
        getRouter(endpoints[pathKey], { ...defaults, ...params }, queryParams);

      return apis;
    },
    {}
  ) as Record<K, RouteFn>;
};
