import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { TEST_ID } from 'components/constants';
import { TextField } from 'components/form/fields/text-field/TextField';
import { Icon } from 'components/icon';

import { styles } from './styles';

interface Props extends TextFieldProps<'standard'> {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isError: string | null;
}

const PasswordFieldComponent = ({ onChange, isError, ...rest }: Props): JSX.Element => {
  const [isHidden, setIsHidden] = useState(true);
  const togglePasswordVisibilityHandler = () => {
    setIsHidden(!isHidden);
  };

  return (
    <TextField
      {...rest}
      type={isHidden ? 'password' : 'text'}
      error={!!isError}
      helperText={isError}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button
              sx={styles.hideButton}
              data-testid={TEST_ID.hideButton}
              startIcon={
                <Icon name={isHidden ? 'view' : 'hide'} width={20} height={20} />
              }
              onClick={togglePasswordVisibilityHandler}
            >
              {isHidden ? (
                <Typography component="span" sx={styles.hideButtonText}>
                  <FormattedMessage id="password.show" />
                </Typography>
              ) : (
                <Typography component="span" sx={styles.hideButtonText}>
                  <FormattedMessage id="password.hide" />
                </Typography>
              )}
            </Button>
          </InputAdornment>
        ),
      }}
      onChange={onChange}
    />
  );
};

const PasswordField = React.memo(PasswordFieldComponent);

export { PasswordField };
