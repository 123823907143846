import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { styles as tableStyles } from 'components/table/common-styles';

import { styles } from './styles';

import { Deal } from '../../../../api/deals/types';
import { Icon } from '../../../icon';
import { AscendingIcon } from '../../../sort-icon/ascending-icon/AscendingIcon';
import { DescendingIcon } from '../../../sort-icon/descending-icon/DescendingIcon';
import { UnsortedIcon } from '../../../sort-icon/unsorted-icon/UnsortedIcon';
import { TextCopyPopover } from '../../../text-copy-popover/TextCopyPopover';
import { Tooltip } from '../../../tooltip/Tooltip';

interface Props {
  records: Partial<Deal>[];
  onRemove: (idx: number) => void;
}

const ImportDealsTable: React.FC<Props> = ({ records, onRemove }): JSX.Element => {
  const intl = useIntl();
  const rows = useMemo(() => records.map((r, id) => ({ ...r, id })), [records]);
  const columns: Array<GridColDef> = [
    {
      field: 'dealId',
      minWidth: 175,
      headerName: intl.formatMessage({ id: 'agency.importDeals.table.column.dealId' }),
      valueGetter: ({ row }) => (row.dealId ? row.dealId : '-'),
    },
    {
      field: 'dealName',
      headerName: intl.formatMessage({ id: 'agency.importDeals.table.column.dealName' }),
      flex: 1,
      renderCell: ({ row }) => (
        <div style={styles.dealNameCell}>
          <TextCopyPopover text={row.dealName ?? '-'} active={!!row.dealName} />
        </div>
      ),
    },
    {
      field: 'agency',
      headerName: intl.formatMessage({ id: 'agency.importDeals.table.column.agency' }),
    },
    {
      field: 'buyer',
      headerName: intl.formatMessage({ id: 'agency.importDeals.table.column.buyer' }),
      valueGetter: ({ row }) => (row.buyer ? `${row.buyer.dsp} ${row.buyer.seat}` : ''),
    },
    {
      field: 'floorPrice',
      headerName: intl.formatMessage({
        id: 'agency.importDeals.table.column.floorPrice',
      }),
      valueGetter: ({ row }) =>
        row.floorPrice
          ? new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(
              row.floorPrice / 100
            )
          : '',
    },
    {
      field: 'SSP',
      headerName: intl.formatMessage({ id: 'agency.importDeals.table.column.ssp' }),
      valueGetter: ({ row }) => row.ssp ?? 'Xandr',
    },
    {
      field: 'actions',
      headerName: intl.formatMessage({ id: 'agency.importDeals.table.column.actions' }),
      type: 'actions',
      align: 'center',
      getActions: ({ row }) => [
        <Tooltip
          title={intl.formatMessage({ id: 'agency.importDeals.table.actions.remove' })}
          size="small"
        >
          <IconButton sx={styles.action} onClick={() => onRemove(row.id)}>
            <Icon width={24} height={24} name="delete" />
          </IconButton>
        </Tooltip>,
      ],
    },
  ];

  return (
    <>
      <Box mt={4} sx={tableStyles.grid}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          rowHeight={72}
          columnHeaderHeight={60}
          hideFooter={true}
          disableColumnMenu={true}
          sx={styles.table}
          slots={{
            columnSortedDescendingIcon: DescendingIcon,
            columnSortedAscendingIcon: AscendingIcon,
            columnUnsortedIcon: UnsortedIcon,
          }}
          getRowClassName={({ row }) =>
            row.dealId && row.dealName ? '' : 'row-incomplete'
          }
        />
      </Box>
    </>
  );
};

export { ImportDealsTable };
