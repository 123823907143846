const nlLink = 'https://privacy.dpgmedia.nl/nl/document/terms-of-use';
const beLink = 'https://privacy.dpgmedia.be/nl/document/terms-of-use';

export const useTermsOfUseLink = () => {
  switch (window.location.hostname) {
    case 'dpgdemand.be':
      return beLink;
    default:
      return nlLink;
  }
};
