import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';

import { SelectMui } from 'components/select';

import { menuItems } from './constants';
import { styles } from './styles';

interface Props {
  pageSize: number;

  onPageSizeChange(size: number): void;
}

export const ShowPerPage: React.FC<Props> = ({ onPageSizeChange, pageSize }) => {
  const [perPage, setPerPage] = useState<number>(pageSize);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const size = event.target.value as number;
    setPerPage(size);
    onPageSizeChange(size);
  };

  return (
    <FormControl variant="outlined" sx={styles.formControl}>
      <SelectMui
        value={perPage}
        sx={styles.select}
        variant="outlined"
        onChange={handleChange}
      >
        {menuItems.map(({ key, value }) => (
          <MenuItem key={key} value={value}>
            <FormattedMessage id="pagination.per.page" values={{ value }} />
          </MenuItem>
        ))}
      </SelectMui>
    </FormControl>
  );
};
