import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Icon, IconName } from 'components/icon';
import { UserLogoDropdown } from 'components/menu/UserLogoDropdown';

import { styles } from './styles';
import { MenuItem } from './types';

import { appRoutes } from '../../routes/appRoutes';
import { themeColors } from '../../theme/themeColors';
import { login } from '../../utils/keycloak/Keycloak';
import { useKeycloak } from '../../utils/keycloak/KeycloakProvider';
import { Button } from '../button';

interface Props {
  menuItems: MenuItem[];
}

const MenuComponent: React.FC<Props> = ({ menuItems }) => {
  const { authenticated } = useKeycloak();

  if (!authenticated) {
    return (
      <Grid key="login" item display="flex">
        <Box className="link" display="flex" alignItems="center">
          <Button
            variant="text"
            onClick={() => login(window.location.origin + appRoutes.main())}
          >
            <Typography
              variant="h3"
              sx={{
                color: themeColors.purplePrimary,
                fontFamily: 'Proxima Nova Semibold',
              }}
            >
              <FormattedMessage id="nav.link.login" />
            </Typography>
          </Button>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item sx={styles.root}>
      <Grid container height="100%" alignItems="center">
        {menuItems.map(({ path, translationId, icon, title, ...props }) => {
          if (icon) {
            return (
              <Grid key={icon} item display="flex" alignItems="center">
                <NavLink
                  to={path}
                  activeClassName="active"
                  className={icon}
                  title={title}
                  {...props}
                >
                  <Box sx={{ position: 'relative' }}>
                    <Icon width="24px" height="24px" name={icon as IconName} />
                  </Box>
                </NavLink>
              </Grid>
            );
          }

          if (translationId) {
            return (
              <Grid key={translationId} item>
                <NavLink
                  to={path}
                  activeClassName="active"
                  title={title}
                  style={{ pointerEvents: 'none' }}
                  {...props}
                >
                  <Typography variant="h3">
                    <FormattedMessage id={`nav.link.${translationId}`} />
                  </Typography>
                </NavLink>
              </Grid>
            );
          }

          return <></>;
        })}
        <UserLogoDropdown />
      </Grid>
    </Grid>
  );
};

const Menu = React.memo(MenuComponent);

export { Menu };
