import { theme } from 'theme';
import { themeColors } from 'theme/themeColors';

export const styles = {
  errorIcon: {
    width: '16px',
    height: '16px',
    fill: themeColors.redPrimary,
    display: 'none',
  },
  icon: {
    '& .MuiSelect-icon': {
      color: themeColors.lightBlack,
      right: 13,
      width: 27,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  iconMui: {
    '& .MuiSelect-icon': {
      fontSize: 24,
      top: 'calc(50% - 12px)',
    },
  },
  select: {
    borderRadius: '3px',
    '& .MuiSelect-select': {
      fontSize: 16,
    },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    '& .MuiInputAdornment-root': {
      mr: 3,
    },
  },
  dropdown: {
    background: themeColors.white,
    border: `1px solid ${themeColors.purplePrimary}`,
    borderTopWidth: 0,
    '& ul': {
      padding: 0,
      '& li': {
        height: 48,
        '&.Mui-selected, &.MuiListItem-button:focus': {
          background: theme.palette.primary.main,
          color: themeColors.white,
          '& .MuiTypography-root': {
            color: themeColors.white,
          },
        },
        '&:hover': {
          background: themeColors.purple20,
          color: themeColors.black,
          '& .MuiTypography-root': {
            color: themeColors.black,
          },
        },
      },
    },
  },
  dropdownMui: {
    background: themeColors.white,
    width: 94,
    height: 70,
    '& ul': {
      padding: 0,
      '& li': {
        height: 35,
        color: themeColors.black,
        fontSize: 12,
        fontFamily: 'Proxima Nova Regular',

        '&.Mui-selected': {
          backgroundColor: themeColors.white,
          fontWeight: 'bold',
        },
        '&.MuiListItem-button:hover': {
          backgroundColor: themeColors.purple20,
        },
      },
    },
  },
};
