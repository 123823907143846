import { useState } from 'react';

import { Reason, Reasons } from 'components/dialog2/types';

const useModal = (disableBackdropClick = false) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event?: Record<string, unknown>, reason?: Reason) => {
    if (disableBackdropClick && Reasons.backdropClick === reason) {
      return;
    }
    setOpen(false);
  };

  return { open, setOpen, handleOpen, handleClose };
};

export default useModal;
