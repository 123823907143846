import React from 'react';

import { MenuItem, Select, SelectProps } from '@mui/material';
import { BaseSelectProps, OutlinedSelectProps } from '@mui/material/Select/Select';

import { convertSxToThemeType } from 'utils';

import { styles } from './styles';

import { ReactFCC } from '../../react-util';

interface Props extends BaseSelectProps {
  options?: { value: number | string; label: string }[];
}

export const SelectMui: ReactFCC<Props> = (props) => {
  const clonedProps = {
    MenuProps: {
      PaperProps: {
        sx: styles.dropdown,
      },
    },
    sx: convertSxToThemeType([styles.select, styles.icon]),
    ...props,
  };

  return (
    <Select {...clonedProps}>
      {props.options &&
        props.options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      {props.children}
    </Select>
  );
};
