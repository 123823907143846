import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Card, Container, Grid, Typography } from '@mui/material';

import AccessDeniedBg from 'assets/icons/access-denied-bg';
import ErrorBg from 'assets/icons/error-bg';
import PageNotFoundBg from 'assets/icons/page-not-found-bg';
import { styles } from 'components/app-layout/styles';
import { themeColors } from 'theme/themeColors';

import {
  apiErrors,
  ErrorDescription,
  ErrorHeaderTitle,
  ErrorSubTitle,
  ErrorSupDescription,
  ErrorTitle,
} from './constants';

import { Button } from '../button';

interface Props {
  type?: string | null;
}

const ErrorPage = ({ type = apiErrors.ServerError }: Props) => {
  const onClick = useCallback(() => {
    window.location.href = '/';
  }, []);

  const errorContent = useMemo(
    () => ({
      header: ErrorHeaderTitle[type as keyof typeof apiErrors],
      subTitle: ErrorSubTitle[type as keyof typeof apiErrors],
      title: ErrorTitle[type as keyof typeof apiErrors],
      description: ErrorDescription[type as keyof typeof apiErrors],
      support: ErrorSupDescription[type as keyof typeof apiErrors],
    }),
    [type]
  );

  const errorBg = (width: number) => {
    if (errorContent.header === ErrorHeaderTitle.ServerError) {
      return <ErrorBg width={width} />;
    }
    if (errorContent.header === ErrorHeaderTitle.Forbidden) {
      return <AccessDeniedBg width={width} />;
    }
    if (errorContent.header === ErrorHeaderTitle.NotFound) {
      return <PageNotFoundBg width={width} />;
    }
  };

  return (
    <Box
      sx={{ backgroundColor: themeColors.grey20, minWidth: 1440 }}
      display="flex"
      flexDirection="column"
      minHeight="100vh"
    >
      <Container maxWidth={false} sx={styles.header}>
        <Box sx={{ height: 112 }} />
      </Container>

      <Box sx={{ height: 48, background: themeColors.grey30 }} />

      <Container disableGutters sx={{ minHeight: 'calc(100vh - 315px)' }}>
        <Box pt={0} pb={20} paddingX={2}>
          <Box mt={8} mb={4}>
            <Typography variant="h1" color="textPrimary" component="span">
              <FormattedMessage id={errorContent.header} />
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Proxima Nova Regular',
                color: themeColors.greyPrimary,
                ml: 0.5,
              }}
              variant="h1"
              component="span"
            >
              <FormattedMessage id={errorContent.subTitle} />
            </Typography>
          </Box>

          <Grid container>
            <Grid item xs={5}>
              <Card sx={{ p: 4, mb: 4, borderRadius: '3px' }}>
                <Typography variant="h3" sx={{ fontSize: '18px' }}>
                  <FormattedMessage id={errorContent.title} />
                </Typography>
                <Typography sx={{ mt: 3 }}>
                  <FormattedMessage id={errorContent.description} />
                </Typography>

                <Typography sx={{ mt: 3 }}>
                  <FormattedMessage id={errorContent.support} />
                </Typography>

                {/* todo: uncomment when you have proper link to servicedesk  */}
                {/* <Box>*/}
                {/*  <ExternalLink*/}
                {/*    type={LinkType.Primary}*/}
                {/*    href=""*/}
                {/*    sx={{*/}
                {/*      display: 'inline-flex',*/}
                {/*      alignItems: 'center',*/}
                {/*      mt: 1,*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <>*/}
                {/*      <Support sx={{ mr: 0.5 }} />*/}
                {/*      <FormattedMessage id="error.server_error.link" />*/}
                {/*    </>*/}
                {/*  </ExternalLink>*/}
                {/* </Box>*/}

                <Button
                  sx={{ mt: 4 }}
                  startIconName="arrow-left"
                  labelId="error.server_error.back"
                  onClick={onClick}
                />
              </Card>
            </Grid>

            <Grid
              item
              xs={7}
              sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
            >
              {errorBg(646)}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box sx={{ height: 152, backgroundColor: themeColors.black }} />
    </Box>
  );
};

export { ErrorPage };
