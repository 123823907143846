import React, { Component, ReactNode } from 'react';

import { ErrorPage } from 'components/error-page';

import ForbiddenError from '../../utils/exceptions/forbidden';
import NotFoundError from '../../utils/exceptions/not-found';
import { apiErrors } from '../error-page/constants';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  type?: keyof typeof apiErrors;
}

class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/sort-comp
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(e: Error): State {
    let type = apiErrors.ServerError;

    if (e instanceof ForbiddenError) {
      type = apiErrors.Forbidden;
    }

    if (e instanceof NotFoundError) {
      type = apiErrors.NotFound;
    }

    return { hasError: true, type };
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage type={this.state.type} />;
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
