import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import { useUpdateUser } from 'api/users/hooks';
import { User } from 'api/users/types';
import { ContainerWithTitle } from 'components/elements/container-with-title';

import { AgencySelect } from '../../../../../components/agency-select/AgencySelect';
import { UpdateUserFormWrapper } from '../update-user-form-wrapper';

interface Props {
  user: User;
  onCancel: () => void;
}

export const EditAgencies: React.FC<Props> = ({ user, onCancel }) => {
  const mutation = useUpdateUser();
  const intl = useIntl();
  const [agencies, setAgencies] = useState(user.agencies ?? []);

  return (
    <Box>
      <UpdateUserFormWrapper
        mutation={mutation}
        onCancel={onCancel}
        onSave={() => {
          mutation.mutate(
            {
              user,
              updates: { agencies },
            },
            {
              onSuccess: () => {
                onCancel();
              },
            }
          );
        }}
      >
        <Box mt={3}>
          <ContainerWithTitle
            title={intl.formatMessage({ id: 'user.manage.set_agencies.label' })}
            mt={0}
            mb={3}
            infoModalContent={
              <Box width={400}>
                <FormattedMessage id="user.manage.set_agencies.description" />
              </Box>
            }
          >
            <AgencySelect user={{ ...user, agencies }} onChange={setAgencies} />
          </ContainerWithTitle>
        </Box>
      </UpdateUserFormWrapper>
    </Box>
  );
};
