import React from 'react';

import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';

import { Icon, IconName } from 'components/icon';
import { themeColors } from 'theme/themeColors';
import { convertSxToThemeType } from 'utils';

import { TEST_ID } from './constants';

import { ReactFCC } from '../../react-util';

interface Props {
  children?: React.ReactNode;
  maxHeight?: number | string;
  maxWidth?: number | string;
  CustomIconRenderer?: React.ReactElement;
  iconWidth?: number;
  infoIconSx?: SxProps;
  iconName?: IconName;
  isErrorIcon?: boolean;
  isHoverEnabled?: boolean;
}

const InfoModal: ReactFCC<Props> = ({
  children,
  CustomIconRenderer = null,
  infoIconSx = {},
  maxHeight = 'auto',
  maxWidth = 'auto',
  iconWidth = 24,
  iconName = 'tooltip',
  isErrorIcon = false,
  isHoverEnabled = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const closeHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isHoverEnabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const onMouseLeave = (_: React.MouseEvent<HTMLDivElement>) => {
    if (isHoverEnabled) {
      setAnchorEl(null);
    }
  };
  return (
    <>
      <Box
        sx={convertSxToThemeType([infoIconSx, { cursor: 'pointer' }] as Array<SxProps>)}
        data-testid={TEST_ID.infoModalIcon}
        display="flex"
        onClick={clickHandler}
        onMouseEnter={onMouseEnter}
      >
        {isErrorIcon ? (
          <Icon name="exclamation" />
        ) : (
          CustomIconRenderer || (
            <Icon
              name={iconName}
              width={iconWidth}
              color={anchorEl ? themeColors.redPrimary : themeColors.grey120}
            />
          )
        )}
      </Box>
      <Popover
        anchorReference="anchorEl"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={closeHandler}
      >
        <Box maxHeight={maxHeight} maxWidth={maxWidth}>
          <Paper
            square
            variant="outlined"
            sx={{ backgroundColor: themeColors.white }}
            onMouseLeave={onMouseLeave}
          >
            <Box p={3} data-testid={TEST_ID.infoModalContent}>
              {children}
            </Box>
          </Paper>
        </Box>
      </Popover>
    </>
  );
};

export { InfoModal };
