import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { CircularProgress, Grid } from '@mui/material';

import { getDeal } from '../../api/deals';
import { Deal } from '../../api/deals/types';
import { queryKeys } from '../../api/queryKeys';
import { DealDetailActions } from '../../components/deal-details/DealDetailActions';
import { DealDetails } from '../../components/deal-details/DealDetails';
import { DealComments } from '../../components/DealComments/DealComments';
import { Icon } from '../../components/icon';
import { SectionContent } from '../../components/section-content';
import { SectionTitle } from '../../components/section-title';

export const ViewDeal = (): JSX.Element => {
  const dealId = (useParams() as Record<string, string>).id;
  const { data, isLoading } = useQuery([queryKeys.getDeal, dealId], getDeal);
  const [deal, setDeal] = useState<Deal>(data ?? ({} as Deal));

  useEffect(() => {
    setDeal(data ?? deal);
  }, [data]);

  return (
    <Grid container justifyContent="space-between" spacing={10}>
      <Grid item md={8}>
        <SectionTitle
          title={<FormattedMessage id="deal.detail.title" />}
          description={<FormattedMessage id="deal.detail.description" />}
          sx={{ marginBottom: '32px' }}
          state={deal?.state}
        />
        {isLoading && (
          <CircularProgress
            color="error"
            sx={{
              marginLeft: '16px',
              marginBottom: '16px',
              width: '25px !important',
              height: '25px !important',
            }}
          />
        )}
        <SectionContent
          size="halfScreen"
          sx={{ marginBottom: '8px', paddingBottom: '75px' }}
        >
          <DealDetails deal={deal} />
        </SectionContent>
        <SectionContent
          size="halfScreen"
          sx={{ marginBottom: '8px', paddingBottom: '40px' }}
        >
          <DealComments comments={(deal as Deal)?.comments} dealId={dealId} />
        </SectionContent>
        <DealDetailActions deal={deal} />
      </Grid>
      <Grid item xs={4} mt={12}>
        <Icon name="io-man" viewBox="0 0 800 1162" height={1000} />
      </Grid>
    </Grid>
  );
};
