import { Agency } from '../../api/agencies/types';
import { createDeal, editDeal, requestDealIds } from '../../api/deals';
import { Deal, EditDealData } from '../../api/deals/types';

export type DealEntry = {
  deal: Partial<Deal> & { ownedBy?: Agency['id'] };
  error: null | Error;
};

export const saveDealEntries = async (items: DealEntry[]) => {
  return await Promise.all(
    items.map(async (item) => {
      try {
        if (item.deal.id) {
          await editDeal(item.deal as EditDealData);
        } else {
          const createdDeal = await createDeal({
            ...item.deal,
            ownedBy: item.deal.ownedBy!,
          });

          return {
            ...item,
            deal: createdDeal,
            error: null,
          };
        }

        return item;
      } catch (e) {
        console.error('Could not save a deal', item, e);

        return {
          ...item,
          error: e as Error,
        };
      }
    })
  );
};

export const requestDealEntries = async (items: DealEntry[]) => {
  await requestDealIds(items.map((d) => d.deal.id!));
};
