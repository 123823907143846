import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/queryKeys';
import { NotificationMessage, useNotification } from 'notifications';

import { getFullName } from '../../../features/users/utils';
import { updateUserSettings } from '../index';
import { UserUpdateRequest } from '../types';

export const useUpdateUser = () => {
  const cache = useQueryClient();
  const sendNotification = useNotification({});

  return useMutation<void, AxiosError, UserUpdateRequest>(updateUserSettings, {
    onSuccess: (_, data) => {
      cache.invalidateQueries([queryKeys.getUsers]);
      cache.invalidateQueries([queryKeys.getUser, data.user.id]);

      const user = {
        ...data.user,
        ...data.updates,
      };

      sendNotification({
        type: 'success',
        message: (
          <NotificationMessage
            id="user.manage.updated.message"
            values={[{ key: 'username', value: getFullName(user) }]}
          />
        ),
      });
    },
  });
};
