import React from 'react';

import Typography from '@mui/material/Typography';

interface Props {
  children: React.ReactElement | string;
}

const ValueLabelContainer: React.FC<Props> = ({ children }) => (
  <Typography
    component="span"
    style={{
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '400px',
    }}
  >
    {children}
  </Typography>
);

export default ValueLabelContainer;
