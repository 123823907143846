import { DetailedTargeting } from './types';

export const TEST_ID = {
  targetingFilter: 'targetingFilter',
  targetingFilterModal: 'targetingFilterModal',
  backItem: 'backItem',
  categoryItem: 'categoryItem',
  leafItem: 'leafItem',
  keywords: 'keywords',
  keywordsDescription: 'keywordsDescription',
  mainItems: 'mainItems',
  browseButton: 'browseButton',
  showExcludeButton: 'showExcludeButton',
  autocomplete: 'autocomplete',
  tags: 'tags',
  browseMode: 'browseMode',
  infoModalIcon: 'infoModalIcon',
  infoModalContent: 'infoModalContent',
};

export const NONE_TARGETING_ID = 'none';

export const TARGETING_DATA: DetailedTargeting = {
  gender: [
    {
      // TODO make automatic
      id: NONE_TARGETING_ID,
      label: 'No gender targeting',
      size: 23939152,
      exclusive: true,
    },
    {
      id: 'gender-nl-female',
      label: 'Women',
      size: 11788178,
      exclusive: true,
    },
    {
      id: 'gender-nl-male',
      label: 'Men',
      size: 12150974,
      exclusive: true,
    },
  ],
  age: [
    {
      id: 'iab-nl-3',
      label: 'Age: 18 - 24',
      size: 271005,
    },
    {
      id: 'iab-nl-5',
      label: 'Age: 25 - 34',
      size: 2086483,
    },
    {
      id: 'iab-nl-7',
      label: 'Age: 35 - 44',
      size: 7749496,
    },
    {
      id: 'iab-nl-9',
      label: 'Age: 45 - 54',
      size: 7048383,
    },
    {
      id: 'iab-nl-11',
      label: 'Age: 55 - 64',
      size: 4276224,
    },
    {
      id: 'iab-nl-13',
      label: 'Age: 65+',
      size: 2159103,
    },
  ],
  interest: [
    {
      id: 'iab-nl-368',
      label: 'Food & Drinks',
      size: 2144249,
      children: [
        {
          id: 'iab-nl-369',
          label: 'Beer & Wine',
          size: 395079,
        },
        {
          id: 'iab-nl-371',
          label: 'Cooking',
          size: 486825,
        },
        {
          id: 'iab-nl-372',
          label: 'Baking',
          size: 492295,
        },
        {
          id: 'iab-nl-373',
          label: 'Dining out',
          size: 94851,
        },
        {
          id: 'iab-nl-376',
          label: 'Healthy food',
          size: 950855,
        },
        {
          id: 'iab-nl-378',
          label: 'Vegan',
          size: 99417,
        },
        {
          id: 'iab-nl-379',
          label: 'Vegetarian',
          size: 146710,
        },
      ],
    },
    {
      id: 'iab-nl-207',
      label: 'Science',
      size: 13380688,
      children: [
        {
          id: 'iab-nl-211',
          label: 'History',
          size: 1875740,
        },
        {
          id: 'iab-nl-218',
          label: 'Nature',
          size: 13110381,
        },
        {
          id: 'iab-nl-241',
          label: 'Education',
          size: 1686435,
        },
      ],
    },
    {
      id: 'iab-nl-243',
      label: 'Automotive',
      size: 3580178,
      children: [
        {
          id: 'iab-nl-247',
          label: 'Budget cars',
          size: 70029,
        },
        {
          id: 'iab-nl-253',
          label: 'Electric cars',
          size: 733969,
        },
        {
          id: 'iab-nl-254',
          label: 'Luxury cars',
          size: 321692,
        },
        {
          id: 'iab-nl-255',
          label: 'Motor bikes',
          size: 2258713,
        },
        {
          id: 'iab-nl-c-3249',
          label: 'Family cars',
          size: 130478,
        },
      ],
    },
    {
      id: 'iab-nl-268',
      label: 'Business & finance',
      size: 6715102,
      children: [
        {
          id: 'iab-nl-1388',
          label: 'Credit Cards',
          size: 42647,
        },
        {
          id: 'iab-nl-269',
          label: 'Business',
          size: 5358475,
        },
        {
          id: 'iab-nl-280',
          label: 'Stocks',
          size: 800881,
        },
        {
          id: 'iab-nl-295',
          label: 'Finance',
          size: 3289061,
        },
        {
          id: 'iab-nl-c-3404',
          label: 'Entrepreneurs',
          size: 470112,
        },
        {
          id: 'iab-nl-c-3405',
          label: 'Crypto',
          size: 72753,
        },
      ],
    },
    {
      id: 'iab-nl-308',
      label: 'Entertainment',
      size: 12038791,
      children: [
        {
          id: 'iab-nl-802',
          label: 'Podcasts',
          size: 1586974,
        },
        {
          id: 'iab-nl-258',
          label: 'Books',
          size: 8517269,
        },
        {
          id: 'iab-nl-467',
          label: 'Movies',
          size: 5045347,
        },
        {
          id: 'iab-nl-481',
          label: 'Music',
          size: 1459390,
        },
        {
          id: 'iab-nl-706',
          label: 'TV viewers (Light/Heavy)',
          size: 502807,
        },
        {
          id: 'iab-nl-733',
          label: 'Gaming',
          size: 166547,
        },
        {
          id: 'iab-nl-c-3406',
          label: 'Showbizz',
          size: 1438924,
        },
        {
          id: 'iab-nl-c-3407',
          label: 'Series',
          size: 245851,
        },
        {
          id: 'iab-nl-c-3408',
          label: 'Social Media',
          size: 1532016,
        },
      ],
    },
    {
      id: 'iab-nl-422',
      label: "Hobby's & lifestyle",
      size: 15391225,
      children: [
        {
          id: 'iab-nl-359',
          label: 'Art & Culture',
          size: 4724302,
        },
        {
          id: 'iab-nl-784',
          label: 'Theater & Musical',
          size: 7856643,
        },
        {
          id: 'iab-nl-406',
          label: 'Health & Wellbeing',
          size: 7166914,
        },
        {
          id: 'iab-nl-408',
          label: 'Fitness & Exercising',
          size: 326836,
        },
        {
          id: 'iab-nl-677',
          label: 'Beauty & Fashion',
          size: 2646418,
        },
        {
          id: 'iab-nl-719',
          label: 'Travel',
          size: 7641783,
        },
        {
          id: 'iab-nl-c-3409',
          label: 'Festival',
          size: 1705202,
        },
        {
          id: 'iab-nl-c-3410',
          label: 'Nightlife',
          size: 1678951,
        },
      ],
    },
    {
      id: 'iab-nl-457',
      label: 'Home & Garden',
      size: 3610500,
      children: [
        {
          id: 'iab-nl-458',
          label: 'Gardening',
          size: 2283981,
        },
        {
          id: 'iab-nl-460',
          label: 'Home Improvement',
          size: 1295379,
        },
        {
          id: 'iab-nl-461',
          label: 'Home Decoration',
          size: 139933,
        },
        {
          id: 'iab-nl-583',
          label: 'Real Estate',
          size: 95107,
        },
      ],
    },
    {
      id: 'iab-nl-522',
      label: 'News',
      size: 18129438,
      children: [
        {
          id: 'iab-nl-525',
          label: 'International News',
          size: 5265643,
        },
        {
          id: 'iab-nl-527',
          label: 'Regional News',
          size: 3800275,
        },
        {
          id: 'iab-nl-528',
          label: 'National News',
          size: 9358278,
        },
        {
          id: 'iab-nl-529',
          label: 'Politics',
          size: 11655002,
        },
      ],
    },
    {
      id: 'iab-nl-541',
      label: 'Pets',
      size: 2269950,
      children: [
        {
          id: 'iab-nl-543',
          label: 'Cats',
          size: 380053,
        },
        {
          id: 'iab-nl-544',
          label: 'Dogs',
          size: 2074672,
        },
      ],
    },
    {
      id: 'iab-nl-607',
      label: 'Sports',
      size: 9719475,
      children: [
        {
          id: 'iab-nl-410',
          label: 'Running',
          size: 2298305,
        },
        {
          id: 'iab-nl-610',
          label: 'Formula 1',
          size: 2485130,
        },
        {
          id: 'iab-nl-625',
          label: 'Cycling',
          size: 1638655,
        },
        {
          id: 'iab-nl-6261',
          label: 'Darts',
          size: 92618,
        },
        {
          id: 'iab-nl-650',
          label: 'Martial Arts',
          size: 1648624,
        },
        {
          id: 'iab-nl-663',
          label: 'Football',
          size: 4448384,
        },
        {
          id: 'iab-nl-669',
          label: 'Tennis',
          size: 2559909,
        },
        {
          id: 'iab-nl-735',
          label: 'E-sports',
          size: 137223,
        },
      ],
    },
    {
      id: 'iab-nl-687',
      label: 'Consumer Technology',
      size: 332212,
      children: [
        {
          id: 'iab-nl-465',
          label: 'Smart Devices',
          size: 85351,
        },
        {
          id: 'iab-nl-954',
          label: 'Laptop',
          size: 87872,
        },
        {
          id: 'iab-nl-961',
          label: 'Smartphone',
          size: 156716,
        },
        {
          id: 'iab-nl-965',
          label: 'Tablet',
          size: 14312,
        },
      ],
    },
    {
      id: 'iab-nl-c-3400',
      label: 'Green Living',
      size: 4646869,
      children: [
        {
          id: 'iab-nl-227',
          label: 'Sustainability',
          size: 1200903,
        },
        {
          id: 'iab-nl-c-3401',
          label: 'Sustainable home',
          size: 295882,
        },
        {
          id: 'iab-nl-c-3402',
          label: 'Green energy',
          size: 710133,
        },
        {
          id: 'iab-nl-c-3403',
          label: 'Electric bikes',
          size: 228643,
        },
        {
          id: 'iab-nl-c-3411',
          label: 'Green cars',
          size: 660745,
        },
      ],
    },
  ],
  moments: [
    {
      id: 'iab-nl-c-3100',
      label: 'Life',
      size: 8532747,
      children: [
        {
          id: 'iab-nl-c-3101',
          label: 'Family expansion',
          size: 3841687,
        },
        {
          id: 'iab-nl-c-3102',
          label: 'Recent birth',
          size: 3841687,
        },
        {
          id: 'iab-nl-c-3103',
          label: 'Wedding',
          size: 937096,
        },
        {
          id: 'iab-nl-c-3104',
          label: 'New job',
          size: 85465,
        },
        {
          id: 'iab-nl-c-3105',
          label: 'Moved',
          size: 20143,
        },
        {
          id: 'iab-nl-c-3106',
          label: 'Home renovation',
          size: 942801,
        },
        {
          id: 'iab-nl-c-3107',
          label: 'Retirement',
          size: 2272934,
        },
        {
          id: 'iab-nl-c-3111',
          label: 'New kitchen',
          size: 0,
        },
        {
          id: 'iab-nl-c-3109',
          label: 'New pet',
          size: 6955,
        },
        {
          id: 'iab-nl-c-3110',
          label: 'New car',
          size: 1840464,
        },
        {
          id: 'iab-nl-c-3112',
          label: 'Movers intention',
          size: 922329,
        },
      ],
    },
    {
      id: 'iab-nl-c-3002',
      label: 'Daily',
      size: 13701925,
      children: [
        {
          id: 'iab-nl-c-3003',
          label: 'Breakfast',
          size: 74964,
        },
        {
          id: 'iab-nl-c-3004',
          label: 'Lunch',
          size: 9056,
        },
        {
          id: 'iab-nl-c-3005',
          label: 'Dinner',
          size: 10984,
        },
        {
          id: 'iab-nl-c-3006',
          label: 'Cooking/Baking',
          size: 486840,
        },
        {
          id: 'iab-nl-c-3007',
          label: 'Drinks',
          size: 462501,
        },
        {
          id: 'iab-nl-c-3008',
          label: 'Going out',
          size: 19823,
        },
        {
          id: 'iab-nl-c-3009',
          label: 'Personal Care',
          size: 495794,
        },
        {
          id: 'iab-nl-c-3010',
          label: 'Entertainment',
          size: 5610959,
        },
        {
          id: 'iab-nl-c-3011',
          label: 'Reading',
          size: 268649,
        },
        {
          id: 'iab-nl-c-3012',
          label: 'Board games/ Online games',
          size: 136458,
        },
        {
          id: 'iab-nl-c-3013',
          label: 'Sports',
          size: 8059613,
        },
        {
          id: 'iab-nl-c-3014',
          label: 'Walking',
          size: 42355,
        },
        {
          id: 'iab-nl-c-3015',
          label: 'Shopping',
          size: 3536,
        },
        {
          id: 'iab-nl-c-3016',
          label: 'Being Creative',
          size: 287340,
        },
        {
          id: 'iab-nl-c-3017',
          label: 'Doing chores',
          size: 226438,
        },
        {
          id: 'iab-nl-c-3018',
          label: 'Pet care',
          size: 5832,
        },
        {
          id: 'iab-nl-c-3019',
          label: 'Study/work',
          size: 1265785,
        },
        {
          id: 'iab-nl-c-3020',
          label: 'Being ill',
          size: 5991103,
        },
      ],
    },
    {
      id: 'iab-nl-c-3080',
      label: 'Yearly',
      size: 6241125,
      children: [
        {
          id: 'iab-nl-c-3076',
          label: 'Festival',
          size: 3299062,
        },
        {
          id: 'iab-nl-c-3077',
          label: 'Wintersport',
          size: 102956,
        },
        {
          id: 'iab-nl-c-3078',
          label: 'Cyber Monday',
          size: 8,
        },
        {
          id: 'iab-nl-c-3079',
          label: 'Spring',
          size: 352105,
        },
        {
          id: 'iab-nl-c-3081',
          label: 'New years Eve',
          size: 170385,
        },
        {
          id: 'iab-nl-c-3082',
          label: 'Holiday early bird season',
          size: 380866,
        },
        {
          id: 'iab-nl-c-3083',
          label: 'Valentine',
          size: 105683,
        },
        {
          id: 'iab-nl-c-3084',
          label: 'Carnaval',
          size: 76711,
        },
        {
          id: 'iab-nl-c-3085',
          label: 'Easter',
          size: 76678,
        },
        {
          id: 'iab-nl-c-3086',
          label: 'Kingsday',
          size: 371034,
        },
        {
          id: 'iab-nl-c-3087',
          label: 'BBQ',
          size: 300672,
        },
        {
          id: 'iab-nl-c-3088',
          label: 'Mothersday',
          size: 255421,
        },
        {
          id: 'iab-nl-c-3089',
          label: 'Fathersday',
          size: 529,
        },
        {
          id: 'iab-nl-c-3090',
          label: 'New School year',
          size: 1499447,
        },
        {
          id: 'iab-nl-c-3091',
          label: 'Halloween',
          size: 743,
        },
        {
          id: 'iab-nl-c-3092',
          label: 'Black Friday',
          size: 188,
        },
        {
          id: 'iab-nl-c-3093',
          label: 'Sinterklaas',
          size: 83790,
        },
        {
          id: 'iab-nl-c-3094',
          label: 'Christmas',
          size: 847559,
        },
        {
          id: 'iab-nl-c-3095',
          label: 'Zorgseizoen',
          size: 75238,
        },
        {
          id: 'iab-nl-c-3096',
          label: 'Suikerfeest/Ramadan',
          size: 27768,
        },
      ],
    },
  ],
  sociodemographic: [
    {
      id: 'iab-nl-16',
      label: 'Education',
      size: 7020251,
      children: [
        {
          id: 'iab-nl-19',
          label: 'Low Education',
          size: 138074,
        },
        {
          id: 'iab-nl-21',
          label: 'Medium Eduction',
          size: 2677432,
        },
        {
          id: 'iab-nl-22',
          label: 'High Education',
          size: 4278261,
        },
        {
          id: 'iab-nl-23',
          label: 'Very High Education (PhD)',
          size: 4278261,
        },
      ],
    },
    {
      id: 'iab-nl-163',
      label: 'Income',
      size: 6705560,
      children: [
        {
          id: 'iab-nl-c-164',
          label: 'Low Income',
          size: 1365260,
        },
        {
          id: 'iab-nl-c-165',
          label: 'Medium Income',
          size: 4488800,
        },
        {
          id: 'iab-nl-c-166',
          label: 'High Income',
          size: 1107026,
        },
        {
          id: 'iab-nl-c-167',
          label: 'Very High Income (2.5x times average)',
          size: 194348,
        },
      ],
    },
    {
      id: 'iab-nl-45',
      label: 'Entrepreneurs',
      size: 470112,
      children: [
        {
          id: 'iab-nl-272',
          label: 'Large Business',
          size: 220,
        },
        {
          id: 'iab-nl-276',
          label: 'MKB / Small Business',
          size: 46400,
        },
        {
          id: 'iab-nl-33',
          label: 'Self Employed',
          size: 124427,
        },
      ],
    },
    {
      id: 'iab-nl-93',
      label: 'Lifestage',
      size: 9151463,
      children: [
        {
          id: 'iab-nl-100',
          label: 'Grandparents',
          size: 1929395,
        },
        {
          id: 'iab-nl-162',
          label: 'Single',
          size: 88850,
        },
        {
          id: 'iab-nl-36',
          label: 'Student',
          size: 317193,
        },
        {
          id: 'iab-nl-96',
          label: 'Couples',
          size: 195917,
        },
        {
          id: 'iab-nl-98',
          label: 'Parents',
          size: 8101719,
          children: [
            {
              id: 'iab-nl-354',
              label: 'Parenting Babies and Toddlers',
              size: 470455,
            },
            {
              id: 'iab-nl-355',
              label: 'Parenting Children Aged 4-11',
              size: 349975,
            },
            {
              id: 'iab-nl-356',
              label: 'Parenting Teens',
              size: 122455,
            },
          ],
        },
      ],
    },
    {
      id: 'iab-nl-c-52',
      label: 'Social Class',
      size: 12599746,
      children: [
        {
          id: 'iab-nl-c-54',
          label: 'Social Class A',
          size: 12505258,
        },
        {
          id: 'iab-nl-c-55',
          label: 'Social Class B',
          size: 5668723,
        },
        {
          id: 'iab-nl-c-56',
          label: 'Social Class C',
          size: 5678101,
        },
        {
          id: 'iab-nl-c-57',
          label: 'Social Class D',
          size: 1472348,
        },
      ],
    },
  ],
  contextualTargeting: [
    {
      id: 'context-391',
      label: 'Personal Finance',
      children: [
        {
          id: 'context-397',
          label: 'Frugal Living',
          description: 'frugal-living',
        },
        {
          id: 'context-396',
          label: 'Financial Planning',
          description: 'financial-planning',
        },
        {
          id: 'context-398',
          label: 'Insurance',
          children: [
            {
              id: 'context-401',
              label: 'Life Insurance',
              description: 'life-insurance',
            },
            {
              id: 'context-400',
              label: 'Home Insurance',
              description: 'home-insurance',
            },
            {
              id: 'context-399',
              label: 'Health Insurance',
              description: 'health-insurance',
            },
            {
              id: 'context-402',
              label: 'Motor Insurance',
              description: 'motor-insurance',
            },
            {
              id: 'context-403',
              label: 'Pet Insurance',
              description: 'pet-insurance',
            },
            {
              id: 'context-404',
              label: 'Travel Insurance',
              description: 'travel-insurance',
            },
          ],
          description: 'insurance',
        },
        {
          id: 'context-393',
          label: 'Financial Assistance',
          children: [
            {
              id: 'context-394',
              label: 'Government Support and Welfare',
              description: 'government-support-and-welfare',
            },
            {
              id: 'context-395',
              label: 'Student Financial Aid',
              description: 'student-financial-aid',
            },
          ],
          description: 'financial-assistance',
        },
        {
          id: 'context-392',
          label: 'Consumer Banking',
          description: 'consumer-banking',
        },
        {
          id: 'context-405',
          label: 'Personal Debt',
          children: [
            {
              id: 'context-407',
              label: 'Home Financing',
              description: 'home-financing',
            },
            {
              id: 'context-408',
              label: 'Personal Loans',
              description: 'personal-loans',
            },
            {
              id: 'context-409',
              label: 'Student Loans',
              description: 'student-loans',
            },
            {
              id: 'context-406',
              label: 'Credit Cards',
              description: 'credit-cards',
            },
          ],
          description: 'personal-debt',
        },
        {
          id: 'context-410',
          label: 'Personal Investing',
          children: [
            {
              id: 'context-414',
              label: 'Stocks and Bonds',
              description: 'stocks-and-bonds',
            },
            {
              id: 'context-413',
              label: 'Options',
              description: 'options',
            },
            {
              id: 'context-411',
              label: 'Hedge Funds',
              description: 'hedge-funds',
            },
            {
              id: 'context-412',
              label: 'Mutual Funds',
              description: 'mutual-funds',
            },
          ],
          description: 'personal-investing',
        },
        {
          id: 'context-417',
          label: 'Home Utilities',
          children: [
            {
              id: 'context-420',
              label: 'Phone Services',
              description: 'phone-services',
            },
            {
              id: 'context-421',
              label: 'Water Services',
              description: 'water-services',
            },
            {
              id: 'context-418',
              label: 'Gas and Electric',
              description: 'gas-and-electric',
            },
            {
              id: 'context-419',
              label: 'Internet Service Providers',
              description: 'internet-service-providers',
            },
          ],
          description: 'home-utilities',
        },
        {
          id: 'context-415',
          label: 'Personal Taxes',
          description: 'personal-taxes',
        },
        {
          id: 'context-416',
          label: 'Retirement Planning',
          description: 'retirement-planning',
        },
      ],
      description: 'personal-finance',
    },
    {
      id: 'context-680',
      label: 'Video Gaming',
      children: [
        {
          id: 'context-681',
          label: 'Console Games',
          description: 'console-games',
        },
        {
          id: 'context-682',
          label: 'eSports',
          description: 'esports',
        },
        {
          id: 'context-683',
          label: 'Mobile Games',
          description: 'mobile-games',
        },
        {
          id: 'context-684',
          label: 'PC Games',
          description: 'pc-games',
        },
        {
          id: 'context-685',
          label: 'Video Game Genres',
          children: [
            {
              id: 'context-686',
              label: 'Action Video Games',
              description: 'action-video-games',
            },
            {
              id: 'context-687',
              label: 'Role-Playing Video Games',
              description: 'role-playing-video-games',
            },
            {
              id: 'context-688',
              label: 'Simulation Video Games',
              description: 'simulation-video-games',
            },
            {
              id: 'context-689',
              label: 'Sports Video Games',
              description: 'sports-video-games',
            },
            {
              id: 'context-690',
              label: 'Strategy Video Games',
              description: 'strategy-video-games',
            },
            {
              id: 'context-691',
              label: 'Action-Adventure Video Games',
              description: 'action-adventure-video-games',
            },
            {
              id: 'context-692',
              label: 'Adventure Video Games',
              description: 'adventure-video-games',
            },
            {
              id: 'context-693',
              label: 'Casual Games',
              description: 'casual-games',
            },
            {
              id: 'context-694',
              label: 'Educational Video Games',
              description: 'educational-video-games',
            },
            {
              id: 'context-695',
              label: 'Exercise and Fitness Video Games',
              description: 'exercise-and-fitness-video-games',
            },
            {
              id: 'context-696',
              label: 'MMOs',
              description: 'mmos',
            },
            {
              id: 'context-697',
              label: 'Music and Party Video Games',
              description: 'music-and-party-video-games',
            },
            {
              id: 'context-698',
              label: 'Puzzle Video Games',
              description: 'puzzle-video-games',
            },
          ],
          description: 'video-game-genres',
        },
      ],
      description: 'video-gaming',
    },
    {
      id: 'context-223',
      label: 'Healthy Living',
      children: [
        {
          id: 'context-224',
          label: "Children's Health",
          description: 'children-s-health',
        },
        {
          id: 'context-225',
          label: 'Fitness and Exercise',
          children: [
            {
              id: 'context-226',
              label: 'Participant Sports',
              description: 'participant-sports',
            },
            {
              id: 'context-227',
              label: 'Running and Jogging',
              description: 'running-and-jogging',
            },
          ],
          description: 'fitness-and-exercise',
        },
        {
          id: 'context-228',
          label: "Men's Health",
          description: 'men-s-health',
        },
        {
          id: 'context-229',
          label: 'Nutrition',
          description: 'nutrition',
        },
        {
          id: 'context-232',
          label: 'Wellness',
          children: [
            {
              id: 'context-233',
              label: 'Alternative Medicine',
              children: [
                {
                  id: 'context-235',
                  label: 'Holistic Health',
                  description: 'holistic-health',
                },
                {
                  id: 'context-234',
                  label: 'Herbs and Supplements',
                  description: 'herbs-and-supplements',
                },
              ],
              description: 'alternative-medicine',
            },
            {
              id: 'context-237',
              label: 'Smoking Cessation',
              description: 'smoking-cessation',
            },
            {
              id: 'context-236',
              label: 'Physical Therapy',
              description: 'physical-therapy',
            },
          ],
          description: 'wellness',
        },
        {
          id: 'context-238',
          label: "Women's Health",
          description: 'women-s-health',
        },
        {
          id: 'context-230',
          label: 'Senior Health',
          description: 'senior-health',
        },
        {
          id: 'context-231',
          label: 'Weight Loss',
          description: 'weight-loss',
        },
      ],
      description: 'healthy-living',
    },
    {
      id: 'context-186',
      label: 'Family and Relationships',
      children: [
        {
          id: 'context-188',
          label: 'Dating',
          description: 'dating',
        },
        {
          id: 'context-187',
          label: 'Bereavement',
          description: 'bereavement',
        },
        {
          id: 'context-200',
          label: 'Single Life',
          description: 'single-life',
        },
        {
          id: 'context-189',
          label: 'Divorce',
          description: 'divorce',
        },
        {
          id: 'context-191',
          label: 'Marriage and Civil Unions',
          description: 'marriage-and-civil-unions',
        },
        {
          id: 'context-190',
          label: 'Eldercare',
          description: 'eldercare',
        },
        {
          id: 'context-192',
          label: 'Parenting',
          children: [
            {
              id: 'context-196',
              label: 'Parenting Babies and Toddlers',
              description: 'parenting-babies-and-toddlers',
            },
            {
              id: 'context-193',
              label: 'Adoption and Fostering',
              description: 'adoption-and-fostering',
            },
            {
              id: 'context-197',
              label: 'Parenting Children Aged 4-11',
              description: 'parenting-children-aged-4-11',
            },
            {
              id: 'context-194',
              label: 'Daycare and Pre-School',
              description: 'daycare-and-pre-school',
            },
            {
              id: 'context-195',
              label: 'Internet Safety',
              description: 'internet-safety',
            },
            {
              id: 'context-198',
              label: 'Parenting Teens',
              description: 'parenting-teens',
            },
            {
              id: 'context-199',
              label: 'Special Needs Kids',
              description: 'special-needs-kids',
            },
          ],
          description: 'parenting',
        },
      ],
      description: 'family-and-relationships',
    },
    {
      id: 'context-379',
      label: 'News and Politics',
      children: [
        {
          id: 'context-380',
          label: 'Crime',
          description: 'crime',
        },
        {
          id: 'context-383',
          label: 'Law',
          description: 'law',
        },
        {
          id: 'context-384',
          label: 'Local News',
          description: 'local-news',
        },
        {
          id: 'context-381',
          label: 'Disasters',
          description: 'disasters',
        },
        {
          id: 'context-382',
          label: 'International News',
          description: 'international-news',
        },
        {
          id: 'context-385',
          label: 'National News',
          description: 'national-news',
        },
        {
          id: 'context-386',
          label: 'Politics',
          children: [
            {
              id: 'context-387',
              label: 'Elections',
              description: 'elections',
            },
            {
              id: 'context-388',
              label: 'Political Issues',
              description: 'political-issues',
            },
            {
              id: 'context-389',
              label: 'War and Conflicts',
              description: 'war-and-conflicts',
            },
          ],
          description: 'politics',
        },
        {
          id: 'context-390',
          label: 'Weather',
          description: 'weather',
        },
      ],
      description: 'news-and-politics',
    },
    {
      id: 'context-596',
      label: 'Technology & Computing',
      children: [
        {
          id: 'context-597',
          label: 'Artificial Intelligence',
          description: 'artificial-intelligence',
        },
        {
          id: 'context-598',
          label: 'Augmented Reality',
          description: 'augmented-reality',
        },
        {
          id: 'context-599',
          label: 'Computing',
          children: [
            {
              id: 'context-600',
              label: 'Computer Networking',
              description: 'computer-networking',
            },
            {
              id: 'context-601',
              label: 'Computer Peripherals',
              description: 'computer-peripherals',
            },
            {
              id: 'context-602',
              label: 'Computer Software and Applications',
              children: [
                {
                  id: 'context-603',
                  label: '3-D Graphics',
                  description: '3-d-graphics',
                },
                {
                  id: 'context-604',
                  label: 'Photo Editing Software',
                  description: 'photo-editing-software',
                },
                {
                  id: 'context-605',
                  label: 'Shareware and Freeware',
                  description: 'shareware-and-freeware',
                },
                {
                  id: 'context-606',
                  label: 'Video Software',
                  description: 'video-software',
                },
                {
                  id: 'context-607',
                  label: 'Web Conferencing',
                  description: 'web-conferencing',
                },
                {
                  id: 'context-608',
                  label: 'Antivirus Software',
                  description: 'antivirus-software',
                },
                {
                  id: 'context-609',
                  label: 'Browsers',
                  description: 'browsers',
                },
                {
                  id: 'context-610',
                  label: 'Computer Animation',
                  description: 'computer-animation',
                },
                {
                  id: 'context-611',
                  label: 'Databases',
                  description: 'databases',
                },
                {
                  id: 'context-612',
                  label: 'Desktop Publishing',
                  description: 'desktop-publishing',
                },
                {
                  id: 'context-613',
                  label: 'Digital Audio',
                  description: 'digital-audio',
                },
                {
                  id: 'context-614',
                  label: 'Graphics Software',
                  description: 'graphics-software',
                },
                {
                  id: 'context-615',
                  label: 'Operating Systems',
                  description: 'operating-systems',
                },
              ],
              description: 'computer-software-and-applications',
            },
            {
              id: 'context-616',
              label: 'Data Storage and Warehousing',
              description: 'data-storage-and-warehousing',
            },
            {
              id: 'context-617',
              label: 'Desktops',
              description: 'desktops',
            },
            {
              id: 'context-618',
              label: 'Information and Network Security',
              description: 'information-and-network-security',
            },
            {
              id: 'context-619',
              label: 'Internet',
              children: [
                {
                  id: 'context-620',
                  label: 'Cloud Computing',
                  description: 'cloud-computing',
                },
                {
                  id: 'context-621',
                  label: 'Web Development',
                  description: 'web-development',
                },
                {
                  id: 'context-622',
                  label: 'Web Hosting',
                  description: 'web-hosting',
                },
                {
                  id: 'context-623',
                  label: 'Email',
                  description: 'email',
                },
                {
                  id: 'context-624',
                  label: 'Internet for Beginners',
                  description: 'internet-for-beginners',
                },
                {
                  id: 'context-625',
                  label: 'Internet of Things',
                  description: 'internet-of-things',
                },
                {
                  id: 'context-626',
                  label: 'IT and Internet Support',
                  description: 'it-and-internet-support',
                },
                {
                  id: 'context-627',
                  label: 'Search',
                  description: 'search',
                },
                {
                  id: 'context-628',
                  label: 'Social Networking',
                  description: 'social-networking',
                },
                {
                  id: 'context-629',
                  label: 'Web Design and HTML',
                  description: 'web-design-and-html',
                },
              ],
              description: 'internet',
            },
            {
              id: 'context-630',
              label: 'Laptops',
              description: 'laptops',
            },
            {
              id: 'context-631',
              label: 'Programming Languages',
              description: 'programming-languages',
            },
          ],
          description: 'computing',
        },
        {
          id: 'context-632',
          label: 'Consumer Electronics',
          children: [
            {
              id: 'context-633',
              label: 'Cameras and Camcorders',
              description: 'cameras-and-camcorders',
            },
            {
              id: 'context-634',
              label: 'Home Entertainment Systems',
              description: 'home-entertainment-systems',
            },
            {
              id: 'context-635',
              label: 'Smartphones',
              description: 'smartphones',
            },
            {
              id: 'context-636',
              label: 'Tablets and E-readers',
              description: 'tablets-and-e-readers',
            },
            {
              id: 'context-637',
              label: 'Wearable Technology',
              description: 'wearable-technology',
            },
          ],
          description: 'consumer-electronics',
        },
        {
          id: 'context-638',
          label: 'Robotics',
          description: 'robotics',
        },
        {
          id: 'context-639',
          label: 'Virtual Reality',
          description: 'virtual-reality',
        },
      ],
      description: 'technology-and-computing',
    },
    {
      id: 'context-150',
      label: 'Events and Attractions',
      children: [
        {
          id: 'context-155',
          label: 'Museums & Galleries',
          description: 'museums-and-galleries',
        },
        {
          id: 'context-184',
          label: 'Concerts & Music Events',
          description: 'concerts-and-music-events',
        },
        {
          id: 'context-160',
          label: 'Parks & Nature',
          description: 'parks-and-nature',
        },
        {
          id: 'context-163',
          label: 'Personal Celebrations & Life Events',
          children: [
            {
              id: 'context-164',
              label: 'Anniversary',
              description: 'anniversary',
            },
            {
              id: 'context-167',
              label: 'Bachelor Party',
              description: 'bachelor-party',
            },
            {
              id: 'context-168',
              label: 'Bachelorette Party',
              description: 'bachelorette-party',
            },
            {
              id: 'context-165',
              label: 'Wedding',
              description: 'wedding',
            },
            {
              id: 'context-166',
              label: 'Baby Shower',
              description: 'baby-shower',
            },
            {
              id: 'context-169',
              label: 'Birth',
              description: 'birth',
            },
            {
              id: 'context-170',
              label: 'Birthday',
              description: 'birthday',
            },
            {
              id: 'context-171',
              label: 'Funeral',
              description: 'funeral',
            },
            {
              id: 'context-172',
              label: 'Graduation',
              description: 'graduation',
            },
            {
              id: 'context-173',
              label: 'Prom',
              description: 'prom',
            },
          ],
          description: 'personal-celebrations-and-life-events',
        },
        {
          id: 'context-161',
          label: 'Party Supplies and Decorations',
          description: 'party-supplies-and-decorations',
        },
        {
          id: 'context-162',
          label: 'Awards Shows',
          description: 'awards-shows',
        },
        {
          id: 'context-152',
          label: 'Fashion Events',
          description: 'fashion-events',
        },
        {
          id: 'context-153',
          label: 'Historic Site and Landmark Tours',
          description: 'historic-site-and-landmark-tours',
        },
        {
          id: 'context-151',
          label: 'Amusement and Theme Parks',
          description: 'amusement-and-theme-parks',
        },
        {
          id: 'context-156',
          label: 'Musicals',
          description: 'musicals',
        },
        {
          id: 'context-157',
          label: 'National & Civic Holidays',
          description: 'national-and-civic-holidays',
        },
        {
          id: 'context-154',
          label: 'Malls & Shopping Centers',
          description: 'malls-and-shopping-centers',
        },
        {
          id: 'context-158',
          label: 'Nightclubs',
          description: 'nightclubs',
        },
        {
          id: 'context-159',
          label: 'Outdoor Activities',
          description: 'outdoor-activities',
        },
        {
          id: 'context-181',
          label: 'Casinos & Gambling',
          description: 'casinos-and-gambling',
        },
        {
          id: 'context-182',
          label: 'Cinemas and Events',
          description: 'cinemas-and-events',
        },
        {
          id: 'context-180',
          label: 'Business Expos & Conferences',
          description: 'business-expos-and-conferences',
        },
        {
          id: 'context-185',
          label: 'Fan Conventions',
          description: 'fan-conventions',
        },
        {
          id: 'context-183',
          label: 'Comedy Events',
          description: 'comedy-events',
        },
        {
          id: 'context-174',
          label: 'Political Event',
          description: 'political-event',
        },
        {
          id: 'context-175',
          label: 'Religious Events',
          description: 'religious-events',
        },
        {
          id: 'context-178',
          label: 'Zoos & Aquariums',
          description: 'zoos-and-aquariums',
        },
        {
          id: 'context-179',
          label: 'Bars & Restaurants',
          description: 'bars-and-restaurants',
        },
        {
          id: 'context-176',
          label: 'Sporting Events',
          description: 'sporting-events',
        },
        {
          id: 'context-177',
          label: 'Theater Venues and Events',
          description: 'theater-venues-and-events',
        },
      ],
      description: 'events-and-attractions',
    },
    {
      id: 'context-441',
      label: 'Real Estate',
      children: [
        {
          id: 'context-452',
          label: 'Real Estate Renting and Leasing',
          description: 'real-estate-renting-and-leasing',
        },
        {
          id: 'context-442',
          label: 'Apartments',
          description: 'apartments',
        },
        {
          id: 'context-443',
          label: 'Retail Property',
          description: 'retail-property',
        },
        {
          id: 'context-446',
          label: 'Hotel Properties',
          description: 'hotel-properties',
        },
        {
          id: 'context-447',
          label: 'Houses',
          description: 'houses',
        },
        {
          id: 'context-444',
          label: 'Vacation Properties',
          description: 'vacation-properties',
        },
        {
          id: 'context-445',
          label: 'Developmental Sites',
          description: 'developmental-sites',
        },
        {
          id: 'context-448',
          label: 'Industrial Property',
          description: 'industrial-property',
        },
        {
          id: 'context-449',
          label: 'Land and Farms',
          description: 'land-and-farms',
        },
        {
          id: 'context-450',
          label: 'Office Property',
          description: 'office-property',
        },
        {
          id: 'context-451',
          label: 'Real Estate Buying and Selling',
          description: 'real-estate-buying-and-selling',
        },
      ],
      description: 'real-estate',
    },
    {
      id: 'context-52',
      label: 'Business and Finance',
      children: [
        {
          id: 'context-53',
          label: 'Business',
          children: [
            {
              id: 'context-55',
              label: 'Human Resources',
              description: 'human-resources',
            },
            {
              id: 'context-57',
              label: 'Logistics',
              description: 'logistics',
            },
            {
              id: 'context-58',
              label: 'Marketing and Advertising',
              description: 'marketing-and-advertising',
            },
            {
              id: 'context-56',
              label: 'Large Business',
              description: 'large-business',
            },
            {
              id: 'context-59',
              label: 'Sales',
              description: 'sales',
            },
            {
              id: 'context-60',
              label: 'Small and Medium-sized Business',
              description: 'small-and-medium-sized-business',
            },
            {
              id: 'context-61',
              label: 'Startups',
              description: 'startups',
            },
            {
              id: 'context-62',
              label: 'Business Administration',
              description: 'business-administration',
            },
            {
              id: 'context-63',
              label: 'Business Banking & Finance',
              children: [
                {
                  id: 'context-64',
                  label: 'Angel Investment',
                  description: 'angel-investment',
                },
                {
                  id: 'context-65',
                  label: 'Bankruptcy',
                  description: 'bankruptcy',
                },
                {
                  id: 'context-68',
                  label: 'Mergers and Acquisitions',
                  description: 'mergers-and-acquisitions',
                },
                {
                  id: 'context-69',
                  label: 'Private Equity',
                  description: 'private-equity',
                },
                {
                  id: 'context-67',
                  label: 'Debt Factoring & Invoice Discounting',
                  description: 'debt-factoring-and-invoice-discounting',
                },
                {
                  id: 'context-70',
                  label: 'Sale & Lease Back',
                  description: 'sale-and-lease-back',
                },
                {
                  id: 'context-71',
                  label: 'Venture Capital',
                  description: 'venture-capital',
                },
                {
                  id: 'context-66',
                  label: 'Business Loans',
                  description: 'business-loans',
                },
              ],
              description: 'business-banking-and-finance',
            },
            {
              id: 'context-54',
              label: 'Business Accounting & Finance',
              description: 'business-accounting-and-finance',
            },
            {
              id: 'context-79',
              label: 'Business Utilities',
              description: 'business-utilities',
            },
            {
              id: 'context-77',
              label: 'Government Business',
              description: 'government-business',
            },
            {
              id: 'context-78',
              label: 'Green Solutions',
              description: 'green-solutions',
            },
            {
              id: 'context-76',
              label: 'Executive Leadership & Management',
              description: 'executive-leadership-and-management',
            },
            {
              id: 'context-74',
              label: 'Consumer Issues',
              children: [
                {
                  id: 'context-75',
                  label: 'Recalls',
                  description: 'recalls',
                },
              ],
              description: 'consumer-issues',
            },
            {
              id: 'context-73',
              label: 'Business Operations',
              description: 'business-operations',
            },
            {
              id: 'context-72',
              label: 'Business I.T.',
              description: 'business-i.t.',
            },
          ],
          description: 'business',
        },
        {
          id: 'context-80',
          label: 'Economy',
          children: [
            {
              id: 'context-82',
              label: 'Currencies',
              description: 'currencies',
            },
            {
              id: 'context-83',
              label: 'Financial Crisis',
              description: 'financial-crisis',
            },
            {
              id: 'context-81',
              label: 'Commodities',
              description: 'commodities',
            },
            {
              id: 'context-87',
              label: 'Housing Market',
              description: 'housing-market',
            },
            {
              id: 'context-88',
              label: 'Interest Rates',
              description: 'interest-rates',
            },
            {
              id: 'context-86',
              label: 'Gasoline Prices',
              description: 'gasoline-prices',
            },
            {
              id: 'context-89',
              label: 'Job Market',
              description: 'job-market',
            },
            {
              id: 'context-85',
              label: 'Financial Regulation',
              description: 'financial-regulation',
            },
            {
              id: 'context-84',
              label: 'Financial Reform',
              description: 'financial-reform',
            },
          ],
          description: 'economy',
        },
        {
          id: 'context-90',
          label: 'Industries',
          children: [
            {
              id: 'context-120',
              label: 'Civil Engineering Industry',
              description: 'civil-engineering-industry',
            },
            {
              id: 'context-121',
              label: 'Construction Industry',
              description: 'construction-industry',
            },
            {
              id: 'context-122',
              label: 'Defense Industry',
              description: 'defense-industry',
            },
            {
              id: 'context-110',
              label: 'Power and Energy Industry',
              description: 'power-and-energy-industry',
            },
            {
              id: 'context-111',
              label: 'Publishing Industry',
              description: 'publishing-industry',
            },
            {
              id: 'context-119',
              label: 'Biotech and Biomedical Industry',
              description: 'biotech-and-biomedical-industry',
            },
            {
              id: 'context-115',
              label: 'Technology Industry',
              description: 'technology-industry',
            },
            {
              id: 'context-114',
              label: 'Retail Industry',
              description: 'retail-industry',
            },
            {
              id: 'context-117',
              label: 'Automotive Industry',
              description: 'automotive-industry',
            },
            {
              id: 'context-116',
              label: 'Telecommunications Industry',
              description: 'telecommunications-industry',
            },
            {
              id: 'context-118',
              label: 'Aviation Industry',
              description: 'aviation-industry',
            },
            {
              id: 'context-93',
              label: 'Entertainment Industry',
              description: 'entertainment-industry',
            },
            {
              id: 'context-94',
              label: 'Environmental Services Industry',
              description: 'environmental-services-industry',
            },
            {
              id: 'context-91',
              label: 'Advertising Industry',
              description: 'advertising-industry',
            },
            {
              id: 'context-92',
              label: 'Education industry',
              description: 'education-industry',
            },
            {
              id: 'context-97',
              label: 'Healthcare Industry',
              description: 'healthcare-industry',
            },
            {
              id: 'context-98',
              label: 'Hospitality Industry',
              description: 'hospitality-industry',
            },
            {
              id: 'context-95',
              label: 'Financial Industry',
              description: 'financial-industry',
            },
            {
              id: 'context-96',
              label: 'Food Industry',
              description: 'food-industry',
            },
            {
              id: 'context-113',
              label: 'Apparel Industry',
              description: 'apparel-industry',
            },
            {
              id: 'context-99',
              label: 'Information Services Industry',
              description: 'information-services-industry',
            },
            {
              id: 'context-101',
              label: 'Logistics and Transportation Industry',
              description: 'logistics-and-transportation-industry',
            },
            {
              id: 'context-102',
              label: 'Agriculture',
              description: 'agriculture',
            },
            {
              id: 'context-100',
              label: 'Legal Services Industry',
              description: 'legal-services-industry',
            },
            {
              id: 'context-105',
              label: 'Mechanical and Industrial Engineering Industry',
              description: 'mechanical-and-industrial-engineering-industry',
            },
            {
              id: 'context-106',
              label: 'Media Industry',
              description: 'media-industry',
            },
            {
              id: 'context-103',
              label: 'Management Consulting Industry',
              description: 'management-consulting-industry',
            },
            {
              id: 'context-104',
              label: 'Manufacturing Industry',
              description: 'manufacturing-industry',
            },
            {
              id: 'context-109',
              label: 'Pharmaceutical Industry',
              description: 'pharmaceutical-industry',
            },
            {
              id: 'context-107',
              label: 'Metals Industry',
              description: 'metals-industry',
            },
            {
              id: 'context-108',
              label: 'Non-Profit Organizations',
              description: 'non-profit-organizations',
            },
            {
              id: 'context-112',
              label: 'Real Estate Industry',
              description: 'real-estate-industry',
            },
          ],
          description: 'industries',
        },
      ],
      description: 'business-and-finance',
    },
    {
      id: 'context-201',
      label: 'Fine Art',
      children: [
        {
          id: 'context-204',
          label: 'Design',
          description: 'design',
        },
        {
          id: 'context-205',
          label: 'Digital Arts',
          description: 'digital-arts',
        },
        {
          id: 'context-202',
          label: 'Costume',
          description: 'costume',
        },
        {
          id: 'context-203',
          label: 'Dance',
          description: 'dance',
        },
        {
          id: 'context-208',
          label: 'Opera',
          description: 'opera',
        },
        {
          id: 'context-209',
          label: 'Theater',
          description: 'theater',
        },
        {
          id: 'context-206',
          label: 'Fine Art Photography',
          description: 'fine-art-photography',
        },
        {
          id: 'context-207',
          label: 'Modern Art',
          description: 'modern-art',
        },
      ],
      description: 'fine-art',
    },
    {
      id: 'context-324',
      label: 'Movies',
      children: [
        {
          id: 'context-329',
          label: 'Animation Movies',
          description: 'animation-movies',
        },
        {
          id: 'context-327',
          label: 'Science Fiction Movies',
          description: 'science-fiction-movies',
        },
        {
          id: 'context-328',
          label: 'Special Interest Movies',
          description: 'special-interest-movies',
        },
        {
          id: 'context-325',
          label: 'Action and Adventure Movies',
          description: 'action-and-adventure-movies',
        },
        {
          id: 'context-332',
          label: 'Documentary Movies',
          description: 'documentary-movies',
        },
        {
          id: 'context-333',
          label: 'Drama Movies',
          description: 'drama-movies',
        },
        {
          id: 'context-330',
          label: 'Comedy Movies',
          description: 'comedy-movies',
        },
        {
          id: 'context-331',
          label: 'Crime and Mystery Movies',
          description: 'crime-and-mystery-movies',
        },
        {
          id: 'context-336',
          label: 'Horror Movies',
          description: 'horror-movies',
        },
        {
          id: 'context-337',
          label: 'World Movies',
          description: 'world-movies',
        },
        {
          id: 'context-334',
          label: 'Family and Children Movies',
          description: 'family-and-children-movies',
        },
        {
          id: 'context-335',
          label: 'Fantasy Movies',
          description: 'fantasy-movies',
        },
        {
          id: 'context-326',
          label: 'Romance Movies',
          description: 'romance-movies',
        },
      ],
      description: 'movies',
    },
    {
      id: 'context-453',
      label: 'Religion & Spirituality',
      children: [
        {
          id: 'context-462',
          label: 'Judaism',
          description: 'judaism',
        },
        {
          id: 'context-456',
          label: 'Astrology',
          description: 'astrology',
        },
        {
          id: 'context-463',
          label: 'Sikhism',
          description: 'sikhism',
        },
        {
          id: 'context-455',
          label: 'Spirituality',
          description: 'spirituality',
        },
        {
          id: 'context-458',
          label: 'Buddhism',
          description: 'buddhism',
        },
        {
          id: 'context-460',
          label: 'Hinduism',
          description: 'hinduism',
        },
        {
          id: 'context-454',
          label: 'Agnosticism',
          description: 'agnosticism',
        },
        {
          id: 'context-457',
          label: 'Atheism',
          description: 'atheism',
        },
        {
          id: 'context-459',
          label: 'Christianity',
          description: 'christianity',
        },
        {
          id: 'context-461',
          label: 'Islam',
          description: 'islam',
        },
      ],
      description: 'religion-and-spirituality',
    },
    {
      id: 'context-239',
      label: 'Hobbies & Interests',
      children: [
        {
          id: 'context-273',
          label: 'Genealogy and Ancestry',
          description: 'genealogy-and-ancestry',
        },
        {
          id: 'context-269',
          label: 'Games and Puzzles',
          children: [
            {
              id: 'context-270',
              label: 'Board Games and Puzzles',
              description: 'board-games-and-puzzles',
            },
            {
              id: 'context-271',
              label: 'Card Games',
              description: 'card-games',
            },
            {
              id: 'context-272',
              label: 'Roleplaying Games',
              description: 'roleplaying-games',
            },
          ],
          description: 'games-and-puzzles',
        },
        {
          id: 'context-259',
          label: 'Birdwatching',
          description: 'birdwatching',
        },
        {
          id: 'context-258',
          label: 'Beekeeping',
          description: 'beekeeping',
        },
        {
          id: 'context-240',
          label: 'Antiquing and Antiques',
          description: 'antiquing-and-antiques',
        },
        {
          id: 'context-245',
          label: 'Radio Control',
          description: 'radio-control',
        },
        {
          id: 'context-242',
          label: 'Model Toys',
          description: 'model-toys',
        },
        {
          id: 'context-243',
          label: 'Musical Instruments',
          description: 'musical-instruments',
        },
        {
          id: 'context-247',
          label: 'Workshops and Classes',
          description: 'workshops-and-classes',
        },
        {
          id: 'context-241',
          label: 'Magic and Illusion',
          description: 'magic-and-illusion',
        },
        {
          id: 'context-246',
          label: 'Sci-fi and Fantasy',
          description: 'sci-fi-and-fantasy',
        },
        {
          id: 'context-248',
          label: 'Arts and Crafts',
          children: [
            {
              id: 'context-251',
              label: 'Drawing and Sketching',
              description: 'drawing-and-sketching',
            },
            {
              id: 'context-252',
              label: 'Jewelry Making',
              description: 'jewelry-making',
            },
            {
              id: 'context-250',
              label: 'Candle and Soap Making',
              description: 'candle-and-soap-making',
            },
            {
              id: 'context-255',
              label: 'Photography',
              description: 'photography',
            },
            {
              id: 'context-256',
              label: 'Scrapbooking',
              description: 'scrapbooking',
            },
            {
              id: 'context-253',
              label: 'Needlework',
              description: 'needlework',
            },
            {
              id: 'context-254',
              label: 'Painting',
              description: 'painting',
            },
            {
              id: 'context-257',
              label: 'Woodworking',
              description: 'woodworking',
            },
            {
              id: 'context-249',
              label: 'Beadwork',
              description: 'beadwork',
            },
          ],
          description: 'arts-and-crafts',
        },
        {
          id: 'context-244',
          label: 'Paranormal Phenomena',
          description: 'paranormal-phenomena',
        },
        {
          id: 'context-260',
          label: 'Cigars',
          description: 'cigars',
        },
        {
          id: 'context-261',
          label: 'Collecting',
          children: [
            {
              id: 'context-263',
              label: 'Stamps and Coins',
              description: 'stamps-and-coins',
            },
            {
              id: 'context-262',
              label: 'Comic Books',
              description: 'comic-books',
            },
          ],
          description: 'collecting',
        },
        {
          id: 'context-264',
          label: 'Content Production',
          children: [
            {
              id: 'context-266',
              label: 'Freelance Writing',
              description: 'freelance-writing',
            },
            {
              id: 'context-267',
              label: 'Screenwriting',
              description: 'screenwriting',
            },
            {
              id: 'context-265',
              label: 'Audio Production',
              description: 'audio-production',
            },
            {
              id: 'context-268',
              label: 'Video Production',
              description: 'video-production',
            },
          ],
          description: 'content-production',
        },
      ],
      description: 'hobbies-and-interests',
    },
    {
      id: 'context-422',
      label: 'Pets',
      children: [
        {
          id: 'context-424',
          label: 'Cats',
          description: 'cats',
        },
        {
          id: 'context-425',
          label: 'Dogs',
          description: 'dogs',
        },
        {
          id: 'context-423',
          label: 'Birds',
          description: 'birds',
        },
        {
          id: 'context-428',
          label: 'Pet Adoptions',
          description: 'pet-adoptions',
        },
        {
          id: 'context-429',
          label: 'Reptiles',
          description: 'reptiles',
        },
        {
          id: 'context-426',
          label: 'Fish and Aquariums',
          description: 'fish-and-aquariums',
        },
        {
          id: 'context-427',
          label: 'Large Animals',
          description: 'large-animals',
        },
        {
          id: 'context-431',
          label: 'Pet Supplies',
          description: 'pet-supplies',
        },
        {
          id: 'context-430',
          label: 'Veterinary Medicine',
          description: 'veterinary-medicine',
        },
      ],
      description: 'pets',
    },
    {
      id: 'context-640',
      label: 'Television',
      children: [
        {
          id: 'context-641',
          label: 'Animation TV',
          description: 'animation-tv',
        },
        {
          id: 'context-642',
          label: 'Soap Opera TV',
          description: 'soap-opera-tv',
        },
        {
          id: 'context-643',
          label: 'Special Interest TV',
          description: 'special-interest-tv',
        },
        {
          id: 'context-644',
          label: 'Sports TV',
          description: 'sports-tv',
        },
        {
          id: 'context-645',
          label: "Children's TV",
          description: 'children-s-tv',
        },
        {
          id: 'context-646',
          label: 'Comedy TV',
          description: 'comedy-tv',
        },
        {
          id: 'context-647',
          label: 'Drama TV',
          description: 'drama-tv',
        },
        {
          id: 'context-648',
          label: 'Factual TV',
          description: 'factual-tv',
        },
        {
          id: 'context-649',
          label: 'Holiday TV',
          description: 'holiday-tv',
        },
        {
          id: 'context-650',
          label: 'Music TV',
          description: 'music-tv',
        },
        {
          id: 'context-651',
          label: 'Reality TV',
          description: 'reality-tv',
        },
        {
          id: 'context-652',
          label: 'Science Fiction TV',
          description: 'science-fiction-tv',
        },
      ],
      description: 'television',
    },
    {
      id: 'context-210',
      label: 'Food & Drink',
      children: [
        {
          id: 'context-220',
          label: 'Food Movements',
          description: 'food-movements',
        },
        {
          id: 'context-221',
          label: 'Healthy Cooking and Eating',
          description: 'healthy-cooking-and-eating',
        },
        {
          id: 'context-222',
          label: 'Non-Alcoholic Beverages',
          description: 'non-alcoholic-beverages',
        },
        {
          id: 'context-215',
          label: 'Barbecues and Grilling',
          description: 'barbecues-and-grilling',
        },
        {
          id: 'context-211',
          label: 'Alcoholic Beverages',
          description: 'alcoholic-beverages',
        },
        {
          id: 'context-217',
          label: 'Desserts and Baking',
          description: 'desserts-and-baking',
        },
        {
          id: 'context-219',
          label: 'Food Allergies',
          description: 'food-allergies',
        },
        {
          id: 'context-214',
          label: 'World Cuisines',
          description: 'world-cuisines',
        },
        {
          id: 'context-216',
          label: 'Cooking',
          description: 'cooking',
        },
        {
          id: 'context-212',
          label: 'Vegan Diets',
          description: 'vegan-diets',
        },
        {
          id: 'context-213',
          label: 'Vegetarian Diets',
          description: 'vegetarian-diets',
        },
        {
          id: 'context-218',
          label: 'Dining Out',
          description: 'dining-out',
        },
      ],
      description: 'food-and-drink',
    },
    {
      id: 'context-286',
      label: 'Medical Health',
      children: [
        {
          id: 'context-323',
          label: 'Cosmetic Medical Services',
          description: 'cosmetic-medical-services',
        },
        {
          id: 'context-319',
          label: 'Medical Tests',
          description: 'medical-tests',
        },
        {
          id: 'context-287',
          label: 'Diseases and Conditions',
          children: [
            {
              id: 'context-317',
              label: 'Diabetes',
              description: 'diabetes',
            },
            {
              id: 'context-298',
              label: 'Injuries',
              children: [
                {
                  id: 'context-299',
                  label: 'First Aid',
                  description: 'first-aid',
                },
              ],
              description: 'injuries',
            },
            {
              id: 'context-294',
              label: 'Eye and Vision Conditions',
              description: 'eye-and-vision-conditions',
            },
            {
              id: 'context-310',
              label: 'Sleep Disorders',
              description: 'sleep-disorders',
            },
            {
              id: 'context-314',
              label: 'Cancer',
              description: 'cancer',
            },
            {
              id: 'context-315',
              label: 'Cold and Flu',
              description: 'cold-and-flu',
            },
            {
              id: 'context-312',
              label: 'Bone and Joint Conditions',
              description: 'bone-and-joint-conditions',
            },
            {
              id: 'context-316',
              label: 'Dental Health',
              description: 'dental-health',
            },
            {
              id: 'context-288',
              label: 'Allergies',
              description: 'allergies',
            },
            {
              id: 'context-289',
              label: 'Ear, Nose and Throat Conditions',
              description: 'ear--nose-and-throat-conditions',
            },
            {
              id: 'context-297',
              label: 'Infectious Diseases',
              description: 'infectious-diseases',
            },
            {
              id: 'context-300',
              label: 'Lung and Respiratory Health',
              description: 'lung-and-respiratory-health',
            },
            {
              id: 'context-301',
              label: 'Mental Health',
              description: 'mental-health',
            },
            {
              id: 'context-302',
              label: 'Reproductive Health',
              children: [
                {
                  id: 'context-303',
                  label: 'Birth Control',
                  description: 'birth-control',
                },
                {
                  id: 'context-304',
                  label: 'Infertility',
                  description: 'infertility',
                },
                {
                  id: 'context-305',
                  label: 'Pregnancy',
                  description: 'pregnancy',
                },
              ],
              description: 'reproductive-health',
            },
            {
              id: 'context-307',
              label: 'Sexual Health',
              children: [
                {
                  id: 'context-308',
                  label: 'Sexual Conditions',
                  description: 'sexual-conditions',
                },
              ],
              description: 'sexual-health',
            },
            {
              id: 'context-306',
              label: 'Blood Disorders',
              description: 'blood-disorders',
            },
            {
              id: 'context-309',
              label: 'Skin and Dermatology',
              description: 'skin-and-dermatology',
            },
            {
              id: 'context-318',
              label: 'Digestive Disorders',
              description: 'digestive-disorders',
            },
            {
              id: 'context-313',
              label: 'Brain and Nervous System Disorders',
              description: 'brain-and-nervous-system-disorders',
            },
            {
              id: 'context-311',
              label: 'Substance Abuse',
              description: 'substance-abuse',
            },
            {
              id: 'context-290',
              label: 'Endocrine and Metabolic Diseases',
              children: [
                {
                  id: 'context-291',
                  label: 'Hormonal Disorders',
                  description: 'hormonal-disorders',
                },
                {
                  id: 'context-292',
                  label: 'Menopause',
                  description: 'menopause',
                },
                {
                  id: 'context-293',
                  label: 'Thyroid Disorders',
                  description: 'thyroid-disorders',
                },
              ],
              description: 'endocrine-and-metabolic-diseases',
            },
            {
              id: 'context-295',
              label: 'Foot Health',
              description: 'foot-health',
            },
            {
              id: 'context-296',
              label: 'Heart and Cardiovascular Diseases',
              description: 'heart-and-cardiovascular-diseases',
            },
          ],
          description: 'diseases-and-conditions',
        },
        {
          id: 'context-321',
          label: 'Surgery',
          description: 'surgery',
        },
        {
          id: 'context-322',
          label: 'Vaccines',
          description: 'vaccines',
        },
        {
          id: 'context-320',
          label: 'Pharmaceutical Drugs',
          description: 'pharmaceutical-drugs',
        },
      ],
      description: 'medical-health',
    },
    {
      id: 'context-552',
      label: 'Style & Fashion',
      children: [
        {
          id: 'context-553',
          label: 'Beauty',
          children: [
            {
              id: 'context-554',
              label: 'Hair Care',
              description: 'hair-care',
            },
            {
              id: 'context-555',
              label: 'Makeup and Accessories',
              description: 'makeup-and-accessories',
            },
            {
              id: 'context-556',
              label: 'Nail Care',
              description: 'nail-care',
            },
            {
              id: 'context-557',
              label: 'Natural and Organic Beauty',
              description: 'natural-and-organic-beauty',
            },
            {
              id: 'context-558',
              label: 'Perfume and Fragrance',
              description: 'perfume-and-fragrance',
            },
            {
              id: 'context-559',
              label: 'Skin Care',
              description: 'skin-care',
            },
          ],
          description: 'beauty',
        },
        {
          id: 'context-560',
          label: "Women's Fashion",
          children: [
            {
              id: 'context-561',
              label: "Women's Accessories",
              children: [
                {
                  id: 'context-562',
                  label: "Women's Glasses",
                  description: 'women-s-glasses',
                },
                {
                  id: 'context-563',
                  label: "Women's Handbags and Wallets",
                  description: 'women-s-handbags-and-wallets',
                },
                {
                  id: 'context-564',
                  label: "Women's Hats and Scarves",
                  description: 'women-s-hats-and-scarves',
                },
                {
                  id: 'context-565',
                  label: "Women's Jewelry and Watches",
                  description: 'women-s-jewelry-and-watches',
                },
              ],
              description: 'women-s-accessories',
            },
            {
              id: 'context-566',
              label: "Women's Clothing",
              children: [
                {
                  id: 'context-567',
                  label: "Women's Business Wear",
                  description: 'women-s-business-wear',
                },
                {
                  id: 'context-568',
                  label: "Women's Casual Wear",
                  description: 'women-s-casual-wear',
                },
                {
                  id: 'context-569',
                  label: "Women's Formal Wear",
                  description: 'women-s-formal-wear',
                },
                {
                  id: 'context-570',
                  label: "Women's Intimates and Sleepwear",
                  description: 'women-s-intimates-and-sleepwear',
                },
                {
                  id: 'context-571',
                  label: "Women's Outerwear",
                  description: 'women-s-outerwear',
                },
                {
                  id: 'context-572',
                  label: "Women's Sportswear",
                  description: 'women-s-sportswear',
                },
              ],
              description: 'women-s-clothing',
            },
            {
              id: 'context-573',
              label: "Women's Shoes and Footwear",
              description: 'women-s-shoes-and-footwear',
            },
          ],
          description: 'women-s-fashion',
        },
        {
          id: 'context-574',
          label: 'Body Art',
          description: 'body-art',
        },
        {
          id: 'context-575',
          label: "Children's Clothing",
          description: 'children-s-clothing',
        },
        {
          id: 'context-576',
          label: 'Designer Clothing',
          description: 'designer-clothing',
        },
        {
          id: 'context-577',
          label: 'Fashion Trends',
          description: 'fashion-trends',
        },
        {
          id: 'context-578',
          label: 'High Fashion',
          description: 'high-fashion',
        },
        {
          id: 'context-579',
          label: "Men's Fashion",
          children: [
            {
              id: 'context-580',
              label: "Men's Accessories",
              children: [
                {
                  id: 'context-581',
                  label: "Men's Jewelry and Watches",
                  description: 'men-s-jewelry-and-watches',
                },
              ],
              description: 'men-s-accessories',
            },
            {
              id: 'context-582',
              label: "Men's Clothing",
              children: [
                {
                  id: 'context-583',
                  label: "Men's Business Wear",
                  description: 'men-s-business-wear',
                },
                {
                  id: 'context-584',
                  label: "Men's Casual Wear",
                  description: 'men-s-casual-wear',
                },
                {
                  id: 'context-585',
                  label: "Men's Formal Wear",
                  description: 'men-s-formal-wear',
                },
                {
                  id: 'context-586',
                  label: "Men's Outerwear",
                  description: 'men-s-outerwear',
                },
                {
                  id: 'context-587',
                  label: "Men's Sportswear",
                  description: 'men-s-sportswear',
                },
                {
                  id: 'context-588',
                  label: "Men's Underwear and Sleepwear",
                  description: 'men-s-underwear-and-sleepwear',
                },
              ],
              description: 'men-s-clothing',
            },
            {
              id: 'context-589',
              label: "Men's Shoes and Footwear",
              description: 'men-s-shoes-and-footwear',
            },
          ],
          description: 'men-s-fashion',
        },
        {
          id: 'context-590',
          label: 'Personal Care',
          children: [
            {
              id: 'context-591',
              label: 'Bath and Shower',
              description: 'bath-and-shower',
            },
            {
              id: 'context-592',
              label: 'Deodorant and Antiperspirant',
              description: 'deodorant-and-antiperspirant',
            },
            {
              id: 'context-593',
              label: 'Oral care',
              description: 'oral-care',
            },
            {
              id: 'context-594',
              label: 'Shaving',
              description: 'shaving',
            },
          ],
          description: 'personal-care',
        },
        {
          id: 'context-595',
          label: 'Street Style',
          description: 'street-style',
        },
      ],
      description: 'style-and-fashion',
    },
    {
      id: 'context-338',
      label: 'Music and Audio',
      children: [
        {
          id: 'context-370',
          label: 'Sports Radio',
          description: 'sports-radio',
        },
        {
          id: 'context-371',
          label: 'Talk Radio',
          children: [
            {
              id: 'context-372',
              label: 'Business News Radio',
              description: 'business-news-radio',
            },
            {
              id: 'context-373',
              label: 'Educational Radio',
              description: 'educational-radio',
            },
            {
              id: 'context-376',
              label: 'Public Radio',
              description: 'public-radio',
            },
            {
              id: 'context-374',
              label: 'News Radio',
              description: 'news-radio',
            },
            {
              id: 'context-375',
              label: 'News/Talk Radio',
              description: 'news-talk-radio',
            },
          ],
          description: 'talk-radio',
        },
        {
          id: 'context-377',
          label: 'Urban Contemporary Music',
          description: 'urban-contemporary-music',
        },
        {
          id: 'context-343',
          label: 'Alternative Music',
          description: 'alternative-music',
        },
        {
          id: 'context-344',
          label: "Children's Music",
          description: 'children-s-music',
        },
        {
          id: 'context-342',
          label: 'Adult Album Alternative',
          description: 'adult-album-alternative',
        },
        {
          id: 'context-347',
          label: 'College Radio',
          description: 'college-radio',
        },
        {
          id: 'context-348',
          label: 'Comedy (Music and Audio)',
          description: 'comedy-music-and-audio-',
        },
        {
          id: 'context-345',
          label: 'Classic Hits',
          description: 'classic-hits',
        },
        {
          id: 'context-346',
          label: 'Classical Music',
          description: 'classical-music',
        },
        {
          id: 'context-349',
          label: 'Contemporary Hits/Pop/Top 40',
          description: 'contemporary-hits-pop-top-40',
        },
        {
          id: 'context-378',
          label: 'Variety (Music and Audio)',
          description: 'variety-music-and-audio-',
        },
        {
          id: 'context-339',
          label: 'Adult Contemporary Music',
          children: [
            {
              id: 'context-341',
              label: 'Urban AC Music',
              description: 'urban-ac-music',
            },
            {
              id: 'context-340',
              label: 'Soft AC Music',
              description: 'soft-ac-music',
            },
          ],
          description: 'adult-contemporary-music',
        },
        {
          id: 'context-361',
          label: 'Religious (Music and Audio)',
          description: 'religious-music-and-audio-',
        },
        {
          id: 'context-362',
          label: 'R&B/Soul/Funk',
          description: 'randb-soul-funk',
        },
        {
          id: 'context-360',
          label: 'Blues',
          description: 'blues',
        },
        {
          id: 'context-363',
          label: 'Rock Music',
          children: [
            {
              id: 'context-365',
              label: 'Alternative Rock',
              description: 'alternative-rock',
            },
            {
              id: 'context-366',
              label: 'Classic Rock',
              description: 'classic-rock',
            },
            {
              id: 'context-364',
              label: 'Album-oriented Rock',
              description: 'album-oriented-rock',
            },
            {
              id: 'context-367',
              label: 'Hard Rock',
              description: 'hard-rock',
            },
            {
              id: 'context-368',
              label: 'Soft Rock',
              description: 'soft-rock',
            },
          ],
          description: 'rock-music',
        },
        {
          id: 'context-369',
          label: 'Soundtracks, TV and Showtunes',
          description: 'soundtracks--tv-and-showtunes',
        },
        {
          id: 'context-350',
          label: 'Country Music',
          description: 'country-music',
        },
        {
          id: 'context-351',
          label: 'Dance and Electronic Music',
          description: 'dance-and-electronic-music',
        },
        {
          id: 'context-354',
          label: 'Gospel Music',
          description: 'gospel-music',
        },
        {
          id: 'context-355',
          label: 'Hip Hop Music',
          description: 'hip-hop-music',
        },
        {
          id: 'context-352',
          label: 'World/International Music',
          description: 'world-international-music',
        },
        {
          id: 'context-353',
          label: 'Songwriters/Folk',
          description: 'songwriters-folk',
        },
        {
          id: 'context-358',
          label: 'Oldies/Adult Standards',
          description: 'oldies-adult-standards',
        },
        {
          id: 'context-359',
          label: 'Reggae',
          description: 'reggae',
        },
        {
          id: 'context-356',
          label: 'Inspirational/New Age Music',
          description: 'inspirational-new-age-music',
        },
        {
          id: 'context-357',
          label: 'Jazz',
          description: 'jazz',
        },
      ],
      description: 'music-and-audio',
    },
    {
      id: 'context-432',
      label: 'Pop Culture',
      children: [
        {
          id: 'context-434',
          label: 'Celebrity Families',
          description: 'celebrity-families',
        },
        {
          id: 'context-440',
          label: 'Humor and Satire',
          description: 'humor-and-satire',
        },
        {
          id: 'context-436',
          label: 'Celebrity Pregnancy',
          description: 'celebrity-pregnancy',
        },
        {
          id: 'context-433',
          label: 'Celebrity Deaths',
          description: 'celebrity-deaths',
        },
        {
          id: 'context-437',
          label: 'Celebrity Relationships',
          description: 'celebrity-relationships',
        },
        {
          id: 'context-438',
          label: 'Celebrity Scandal',
          description: 'celebrity-scandal',
        },
        {
          id: 'context-435',
          label: 'Celebrity Homes',
          description: 'celebrity-homes',
        },
        {
          id: 'context-439',
          label: 'Celebrity Style',
          description: 'celebrity-style',
        },
      ],
      description: 'pop-culture',
    },
    {
      id: 'context-1',
      label: 'Automotive',
      children: [
        {
          id: 'context-28',
          label: 'Road-Side Assistance',
          description: 'road-side-assistance',
        },
        {
          id: 'context-25',
          label: 'Car Culture',
          description: 'car-culture',
        },
        {
          id: 'context-26',
          label: 'Dash Cam Videos',
          description: 'dash-cam-videos',
        },
        {
          id: 'context-27',
          label: 'Motorcycles',
          description: 'motorcycles',
        },
        {
          id: 'context-2',
          label: 'Auto Body Styles',
          children: [
            {
              id: 'context-3',
              label: 'Commercial Trucks',
              description: 'commercial-trucks',
            },
            {
              id: 'context-6',
              label: 'SUV',
              description: 'suv',
            },
            {
              id: 'context-7',
              label: 'Van',
              description: 'van',
            },
            {
              id: 'context-4',
              label: 'Sedan',
              description: 'sedan',
            },
            {
              id: 'context-5',
              label: 'Station Wagon',
              description: 'station-wagon',
            },
            {
              id: 'context-8',
              label: 'Convertible',
              description: 'convertible',
            },
            {
              id: 'context-9',
              label: 'Coupe',
              description: 'coupe',
            },
            {
              id: 'context-13',
              label: 'Minivan',
              description: 'minivan',
            },
            {
              id: 'context-14',
              label: 'Off-Road Vehicles',
              description: 'off-road-vehicles',
            },
            {
              id: 'context-11',
              label: 'Hatchback',
              description: 'hatchback',
            },
            {
              id: 'context-12',
              label: 'Microcar',
              description: 'microcar',
            },
            {
              id: 'context-15',
              label: 'Pickup Trucks',
              description: 'pickup-trucks',
            },
            {
              id: 'context-10',
              label: 'Crossover',
              description: 'crossover',
            },
          ],
          description: 'auto-body-styles',
        },
        {
          id: 'context-41',
          label: 'Auto Rentals',
          description: 'auto-rentals',
        },
        {
          id: 'context-29',
          label: 'Scooters',
          description: 'scooters',
        },
        {
          id: 'context-37',
          label: 'Auto Technology',
          children: [
            {
              id: 'context-40',
              label: 'Auto Safety Technologies',
              description: 'auto-safety-technologies',
            },
            {
              id: 'context-39',
              label: 'Auto Navigation Systems',
              description: 'auto-navigation-systems',
            },
            {
              id: 'context-38',
              label: 'Auto Infotainment Technologies',
              description: 'auto-infotainment-technologies',
            },
          ],
          description: 'auto-technology',
        },
        {
          id: 'context-36',
          label: 'Auto Shows',
          description: 'auto-shows',
        },
        {
          id: 'context-35',
          label: 'Auto Safety',
          description: 'auto-safety',
        },
        {
          id: 'context-31',
          label: 'Auto Insurance',
          description: 'auto-insurance',
        },
        {
          id: 'context-32',
          label: 'Auto Parts',
          description: 'auto-parts',
        },
        {
          id: 'context-30',
          label: 'Auto Buying and Selling',
          description: 'auto-buying-and-selling',
        },
        {
          id: 'context-33',
          label: 'Auto Recalls',
          description: 'auto-recalls',
        },
        {
          id: 'context-34',
          label: 'Auto Repair',
          description: 'auto-repair',
        },
        {
          id: 'context-16',
          label: 'Auto Type',
          children: [
            {
              id: 'context-24',
              label: 'Performance Cars',
              description: 'performance-cars',
            },
            {
              id: 'context-22',
              label: 'Green Vehicles',
              description: 'green-vehicles',
            },
            {
              id: 'context-23',
              label: 'Luxury Cars',
              description: 'luxury-cars',
            },
            {
              id: 'context-20',
              label: 'Concept Cars',
              description: 'concept-cars',
            },
            {
              id: 'context-21',
              label: 'Driverless Cars',
              description: 'driverless-cars',
            },
            {
              id: 'context-17',
              label: 'Budget Cars',
              description: 'budget-cars',
            },
            {
              id: 'context-18',
              label: 'Certified Pre-Owned Cars',
              description: 'certified-pre-owned-cars',
            },
            {
              id: 'context-19',
              label: 'Classic Cars',
              description: 'classic-cars',
            },
          ],
          description: 'auto-type',
        },
      ],
      description: 'automotive',
    },
    {
      id: 'context-123',
      label: 'Careers',
      children: [
        {
          id: 'context-124',
          label: 'Apprenticeships',
          description: 'apprenticeships',
        },
        {
          id: 'context-127',
          label: 'Job Search',
          children: [
            {
              id: 'context-128',
              label: 'Job Fairs',
              description: 'job-fairs',
            },
            {
              id: 'context-129',
              label: 'Resume Writing and Advice',
              description: 'resume-writing-and-advice',
            },
          ],
          description: 'job-search',
        },
        {
          id: 'context-125',
          label: 'Career Advice',
          description: 'career-advice',
        },
        {
          id: 'context-126',
          label: 'Career Planning',
          description: 'career-planning',
        },
        {
          id: 'context-130',
          label: 'Remote Working',
          description: 'remote-working',
        },
        {
          id: 'context-131',
          label: 'Vocational Training',
          description: 'vocational-training',
        },
      ],
      description: 'careers',
    },
    {
      id: 'context-473',
      label: 'Shopping',
      children: [
        {
          id: 'context-474',
          label: 'Coupons and Discounts',
          description: 'coupons-and-discounts',
        },
        {
          id: 'context-475',
          label: 'Flower Shopping',
          description: 'flower-shopping',
        },
        {
          id: 'context-476',
          label: 'Gifts and Greetings Cards',
          description: 'gifts-and-greetings-cards',
        },
        {
          id: 'context-477',
          label: 'Grocery Shopping',
          description: 'grocery-shopping',
        },
        {
          id: 'context-478',
          label: 'Holiday Shopping',
          description: 'holiday-shopping',
        },
        {
          id: 'context-479',
          label: 'Household Supplies',
          description: 'household-supplies',
        },
        {
          id: 'context-480',
          label: 'Lotteries and Scratchcards',
          description: 'lotteries-and-scratchcards',
        },
        {
          id: 'context-481',
          label: 'Sales and Promotions',
          description: 'sales-and-promotions',
        },
        {
          id: 'context-482',
          label: "Children's Games and Toys",
          description: 'children-s-games-and-toys',
        },
      ],
      description: 'shopping',
    },
    {
      id: 'context-483',
      label: 'Sports',
      children: [
        {
          id: 'context-484',
          label: 'American Football',
          description: 'american-football',
        },
        {
          id: 'context-485',
          label: 'Boxing',
          description: 'boxing',
        },
        {
          id: 'context-486',
          label: 'Cheerleading',
          description: 'cheerleading',
        },
        {
          id: 'context-487',
          label: 'College Sports',
          children: [
            {
              id: 'context-488',
              label: 'College Football',
              description: 'college-football',
            },
            {
              id: 'context-489',
              label: 'College Basketball',
              description: 'college-basketball',
            },
            {
              id: 'context-490',
              label: 'College Baseball',
              description: 'college-baseball',
            },
          ],
          description: 'college-sports',
        },
        {
          id: 'context-491',
          label: 'Cricket',
          description: 'cricket',
        },
        {
          id: 'context-492',
          label: 'Cycling',
          description: 'cycling',
        },
        {
          id: 'context-493',
          label: 'Darts',
          description: 'darts',
        },
        {
          id: 'context-494',
          label: 'Disabled Sports',
          description: 'disabled-sports',
        },
        {
          id: 'context-495',
          label: 'Diving',
          description: 'diving',
        },
        {
          id: 'context-496',
          label: 'Equine Sports',
          description: 'equine-sports',
        },
        {
          id: 'context-497',
          label: 'Horse Racing',
          description: 'horse-racing',
        },
        {
          id: 'context-498',
          label: 'Extreme Sports',
          children: [
            {
              id: 'context-499',
              label: 'Canoeing and Kayaking',
              description: 'canoeing-and-kayaking',
            },
            {
              id: 'context-500',
              label: 'Climbing',
              description: 'climbing',
            },
            {
              id: 'context-501',
              label: 'Paintball',
              description: 'paintball',
            },
            {
              id: 'context-502',
              label: 'Scuba Diving',
              description: 'scuba-diving',
            },
            {
              id: 'context-503',
              label: 'Skateboarding',
              description: 'skateboarding',
            },
            {
              id: 'context-504',
              label: 'Snowboarding',
              description: 'snowboarding',
            },
            {
              id: 'context-505',
              label: 'Surfing and Bodyboarding',
              description: 'surfing-and-bodyboarding',
            },
            {
              id: 'context-506',
              label: 'Waterskiing and Wakeboarding',
              description: 'waterskiing-and-wakeboarding',
            },
          ],
          description: 'extreme-sports',
        },
        {
          id: 'context-507',
          label: 'Australian Rules Football',
          description: 'australian-rules-football',
        },
        {
          id: 'context-508',
          label: 'Fantasy Sports',
          description: 'fantasy-sports',
        },
        {
          id: 'context-509',
          label: 'Field Hockey',
          description: 'field-hockey',
        },
        {
          id: 'context-510',
          label: 'Figure Skating',
          description: 'figure-skating',
        },
        {
          id: 'context-511',
          label: 'Fishing Sports',
          description: 'fishing-sports',
        },
        {
          id: 'context-512',
          label: 'Golf',
          description: 'golf',
        },
        {
          id: 'context-513',
          label: 'Gymnastics',
          description: 'gymnastics',
        },
        {
          id: 'context-514',
          label: 'Hunting and Shooting',
          description: 'hunting-and-shooting',
        },
        {
          id: 'context-515',
          label: 'Ice Hockey',
          description: 'ice-hockey',
        },
        {
          id: 'context-516',
          label: 'Inline Skating',
          description: 'inline-skating',
        },
        {
          id: 'context-517',
          label: 'Lacrosse',
          description: 'lacrosse',
        },
        {
          id: 'context-518',
          label: 'Auto Racing',
          children: [
            {
              id: 'context-519',
              label: 'Motorcycle Sports',
              description: 'motorcycle-sports',
            },
          ],
          description: 'auto-racing',
        },
        {
          id: 'context-520',
          label: 'Martial Arts',
          description: 'martial-arts',
        },
        {
          id: 'context-521',
          label: 'Olympic Sports',
          children: [
            {
              id: 'context-522',
              label: 'Summer Olympic Sports',
              description: 'summer-olympic-sports',
            },
            {
              id: 'context-523',
              label: 'Winter Olympic Sports',
              description: 'winter-olympic-sports',
            },
          ],
          description: 'olympic-sports',
        },
        {
          id: 'context-524',
          label: 'Poker and Professional Gambling',
          description: 'poker-and-professional-gambling',
        },
        {
          id: 'context-525',
          label: 'Rodeo',
          description: 'rodeo',
        },
        {
          id: 'context-526',
          label: 'Rowing',
          description: 'rowing',
        },
        {
          id: 'context-527',
          label: 'Rugby',
          children: [
            {
              id: 'context-528',
              label: 'Rugby League',
              description: 'rugby-league',
            },
            {
              id: 'context-529',
              label: 'Rugby Union',
              description: 'rugby-union',
            },
          ],
          description: 'rugby',
        },
        {
          id: 'context-530',
          label: 'Sailing',
          description: 'sailing',
        },
        {
          id: 'context-531',
          label: 'Skiing',
          description: 'skiing',
        },
        {
          id: 'context-532',
          label: 'Snooker/Pool/Billiards',
          description: 'snooker-pool-billiards',
        },
        {
          id: 'context-533',
          label: 'Soccer',
          description: 'soccer',
        },
        {
          id: 'context-534',
          label: 'Badminton',
          description: 'badminton',
        },
        {
          id: 'context-535',
          label: 'Softball',
          description: 'softball',
        },
        {
          id: 'context-536',
          label: 'Squash',
          description: 'squash',
        },
        {
          id: 'context-537',
          label: 'Swimming',
          description: 'swimming',
        },
        {
          id: 'context-538',
          label: 'Table Tennis',
          description: 'table-tennis',
        },
        {
          id: 'context-539',
          label: 'Tennis',
          description: 'tennis',
        },
        {
          id: 'context-540',
          label: 'Track and Field',
          description: 'track-and-field',
        },
        {
          id: 'context-541',
          label: 'Volleyball',
          description: 'volleyball',
        },
        {
          id: 'context-542',
          label: 'Walking',
          description: 'walking',
        },
        {
          id: 'context-543',
          label: 'Water Polo',
          description: 'water-polo',
        },
        {
          id: 'context-544',
          label: 'Weightlifting',
          description: 'weightlifting',
        },
        {
          id: 'context-545',
          label: 'Baseball',
          description: 'baseball',
        },
        {
          id: 'context-546',
          label: 'Wrestling',
          description: 'wrestling',
        },
        {
          id: 'context-547',
          label: 'Basketball',
          description: 'basketball',
        },
        {
          id: 'context-548',
          label: 'Beach Volleyball',
          description: 'beach-volleyball',
        },
        {
          id: 'context-549',
          label: 'Bodybuilding',
          description: 'bodybuilding',
        },
        {
          id: 'context-550',
          label: 'Bowling',
          description: 'bowling',
        },
        {
          id: 'context-551',
          label: 'Sports Equipment',
          description: 'sports-equipment',
        },
      ],
      description: 'sports',
    },
    {
      id: 'context-653',
      label: 'Travel',
      children: [
        {
          id: 'context-654',
          label: 'Travel Accessories',
          description: 'travel-accessories',
        },
        {
          id: 'context-655',
          label: 'Travel Locations',
          children: [
            {
              id: 'context-656',
              label: 'Africa Travel',
              description: 'africa-travel',
            },
            {
              id: 'context-657',
              label: 'Asia Travel',
              description: 'asia-travel',
            },
            {
              id: 'context-658',
              label: 'Australia and Oceania Travel',
              description: 'australia-and-oceania-travel',
            },
            {
              id: 'context-659',
              label: 'Europe Travel',
              description: 'europe-travel',
            },
            {
              id: 'context-660',
              label: 'North America Travel',
              description: 'north-america-travel',
            },
            {
              id: 'context-661',
              label: 'Polar Travel',
              description: 'polar-travel',
            },
            {
              id: 'context-662',
              label: 'South America Travel',
              description: 'south-america-travel',
            },
          ],
          description: 'travel-locations',
        },
        {
          id: 'context-663',
          label: 'Travel Preparation and Advice',
          description: 'travel-preparation-and-advice',
        },
        {
          id: 'context-664',
          label: 'Travel Type',
          children: [
            {
              id: 'context-665',
              label: 'Adventure Travel',
              description: 'adventure-travel',
            },
            {
              id: 'context-666',
              label: 'Family Travel',
              description: 'family-travel',
            },
            {
              id: 'context-667',
              label: 'Honeymoons and Getaways',
              description: 'honeymoons-and-getaways',
            },
            {
              id: 'context-668',
              label: 'Hotels and Motels',
              description: 'hotels-and-motels',
            },
            {
              id: 'context-669',
              label: 'Rail Travel',
              description: 'rail-travel',
            },
            {
              id: 'context-670',
              label: 'Road Trips',
              description: 'road-trips',
            },
            {
              id: 'context-671',
              label: 'Spas',
              description: 'spas',
            },
            {
              id: 'context-672',
              label: 'Air Travel',
              description: 'air-travel',
            },
            {
              id: 'context-673',
              label: 'Beach Travel',
              description: 'beach-travel',
            },
            {
              id: 'context-674',
              label: 'Bed & Breakfasts',
              description: 'bed-and-breakfasts',
            },
            {
              id: 'context-675',
              label: 'Budget Travel',
              description: 'budget-travel',
            },
            {
              id: 'context-676',
              label: 'Business Travel',
              description: 'business-travel',
            },
            {
              id: 'context-677',
              label: 'Camping',
              description: 'camping',
            },
            {
              id: 'context-678',
              label: 'Cruises',
              description: 'cruises',
            },
            {
              id: 'context-679',
              label: 'Day Trips',
              description: 'day-trips',
            },
          ],
          description: 'travel-type',
        },
      ],
      description: 'travel',
    },
    {
      id: 'context-274',
      label: 'Home & Garden',
      children: [
        {
          id: 'context-280',
          label: 'Home Improvement',
          description: 'home-improvement',
        },
        {
          id: 'context-281',
          label: 'Home Security',
          description: 'home-security',
        },
        {
          id: 'context-284',
          label: 'Landscaping',
          description: 'landscaping',
        },
        {
          id: 'context-285',
          label: 'Outdoor Decorating',
          description: 'outdoor-decorating',
        },
        {
          id: 'context-282',
          label: 'Indoor Environmental Quality',
          description: 'indoor-environmental-quality',
        },
        {
          id: 'context-277',
          label: 'Smart Home',
          description: 'smart-home',
        },
        {
          id: 'context-278',
          label: 'Home Appliances',
          description: 'home-appliances',
        },
        {
          id: 'context-275',
          label: 'Gardening',
          description: 'gardening',
        },
        {
          id: 'context-276',
          label: 'Remodeling & Construction',
          description: 'remodeling-and-construction',
        },
        {
          id: 'context-283',
          label: 'Interior Decorating',
          description: 'interior-decorating',
        },
        {
          id: 'context-279',
          label: 'Home Entertaining',
          description: 'home-entertaining',
        },
      ],
      description: 'home-and-garden',
    },
    {
      id: 'context-132',
      label: 'Education',
      children: [
        {
          id: 'context-133',
          label: 'Adult Education',
          description: 'adult-education',
        },
        {
          id: 'context-142',
          label: 'Early Childhood Education',
          description: 'early-childhood-education',
        },
        {
          id: 'context-145',
          label: 'Homeschooling',
          description: 'homeschooling',
        },
        {
          id: 'context-143',
          label: 'Educational Assessment',
          children: [
            {
              id: 'context-144',
              label: 'Standardized Testing',
              description: 'standardized-testing',
            },
          ],
          description: 'educational-assessment',
        },
        {
          id: 'context-148',
          label: 'Online Education',
          description: 'online-education',
        },
        {
          id: 'context-136',
          label: 'Special Education',
          description: 'special-education',
        },
        {
          id: 'context-137',
          label: 'College Education',
          children: [
            {
              id: 'context-139',
              label: 'Postgraduate Education',
              children: [
                {
                  id: 'context-140',
                  label: 'Professional School',
                  description: 'professional-school',
                },
              ],
              description: 'postgraduate-education',
            },
            {
              id: 'context-141',
              label: 'Undergraduate Education',
              description: 'undergraduate-education',
            },
            {
              id: 'context-138',
              label: 'College Planning',
              description: 'college-planning',
            },
          ],
          description: 'college-education',
        },
        {
          id: 'context-135',
          label: 'Secondary Education',
          description: 'secondary-education',
        },
        {
          id: 'context-134',
          label: 'Private School',
          description: 'private-school',
        },
        {
          id: 'context-147',
          label: 'Language Learning',
          description: 'language-learning',
        },
        {
          id: 'context-149',
          label: 'Primary Education',
          description: 'primary-education',
        },
        {
          id: 'context-146',
          label: 'Homework and Study',
          description: 'homework-and-study',
        },
      ],
      description: 'education',
    },
    {
      id: 'context-42',
      label: 'Books and Literature',
      children: [
        {
          id: 'context-45',
          label: "Children's Literature",
          description: 'children-s-literature',
        },
        {
          id: 'context-43',
          label: 'Art and Photography Books',
          description: 'art-and-photography-books',
        },
        {
          id: 'context-46',
          label: 'Comics and Graphic Novels',
          description: 'comics-and-graphic-novels',
        },
        {
          id: 'context-47',
          label: 'Cookbooks',
          description: 'cookbooks',
        },
        {
          id: 'context-44',
          label: 'Biographies',
          description: 'biographies',
        },
        {
          id: 'context-48',
          label: 'Fiction',
          description: 'fiction',
        },
        {
          id: 'context-49',
          label: 'Poetry',
          description: 'poetry',
        },
        {
          id: 'context-50',
          label: 'Travel Books',
          description: 'travel-books',
        },
        {
          id: 'context-51',
          label: 'Young Adult Literature',
          description: 'young-adult-literature',
        },
      ],
      description: 'books-and-literature',
    },
    {
      id: 'context-464',
      label: 'Science',
      children: [
        {
          id: 'context-465',
          label: 'Biological Sciences',
          description: 'biological-sciences',
        },
        {
          id: 'context-466',
          label: 'Chemistry',
          description: 'chemistry',
        },
        {
          id: 'context-467',
          label: 'Environment',
          description: 'environment',
        },
        {
          id: 'context-468',
          label: 'Genetics',
          description: 'genetics',
        },
        {
          id: 'context-469',
          label: 'Geography',
          description: 'geography',
        },
        {
          id: 'context-470',
          label: 'Geology',
          description: 'geology',
        },
        {
          id: 'context-471',
          label: 'Physics',
          description: 'physics',
        },
        {
          id: 'context-472',
          label: 'Space and Astronomy',
          description: 'space-and-astronomy',
        },
      ],
      description: 'science',
    },
  ],
};
