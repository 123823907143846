import { theme } from 'theme';
import { themeColors } from 'theme/themeColors';

export const styles = {
  stateWrapper: {
    display: 'flex',
    marginLeft: '-8px',
    marginTop: '-5px',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '7px',
  },
  notification: {
    display: 'flex',
    alignItems: 'center',
    color: themeColors.black,
    '& svg': {
      color: themeColors.redPrimary,
      width: 24,
      height: 24,
      marginRight: '2px',
    },
    marginRight: 1,
    cursor: 'auto',
  },
  button: {
    background: 'none',
    color: '#5b2a7a',
    fontFamily: 'Proxima Nova Regular',
    fontSize: 16,
    textDecoration: 'none',
    '&:hover, &:active': {
      color: theme.palette.primary.main,
      textDecoration: 'underlined',
    },
  },
  errorStatusWrapper: {
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none',
  },
};
