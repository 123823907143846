import { QueryFunctionContext } from 'react-query';

import {
  Comment,
  CreateDealData,
  Deal,
  EditDealData,
  EditDealIdData,
  GetDealsResult,
} from './types';

import { Api } from '../api';
import { apiEndpoints } from '../endpoints';

export async function getDeals(): Promise<GetDealsResult> {
  const { data } = await Api.get(apiEndpoints.deals());

  return data;
}

export async function getDeal({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<Deal> {
  const [, id] = queryKey;
  const { data } = await Api.get<Deal>(apiEndpoints.deal({ id }));

  return data;
}

export function filterDeals(
  dealsResult: GetDealsResult,
  searchValue?: string
): GetDealsResult {
  const searchEntries = searchValue?.split(',').map((t) => t.trim()) ?? [];
  const filteredDeals = dealsResult.deals.filter((d) => searchFilter(d, searchEntries));

  return {
    ...dealsResult,
    deals: filteredDeals,
    meta: {
      ...dealsResult.meta,
      totalElements: filteredDeals.length,
    },
  };
}

export function searchFilter(deal: Deal, search: string[]) {
  if (search.length === 0) {
    return true;
  }

  return search.some(
    (s) =>
      deal.id === s ||
      deal.dealName?.toLowerCase().includes(s.toLowerCase()) ||
      deal.state.toLowerCase().includes(s.toLowerCase()) ||
      deal.dealId?.includes(s)
  );
}

export async function editDeal(deal: EditDealData): Promise<void> {
  await Api.post(apiEndpoints.deal({ id: deal.id }), deal);
}

export async function editDealId(deal: EditDealIdData): Promise<void> {
  await Api.post(apiEndpoints.dealId({ id: deal.id }), { dealId: deal.dealId });
}

export async function createDeal(deal: CreateDealData): Promise<Deal> {
  const { data } = await Api.post<Deal>(apiEndpoints.dealCreate(), deal);

  return data;
}

export async function requestDealIds(ids: Array<Deal['id']>): Promise<void> {
  await Api.post(apiEndpoints.dealsRequest(), { ids });
}

export async function addComment(id: string, comment: Comment): Promise<void> {
  await Api.post(apiEndpoints.dealComment({ id }), comment);
}

export async function importDeals(
  agencyId: string,
  deals: Partial<Deal>[]
): Promise<void> {
  await Api.post(apiEndpoints.dealsImport(), {
    deals,
    agencyId,
  });
}

export async function sendToXandr(id: string): Promise<void> {
  await Api.post(apiEndpoints.dealSendToXandr({ id }));
}
