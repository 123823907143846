import { theme } from '../../../../theme';
import { themeColors } from '../../../../theme/themeColors';

export const styles = {
  table: {
    '& .MuiDataGrid-row': {
      color: theme.palette.text.primary,

      '&.row-incomplete': {
        backgroundColor: themeColors.red20,
      },
    },
    '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
  dealNameCell: {
    maxWidth: '100%',
  },
  action: {
    padding: 0,
    maxWidth: '24px',
  },
};
