import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Button } from 'components/button';
import { Icon } from 'components/icon';

import { styles } from './styles';

import { useValidateEmail } from '../../../api/users/hooks/useValidateEmail';
import { ValidateEmailData } from '../../../api/users/types';
import { LoadingOverlay } from '../../../components/loading-overlay';
import { appRoutes } from '../../../routes/appRoutes';

export const ValidateEmail: React.FC = () => {
  const history = useHistory();
  const token = new URLSearchParams(window.location.search).get('token');
  const { mutate } = useValidateEmail();
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    mutate(
      {
        token,
      } as ValidateEmailData,
      {
        onError: () => {
          setIsError(true);
        },
        onSettled: () => {
          setIsSubmitting(false);
        },
      }
    );
  }, [token]);

  const onLoginClick = () => {
    history.push(appRoutes.login());
  };

  return (
    <>
      <Grid container>
        <Grid item md={6}>
          {isSubmitting ? (
            <LoadingOverlay />
          ) : (
            <>
              {isError ? (
                <Box>
                  <Typography variant="h1">
                    <FormattedMessage id="users.validate_email.error.title"></FormattedMessage>
                  </Typography>

                  <Box sx={styles.textWrapper}>
                    <Box sx={styles.text}>
                      <FormattedMessage id="users.validate_email.error.description"></FormattedMessage>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box>
                    <Typography variant="h1">
                      <FormattedMessage id="users.validate_email.title"></FormattedMessage>
                    </Typography>

                    <Box sx={styles.textWrapper}>
                      <Box sx={styles.text}>
                        <FormattedMessage id="users.validate_email.description"></FormattedMessage>
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={5}>
                    <Button
                      disabled={isSubmitting}
                      endIconName="arrow-right"
                      labelId="users.validate_email.submit"
                      onClick={onLoginClick}
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </Grid>
        <Grid item container md={6} justifyContent="flex-end">
          <Icon name="man" width={643} height={541} viewBox="0 0 643 562" />
        </Grid>
      </Grid>
    </>
  );
};
