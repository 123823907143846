import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Divider, Typography } from '@mui/material';

import { DealAgencySelect } from './agency-field/DealAgencySelect';
import { InventoryField } from './inventory-field/InventoryField';
import { styles } from './styles';
import { DetailedTargetingDialog } from './targeting-field/DetailedTargetingDialog';
import {
  calculatePriceFloor,
  cleanAndUpdateDevices,
  cleanAndUpdateEnvironment,
  cleanAndUpdateFormat,
  cleanAndUpdateInventory,
  getDealAdvertiserOptions,
  getDealBuyerOptions,
  getFormatOptions,
} from './util';

import { Agency } from '../../api/agencies/types';
import {
  Buyer,
  Comment,
  Deal,
  DealAspects,
  Device,
  Environment,
} from '../../api/deals/types';
import { ProductSpec, productSpecs } from '../../static/productSpecs';
import { useUser } from '../../utils/keycloak/KeycloakProvider';
import { DealEntry } from '../deals-form/util';
import { RowWithTitle } from '../elements/row-with-title';
import { SelectField } from '../form/fields/select-field';
import { Option } from '../form/fields/select-field/SelectField';
import { TextField } from '../form/fields/text-field/TextField';
import { InlineAlert } from '../inline-alert';

export type Props = {
  deal: DealEntry['deal'];
  canEditAgency: boolean;
  canComment?: boolean;
  onChange(
    deal: Optional<DealAspects> & Pick<Deal, 'dealId'> & { ownedBy?: Agency['id'] }
  ): void;
};

export const DealFormItem: React.FC<Props> = ({
  deal,
  onChange,
  canEditAgency,
  canComment = true,
}) => {
  const intl = useIntl();
  const user = useUser();
  const _formOptions = (options: string[]) => {
    return options
      .map((option) => {
        return {
          label: intl.formatMessage({ id: `deal.form.item.${option}` }),
          value: option.split('_'),
        };
      })
      .sort((prev, next) => prev.label.localeCompare(next.label));
  };
  const _getDeviceOptions = () => {
    const devices: string[] = Array.from(
      new Set(Object.values(productSpecs).map((s) => s.devices.join('_')))
    );

    return _formOptions(devices);
  };
  const _getEnvironmentOptions = () => {
    const environments: string[] = Array.from(
      new Set(Object.values(productSpecs).map((s) => s.environments.join('_')))
    );

    // No product spec has app-only yet, so add it for now
    environments.push('app');

    return _formOptions(environments);
  };
  const formatOptions = useMemo(() => {
    const options: Array<Option> = getFormatOptions(deal);
    const disabledIdx = options.findIndex((s) => s.disabled);

    if (disabledIdx === -1) {
      return options;
    }

    return [
      ...options.slice(0, disabledIdx),
      {
        heading: intl.formatMessage({ id: 'deal.form.item.format.notSupported' }),
      },
      ...options.slice(disabledIdx),
    ];
  }, [deal, intl]);
  const matchedSpec = useMemo(
    () =>
      deal.format
        ? (Object.values(productSpecs).find((spec) => spec.name === deal.format) ?? null)
        : null,
    [deal.format]
  );
  const hasEnvironmentWarning =
    deal.devices?.join('_') === 'desktop_tablet' &&
    deal.environments?.join('_') === 'app';
  const buyerOptions = useMemo(() => getDealBuyerOptions(deal, user), [deal, user]);
  const advertiserOptions = useMemo(
    () => getDealAdvertiserOptions(deal, user),
    [deal, user]
  );

  return (
    <>
      <Typography variant="h2" sx={styles.title}>
        <FormattedMessage id="deal.form.item.title" />
      </Typography>
      <DealAgencySelect deal={deal} canEditAgency={canEditAgency} onChange={onChange} />
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.form.item.buyer' })}
        mt={3}
        mb={3}
      >
        {buyerOptions.length === 0 ? (
          <Typography sx={styles.textValueNormal}>
            <FormattedMessage id="deal.form.item.noBuyers" />
          </Typography>
        ) : (
          <SelectField
            displayEmpty
            fullWidth
            value={deal.buyer ?? ''}
            options={buyerOptions}
            variant="outlined"
            onChange={(e) => {
              onChange({
                ...deal,
                buyer: e.target.value as Buyer,
              });
            }}
          />
        )}
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.form.item.advertiser' })}
        mt={3}
        mb={3}
      >
        {advertiserOptions.length === 0 ? (
          <Typography sx={styles.textValueNormal}>
            <FormattedMessage id="deal.form.item.noAdvertisers" />
          </Typography>
        ) : (
          <SelectField
            displayEmpty
            fullWidth
            value={deal.advertiser ?? ''}
            options={advertiserOptions}
            variant="outlined"
            onChange={(e) => {
              onChange({
                ...deal,
                advertiser: e.target.value as string,
              });
            }}
          />
        )}
      </RowWithTitle>
      <Divider />

      <InventoryField
        inventory={deal.inventory}
        onChange={(inventory) => onChange(cleanAndUpdateInventory(inventory, deal))}
      />

      <DetailedTargetingDialog
        isManageable={true}
        targeting={deal.targeting}
        onChange={(targeting) => onChange({ ...deal, targeting })}
      />

      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.form.item.devices' })}
        mt={3}
        mb={3}
      >
        <SelectField
          displayEmpty
          fullWidth
          value={deal.devices ?? ''}
          options={_getDeviceOptions()}
          variant="outlined"
          onChange={(e) => {
            onChange(
              cleanAndUpdateDevices(
                e.target.value as Device[],
                deal,
                matchedSpec
              ) as Optional<DealAspects> & Pick<Deal, 'dealId'>
            );
          }}
        />
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.form.item.environments' })}
        mt={3}
        mb={3}
      >
        <Box sx={styles.fullWidth}>
          <SelectField
            displayEmpty
            fullWidth
            value={deal.environments ?? ''}
            options={_getEnvironmentOptions()}
            variant="outlined"
            onChange={(e) => {
              onChange(
                cleanAndUpdateEnvironment(
                  e.target.value as Environment[],
                  deal,
                  matchedSpec
                ) as Optional<DealAspects> & Pick<Deal, 'dealId'>
              );
            }}
          />
          {hasEnvironmentWarning && (
            <InlineAlert severity="warning" sx={{ mt: 3 }}>
              {intl.formatMessage({ id: 'deal.form.item.environment.warning' })}
            </InlineAlert>
          )}
        </Box>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.form.item.format' })}
        mt={3}
        mb={3}
      >
        <SelectField
          displayEmpty
          fullWidth
          value={matchedSpec ?? ''}
          options={formatOptions}
          variant="outlined"
          onChange={(e) => {
            onChange(
              cleanAndUpdateFormat(
                e.target.value as ProductSpec,
                deal
              ) as Optional<DealAspects> & Pick<Deal, 'dealId'>
            );
          }}
        />
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.form.item.floorPrice' })}
        mt={3}
        mb={3}
      >
        <Typography sx={styles.textValue}>{calculatePriceFloor(deal)}</Typography>
      </RowWithTitle>
      {canComment && (
        <>
          <Divider />

          <RowWithTitle
            title={intl.formatMessage({ id: 'deal.form.item.comment' })}
            mt={3}
            mb={3}
          >
            <TextField
              fullWidth
              multiline={true}
              maxRows={4}
              value={deal.comments?.[0]?.message ?? ''}
              onChange={(e) => {
                onChange({
                  ...deal,
                  comments: [
                    { message: e.target.value } as unknown as Comment,
                    ...(deal.comments?.slice(1) ?? []),
                  ],
                });
              }}
            />
          </RowWithTitle>
        </>
      )}
    </>
  );
};
