import React, { useState } from 'react';

import { Box, IconButton, SxProps } from '@mui/material';

import { DealNameField } from './DealNameField';

import { Deal, State } from '../../api/deals/types';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { styles } from '../deals-table-layout/components/styles';
import { Icon } from '../icon';
import { TextCopyPopover } from '../text-copy-popover/TextCopyPopover';

interface Props {
  deal: Deal;
  sx?: SxProps;
}

export const ToggleableDealNameField: React.FC<Props> = ({ deal, sx }) => {
  const ability = useAbility();
  const canEditDealName =
    ability.can(Actions.Manage, Permissions.DealName) && deal.state !== State.Draft;
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Box sx={{ ...styles.toggleFieldContainer, ...sx }}>
      {isEditing ? (
        <DealNameField
          // autoFocus is ok because the input shows on user action
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          deal={deal}
          alwaysShowCancel={true}
          onComplete={() => setIsEditing(false)}
        />
      ) : (
        <>
          <div style={styles.dealEditableCellText}>
            <TextCopyPopover text={deal.dealName ?? '-'} active={!!deal.dealName} />
          </div>
          {canEditDealName && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(!isEditing);
              }}
            >
              <Icon width={18} height={18} name="edit" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};
