import React from 'react';
import { useIntl } from 'react-intl';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { themeColors } from 'theme/themeColors';

import { TEST_ID } from '../../constants';

type Props = {
  setScreen(v: string[]): void;
};

const KeywordsScreen: React.FC<Props> = ({ setScreen }) => {
  const intl = useIntl();
  const clickHandler = () => setScreen([]);

  return (
    <Box borderBottom={`2px solid ${themeColors.grey30}`} data-testid={TEST_ID.keywords}>
      <Box
        p={1}
        mt={1}
        display="flex"
        alignItems="center"
        bgcolor={themeColors.grey30}
        style={{ cursor: 'pointer' }}
        onClick={clickHandler}
      >
        <Box display="flex">
          <ChevronLeftIcon htmlColor={themeColors.grey120} />
        </Box>
        <Box ml={1}>
          <Typography variant="body1">
            {intl.formatMessage({
              id: `deal.targeting.dialog.top.keywords`,
            })}
          </Typography>
        </Box>
      </Box>
      <Box mx={2} my={3} data-testid={TEST_ID.keywordsDescription}>
        <Typography variant="body1">
          {intl.formatMessage({
            id: 'deal.targeting.dialog.top.keywords.description',
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export { KeywordsScreen };
