import { Device, Environment } from '../api/deals/types';

export type ProductSpec = {
  name: string;
  format: string;
  devices: Device[];
  environments: Environment[];
  priceFloor: number;
};

export const enum ProductSpecs {
  IABMobileSmall,
  IABStandard,
  Billboard,
  HalfpageAd,
  BillboardAndHalfpageAd,
  Interscroller,
  Interstitial,
  MobilePortrait,
  Superheader,
  SkinBillboardAndLeaderboard,
  NativePost,
  OutstreamVideo,
  FramedOutstreamVideo,
  VerticalOutstreamVideo,
}

export const productSpecs: Record<ProductSpecs, ProductSpec> = {
  [ProductSpecs.IABMobileSmall]: {
    name: 'IAB mobile small',
    format: '320x50/320x100',
    devices: ['mobile'],
    environments: ['web', 'app'],
    priceFloor: 120,
  },
  [ProductSpecs.IABStandard]: {
    name: 'IAB standard',
    format: '320x240/300x250/336x280/120x600/160x600/728x90',
    devices: ['desktop', 'tablet', 'mobile'],
    environments: ['web', 'app'],
    priceFloor: 240,
  },
  [ProductSpecs.Billboard]: {
    name: 'Billboard',
    format: '970x250',
    devices: ['desktop', 'tablet'],
    environments: ['web'],
    priceFloor: 650,
  },
  [ProductSpecs.HalfpageAd]: {
    name: 'Halfpage ad',
    format: '300x600',
    devices: ['desktop', 'tablet', 'mobile'],
    environments: ['web', 'app'],
    priceFloor: 650,
  },
  [ProductSpecs.BillboardAndHalfpageAd]: {
    name: 'Billboard/Halfpage ad',
    format: '970x250/300x600',
    devices: ['desktop', 'tablet'],
    environments: ['web'],
    priceFloor: 650,
  },
  [ProductSpecs.Interscroller]: {
    name: 'Interscroller',
    format: '320x500',
    devices: ['mobile'],
    environments: ['web', 'app'],
    priceFloor: 650,
  },
  [ProductSpecs.Interstitial]: {
    name: 'Interstitial',
    format: '320x480',
    devices: ['mobile'],
    environments: ['web', 'app'],
    priceFloor: 650,
  },
  [ProductSpecs.MobilePortrait]: {
    name: 'Mobile portrait',
    format: '320x400',
    devices: ['mobile'],
    environments: ['web', 'app'],
    priceFloor: 650,
  },
  [ProductSpecs.Superheader]: {
    name: 'Superheader',
    format: '1800x200',
    devices: ['desktop', 'tablet', 'mobile'],
    environments: ['web'],
    priceFloor: 650,
  },
  [ProductSpecs.SkinBillboardAndLeaderboard]: {
    name: 'Skin billboard/leaderboard',
    format: '970x1000/1800x1000',
    devices: ['desktop'],
    environments: ['web'],
    priceFloor: 1200,
  },
  [ProductSpecs.NativePost]: {
    name: 'Native post',
    format: '1x1',
    devices: ['desktop', 'tablet', 'mobile'],
    environments: ['web', 'app'],
    priceFloor: 450,
  },
  [ProductSpecs.OutstreamVideo]: {
    name: 'Outstream video',
    format: '1x1',
    devices: ['desktop', 'tablet', 'mobile'],
    environments: ['web', 'app'],
    priceFloor: 780,
  },
  [ProductSpecs.FramedOutstreamVideo]: {
    name: 'Framed outstream video',
    format: '1x1',
    devices: ['desktop', 'tablet', 'mobile'],
    environments: ['web', 'app'],
    priceFloor: 780,
  },
  [ProductSpecs.VerticalOutstreamVideo]: {
    name: 'Vertical outstream video',
    format: '1x1/3x4/4x5',
    devices: ['mobile'],
    environments: ['web'],
    priceFloor: 780,
  },
};
