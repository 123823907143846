import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { AppLayout } from 'components/app-layout';

import { redirectsConfig, routes } from './appRoutes';
import { PrivateRoute } from './PrivateRoute';
import { privateRoutesConfig } from './privateRoutesConfig';
import { publicRoutesConfig } from './publicRoutesConfig';
import { Route } from './Route';

import { ScrollToTop } from '../components/scroll-to-top/ScrollToTop';

const usePrivateRoutes = () => {
  const privateRoutes = privateRoutesConfig.map((route) => (
    <PrivateRoute key={route.path} exact {...route} />
  ));

  return {
    privateRoutes,
  };
};

const publicRoutes = publicRoutesConfig.map((route) => (
  <Route key={route.path} {...route} />
));

const redirects = redirectsConfig.map((props) => (
  <Redirect key={`${props.to}${props.from}`} {...props} />
));

export const Routes = () => {
  const { privateRoutes } = usePrivateRoutes();

  return (
    <AppLayout>
      <Switch>
        {privateRoutes}
        {publicRoutes}
        {redirects}
        <Redirect to={routes.main} />
      </Switch>
      <ScrollToTop />
    </AppLayout>
  );
};
