import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@mui/material';

import { buildMappedFormFieldValues, filterEmptyChildren, sortItems } from './utils';

import { TEST_ID } from '../../constants';
import {
  DetailedTargeting,
  DetailedTargetingItem,
  DetailedTargetingKeys,
  Keyword,
  Relation,
  RestrictedRelation,
  RootCategories,
  TargetingValue,
} from '../../types';
import { getItem, getTransformedItems } from '../../utils';
import { Autocomplete } from '../autocomplete/Autocomplete';
import { CategoryScreen } from '../category-screen/CategoryScreen';
import { KeywordsScreen } from '../keywords-screen/KeywordsScreen';
import { MainScreen } from '../main-screen/MainScreen';

type Props = {
  data: DetailedTargeting;
  value: DetailedTargeting & { keywords?: Keyword[] };
  onChange: (value: DetailedTargeting) => void;
  type?: RestrictedRelation;
};

const Browse: React.FC<Props> = ({ data, value, onChange, type = Relation.INCLUDED }) => {
  const intl = useIntl();
  const [screen, setScreen] = useState<string[]>([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const ref = React.useRef(false);

  const formattedData: DetailedTargetingItem[] = useMemo(
    () =>
      Object.keys(RootCategories).map((item) => ({
        id: item,
        label: intl.formatMessage({
          id: `deal.targeting.dialog.category.${item}`,
        }),
        size: 0,
        children: filterEmptyChildren(data[item as DetailedTargetingKeys]),
      })),
    [data, intl]
  );

  const current = getItem(screen, formattedData);
  const currentScreenData = !screen.length
    ? formattedData
    : current?.children?.sort(sortItems) || [];

  const isMainScreen = !screen.length;
  const isKeywords = screen?.[0] === 'keywords';
  const isCategory = !isMainScreen && !isKeywords;

  const handleBrowseSave = (data: TargetingValue) => {
    onChange({
      ...data,
      ...(value.keywords ? { keywords: value.keywords } : {}),
    });
  };

  const initialValues = useMemo(
    () => buildMappedFormFieldValues(data, value),
    [data, value]
  );

  const formFieldMappedValues = buildMappedFormFieldValues(data, value) || [];

  const targetingAutocompleteData = useMemo(
    () =>
      data && getTransformedItems(data).sort((a, b) => Number(b.size) - Number(a.size)),
    [data]
  );

  if (!data) {
    return null;
  }

  return (
    <Box display="flex">
      <Box width="100%" data-testid={TEST_ID.browseMode}>
        <Box mt={2} mb={0}>
          {targetingAutocompleteData && (
            <Autocomplete
              filterData={targetingAutocompleteData}
              formFieldMappedValues={formFieldMappedValues}
              relation={type}
              setScreen={setScreen}
              inputRef={ref}
              formValue={value}
              browseValue={formattedData}
              setIsSearchActive={setIsSearchActive}
              isSearchActive={isSearchActive}
              onChange={onChange}
            />
          )}
          {!isSearchActive && (
            <Box mt={3}>
              {isMainScreen && (
                <MainScreen
                  value={initialValues!}
                  setScreen={setScreen}
                  formFieldMappedValues={formFieldMappedValues}
                />
              )}
              {isKeywords && <KeywordsScreen setScreen={setScreen} />}
              {isCategory && (
                <CategoryScreen
                  categoryLabel={current?.label}
                  data={currentScreenData}
                  formData={initialValues!}
                  name={`${screen.join('.')}`}
                  type={type}
                  setScreen={setScreen}
                  onChange={handleBrowseSave}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { Browse };
