import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { styles } from './styles';
import { DealEntry, requestDealEntries, saveDealEntries } from './util';

import { Deal } from '../../api/deals/types';
import { NotificationMessage, useNotification } from '../../notifications';
import { appRoutes } from '../../routes/appRoutes';
import { theme } from '../../theme';
import { Button } from '../button';
import { ConfirmationDialog } from '../confirmation-dialog';
import { DealFormItem } from '../deal-form-item';
import { LoadingOverlay } from '../loading-overlay';
import { SectionContent } from '../section-content';

export type Props = {
  deals: Partial<Deal>[];
  canAddItems: boolean;
};

export const DealsForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const [items, setItems] = useState<DealEntry[]>(() =>
    props.deals.map((deal) => ({
      deal,
      error: null,
    }))
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const sendNotification = useNotification();
  const hasIncompleteDeal = useMemo(
    () =>
      items.some(
        ({ deal }) =>
          !deal.agency ||
          !deal.advertiser ||
          !deal.buyer ||
          !deal.devices ||
          !deal.environments ||
          !deal.format
      ),
    [items]
  );

  const onSaveDrafts = async () => {
    setIsSubmitting(true);
    try {
      const updatedItems = await saveDealEntries(items);

      setItems(updatedItems);

      if (updatedItems.some((s) => s.error !== null)) {
        sendNotification({
          type: 'error',
          message: <NotificationMessage id={'deals.form.saveDeals.error'} />,
        });

        return;
      }

      sendNotification({
        type: 'success',
        message: <NotificationMessage id={'deals.form.saveDeals.success'} />,
      });

      history.push(appRoutes.deals());
    } finally {
      setIsSubmitting(false);
    }
  };

  const onRequestDeals = async () => {
    setIsSubmitting(true);
    try {
      // Only update the non-requested deals
      let updatedItems = await saveDealEntries(items);

      if (updatedItems.some((s) => s.error !== null)) {
        sendNotification({
          type: 'error',
          message: <NotificationMessage id={'deals.form.requestDeals.error'} />,
        });
        setItems(updatedItems);

        return;
      }

      try {
        await requestDealEntries(updatedItems);
      } catch (e) {
        console.error('Could not request deals', updatedItems, e);

        sendNotification({
          type: 'error',
          message: <NotificationMessage id={'deals.form.requestDeals.error'} />,
        });

        return;
      }

      sendNotification({
        type: 'success',
        message: <NotificationMessage id={'deals.form.requestDeals.success'} />,
      });

      history.push(appRoutes.deals());
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setItems(
      props.deals.map((deal) => {
        return {
          deal,
          error: null,
        };
      })
    );
  }, [props.deals]);

  return (
    <>
      {isSubmitting && (
        <Backdrop
          open={true}
          style={{
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <LoadingOverlay />
        </Backdrop>
      )}
      {items.map(({ deal, error }, idx) => (
        <SectionContent
          key={idx + (deal.id ?? '')} // TODO fix for added items?
          size="halfScreen"
          sx={styles.dealItem}
        >
          {!!error && (
            <Box mb={4}>
              <Alert variant="filled" severity="error">
                {`${(error as Error).message}`}
              </Alert>
            </Box>
          )}
          <DealFormItem
            deal={deal}
            canEditAgency={!deal.id}
            onChange={(newDeal: DealEntry['deal']) => {
              items[idx] = {
                ...items[idx],
                deal: newDeal,
              };

              setItems([...items]);
            }}
          />
          {props.canAddItems && (
            <>
              <Button
                startIconName="plus"
                labelId="deals.form.button.addItem"
                color="secondary"
                sx={styles.dealActionButton}
                onClick={() => {
                  // Copy over some previous deal info if available
                  setItems([
                    ...items.slice(0, idx + 1),
                    {
                      deal: {
                        agency: items[idx]?.deal.agency,
                        advertiser: items[idx]?.deal.advertiser,
                        buyer: items[idx]?.deal.buyer,
                      },
                      error: null,
                    },
                    ...items.slice(idx + 1),
                  ]);
                }}
              />
              <Button
                startIconName="copy"
                labelId="deals.form.button.duplicateItem"
                variant="text"
                color="secondary"
                sx={styles.dealActionButton}
                onClick={() => {
                  // Copy over _all_ previous deal info if available
                  setItems([
                    ...items.slice(0, idx + 1),
                    {
                      deal: JSON.parse(JSON.stringify(items[idx]?.deal)),
                      error: null,
                    },
                    ...items.slice(idx + 1),
                  ]);
                }}
              />
              {items.length > 1 && (
                <ConfirmationDialog
                  triggerEl={
                    <Button
                      startIconName="delete"
                      labelId="deals.form.button.removeItem"
                      variant="text"
                      color="secondary"
                      sx={styles.dealActionButton}
                    />
                  }
                  title={intl.formatMessage({
                    id: 'deals.form.button.removeItem.confirm.title',
                  })}
                  description={intl.formatMessage({
                    id: 'deals.form.button.removeItem.confirm.description',
                  })}
                  onConfirm={() => {
                    items.splice(idx, 1);

                    setItems([...items]);
                  }}
                />
              )}
            </>
          )}
        </SectionContent>
      ))}
      <SectionContent size="halfScreen" sx={{ display: 'flex' }}>
        <Button
          labelId="deals.form.button.saveDraft"
          disabled={isSubmitting}
          color="secondary"
          sx={styles.formActionButton}
          onClick={onSaveDrafts}
        />
        <Button
          endIconName="arrow-right"
          labelId="deals.form.button.requestDeals"
          disabled={isSubmitting || hasIncompleteDeal}
          sx={styles.formActionButton}
          onClick={onRequestDeals}
        />
        {hasIncompleteDeal && (
          <Typography color="red" display="flex" alignItems="center">
            <FormattedMessage id="deals.form.button.requestDeals.incomplete" />
          </Typography>
        )}
      </SectionContent>
    </>
  );
};
