import * as React from 'react';
import { useEffect, useState } from 'react';

interface Props {
  targetRef: React.MutableRefObject<HTMLDivElement | undefined>;
  children: React.ReactElement;
}

export const FooterScroll: React.FC<Props> = ({ children, targetRef }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const contentRef = targetRef?.current;

    if (contentRef) {
      const handleScroll = () => {
        if (contentRef) {
          const scrollPosition = contentRef?.scrollHeight - contentRef?.scrollTop;
          const visibleHeight = contentRef?.clientHeight;

          setTrigger(scrollPosition > visibleHeight);
        }
      };

      const resizeObserver = new ResizeObserver(() => {
        handleScroll();
      });

      resizeObserver.observe(contentRef);
      contentRef.addEventListener('scroll', handleScroll);

      handleScroll();

      return () => {
        contentRef.removeEventListener('scroll', handleScroll);
        resizeObserver.disconnect();
      };
    }
  }, [targetRef]);

  return React.cloneElement(children, {
    bottomDivider: trigger,
  });
};
