import { useMutation } from 'react-query';

import { NotificationMessage, useNotification } from 'notifications';

import { getFullName } from '../../../features/users/utils';
import { startResetPassword } from '../index';
import { User } from '../types';

export const useStartResetPassword = (user: User) => {
  const sendNotification = useNotification({});

  return useMutation<void, Record<string, unknown>>(() => startResetPassword(user), {
    onSuccess: () => {
      sendNotification({
        type: 'success',
        message: (
          <NotificationMessage
            id="user.manage.password_reseted.message"
            values={[{ key: 'username', value: getFullName(user) }]}
          />
        ),
      });
    },
  });
};
