import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { UserBasicInfoForm } from './components/UserBasicInfoForm';
import { UserPreferencesForm } from './components/UserPreferencesForm';

import { Agency } from '../../api/agencies/types';
import { createUser } from '../../api/users';
import { User } from '../../api/users/types';
import { NotificationMessage, useNotification } from '../../notifications';
import { appRoutes } from '../../routes/appRoutes';
import { getRole, Roles } from '../../utils/keycloak/Keycloak';
import { useUser } from '../../utils/keycloak/KeycloakProvider';
import { Button } from '../button';
import { isValidEmail } from '../create-agency-dialog/util';
import { SectionContent } from '../section-content';

export const UserForm = (): JSX.Element => {
  const userAgencies = useUser()?.agencies ?? ([] as unknown as Array<Agency>);
  const [user, setUser] = useState<User>({
    agencies: getRole() === Roles.ClientAdmin ? userAgencies : [],
  } as User);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasInvalidEmail, setHasInvalidEmail] = useState(false);
  const sendNotification = useNotification();
  const history = useHistory();
  const hasIncompleteUser =
    !user.firstName || !user.lastName || !user.email || !user.roles?.[0];
  const onCreateUser = async () => {
    if (!isValidEmail(user.email)) {
      setHasInvalidEmail(true);
    } else {
      setHasInvalidEmail(false);
      setIsSubmitting(true);

      try {
        await createUser(user);

        sendNotification({
          type: 'success',
          message: <NotificationMessage id={'user.form.createdUser.success'} />,
        });

        history.push(appRoutes.users());
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <SectionContent size="halfScreen" sx={{ marginBottom: '8px' }}>
        <UserBasicInfoForm
          user={user}
          hasInvalidEmail={hasInvalidEmail}
          userAgencies={userAgencies}
          setHasInvalidEmail={setHasInvalidEmail}
          onChange={(newUser) => setUser({ ...newUser })}
        />
      </SectionContent>
      {user.roles?.[0] && user.roles?.[0] !== Roles.DPGAdmin && (
        <SectionContent size="halfScreen" sx={{ marginBottom: '8px' }}>
          <UserPreferencesForm
            user={user}
            onChange={(newUser) => setUser({ ...newUser })}
          />
        </SectionContent>
      )}
      <SectionContent size="halfScreen">
        <Button
          labelId="user.form.button.createUser"
          endIconName="arrow-right"
          disabled={isSubmitting || hasIncompleteUser}
          onClick={onCreateUser}
        />
      </SectionContent>
    </>
  );
};
