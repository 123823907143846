import React from 'react';

import { ReadOnlyText } from './ReadOnlyText';

import { ReactFCC } from '../../../../react-util';

interface Props {
  editable: boolean;
  value: string | undefined | JSX.Element | Element[];
  padding?: boolean;
  width?: string;
}

export const ReadOnlyToggleField: ReactFCC<Props> = ({
  editable,
  value,
  padding,
  width,
  children,
}) => {
  if (editable) {
    return <>{children}</>;
  }

  return <ReadOnlyText value={value} padding={padding} width={width} />;
};
