import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { usePagination } from 'components/table/pagination/usePagination';

import { queryKeys } from '../../api/queryKeys';
import { filterUsers, getUsers } from '../../api/users';
import { GetUsersResult } from '../../api/users/types';
import { useSearch } from '../../components/table/hooks';
import { UsersTableLayout } from '../../components/users-table-layout';

export type UsersOverviewState = {
  searchFor?: string;
};

const UsersOverview = (): JSX.Element => {
  const { SearchInput, searchValue, setSearchValue } = useSearch();
  const { page, pageSize, onPageChange, onPageSizeChange } = usePagination();
  const { data, isLoading } = useQuery<GetUsersResult>(queryKeys.getUsers, getUsers);
  const usersResult = useMemo(
    () => data && filterUsers(data, searchValue),
    [data, searchValue]
  );
  const { state } = useLocation<UsersOverviewState>();

  useEffect(() => {
    if (state?.searchFor) {
      setSearchValue(state.searchFor);
    }
  }, [state?.searchFor]);

  return (
    <UsersTableLayout
      data={usersResult}
      isLoading={isLoading}
      SearchInput={SearchInput}
      searchValue={searchValue}
      page={page}
      pageSize={pageSize}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    />
  );
};

export { UsersOverview };
