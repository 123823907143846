import * as React from 'react';
import { useMemo } from 'react';

import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styles } from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Option<T> = { label: string; value: T };

export type Props<T> = {
  options: Array<string | Option<T>>;
  selected: T[];
  onChange(selectedItems: T[]): void;
};

export function MultiSelect<T = string>({ options, onChange, selected }: Props<T>) {
  const normalizedOptions = useMemo<Array<Option<T>>>(
    () =>
      options.map((option) =>
        typeof option === 'string'
          ? ({ label: option, value: option } as Option<T>)
          : option
      ),
    [options]
  );
  const selectedValues = useMemo(
    () =>
      selected
        .map((s) =>
          normalizedOptions.findIndex(
            (o) => JSON.stringify(o.value) === JSON.stringify(s)
          )
        )
        .filter((s) => s >= 0),
    [selected, normalizedOptions]
  );
  const fromIndeces = (indeces: number[]) => {
    return indeces.map((idx) => normalizedOptions[idx]);
  };
  const handleChange = (event: SelectChangeEvent<number[]>) => {
    onChange(fromIndeces(event.target.value as number[]).map((o) => o.value));
  };

  return (
    <Select
      multiple
      fullWidth
      value={selectedValues}
      renderValue={(selectedIdxs) =>
        fromIndeces(selectedIdxs)
          .map((o) => o.label)
          .join(', ')
      }
      MenuProps={MenuProps}
      sx={styles.select}
      onChange={handleChange}
    >
      {normalizedOptions.map(({ label, value }, idx) => (
        <MenuItem key={label} value={idx}>
          <Checkbox checked={selected.includes(value)} />
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </Select>
  );
}
