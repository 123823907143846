import React from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@mui/material';

export const RoleInfo = (): JSX.Element => {
  const intl = useIntl();

  return (
    <Box width={320}>
      <b>{intl.formatMessage({ id: 'user.form.info.dpgAdmin.title' })} </b> <br />
      {intl.formatMessage({ id: 'user.form.info.dpgAdmin.description' })} <br />
      <br />
      <b>{intl.formatMessage({ id: 'user.form.info.dpgUser.title' })} </b> <br />
      {intl.formatMessage({ id: 'user.form.info.dpgUser.description' })} <br />
      <br />
      <b>{intl.formatMessage({ id: 'user.form.info.agentAdmin.title' })} </b> <br />
      {intl.formatMessage({ id: 'user.form.info.agentAdmin.description' })} <br />
      <br />
      <b>{intl.formatMessage({ id: 'user.form.info.agentUser.title' })} </b> <br />
      {intl.formatMessage({ id: 'user.form.info.agentUser.description' })}
    </Box>
  );
};
