import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';

import { User } from '../../api/users/types';

const KEYCLOAK_URLS: Record<string, string> = {
  // local: 'https://auth-admanager-dev.dpgdsp-adv-nonprod.dpgmedia.cloud/auth/',
  // nonprod: 'https://auth-admanager-dev.dpgdsp-adv-nonprod.dpgmedia.cloud/auth/',
  local: 'https://auth-dev.dpgdsp-adv-nonprod.dpgmedia.cloud/auth/',
  nonprod: 'https://auth-dev.dpgdsp-adv-nonprod.dpgmedia.cloud/auth/',
  prod: 'https://auth.dpgadmanager.com/auth/',
};

const keycloakConfig = {
  url: KEYCLOAK_URLS[process.env.REACT_APP_STAGE as string] ?? KEYCLOAK_URLS.nonprod,
  realm: 'DSP',
  clientId: 'pmp-portal-frontend',
} as KeycloakConfig;

export const keycloakInstance = new Keycloak(keycloakConfig);

export const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  pkceMethod: 'S256',
};

export function login(redirectUri: string = window.location.href) {
  return keycloakInstance.login({
    scope: 'openid email profile', // TODO add 'agencies'
    redirectUri,
  });
}

export const Roles = {
  DPGAdmin: 'PMP_DPG_ADMIN',
  DPGUser: 'PMP_DPG_USER',
  ClientAdmin: 'PMP_CLIENT_ADMIN',
  ClientUser: 'PMP_CLIENT_USER',
};

export const RolesNameMap = Object.fromEntries(
  Object.entries(Roles).map(([n, r]) => [r, n])
);

export const DEFAULT_ROLE = Roles.ClientUser;

export function getRole(): string {
  return (
    Object.values(Roles).find((role) => keycloakInstance.hasRealmRole(role)) ??
    DEFAULT_ROLE
  );
}

export function getUserRole(user: User): string {
  return Object.values(Roles).find((role) => user.roles.includes(role)) ?? DEFAULT_ROLE;
}
