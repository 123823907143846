export interface UserUpdatePasswordFormData {
  password: string;
  repeatPassword: string;
  termsOfUse: boolean;
}

export enum ValidationErrorType {
  password = 'PASSWORD',
  repeatPassword = 'REPEAT_PASSWORD',
  termsOfUse = 'TERMS_OF_USER',
}

export type ValidationError = {
  message: string;
  type: ValidationErrorType;
};
