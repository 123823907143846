import { createAppRoutes } from 'utils/create-app-routes';

export const routes = {
  main: '/',
  login: '/login',
  deals: '/deals',
  createDeals: '/deals/new',
  editDeals: '/deals/edit',
  viewDeal: '/deal/:id',
  importDeals: '/agency/:id/import',
  users: '/users',
  user: '/user/:id',
  createUser: '/user/new',
  agencies: '/agencies',
  activate: '/activate',
  validateEmail: '/validate-email',
};

export const appRoutes = createAppRoutes(routes);

export const redirectsConfig = [
  {
    from: appRoutes.main(),
    to: appRoutes.deals(),
  },
];
