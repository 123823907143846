import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { DealAspects } from 'api/deals/types';

import { ChannelSelect } from './ChannelSelect';
import { RON_DPG_INVENTORY, RON_PPN_INVENTORY } from './constants';
import { SiteSelect } from './SiteSelect';

import { theme } from '../../../theme';
import { SelectField } from '../../form/fields/select-field';

export type Props = {
  inventory: DealAspects['inventory'];
  onChange(inventory: DealAspects['inventory']): void;
};

type Type = 'all' | 'rondpg' | 'ronppn' | 'channel' | 'site';

function getType(inventory: DealAspects['inventory']): Type {
  if (!inventory || inventory.length === 0) {
    return 'all';
  }

  if (JSON.stringify(inventory) === JSON.stringify([RON_DPG_INVENTORY])) {
    return 'rondpg';
  }

  if (JSON.stringify(inventory) === JSON.stringify([RON_PPN_INVENTORY])) {
    return 'ronppn';
  }

  return inventory[0].type;
}

function getDefaultInventoryForType(type: Type): DealAspects['inventory'] {
  switch (type) {
    case 'all':
      return null;
    case 'rondpg':
      return [RON_DPG_INVENTORY];
    case 'ronppn':
      return [RON_PPN_INVENTORY];
    case 'channel':
    case 'site':
      return [];
  }
}

export const InventoryDialogContent: React.FC<Props> = ({ inventory, onChange }) => {
  const intl = useIntl();
  const [type, setType] = useState(() => getType(inventory));

  return (
    <>
      <SelectField
        fullWidth
        variant="outlined"
        sx={{ marginBottom: theme.spacing(2) }}
        value={type}
        options={['all', 'rondpg', 'ronppn', 'channel', 'site'].map((type) => ({
          value: type,
          label: intl.formatMessage({
            id: `deal.inventory.dialog.type.${type}`,
          }),
        }))}
        onChange={(e) => {
          const newType = e.target.value as Type;

          setType(newType);

          if (newType !== type) {
            onChange(getDefaultInventoryForType(newType));
          }
        }}
      />
      {type === 'channel' && <ChannelSelect inventory={inventory} onChange={onChange} />}
      {type === 'site' && <SiteSelect inventory={inventory} onChange={onChange} />}
    </>
  );
};
