import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { login } from '../../utils/keycloak/Keycloak';
import { useKeycloak } from '../../utils/keycloak/KeycloakProvider';

export const Login: React.FC = () => {
  const keycloak = useKeycloak();
  const { state } = useLocation<{ referer?: string }>();

  useEffect(() => {
    login(state?.referer || window.location.origin);
  }, [keycloak, state]);

  return null;
};
