import { themeColors } from 'theme/themeColors';

export const styles = {
  text: {
    fontSize: 18,
    lineHeight: '31px',
    color: themeColors.black,
    fontFamily: 'Proxima Nova Regular',
    '& span': {
      fontFamily: 'Proxima Nova Bold',
    },
  },
  textWrapper: {
    marginTop: '30px',
  },
};
