import { AxiosError } from 'axios';

import { NotificationMessage } from 'notifications';
import { SendNotification } from 'notifications/snackbar/useNotification';

enum ErrorCode {
  ServerError = 'UI0000000',
  NotAuthorized = 'G00004',
  IllegalServiceRequestBody = 'G00007',
}

const UserMessages = {
  [ErrorCode.ServerError]: {
    title: 'error.message.title.server',
    copy: 'error.message.copy.server',
  },
  [ErrorCode.NotAuthorized]: {
    title: 'error.message.title.notAuthorized',
    copy: 'error.message.copy.notAuthorized',
  },
  [ErrorCode.IllegalServiceRequestBody]: {
    title: 'error.message.title.illegalService',
    copy: 'error.message.copy.illegalService',
  },
};

const isListedError = (code: string) => {
  for (const errorCode in UserMessages) {
    if (errorCode === code) {
      return true;
    }
  }
  return false;
};

type StandardAPIResponse = { message?: string };

export const handleApiError = (sendNotification: SendNotification, error: AxiosError) => {
  const errorResponse = error.response?.data as StandardAPIResponse;
  const message = errorResponse?.message ?? error.message;

  error.message = message;

  sendNotification({
    type: 'error',
    message: (
      <NotificationMessage
        id={message ? 'something.went.wrong.message' : 'something.went.wrong'}
        values={message ? [{ key: 'message', value: message }] : []}
        serverError={
          isListedError(error.code as string)
            ? {
                title: UserMessages[error.code as keyof typeof UserMessages].title,
                copy: UserMessages[error.code as keyof typeof UserMessages].copy,
              }
            : null
        }
      />
    ),
  });
};
