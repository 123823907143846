import { theme } from '../../theme';
import { themeColors } from '../../theme/themeColors';

export const styles = {
  title: {
    color: themeColors.greyPrimary,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer',
  },
  text: {
    paddingTop: '15px',
  },
  mutedText: {
    color: themeColors.greyPrimary,
  },
};
