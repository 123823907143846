import React from 'react';
import { FormattedMessage } from 'react-intl';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { Alert, AlertProps, SxProps } from '@mui/material';

import { Icon } from 'components/icon';
import { Link } from 'components/link';
import { convertSxToThemeType } from 'utils';

import { styles } from './styles';

interface Props extends AlertProps {
  link?: {
    textId: string;
    to: string;
  };
}

export const InlineAlert: React.FC<Props> = ({ children, link, sx, ...rest }) => {
  return (
    <Alert
      variant="filled"
      iconMapping={{
        error: <Icon name="exclamation" width={24} height={24} />,
        success: <CheckCircleIcon />,
        info: <InfoIcon />,
        warning: <Icon name="warning" width={24} height={24} />,
      }}
      sx={convertSxToThemeType([styles, sx as SxProps])}
      {...rest}
    >
      {children}
      {link && (
        <Link withIcon to={link.to} sx={{ marginTop: '8px' }}>
          <FormattedMessage id={link.textId} />
        </Link>
      )}
    </Alert>
  );
};
