import React from 'react';

import { Grid } from '@mui/material';

import { styles } from 'components/footer-small/styles';
import { useTermsOfUseLink } from 'utils/useTermsOfUseLink';

import { HelpLink } from './HelpLink';

import { TEST_ID } from '../../constants';

const HelpLinks: React.FC = () => {
  const termsOfUseLink = useTermsOfUseLink();

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      data-testid={TEST_ID.appFooterHelpLinks}
      sx={styles.helpLinks}
    >
      <HelpLink href="#" translationId="support" isVisible={false} />
      <HelpLink href="#" translationId="faq" isVisible={false} />
      <HelpLink href={termsOfUseLink} translationId="terms" />
      <HelpLink href="https://www.dpgmediagroup.com/" translationId="dpg_media_group" />
    </Grid>
  );
};

export { HelpLinks };
