import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { styles } from 'components/deals-table-layout/styles';
import { StatusLabel } from 'components/status-label/StatusLabel';
import { styles as tableStyles } from 'components/table/common-styles';

import { TableTitle } from './components';
import { DealActions } from './components/DealActions';
import { LoadingOverlay } from './components/LoadingOverlay';
import { NoRowsOverlay } from './components/NoRowsOverlay';
import { TableLayoutActions } from './components/TableLayoutActions';
import { TableLayoutTitle } from './components/TableLayoutTitle';
import { Props } from './types';

import { Deal } from '../../api/deals/types';
import { appRoutes } from '../../routes/appRoutes';
import { Button } from '../button';
import { ToggleableDealIdField } from '../deal-details/ToggleableDealIdField';
import { ToggleableDealNameField } from '../deal-details/ToggleableDealNameField';
import { AscendingIcon } from '../sort-icon/ascending-icon/AscendingIcon';
import { DescendingIcon } from '../sort-icon/descending-icon/DescendingIcon';
import { UnsortedIcon } from '../sort-icon/unsorted-icon/UnsortedIcon';
import { Pagination } from '../table/pagination/Pagination';
import { Tooltip } from '../tooltip/Tooltip';

const DealsTableLayout: React.FC<Props> = ({
  data,
  isLoading,
  SearchInput,
  searchValue,
  page,
  pageSize,
  onPageSizeChange,
  onPageChange,
  sortModel,
  onSortModelChange,
}): JSX.Element => {
  const sxStyles = styles({
    notClickable: false,
    disableHeaderOverflow: false,
    selectAllCheckboxHidden: false,
  });
  const intl = useIntl();
  const columns: Array<GridColDef> = [
    {
      field: 'dealId',
      minWidth: 175,
      headerName: intl.formatMessage({ id: 'deals.overview.column.dealId' }),
      renderCell: ({ row }) => <ToggleableDealIdField deal={row} />,
    },
    {
      field: 'dealName',
      headerName: intl.formatMessage({ id: 'deals.overview.column.dealName' }),
      flex: 1,
      renderCell: ({ row }) => <ToggleableDealNameField deal={row} />,
    },
    {
      field: 'ssp',
      headerName: intl.formatMessage({ id: 'deals.overview.column.ssp' }),
      valueGetter: ({ row }) => row.ssp ?? 'Xandr',
    },
    {
      field: 'state',
      headerName: intl.formatMessage({ id: 'deals.overview.column.state' }),
      minWidth: 150,
      renderCell: ({ row }) => (
        <>
          <StatusLabel state={row.state} />
          {row.external && (
            <Tooltip title={intl.formatMessage({ id: 'deals.overview.externalHint' })}>
              <Typography variant="body2">E</Typography>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: intl.formatMessage({ id: 'deals.overview.column.actions' }),
      type: 'actions',
      align: 'left',
      getActions: ({ row }) => [<DealActions deal={row} />],
    },
    {
      field: 'lastModifiedDate',
      valueGetter: ({ row }) => row.lastModifiedDate ?? row.creationDate,
    },
  ];
  const [selectedRowIds, setSelectedRowIds] = useState<Array<string>>([]);
  const selectedDeals = selectedRowIds
    .map((id) => data?.deals?.find((d: Deal) => d.id === id))
    .filter((d) => !!d) as Array<Deal>;

  return (
    <>
      <TableLayoutTitle
        titleId="deals.overview.title"
        descriptionId="deals.overview.description"
      />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Button
            to={appRoutes.createDeals()}
            endIconName="arrow-right"
            labelId="deals.overview.button.new"
          />
        </Grid>
      </Grid>
      <Box mt={4} sx={tableStyles.grid}>
        <TableTitle
          totalId="deals.overview.table.title"
          searchField={searchValue}
          total={data?.meta.totalElements ?? 0}
        />
        <TableLayoutActions selectedDeals={selectedDeals} SearchInput={SearchInput} />
        <DataGrid
          checkboxSelection
          autoHeight
          rows={data?.deals ?? []}
          columns={columns}
          loading={isLoading}
          sx={sxStyles.root}
          rowHeight={72}
          columnHeaderHeight={60}
          hideFooter={true}
          disableColumnMenu={true}
          slots={{
            columnSortedDescendingIcon: DescendingIcon,
            columnSortedAscendingIcon: AscendingIcon,
            columnUnsortedIcon: UnsortedIcon,
            loadingOverlay: LoadingOverlay,
            noRowsOverlay: () => (
              <NoRowsOverlay
                title="deals.overview.noRows.title"
                description="deals.overview.noRows.description"
              />
            ),
          }}
          rowCount={data?.meta.totalElements ?? 0}
          paginationModel={{ page: page - 1, pageSize }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'lastModifiedDate', sort: 'desc' }],
            },
          }}
          columnVisibilityModel={{
            lastModifiedDate: false,
          }}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          onRowSelectionModelChange={(ids) => setSelectedRowIds(ids as Array<string>)}
        />
        <Pagination
          page={page}
          size={pageSize}
          itemsLength={data?.meta.totalElements ?? 0}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  );
};

export { DealsTableLayout };
