import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { usePagination } from 'components/table/pagination/usePagination';

import { filterDeals, getDeals } from '../../api/deals';
import { queryKeys } from '../../api/queryKeys';
import { DealsTableLayout } from '../../components/deals-table-layout';
import { useSearch } from '../../components/table/hooks';

export type DealsOverviewState = {
  searchFor?: string;
};

const DealsOverview = (): JSX.Element => {
  const { SearchInput, searchValue, setSearchValue } = useSearch();
  const { page, pageSize, onPageChange, onPageSizeChange } = usePagination();
  // Currently pagination, sorting and filtering is done client side
  // could be forwarded to getDeals if we get too many deals
  const { data, isLoading } = useQuery(queryKeys.getDeals, getDeals);
  const dealsResult = useMemo(
    () => data && filterDeals(data, searchValue),
    [data, searchValue]
  );
  const { state } = useLocation<DealsOverviewState>();

  useEffect(() => {
    if (state?.searchFor) {
      setSearchValue(state.searchFor);
    }
  }, [state?.searchFor]);

  return (
    <DealsTableLayout
      data={dealsResult}
      isLoading={isLoading}
      SearchInput={SearchInput}
      searchValue={searchValue}
      page={page}
      pageSize={pageSize}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    />
  );
};

export { DealsOverview };
