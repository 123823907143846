import { themeColors } from '../../theme/themeColors';

export const styles = {
  title: {
    color: themeColors.greyPrimary,
  },
  fullWidthAgencyField: {
    flexBasis: '100%',
    width: '0',
  },
  textValue: {
    width: '100%',
    alignSelf: 'flex-end',
  },
  box: {
    display: 'flex',
  },
};
