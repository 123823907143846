export const styles = {
  paper: {
    '& .MuiPopover-paper': {
      minWidth: 437,
      borderRadius: '4px',
      boxShadow: '0 2px 40px 0 rgba(0, 0, 0, 0.37)',
      padding: '20px 51px 42px 50px',
    },
  },
};
