import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ImportDealsTable } from './components/import-deals-table/ImportDealsTable';
import { UploadButton } from './components/upload-button/UploadButton';
import { styles } from './styles';

import { importDeals } from '../../api/deals';
import { Deal } from '../../api/deals/types';
import { NotificationMessage, useNotification } from '../../notifications';
import { themeColors } from '../../theme/themeColors';
import { Icon } from '../icon';

interface Props {
  agencyId: string;
}

export const ImportDealsLayout: React.FC<Props> = ({ agencyId }) => {
  const sendNotification = useNotification();
  const [records, setRecords] = useState<Array<Partial<Deal>>>([]);
  const validRecords = useMemo(
    () => records.filter((s) => s.dealId && s.dealName),
    [records]
  );
  const onRemove = (idx: number) => {
    setRecords((r) => {
      r.splice(idx, 1);
      return [...r];
    });
  };
  const onSubmit = async () => {
    try {
      await importDeals(agencyId, validRecords);

      setRecords([]);
      sendNotification({
        type: 'success',
        message: (
          <NotificationMessage
            id="agency.importDeals.submit.success"
            values={[{ key: 'count', value: `${validRecords.length}` }]}
          />
        ),
      });
    } catch (e) {
      console.error('Error during import', e);

      sendNotification({
        type: 'error',
        message: <NotificationMessage id="agency.importDeals.submit.error" />,
      });
    }
  };

  return (
    <>
      <UploadButton onSelect={(newRecords) => setRecords(newRecords)} />
      <ImportDealsTable records={records} onRemove={onRemove} />
      <div style={styles.buttonContainer}>
        <Button
          variant="contained"
          disabled={validRecords.length === 0}
          onClick={onSubmit}
        >
          <Icon
            name="upload-cloud"
            width={24}
            height={24}
            sx={styles.buttonIcon}
            color={themeColors.white}
          />
          <FormattedMessage
            id="agency.importDeals.submit.button.label"
            values={{ count: validRecords.length }}
          />
        </Button>
        <Typography variant="body1" color="textSecondary">
          <FormattedMessage
            id="agency.importDeals.submit.button.description"
            values={{ count: records.length - validRecords.length }}
          />
        </Typography>
      </div>
    </>
  );
};
