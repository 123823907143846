import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';

import { Icon } from 'components/icon';

import { styles } from './styles';

import { User } from '../../../api/users/types';
import { ManageUserActivationDialog } from '../../../features/users/manage-user/components/manage-user-activation-dialog';
import { appRoutes } from '../../../routes/appRoutes';
import { useAbility } from '../../acl/AclProvider';
import { Actions, Permissions } from '../../acl/constants';
import { Tooltip } from '../../tooltip/Tooltip';

type Props = {
  user: User;
};

export const UserActions: React.FC<Props> = ({ user }) => {
  const intl = useIntl();
  const ability = useAbility();
  const canEditUser = ability.can(Actions.Manage, {
    type: Permissions.UserInfo,
    ...user,
  });
  const canManageActivation =
    canEditUser &&
    ability.can(Actions.Manage, {
      type: Permissions.Activation,
      ...user,
    });

  return (
    <>
      {canEditUser && (
        <Tooltip
          title={intl.formatMessage({
            id: 'users.actions.edit',
          })}
          size="small"
        >
          <IconButton
            sx={styles.item}
            component={Link}
            to={appRoutes.user({ id: user.id })}
          >
            <Icon width={24} height={24} name="edit" />
          </IconButton>
        </Tooltip>
      )}
      {canManageActivation && (
        <ManageUserActivationDialog
          user={user}
          triggerEl={
            <Tooltip
              title={intl.formatMessage({
                id: user.active ? 'users.actions.deactivate' : 'users.actions.activate',
              })}
              size="small"
            >
              <IconButton sx={styles.item}>
                <Icon
                  width={24}
                  height={24}
                  name={user.active ? 'deactivate' : 'activate'}
                />
              </IconButton>
            </Tooltip>
          }
        />
      )}
    </>
  );
};
