import React, { ReactNode } from 'react';

import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { State } from '../../api/deals/types';
import { CONTENT_WIDTH_MAP } from '../section-content/constants';
import { StatusLabel } from '../status-label';

interface Props {
  title: ReactNode;
  description?: ReactNode;
  mb?: number;
  sx?: SxProps;
  state?: State;
}

const SectionTitleComponent: React.FC<Props> = ({
  title,
  description,
  mb = 0,
  sx = {},
  state,
}) => {
  return (
    <Box mb={mb} sx={sx}>
      <Box mb={1}>
        <Typography
          variant="h1"
          color="textPrimary"
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        maxWidth={{ xs: '100%', md: CONTENT_WIDTH_MAP.halfScreen }}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {description && (
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
        )}
        {state && <StatusLabel state={state} sx={{ width: 'auto' }} />}
      </Box>
    </Box>
  );
};

const SectionTitle = React.memo(SectionTitleComponent);

export { SectionTitle };
