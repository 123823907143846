import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';

import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import { ManageAgencyForm } from './ManageAgencyForm';
import { styles } from './styles';

import { editAgency } from '../../api/agencies';
import { Agency } from '../../api/agencies/types';
import { queryKeys } from '../../api/queryKeys';
import { NotificationMessage, useNotification } from '../../notifications';
import { theme } from '../../theme';
import { Button } from '../button';
import { LoadingOverlay } from '../loading-overlay';

export type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  agencyToEdit: Agency;
};

const EditAgencyForm: React.FC<Props> = ({ open, setOpen, agencyToEdit }) => {
  const [agency, setAgency] = useState(agencyToEdit);
  const hasIncompleteAgency = !agency.name || !agency.salesForceId;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sendNotification = useNotification();
  const cache = useQueryClient();
  const onEditAgency = async () => {
    setIsSubmitting(true);
    try {
      await editAgency({
        ...agency,
        buyers: agency.buyers?.filter((buyer) => buyer.dsp || buyer.seat),
        advertisers: agency.advertisers?.filter((advertiser) => advertiser),
      });
      await cache.invalidateQueries([queryKeys.getAgencies]);

      sendNotification({
        type: 'success',
        message: <NotificationMessage id={'agency.form.editAgency.success'} />,
      });

      setOpen(!open);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isSubmitting && (
        <Backdrop
          open={true}
          style={{
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <LoadingOverlay />
        </Backdrop>
      )}
      <Typography variant="h2" sx={styles.title}>
        <FormattedMessage id="agency.form.edit.title" />
      </Typography>
      <ManageAgencyForm agency={agency} setAgency={setAgency} />
      <Button
        labelId="agency.form.button.editAgency"
        endIconName="arrow-right"
        disabled={hasIncompleteAgency}
        onClick={onEditAgency}
      />
    </>
  );
};

export { EditAgencyForm };
