import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Backdrop, Checkbox, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Button } from 'components/button';
import { ContainerWithTitle } from 'components/elements/container-with-title';
import { ExternalLink } from 'components/external-link';
import { LinkType } from 'components/external-link/ExternalLink';
import { PasswordField } from 'components/form/fields/password-field';
import { Icon } from 'components/icon';
import { SectionContent } from 'components/section-content';
import { theme } from 'theme';
import { useTermsOfUseLink } from 'utils/useTermsOfUseLink';

import { styles } from './styles';
import {
  UserUpdatePasswordFormData,
  ValidationError,
  ValidationErrorType,
} from './types';
import { getValidationErrors } from './util';

import { useActivateWithToken } from '../../../api/users/hooks/useActivateWithToken';
import { UserActivateWithTokenData } from '../../../api/users/types';
import { LoadingOverlay } from '../../../components/loading-overlay';
import { NotificationMessage, useNotification } from '../../../notifications';
import { appRoutes } from '../../../routes/appRoutes';

export const ActivateUser: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const sendNotification = useNotification();
  const termsOfUseLink = useTermsOfUseLink();
  const token = new URLSearchParams(window.location.search).get('token');
  const { mutate } = useActivateWithToken();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState([] as Array<ValidationError>);
  const [passwordData, setPasswordData] = useState({} as UserUpdatePasswordFormData);
  const onChangePasswordData = (newPasswordData: UserUpdatePasswordFormData) => {
    if (validationErrors.length > 0) {
      setValidationErrors(getValidationErrors(newPasswordData, intl));
    }
    setPasswordData(newPasswordData);
  };
  const isDisabled = () => {
    return (
      validationErrors.length > 0 ||
      isSubmitting ||
      !passwordData.password ||
      !passwordData.repeatPassword ||
      !passwordData.termsOfUse
    );
  };
  const getErrorMessageForField = (type: ValidationErrorType) => {
    return validationErrors.filter((e) => e.type === type)?.[0]?.message;
  };
  const onActivateAccount = () => {
    const errors = getValidationErrors(passwordData, intl);

    setValidationErrors(errors);

    if (errors.length === 0) {
      setIsSubmitting(true);

      mutate(
        {
          password: passwordData.password,
          token,
        } as UserActivateWithTokenData,
        {
          onSuccess: () => {
            sendNotification({
              type: 'success',
              message: <NotificationMessage id={'users.activate_user.success'} />,
            });

            history.push(appRoutes.login());
          },
          onError: () => {
            sendNotification({
              type: 'error',
              message: <NotificationMessage id={'users.activate_user.error'} />,
            });
          },
          onSettled: () => {
            setIsSubmitting(false);
          },
        }
      );
    }
  };

  return (
    <Grid container>
      <Box mb={4} sx={styles.titleWrapper}>
        <Box>
          <Typography variant="h1">
            <FormattedMessage id="users.activate_user.title" />
          </Typography>
          <Typography variant="body1" color="textSecondary">
            <FormattedMessage id="users.activate_user.description" />
          </Typography>
        </Box>
      </Box>
      <Grid item container md={12} justifyContent="space-between">
        <Grid item md={6}>
          <Box sx={styles.wrapper}>
            {isSubmitting && (
              <Backdrop
                open={true}
                style={{
                  zIndex: theme.zIndex.drawer + 1,
                }}
              >
                <LoadingOverlay />
              </Backdrop>
            )}
            <SectionContent size="lg">
              <ContainerWithTitle
                title={intl.formatMessage({
                  id: 'users.activate_user.setNew.title',
                })}
                description={intl.formatMessage({
                  id: 'users.activate_user.setNew.description',
                })}
              >
                <PasswordField
                  fullWidth
                  name="password"
                  autoComplete="true"
                  isError={getErrorMessageForField(ValidationErrorType.password)}
                  onChange={(e) =>
                    onChangePasswordData({
                      ...passwordData,
                      password: e.target.value,
                    })
                  }
                />
              </ContainerWithTitle>
              <Typography variant="body2">
                <FormattedMessage id="users.activate_user.validation" />
              </Typography>
              <ContainerWithTitle
                title={intl.formatMessage({
                  id: 'users.activate_user.repeat.title',
                })}
                description={intl.formatMessage({
                  id: 'users.activate_user.repeat.description',
                })}
              >
                <PasswordField
                  fullWidth
                  name="repeatPassword"
                  autoComplete="true"
                  isError={getErrorMessageForField(ValidationErrorType.repeatPassword)}
                  onChange={(e) =>
                    onChangePasswordData({
                      ...passwordData,
                      repeatPassword: e.target.value,
                    })
                  }
                />
              </ContainerWithTitle>
              <Box sx={styles.fullWidth}>
                <Checkbox
                  checked={passwordData.termsOfUse ?? false}
                  sx={styles.checkbox}
                  onChange={(e) =>
                    onChangePasswordData({
                      ...passwordData,
                      termsOfUse: e.target.checked,
                    })
                  }
                />
                <Typography>
                  {intl.formatMessage(
                    { id: 'terms_and_conditions.acceptTerms.label' },
                    {
                      link: (
                        <ExternalLink type={LinkType.Primary} href={termsOfUseLink}>
                          <FormattedMessage id="terms_and_conditions.acceptTerms.link" />
                        </ExternalLink>
                      ),
                    }
                  )}
                </Typography>
                {getErrorMessageForField(ValidationErrorType.termsOfUse) && (
                  <Typography sx={styles.errorMessage}>
                    {getErrorMessageForField(ValidationErrorType.termsOfUse)}
                  </Typography>
                )}
                <Box mt={{ xs: 3, md: 5 }}>
                  <Button
                    disabled={isDisabled()}
                    endIconName="arrow-right"
                    labelId="users.activate_user.submit"
                    onClick={onActivateAccount}
                  />
                </Box>
              </Box>
            </SectionContent>
          </Box>
        </Grid>
        <Grid item container md={6} justifyContent="flex-end">
          <Icon name="man" width={643} height={541} viewBox="0 0 643 562" />
        </Grid>
      </Grid>
    </Grid>
  );
};
