import React from 'react';

import Box from '@mui/material/Box';

import { CONTENT_MAX_WIDTH, NARROW_MAX_WIDTH } from './constants';
import { styles } from './styles';

import { ReactFCC } from '../../react-util';

const ContentLayout: ReactFCC<{
  type?: 'wide' | 'narrow';
  disablePaddings?: boolean;
}> = ({ children, type = 'wide', disablePaddings = false }) => (
  <Box
    sx={{
      ...styles.containerLayout,
      ...(disablePaddings ? { padding: '0px !important' } : {}),
    }}
    maxWidth={type === 'wide' ? CONTENT_MAX_WIDTH : NARROW_MAX_WIDTH}
  >
    {children}
  </Box>
);
export { ContentLayout };
