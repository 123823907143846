export const styles = {
  paper: {
    '& .MuiDialog-paper': {
      width: 545,
      borderRadius: '4px',
      '& .MuiDialogContent-root': {
        padding: 0,
      },
    },
  },
  actions: {
    marginTop: '36px',
  },
};
