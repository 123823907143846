import { useMemo, useState } from 'react';

import { NONE_TARGETING_ID } from '../constants';
import { DetailedTargeting } from '../types';

export default function useDetailedTargeting(): DetailedTargeting {
  const [detailedTargeting, setDetailedTargeting] = useState<DetailedTargeting>({});

  useMemo(() => {
    getDetailedTargeting().then((dt) => {
      setDetailedTargeting(dt);
    });
  }, []);

  return detailedTargeting;
}

// Transforms the AdManager API format to our expected
async function getDetailedTargeting(): Promise<DetailedTargeting> {
  // Result of AdManager call to
  // https://api-gateway.dpgadmanager.com/api/backend/audience/filters/display/NL
  const filters = (await import('../data/filters.json')).default;

  const detailedTargeting: DetailedTargeting = {
    gender: [
      {
        id: NONE_TARGETING_ID,
        label: 'No gender targeting',
        size: filters.gender.reduce((agg, g) => agg + g.size, 0),
        exclusive: true,
      },
      ...filters.gender,
    ],
    age: [...filters.ages],
    interest: [...filters['detailed-targeting'].interest],
    moments: [...filters['detailed-targeting'].moments],
    sociodemographic: [...filters['detailed-targeting'].sociodemographic],
    contextualTargeting: [...filters['detailed-targeting']['contextual-targeting']],
  };

  // Add hardcoded segment codes for genders:
  // https://bitbucket.org/persgroep/dpg-dsp-backend/src/e515e1c6ccad04e4469e9ca1d4ddddc93b8c2ca5/src/main/java/dpgmedia/dsp/api/audience/service/AudienceMapper.java?at=master#lines-41
  const genderSegments: Record<string, string> = {
    'gender-nl-male': 'dmoi_gender_male',
    'gender-nl-female': 'dmoi_gender_female',
  };

  detailedTargeting.gender?.forEach((gender) => {
    if (genderSegments[gender.id]) {
      gender.xandrSegmentCode = genderSegments[gender.id];
    }

    // AdManager does not mark them exclusive
    gender.exclusive = true;
  });

  return detailedTargeting;
}
