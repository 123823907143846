import React, { useState } from 'react';

import Box from '@mui/material/Box';

import { useUpdateUserRole } from 'api/users/hooks';
import { User } from 'api/users/types';
import { SelectRole } from 'features/users/components/select-role';
import { getUserRole, Roles } from 'utils/keycloak/Keycloak';

import { UpdateUserFormWrapper } from '../update-user-form-wrapper';

interface Props {
  user: User;
  onCancel: () => void;
}

export const EditRole: React.FC<Props> = ({ user, onCancel }) => {
  const userRole = getUserRole(user);
  const dpgRoleGroup = [Roles.DPGAdmin, Roles.DPGUser];
  const clientRoleGroup = [Roles.ClientAdmin, Roles.ClientUser];
  const relatedRoles = dpgRoleGroup.includes(userRole) ? dpgRoleGroup : clientRoleGroup;
  const mutation = useUpdateUserRole();
  const [role, setRole] = useState(userRole);

  return (
    <Box>
      <UpdateUserFormWrapper
        mutation={mutation}
        onCancel={onCancel}
        onSave={() => {
          mutation.mutate(
            {
              user,
              role: role!,
            },
            {
              onSuccess: () => {
                onCancel();
              },
            }
          );
        }}
      >
        <Box mt={3}>
          <SelectRole
            fullWidth
            role={role}
            roles={relatedRoles}
            variant="outlined"
            onChange={(e) => setRole(e.target.value as string)}
          />
        </Box>
      </UpdateUserFormWrapper>
    </Box>
  );
};
