import { useSnackbar } from 'notistack';
import React from 'react';
import { useIntl } from 'react-intl';

import { CustomNotification } from './CustomNotification';

export interface Props {
  type: 'success' | 'error' | 'warning' | 'info';
  title?: React.ReactElement;
  message?: React.ReactElement;
  button?: {
    labelId: string;
    clickHandler(): void;
  };
  persist?: boolean;
}

export type SendNotification = (props: Props) => void;

export const useNotification = ({
  autoHideDuration = 3000,
}: {
  autoHideDuration?: number;
} = {}): SendNotification => {
  const intl = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return ({ type, title, message, button, persist = false }: Props) => {
    enqueueSnackbar(message, {
      persist,
      ...(type === 'error' ? {} : { autoHideDuration }),
      content: (key: string | number) =>
        (
          <CustomNotification
            id={key}
            variant={type}
            title={title}
            message={message}
            button={
              button?.labelId
                ? {
                    label: intl.formatMessage({ id: button.labelId }),
                    clickHandler: () => {
                      button?.clickHandler();
                      closeSnackbar(key);
                    },
                  }
                : undefined
            }
          />
        ) as React.ReactNode,
    });
  };
};
