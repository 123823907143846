import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { Box, Checkbox, IconButton } from '@mui/material';

import { styles } from './styles';

import { Agency } from '../../api/agencies/types';
import { queryKeys } from '../../api/queryKeys';
import { getUsers } from '../../api/users';
import { GetUsersResult } from '../../api/users/types';
import { themeColors } from '../../theme/themeColors';
import { Button } from '../button';
import { RowWithTitle } from '../elements/row-with-title';
import { SelectField } from '../form/fields/select-field';
import { TextField } from '../form/fields/text-field/TextField';
import { Icon } from '../icon';
import { Tooltip } from '../tooltip/Tooltip';

export type Props = {
  agency: Agency;
  setAgency: (agency: Agency) => void;
};

const ManageAgencyForm: React.FC<Props> = ({ agency, setAgency }) => {
  const intl = useIntl();
  const { data } = useQuery<GetUsersResult>(queryKeys.getUsers, getUsers);

  return (
    <>
      <RowWithTitle title={intl.formatMessage({ id: 'agency.form.name' })} mt={3} mb={3}>
        <TextField
          fullWidth
          value={agency.name ?? ''}
          onChange={(e) => {
            setAgency({
              ...agency,
              name: e.target.value,
            });
          }}
        />
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'agency.form.dealNameAlias' })}
        mt={3}
        mb={3}
      >
        <TextField
          fullWidth
          value={agency.dealNameAlias ?? ''}
          placeholder={agency.name ?? ''}
          onChange={(e) => {
            setAgency({
              ...agency,
              dealNameAlias: e.target.value,
            });
          }}
        />
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'agency.form.dealManagers' })}
        mt={3}
        mb={3}
      >
        <SelectField
          displayEmpty
          fullWidth
          sx={styles.selectField}
          options={(data?.users ?? []).map((user) => ({
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          }))}
          value={agency.dealManagers ?? []}
          multiple={true}
          variant="outlined"
          onChange={(e) => {
            setAgency({
              ...agency,
              dealManagers: e.target.value as Array<string>,
            });
          }}
        />
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'agency.form.salesForce' })}
        mt={3}
        mb={3}
      >
        <TextField
          fullWidth
          value={agency.salesForceId ?? ''}
          onChange={(e) => {
            setAgency({
              ...agency,
              salesForceId: e.target.value,
            });
          }}
        />
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'agency.form.buyers' })}
        mt={3}
        mb={3}
      >
        <Box sx={styles.fullWidth}>
          {(agency.buyers ?? []).map((buyer, index) => (
            <Box key={index} sx={styles.box}>
              <TextField
                label={<FormattedMessage id="agency.form.dsp" />}
                InputLabelProps={{ shrink: true }}
                value={buyer.dsp ?? ''}
                onChange={(e) => {
                  const newBuyers = [...(agency.buyers ?? [])];
                  newBuyers[index].dsp = e.target.value;

                  setAgency({
                    ...agency,
                    buyers: newBuyers,
                  });
                }}
              />
              <TextField
                label={<FormattedMessage id="agency.form.seat" />}
                InputLabelProps={{ shrink: true }}
                value={buyer.seat ?? ''}
                onChange={(e) => {
                  const newBuyers = [...(agency.buyers ?? [])];
                  newBuyers[index].seat = e.target.value;

                  setAgency({
                    ...agency,
                    buyers: newBuyers,
                  });
                }}
              />
              <IconButton
                sx={styles.deleteIcon}
                onClick={() => {
                  const newBuyers = [...(agency.buyers ?? [])];
                  newBuyers.splice(index, 1);

                  setAgency({
                    ...agency,
                    buyers: newBuyers,
                  });
                }}
              >
                <Icon width={24} height={24} name={'delete'} />
              </IconButton>
            </Box>
          ))}
          <Button
            labelId="agency.form.addBuyer"
            startIconName="plus"
            color="secondary"
            onClick={() => {
              const newBuyers = [...(agency.buyers ?? [])];

              newBuyers.push({ dsp: '', seat: '' });

              setAgency({
                ...agency,
                buyers: newBuyers,
              });
            }}
          />
        </Box>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'agency.form.advertisers' })}
        mt={3}
        mb={3}
      >
        <Box sx={styles.fullWidth}>
          {agency.advertisers?.map((advertiser, index) => (
            <Box key={index} sx={styles.box}>
              <TextField
                fullWidth
                label={<FormattedMessage id="agency.form.advertisers.name" />}
                InputLabelProps={{ shrink: true }}
                value={advertiser.name}
                onChange={(e) => {
                  const advertisers = [...(agency.advertisers ?? [])];

                  advertisers.splice(index, 1, {
                    ...advertiser,
                    name: e.target.value,
                  });

                  setAgency({
                    ...agency,
                    advertisers,
                  });
                }}
              />
              <Tooltip
                title={intl.formatMessage({
                  id: `agency.form.advertisers.gamblingTooltip.${advertiser.isGambling ? 'enabled' : 'disabled'}`,
                })}
                size="small"
              >
                <IconButton
                  sx={styles.gamblingIcon}
                  onClick={() => {
                    const advertisers = [...(agency.advertisers ?? [])];

                    advertisers.splice(index, 1, {
                      ...advertiser,
                      isGambling: !advertiser.isGambling,
                    });

                    setAgency({
                      ...agency,
                      advertisers,
                    });
                  }}
                >
                  <Icon
                    name="gambling"
                    width={24}
                    height={24}
                    color={
                      advertiser.isGambling
                        ? themeColors.purplePrimary
                        : themeColors.greyPrimary
                    }
                  />
                </IconButton>
              </Tooltip>
              <IconButton
                sx={styles.deleteIcon}
                onClick={() => {
                  const advertisers = [...(agency.advertisers ?? [])];

                  advertisers.splice(index, 1);

                  setAgency({
                    ...agency,
                    advertisers,
                  });
                }}
              >
                <Icon width={24} height={24} name="delete" />
              </IconButton>
            </Box>
          ))}
          <Button
            labelId="agency.form.addAdvertiser"
            startIconName="plus"
            color="secondary"
            onClick={() => {
              const advertisers = [...(agency.advertisers ?? [])];

              advertisers.push({ name: '' });

              setAgency({
                ...agency,
                advertisers,
              });
            }}
          />
        </Box>
      </RowWithTitle>
      <RowWithTitle mt={3} mb={3} title={intl.formatMessage({ id: 'agency.form.prio' })}>
        <Box
          sx={{
            ...styles.fullWidth,
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Checkbox
            checked={agency.isPrio}
            sx={styles.checkbox}
            onChange={(e) =>
              setAgency({
                ...agency,
                isPrio: e.target.checked,
              })
            }
          />
        </Box>
      </RowWithTitle>
    </>
  );
};

export { ManageAgencyForm };
