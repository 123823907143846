import React, { createContext, useContext, useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';

import Box from '@mui/material/Box';

import { keycloakInitOptions, keycloakInstance, Roles } from './Keycloak';

import { queryKeys } from '../../api/queryKeys';
import { getUser } from '../../api/users';
import { User } from '../../api/users/types';
import { Loader } from '../../components/loader/Loader';
import { ReactFCC } from '../../react-util';

const KeycloakContext = createContext(keycloakInstance);

export const KeycloakProvider: ReactFCC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    keycloakInstance
      .init(keycloakInitOptions)
      .then(() => {
        if (isOtherClientUser()) {
          keycloakInstance.logout({
            redirectUri: window.location.href,
          });

          return;
        }

        setIsLoading(false);
      })
      .catch((...args) => {
        console.error('Keycloak error', ...args);
      });
  }, []);

  return (
    <KeycloakContext.Provider value={keycloakInstance}>
      {isLoading ? (
        <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
          <Loader width={150} />
        </Box>
      ) : (
        children
      )}
    </KeycloakContext.Provider>
  );
};

function isOtherClientUser() {
  return (
    keycloakInstance.authenticated &&
    !Object.values(Roles).some((r) => keycloakInstance.hasRealmRole(r))
  );
}

export function useKeycloak() {
  return useContext(KeycloakContext);
}

export function useUser(): User | undefined {
  const keycloak = useKeycloak();
  const { data } = useQuery(
    [queryKeys.getUser, keycloak.tokenParsed?.sub ?? ''],
    getUser,
    { enabled: !!keycloak.tokenParsed?.sub }
  );

  return data;
}
