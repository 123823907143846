import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Typography } from '@mui/material';

import { styles } from './styles';
import HeadInfo from './UserDropdown/HeadInfo';
import MenuField from './UserDropdown/MenuField';

import { queryKeys } from '../../api/queryKeys';
import { getUser } from '../../api/users';
import { appRoutes } from '../../routes/appRoutes';
import { getRole, RolesNameMap } from '../../utils/keycloak/Keycloak';
import { useKeycloak } from '../../utils/keycloak/KeycloakProvider';

export const useMenuItems = () => {
  const history = useHistory();
  const { logout, tokenParsed } = useKeycloak();
  // TODO check if we can drop this query
  const { data: user, isLoading } = useQuery(
    [queryKeys.getUser, tokenParsed?.sub as string],
    getUser
  );
  const { userAlias, userName, userEmail } = useMemo(() => {
    const firstName = user?.firstName ?? '';
    const lastName = user?.lastName ?? '';

    return {
      userAlias: firstName.charAt(0) + lastName.charAt(0),
      userName: `${firstName} ${lastName}`,
      userEmail: user?.email ?? '',
    };
  }, [user]);

  const UserLogoElem = useMemo(
    () => (
      <Typography variant="h5" sx={styles.title}>
        {userAlias}
      </Typography>
    ),
    [userAlias]
  );

  const menuItems = useMemo(
    () => [
      {
        textId: `${userName}`,
        customRenderer: (
          <HeadInfo
            userName={userName}
            userEmail={userEmail}
            role={`role.${RolesNameMap[getRole()]?.toLowerCase() ?? 'none'}`}
          />
        ),
        defaultExpanded: true,
        sx: styles.customHover,
      },
      {
        textId: 'user_settings',
        customRenderer: <MenuField icon="settings" textId="nav.dropdown.user.settings" />,
        sx: styles.customMenuItem,
        onClick: (handleClose: () => void) => {
          history.push(appRoutes.user({ id: user!.id }));
          handleClose();
        },
      },
      {
        textId: 'nav.dropdown.logout',
        sx: styles.customMenuItem,
        onClick: () => {
          logout({ redirectUri: window.location.origin + appRoutes.login() });
        },
        customRenderer: <MenuField icon="logout" textId="nav.dropdown.logout" />,
      },
    ],
    [history, logout, userName, userEmail]
  );

  return { UserLogoElem, menuItems, isLoading };
};
