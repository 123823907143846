import React from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import { InputAdornment, MenuItem, Select, SelectProps } from '@mui/material';
import { SxProps } from '@mui/system';

import { convertSxToThemeType } from 'utils';

import { styles } from './styles';

export type Option = { label: string; value: unknown; key?: string; disabled?: boolean };
export type OptionHeader = { heading: string; key?: string };
type Props = SelectProps & {
  options: Array<Option | OptionHeader | string | number>;
};

function _normalizeOption(option: Props['options'][0]): Option | OptionHeader {
  if (typeof option === 'string' || typeof option === 'number') {
    return {
      value: option,
      label: `${option}`,
    };
  }

  return option;
}

function isOptionHeader(option: Option | OptionHeader): option is OptionHeader {
  return !!(option as OptionHeader).heading;
}

export const SelectField: React.FC<Props> = ({
  sx,
  options,
  onChange,
  value,
  ...props
}) => {
  const clonedProps = {
    MenuProps: {
      PaperProps: {
        sx: styles.dropdown,
      },
    },
    sx: convertSxToThemeType([styles.select, styles.icon, sx as SxProps]),
    endAdornment: (
      <InputAdornment position="start">
        <ErrorIcon sx={styles.errorIcon} className="ErrorAdornment" />
      </InputAdornment>
    ),
    ...props,
  };
  const stringify = (v: unknown) => (v === '' ? '' : JSON.stringify(v));

  return (
    <Select
      {...clonedProps}
      value={props.multiple ? (value as unknown[]).map(stringify) : stringify(value)}
      onChange={(e, ...rest) => {
        e.target.value = props.multiple
          ? (e.target.value as string[]).map((v) => JSON.parse(v))
          : JSON.parse(e.target.value as string);

        return onChange?.(e, ...rest);
      }}
    >
      {options.map(_normalizeOption).map((option) =>
        isOptionHeader(option) ? (
          <MenuItem
            key={option.key ?? option.heading}
            sx={styles.optionHeading}
            disabled={true}
          >
            {option.heading}
          </MenuItem>
        ) : (
          <MenuItem
            key={option.key ?? option.label}
            value={JSON.stringify(option.value)}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        )
      )}
    </Select>
  );
};
