import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '@mui/material';

import { SectionTitle } from 'components/section-title';
import { styles as tableStyles } from 'components/table/common-styles';

interface Props {
  titleId: string;
  descriptionId?: string;
}

const TableLayoutTitle = ({ titleId, descriptionId }: Props): JSX.Element => (
  <Box mb={4} sx={tableStyles.titleWrapper}>
    <SectionTitle
      title={<FormattedMessage id={titleId} />}
      description={descriptionId && <FormattedMessage id={descriptionId} />}
      sx={{
        width: '100%',
        '& .MuiTypography-h1': {
          whiteSpace: 'nowrap',
        },
      }}
    />
  </Box>
);

export { TableLayoutTitle };
