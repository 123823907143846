import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import qs from 'query-string';

import { keycloakInstance, login } from '../utils/keycloak/Keycloak';

const API_URLS: Record<string, string> = {
  local: 'http://localhost:4000',
  nonprod: 'https://pmp-portal-api-nonprod.target.dpgmedia.cloud',
  prod: 'https://pmp-portal-api.target.dpgmedia.cloud',
};
const BASE_URL = API_URLS[process.env.REACT_APP_STAGE as string] ?? API_URLS.nonprod;

async function updateToken(config: InternalAxiosRequestConfig) {
  try {
    await keycloakInstance.updateToken(30);

    config.headers.setAuthorization(`Bearer ${keycloakInstance.token}`);
  } catch (e) {
    console.warn('Could not get keycloak token', e);
    login();
  }

  return config;
}

function createApiClient(baseUrl: string, authorize: boolean): AxiosInstance {
  const ApiClient = axios.create({
    baseURL: baseUrl,
    paramsSerializer(params) {
      return qs.stringify(params);
    },
  });

  if (authorize) {
    ApiClient.interceptors.request.use(updateToken);
  }

  return ApiClient;
}

export const Api = createApiClient(BASE_URL, true);
export const UnauthApi = createApiClient(BASE_URL, false);
