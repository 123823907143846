import React from 'react';

import { Box } from '@mui/material';

import { TEST_ID } from 'components/constants';
import { Loader } from 'components/loader/Loader';

interface Props {
  height?: string;
}

const LoadingOverlay: React.FC<Props> = ({ height = '100%' }) => {
  return (
    <Box width="100%" height={height} display="flex" alignItems="center">
      <Box margin="auto" data-testid={TEST_ID.loader}>
        <Loader width={150} />
      </Box>
    </Box>
  );
};

export { LoadingOverlay };
