import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { SxProps } from '@mui/material';

import { themeColors } from 'theme/themeColors';

import { theme } from '../../../theme';

const open = keyframes`
  from {
    width: 36px;
  }
  to {
    width: 290px;
  }
`;
const close = keyframes`
  from {
    width: 290px;
  }
  to {
    width: 0;
  }
`;

export const styles: {
  searchBarContainer: SxProps;
  openWithAnimation: SxProps;
  closeWithAnimation: SxProps;
  searchIcon: SxProps;
  searchInput: SxProps;
} = {
  searchBarContainer: {
    position: 'relative',
    marginLeft: '10px',
    height: 45,
    width: 290,
    overflow: 'hidden',
  },
  openWithAnimation: {
    width: 290,
    animation: `${open} 300ms ease`,
  },
  closeWithAnimation: {
    width: 0,
    animation: `${close} 300ms ease`,
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
  },
  searchIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    display: 'flex',
    marginRight: theme.spacing(1),
    '& path + path': {
      fill: themeColors.purplePrimary,
    },
  },
  searchInput: {
    width: '100%',
    border: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    caretColor: themeColors.redPrimary,
    background: themeColors.white,
    '& .MuiOutlinedInput-root': {
      height: 45,
    },
    '& ::placeholder': {
      paddingLeft: '5px',
      fontSize: 16,
      fontFamily: 'Proxima Nova Regular',
    },
  },
};

export const CrossButton = styled.button({
  position: 'absolute',
  top: '50%',
  border: 'none',
  right: 10,
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  width: 20,
  height: 20,
  background: '#d7dcde',
  borderRadius: '50%',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 1,
    height: 11,
    background: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(45deg)',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 1,
    height: 11,
    background: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(-45deg)',
  },
});
