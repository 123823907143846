import React from 'react';
import { useIntl } from 'react-intl';

import { CircularProgress } from '@mui/material';

import { useStartResetPassword } from 'api/users/hooks';
import { User } from 'api/users/types';
import { ConfirmationDialog } from 'components/confirmation-dialog';

import { getFullName } from '../../../utils';

interface Props {
  triggerEl: React.ReactElement;
  user: User;
}

export const ResetPasswordDialog: React.FC<Props> = ({ triggerEl, user }) => {
  const intl = useIntl();
  const { mutate, isLoading } = useStartResetPassword(user);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <ConfirmationDialog
      triggerEl={triggerEl}
      title={intl.formatMessage({ id: 'user.manage.start_reset_password.title' })}
      description={intl.formatMessage(
        { id: 'user.manage.start_reset_password.description' },
        { username: getFullName(user) }
      )}
      onConfirm={() => mutate()}
    />
  );
};
