import React from 'react';

import { IndeterminateCheckBox } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Checkbox, Typography } from '@mui/material';

import { themeColors } from 'theme/themeColors';

import { TEST_ID } from '../../constants';
import { DetailedTargetingItem, ItemProps, Relation } from '../../types';
import { sizeFormatter } from '../../utils';

const styles = {
  hiddenCheckbox: {
    visibility: 'hidden',
  },
};

const CategoryItem: React.FC<ItemProps> = ({
  name,
  config,
  disabled,
  relation,
  changeHandler,
  onClick,
}) => {
  const hasNoSegments = (config: DetailedTargetingItem) => {
    return !config.xandrSegmentCode && !config.xandrIdBe && !config.xandrIdNl;
  };

  return (
    <Box
      key={config.id}
      display="flex"
      alignItems="center"
      data-testid={TEST_ID.categoryItem}
    >
      <Checkbox
        color="primary"
        size="small"
        name={name}
        indeterminateIcon={<IndeterminateCheckBox color="primary" />}
        disabled={disabled || hasNoSegments(config)}
        checked={!!relation}
        indeterminate={relation === Relation.INTERMEDIATE ? true : undefined}
        sx={hasNoSegments(config) ? styles.hiddenCheckbox : {}}
        onChange={changeHandler}
      />
      <Box
        display="contents"
        alignItems="center"
        style={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        <Box>
          <Typography variant="body1">{config.label}</Typography>
        </Box>
        <Typography
          variant="body1"
          style={{ marginLeft: 'auto', marginRight: 0, color: themeColors.greyPrimary }}
        >
          {sizeFormatter(config.size)}
        </Typography>
        {config.children && (
          <Box display="inline-flex" style={{ marginRight: 0, verticalAlign: 'middle' }}>
            <ChevronRightIcon htmlColor={themeColors.grey120} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { CategoryItem };
