import { themeColors } from 'theme/themeColors';

export const styles = {
  showAllButton: {
    color: themeColors.greyPrimary,
    fontFamily: 'Proxima Nova Regular',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  updatedCounter: {
    fontFamily: 'Proxima Nova Bold',
    color: themeColors.pink,
    fontSize: 18,
  },
};
