import React, { useState } from 'react';

import { Box, IconButton, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';

import { DealIdField } from './DealIdField';

import { Deal, State } from '../../api/deals/types';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { styles } from '../deals-table-layout/components/styles';
import { Icon } from '../icon';

interface Props {
  deal: Deal;
  sx?: SxProps;
}

export const ToggleableDealIdField: React.FC<Props> = ({ deal, sx }) => {
  const ability = useAbility();
  const canEditDealId =
    ability.can(Actions.Manage, Permissions.DealId) && deal.state !== State.Draft;
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Box sx={{ ...styles.toggleFieldContainer, ...sx }}>
      {isEditing ? (
        <DealIdField
          // autoFocus is ok because the input shows on user action
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          deal={deal}
          alwaysShowCancel={true}
          onComplete={() => setIsEditing(false)}
        />
      ) : (
        <>
          <Typography sx={styles.dealEditableCellText}>
            {deal.dealId && deal.dealId.length > 0 ? deal.dealId : '-'}
          </Typography>
          {canEditDealId && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(!isEditing);
              }}
            >
              <Icon width={18} height={18} name="edit" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};
