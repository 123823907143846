import React from 'react';

import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { SystemStyleObject } from '@mui/system';

import { CONTENT_WIDTH_MAP } from './constants';
import { styles } from './styles';

import { ReactFCC } from '../../react-util';
import { convertSxToThemeType } from '../../utils';

interface Props {
  size: keyof typeof CONTENT_WIDTH_MAP;
  sx?: SxProps | Array<SystemStyleObject<Theme>>;
}

const SectionContent: ReactFCC<Props> = ({ children, size, sx }) => {
  const sectionSx = convertSxToThemeType([styles.sectionContent, sx as SxProps]);

  return (
    <Box maxWidth={{ xs: '100%', md: CONTENT_WIDTH_MAP[size] }} sx={sectionSx}>
      {children}
    </Box>
  );
};

export { SectionContent };
