import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';

interface Props {
  id: string;
  values?: {
    key: string;
    value: string | React.ReactElement;
  }[];
  serverError?: {
    title: string;
    copy: string;
    id?: string;
  } | null;
}

const NotificationMessage: React.FC<Props> = ({ id, values, serverError }) => {
  const messageValues = (values || []).reduce(
    (acc, i) => ({
      ...acc,
      [i.key]: <b style={{ wordBreak: 'keep-all' }}>{i.value}</b>,
    }),
    {}
  );

  if (serverError) {
    return (
      <>
        <Typography variant="body1" fontSize={18}>
          <FormattedMessage id={serverError.title} />
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          <FormattedMessage id={serverError.copy} />
        </Typography>
        {serverError.id && (
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            <FormattedMessage id="error.message.server.advertiser_id.header" />
            {serverError.id}
          </Typography>
        )}
      </>
    );
  }

  return <FormattedMessage id={id} values={{ ...messageValues }} />;
};

export { NotificationMessage };
