import { QueryFunctionContext } from 'react-query';

import {
  GetUsersResult,
  User,
  UserUpdateActivationRequest,
  UserUpdateEmailRequest,
  UserActivateWithTokenData,
  UserUpdateRequest,
  UserUpdateRoleRequest,
  ValidateEmailData,
  UserDefaultAgencyRequest,
} from './types';

import { Api, UnauthApi } from '../api';
import { apiEndpoints } from '../endpoints';

export async function getUsers(): Promise<GetUsersResult> {
  const { data } = await Api.get<GetUsersResult>(apiEndpoints.users());

  return data;
}

export function filterUsers(
  usersResult: GetUsersResult,
  searchValue?: string
): GetUsersResult {
  const searchEntries = searchValue?.split(',').map((t) => t.trim()) ?? [];
  const filteredUsers = usersResult.users.filter((u) =>
    searchFilterUser(u, searchEntries)
  );

  return {
    ...usersResult,
    users: filteredUsers,
    meta: {
      ...usersResult.meta,
      totalElements: filteredUsers.length,
    },
  };
}

export function searchFilterUser(user: User, search: string[]) {
  if (search.length === 0) {
    return true;
  }

  return search.some(
    (s) =>
      user.firstName.toLowerCase().includes(s.toLowerCase()) ||
      user.lastName.toLowerCase().includes(s.toLowerCase()) ||
      user.email.toLowerCase().includes(s.toLowerCase()) ||
      user.agencies?.some((a) => a.name.toLowerCase()?.includes(s.toLowerCase())) ||
      user.roles.some((r) => r.toLowerCase().includes(s.toLowerCase()))
  );
}

export async function getUser({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<User> {
  const [, id] = queryKey;
  const { data } = await Api.get<User>(apiEndpoints.user({ id }));

  return data;
}

export async function createUser(user: User): Promise<User> {
  const { data } = await Api.post<User>(apiEndpoints.userCreate(), user);

  return data;
}

export const startResetPassword = async (user: User): Promise<void> => {
  await Api.post(apiEndpoints.userResetPassword({ id: user.id }));
};

export async function updateUserEmail(data: UserUpdateEmailRequest): Promise<void> {
  await Api.post(apiEndpoints.userChangeEmail({ id: data.user.id }), {
    email: data.email,
  });
}

export async function updateUserRole(data: UserUpdateRoleRequest): Promise<void> {
  await Api.post(apiEndpoints.userSetRole({ id: data.user.id }), {
    role: data.role,
  });
}

export async function updateUserSettings(data: UserUpdateRequest): Promise<void> {
  await Api.post(apiEndpoints.userEdit({ id: data.user.id }), data.updates);
}

export async function resendActivation(user: User): Promise<void> {
  await Api.post(apiEndpoints.userResendActivationToken({ id: user.id }));
}

export async function updateUserActivation(
  data: UserUpdateActivationRequest
): Promise<void> {
  if (data.active) {
    await Api.post(apiEndpoints.userActivate({ id: data.user.id }));
  } else {
    await Api.post(apiEndpoints.userDeactivate({ id: data.user.id }));
  }
}

export async function activateUserWithToken(
  passwordData: UserActivateWithTokenData
): Promise<void> {
  await UnauthApi.post<UserActivateWithTokenData>(
    apiEndpoints.userActivateToken(),
    passwordData
  );
}

export async function validateEmail(data: ValidateEmailData): Promise<void> {
  await UnauthApi.post<ValidateEmailData>(apiEndpoints.validateEmail(), data);
}

export async function setUserDefaultAgency(
  data: UserDefaultAgencyRequest
): Promise<void> {
  await Api.post(apiEndpoints.userSetDefaultAgency({ id: data.user.id }), {
    agencyId: data.agencyId,
  });
}
