import readXlsxFile, { Row } from 'read-excel-file';

import { EXCEL_FILETYPES, UploadError } from './constants';

import { Deal } from '../../api/deals/types';

type ColumnIndexes = {
  dealId: number;
  dealName: number;
  ssp?: number;
  skipFirstRow: boolean;
};

export async function parseFile(file: File): Promise<{
  error?: UploadError;
  records?: Array<Partial<Deal>>;
}> {
  try {
    if (!EXCEL_FILETYPES.includes(file.type ?? '')) {
      return { error: UploadError.invalidFileType };
    }

    const rows = await readXlsxFile(file);
    const columnIdxs = detectColumns(rows);

    if (!columnIdxs) {
      return { error: UploadError.UnknownColumns };
    }

    return {
      records: rows.slice(columnIdxs.skipFirstRow ? 1 : 0).map((r) => {
        const dealIdStr = r[columnIdxs.dealId]?.toString();
        const dealNameStr = r[columnIdxs.dealName]?.toString();

        if (columnIdxs.ssp) {
          const sspStr = r[columnIdxs.ssp]?.toString();

          return createRecord(dealIdStr, dealNameStr, sspStr);
        }

        return createRecord(dealIdStr, dealNameStr);
      }),
    };
  } catch (e) {
    console.error('Something went wrong parsing uploaded file', e);
    return { error: UploadError.Default };
  }
}

function detectColumns(rows: Row[]): ColumnIndexes | null {
  if (!rows[0]) return null;

  // Header row match
  let dealId = rows[0].findIndex((s) => /deal.?id/i.test(s as string));
  let dealName = rows[0].findIndex((s) => /deal.?name/i.test(s as string));
  let ssp = rows[0].findIndex((s) => /ssp/i.test(s as string));

  if (dealId !== -1 && dealName !== -1) {
    const columnIndexes: ColumnIndexes = {
      dealId,
      dealName,
      skipFirstRow: true,
    };

    if (ssp !== -1) {
      columnIndexes.ssp = ssp;
    }

    return columnIndexes;
  }

  // Content match if there is no header row
  dealId = rows[0].findIndex((s) => /\d+/.test(s as string));
  dealName = rows[0].findIndex((s) => /(.+_){3,}/.test(s as string));

  if (dealId !== -1 && dealName !== -1) {
    return {
      dealId,
      dealName,
      skipFirstRow: false,
    };
  }

  return null;
}

function createRecord(dealId: string, dealName: string, ssp?: string): Partial<Deal> {
  const record: Partial<Deal> = {
    dealId,
    dealName,
  };
  const dealNameParts = dealName?.split('_') ?? [];

  const agency = getAgency(dealNameParts);
  if (agency) record.agency = agency;

  const buyer = getBuyer(dealNameParts);
  if (buyer) record.buyer = buyer;

  const floorPrice = getFloorPrice(dealName);
  if (floorPrice) record.floorPrice = floorPrice;

  if (ssp && ssp.toLowerCase().trim() !== 'xandr') record.ssp = ssp;

  return record;
}

function getAgency(dealNameParts: string[]): Deal['agency'] | null {
  return dealNameParts[1];
}

function getBuyer(dealNameParts: string[]): Deal['buyer'] | null {
  const buyer = dealNameParts[2]?.match(/(?<dsp>[a-zA-Z]+)(?<seat>[0-9]+)/);

  if (!buyer) return null;

  return {
    dsp: buyer.groups!.dsp,
    seat: buyer.groups!.seat,
  };
}

function getFloorPrice(dealName: string): Deal['floorPrice'] | null {
  const cpmString = dealName?.match(/_CPM([0-9.,]+)_/i)?.[1]?.replaceAll(',', '.');

  if (!cpmString) return null;

  const cpmNumber = parseFloat(cpmString);

  if (isNaN(cpmNumber)) return null;

  return Math.floor(cpmNumber * 100);
}
