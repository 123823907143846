import React from 'react';

import { Divider } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { themeColors } from 'theme/themeColors';

interface Props {
  title: string | React.ReactElement | React.ReactNode;
  description: string | React.ReactElement | React.ReactNode;
  boxProps?: BoxProps;
  withDivider?: boolean;
  titleFontSize?: number;
  descriptionFontSize?: number;
}

export const InfoRow: React.FC<Props> = ({
  title,
  description,
  boxProps,
  withDivider = false,
  titleFontSize = 16,
  descriptionFontSize = 16,
}) => {
  return (
    <Box {...boxProps}>
      <Typography variant="h4" mb={1} fontSize={titleFontSize}>
        {title}
      </Typography>
      {withDivider && (
        <Divider
          sx={{
            height: '2px',
            background: themeColors.grey20,
            borderColor: themeColors.grey20,
            mb: 1,
          }}
        />
      )}
      <Typography fontSize={descriptionFontSize}>{description}</Typography>
    </Box>
  );
};
