import { DetailedTargeting, Relation, TagValue, TargetingValueItem } from '../../types';
import { getItem } from '../../utils';
import { convertDetailedTargeting } from '../browse/utils';

export const isOptionMatched = (
  option: TagValue,
  relation: Relation,
  formValue: DetailedTargeting
) => {
  const currentPath = option.path;
  if (!currentPath) {
    return false;
  }

  let isMatched = false;
  let currentPathArray = currentPath.split('.');

  for (let i = 1; i < currentPath.split('.').length; i++) {
    const currentItem = getItem(
      currentPathArray,
      convertDetailedTargeting(formValue) as TargetingValueItem[]
    );
    currentPathArray = currentPathArray.slice(0, -1);
    if (currentItem?.relation === relation) {
      isMatched = true;
    }
  }

  return isMatched;
};

export const isOptionIntermediate = (
  option: TagValue,
  relation: Relation,
  formValue: DetailedTargeting
) => {
  const currentPath = option.path;
  if (!currentPath) {
    return false;
  }

  let isMatched = false;
  let currentPathArray = currentPath.split('.');

  for (let i = 1; i < currentPath.split('.').length; i++) {
    const currentItem = getItem(
      currentPathArray,
      convertDetailedTargeting(formValue) as TargetingValueItem[]
    );
    currentPathArray = currentPathArray.slice(0, -1);
    if (currentItem?.relation === 'INTERMEDIATE' && currentItem?.id === option?.id) {
      isMatched = true;
    }
  }

  return isMatched;
};
