import { NONE_TARGETING_ID } from '../../constants';
import {
  DetailedTargeting,
  DetailedTargetingItem,
  Relation,
  RootCategories,
  TargetingValue,
  TargetingValueItem,
} from '../../types';
import { selectAllChildren } from '../targeting-item/utils';

export const merge = (
  config?: TargetingValueItem[],
  values?: TargetingValueItem[]
): TargetingValueItem[] | undefined =>
  config?.map((i) => {
    const valueItem = values?.find((item) => item.id === i.id);
    let children;
    if (i.children) {
      if (valueItem?.relation === Relation.INCLUDED) {
        children = selectAllChildren(Relation.INCLUDED, i.children);
      } else if (valueItem?.relation === Relation.EXCLUDED) {
        children = selectAllChildren(Relation.EXCLUDED, i.children);
      } else {
        children = merge(i?.children, valueItem?.children);
      }
    }
    return {
      ...i,
      children,
      relation: valueItem?.relation,
    };
  });

export const buildMappedFormFieldValues = (
  data: DetailedTargeting,
  formValues: DetailedTargeting
): TargetingValueItem[] | undefined => {
  const filtersData = convertDetailedTargeting(data);
  const formData = convertDetailedTargeting(formValues);

  return merge(filtersData, formData);
};

export const convertDetailedTargeting = (
  data: DetailedTargeting
): TargetingValueItem[] | undefined =>
  getItems(
    Object.values(RootCategories).map((key) => ({
      id: key,
      label: key,
      children: data[key],
      size: 0,
    }))
  );

const getItems = (
  items: TargetingValueItem[] | undefined
): TargetingValueItem[] | undefined =>
  items?.map(({ children, ...rest }) => ({
    children: getItems(children),
    ...rest,
  }));

export const cleanData = (data: TargetingValueItem[]): TargetingValue => {
  return Object.keys(RootCategories).reduce((agg, cat) => {
    const children = getChildren(cat, data);

    if (children?.length) {
      agg[cat as RootCategories] = children;
    }

    return agg;
  }, {} as TargetingValue);
};

export const getChildren = (
  id: string,
  data: TargetingValueItem[]
): TargetingValueItem[] | undefined =>
  cleanItems(data?.find((i) => i.id === id)?.children);

export const cleanItems = (
  items?: TargetingValueItem[] | undefined
): TargetingValueItem[] | undefined =>
  items
    ?.filter((item) => item.relation && !isNoneItem(item))
    ?.map((item) => ({
      id: item.id,
      relation: item.relation,
      label: item.label,
      description: item.description,
      children:
        item.relation === Relation.INCLUDED || item.relation === Relation.INTERMEDIATE
          ? cleanItems(item.children)
          : undefined,
      xandrSegmentCode: item.xandrSegmentCode,
      xandrIdNl: item.xandrIdNl,
      xandrIdBe: item.xandrIdBe,
    }));

export const sortItems = (a: TargetingValueItem, b: TargetingValueItem) => {
  if (isNoneItem(a)) return -1;
  if (isNoneItem(b)) return 1;

  if (a.children && !b.children) {
    return -1;
  }
  if (b.children && !a.children) {
    return 1;
  }

  // Sort by id, eg. iab-nl-368, iab-nl-367, iab-nl-c-167, context-49
  // Non-numeric is always the same within one group
  // Default locale sorting won't work as 12 < 3
  const aIdN = parseInt(a.id.match(/[0-9]+$/)?.[0] ?? '0', 10);
  const bIdN = parseInt(a.id.match(/[0-9]+$/)?.[0] ?? '0', 10);

  return aIdN - bIdN;
};

export const filterEmptyChildren = (
  items?: DetailedTargetingItem[]
): DetailedTargetingItem[] =>
  (items || [])?.map((item) => ({
    ...item,
    children: item.children?.length ? filterEmptyChildren(item.children) : undefined,
  }));

export const isNoneItem = (item: TargetingValueItem): boolean => {
  return !!item.exclusive && item.id === NONE_TARGETING_ID;
};
