import { theme } from '../../theme';

export const styles = {
  headerLayout: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  headerText: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(2),
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
};
