import React, { useRef } from 'react';

import { SxProps } from '@mui/material';

import { FooterScroll } from 'components/dialog2/components/footer-scroll/FooterScroll';
import { HeaderScroll } from 'components/dialog2/components/header-scroll/HeaderScroll';
import { DialogContent } from 'components/dialog2/DialogContent';

interface Props {
  header?: React.ReactElement;
  footer?: React.ReactElement;
  contentSx?: SxProps;
  children: React.ReactNode;
}

export const DialogContainer: React.FC<Props> = ({
  header,
  footer,
  contentSx,
  children,
}) => {
  const containerRef = useRef();

  return (
    <>
      {header && <HeaderScroll targetRef={containerRef}>{header}</HeaderScroll>}
      <DialogContent currentRef={containerRef} sx={contentSx}>
        {children}
      </DialogContent>
      {footer && <FooterScroll targetRef={containerRef}>{footer}</FooterScroll>}
    </>
  );
};
