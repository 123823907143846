import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FormControlLabel, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { DealAspects } from 'api/deals/types';

import { INVENTORY_SITES, INVENTORY_SITES_MAX } from './constants';
import { styles } from './styles';

import { InlineAlert } from '../../inline-alert';

export type Props = {
  inventory: DealAspects['inventory'];
  onChange(inventory: DealAspects['inventory']): void;
};

export const SiteSelect: React.FC<Props> = ({ inventory, onChange }) => {
  const maxReached = (inventory?.length ?? 0) >= INVENTORY_SITES_MAX;

  return (
    <Grid container>
      {maxReached && (
        <Grid item xs={12} mb={2}>
          <InlineAlert variant="filled" severity="info">
            <FormattedMessage
              id="deal.inventory.dialog.type.site.maxReached"
              values={{ max: INVENTORY_SITES_MAX }}
            />
          </InlineAlert>
        </Grid>
      )}
      {INVENTORY_SITES.map((site) => {
        const checked = inventory?.some((i) => i.value === site) ?? false;

        return (
          <Grid key={site} item xs={6} md={4}>
            <FormControlLabel
              sx={styles.checkboxLabel}
              control={
                <Checkbox
                  checked={checked}
                  disabled={maxReached && !checked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onChange([...(inventory ?? []), { type: 'site', value: site }]);
                    } else {
                      onChange(inventory?.filter((i) => i.value !== site) ?? []);
                    }
                  }}
                />
              }
              label={site}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
