import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { IndeterminateCheckBox } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { styles } from 'components/form/fields/autocomplete/styles';

import {
  DetailedTargeting,
  Keyword,
  Relation,
  RestrictedRelation,
  TagValue,
  TargetingValue,
  TargetingValueItem,
} from '../../types';
import { sizeFormatter } from '../../utils';
import { isOptionIntermediate } from '../autocomplete/utils';
import { cleanData } from '../browse/utils';
import { buildTargetingValue } from '../targeting-item/utils';

interface Props {
  option: TagValue;
  textParts: { highlight: boolean; text: string }[];
  disabled: boolean;
  omittedParam: string;
  selected?: boolean;
  value?: TagValue[] & { keywords?: Keyword[] };
  formValue: DetailedTargeting & { keywords?: Keyword[] };
  browseValue: TargetingValueItem[];

  onChange(value: TargetingValue & { keywords?: Keyword[] }): void;
}

export const AutocompleteOption: React.FC<Props> = ({
  option,
  textParts,
  disabled,
  omittedParam,
  selected,
  value,
  formValue,
  browseValue,
  onChange,
}) => {
  const handleDeleteOption = useCallback(() => {
    const newValue =
      selected &&
      buildTargetingValue(option.path!, browseValue, 'INCLUDED' as RestrictedRelation);
    selected &&
      onChange({
        ...cleanData(newValue as TargetingValueItem[]),
        ...(value?.keywords ? { keywords: value.keywords } : {}),
      });
  }, [browseValue, onChange, option.path, selected, value?.keywords]);

  const leavesCount = option.leafItemsCount! - 1;

  return (
    <Box
      sx={{ ...styles.autoSelectOptionItem, padding: 0 }}
      display="flex"
      justifyContent="space-between"
      onClick={handleDeleteOption}
    >
      <Box>
        <Box>
          <Checkbox
            checked={selected}
            color="primary"
            size="small"
            indeterminate={
              isOptionIntermediate(option, Relation.INTERMEDIATE, formValue)
                ? undefined
                : false
            }
            indeterminateIcon={<IndeterminateCheckBox color="primary" />}
          />
          <span>
            {textParts.map((part, index) =>
              part.highlight ? (
                <span key={index} style={{ fontWeight: 'bold' }}>
                  {part.text}
                </span>
              ) : (
                part.text
              )
            )}
          </span>
          {leavesCount > 0 && <span> ({option.leafItemsCount})</span>}
        </Box>
        <Box>
          <Typography variant="body2" style={{ marginLeft: 32 }}>
            {option.pathLabels
              ?.split('.')
              .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
              .join(' > ')}
          </Typography>
        </Box>
      </Box>
      <span>
        {disabled ? (
          <FormattedMessage id={`filter.${omittedParam.toLowerCase()}`} />
        ) : (
          sizeFormatter(Number(option.size))
        )}
      </span>
    </Box>
  );
};
