import React from 'react';

import { TooltipProps } from '@mui/material';
import MuiTooltip from '@mui/material/Tooltip';

interface Props extends TooltipProps {
  size?: 'small' | 'default';
}

export const Tooltip: React.FC<Props> = ({ children, size, ...props }) => {
  if (size === 'small') {
    props.componentsProps = { tooltip: { sx: { padding: 2 } } };
  }

  return <MuiTooltip {...props}>{children}</MuiTooltip>;
};
