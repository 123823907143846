import { SxProps } from '@mui/material';

import { themeColors } from 'theme/themeColors';

export const styles: {
  audienceWrapper: SxProps;
  contentWrapper: SxProps;
  form: SxProps;
  audienceFiltersTitle: SxProps;
  nameField: SxProps;
  editIcon: SxProps;
  ageDialog: SxProps;
  locationsDialog: SxProps;
  inExcludeDialog: SxProps;
} = {
  audienceWrapper: {},
  contentWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  form: {
    borderRadius: '3px',
    backgroundColor: themeColors.white,
    padding: '28px',
    marginRight: '48px',
  },
  audienceFiltersTitle: {
    color: '#241f20',
    fontSize: 21,
    fontFamily: 'Proxima Nova Regular',
  },
  nameField: {
    width: 210,
  },
  editIcon: {
    '& path': {
      fill: themeColors.purplePrimary,
    },
    width: 48,
    height: 48,
  },
  ageDialog: {
    '& .MuiDialogContent-root': {
      width: 290,
      padding: 0,
    },
  },
  locationsDialog: {
    '& .MuiDialogContent-root': {
      width: 790,
      height: 730,
      padding: '8px 24px',
    },
  },
  inExcludeDialog: {
    '& .MuiDialogContent-root': {
      width: 660,
      padding: '12px 24px',
    },
  },
};
