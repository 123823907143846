import React from 'react';

import { buildTargetingValue, isIntermediateDisabled } from './utils';

import {
  DetailedTargetingItem,
  Keyword,
  Relation,
  RestrictedRelation,
  TargetingValue,
  TargetingValueItem,
} from '../../types';
import { getItem } from '../../utils';
import { cleanData, isNoneItem } from '../browse/utils';
import { CategoryItem } from '../category-item/CategoryItem';
import { LeafItem } from '../leaf-item/LeafItem';

type Props = {
  formData: TargetingValueItem[];
  config: DetailedTargetingItem;
  type: RestrictedRelation;
  baseName: string;
  selectOne?: boolean;
  onClick(): void;
  onChange(value: TargetingValue & { keywords?: Keyword[] }): void;
};

const TargetingItem: React.FC<Props> = ({
  formData: targetingValue,
  config,
  type,
  baseName,
  onClick,
  onChange: detailedTargetingOnChange,
  selectOne,
}) => {
  const name = `${baseName}.${config.id}`;

  const changeHandler = () => {
    const newValue = buildTargetingValue(name, targetingValue, type);

    detailedTargetingOnChange(cleanData(newValue!));
  };

  const item = getItem<TargetingValueItem>(name.split('.'), targetingValue);
  let relation = item?.relation;

  if (isNoneItem(config)) {
    const parent = getItem<TargetingValueItem>(
      name.split('.').slice(0, -1),
      targetingValue
    );

    if (parent.children!.every((c) => !c.relation)) {
      relation = Relation.INCLUDED;
    }
  }

  // disable item with opposite relation or intermediate with opposite inside
  const disabled =
    (relation && relation !== Relation.INTERMEDIATE && relation !== type) ||
    (relation === Relation.INTERMEDIATE && isIntermediateDisabled(type, item.children));

  const isLeaf = !item?.children || !item?.children.length;
  const ItemComponent = isLeaf ? LeafItem : CategoryItem;

  return (
    <ItemComponent
      name={name}
      disabled={disabled}
      relation={relation}
      config={config}
      type={type}
      changeHandler={changeHandler}
      selectOne={selectOne}
      onClick={onClick}
    />
  );
};

export { TargetingItem };
