import React from 'react';

import { Grid } from '@mui/material';

import { GridBulkActions } from './GridBulkActions';
import { styles } from './styles';

import { Deal } from '../../../api/deals/types';

interface Props {
  selectedDeals: Array<Deal>;
  SearchInput: JSX.Element;
}

const TableLayoutActions = ({ selectedDeals, SearchInput }: Props): JSX.Element => {
  return (
    <Grid
      container
      sx={styles.bulkActionsHeader}
      className={selectedDeals.length > 0 ? 'active' : ''}
    >
      <Grid item sx={styles.bulkActions}>
        {selectedDeals.length > 0 && <GridBulkActions selectedDeals={selectedDeals} />}
      </Grid>
      <Grid item>{SearchInput}</Grid>
    </Grid>
  );
};

export { TableLayoutActions };
