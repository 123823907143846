import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@mui/material';

import { Icon } from '../../components/icon';
import { SectionTitle } from '../../components/section-title';
import { UserForm } from '../../components/user-form/UserForm';

export const CreateUser = (): JSX.Element => {
  return (
    <Grid container justifyContent="space-between" spacing={10}>
      <Grid item md={8}>
        <SectionTitle
          title={<FormattedMessage id="user.create.title" />}
          description={<FormattedMessage id="user.create.description" />}
          sx={{ marginBottom: '32px' }}
        />
        <UserForm />
      </Grid>
      <Grid item xs={4} mt={12}>
        <Icon name="io-man" viewBox="0 0 800 1162" height={1000} />
      </Grid>
    </Grid>
  );
};
