import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Deal } from '../../../../api/deals/types';
import { themeColors } from '../../../../theme/themeColors';
import { Icon } from '../../../icon';
import { EXCEL_FILETYPES } from '../../constants';
import { styles } from '../../styles';
import { parseFile } from '../../util';

interface Props {
  onSelect: (records: Array<Pick<Deal, 'dealName' | 'dealId'>>) => void;
}

export const UploadButton: React.FC<Props> = ({ onSelect }) => {
  const [error, setError] = useState('');
  const onFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.[0]) return;

    const result = await parseFile(event.target.files[0]);

    // Reset input so you can upload same file name again
    event.target.value = '';

    if (result.error) {
      setError(result.error);
    } else {
      setError('');
      onSelect(result.records!);
    }
  };

  return (
    <>
      <div style={styles.buttonContainer}>
        <Button variant="contained" component="label">
          <Icon
            name="upload-cloud"
            width={24}
            height={24}
            sx={styles.buttonIcon}
            color={themeColors.white}
          />
          <FormattedMessage id="agency.importDeals.upload.button.label" />
          <input
            hidden
            type="file"
            accept={EXCEL_FILETYPES.join(',')}
            onChange={onFileSelect}
          />
        </Button>
        <Typography variant="body1" color="textSecondary">
          <FormattedMessage id="agency.importDeals.upload.button.description" />
        </Typography>
      </div>
      {error && (
        <Typography variant="body1" color="error">
          <FormattedMessage id={`agency.importDeals.upload.error.${error}`} />
        </Typography>
      )}
    </>
  );
};
