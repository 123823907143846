import { SxProps } from '@mui/system';

import { themeColors } from 'theme/themeColors';

export const styles: SxProps = {
  borderRadius: '0 4px 4px 0',
  alignItems: 'start',
  fontSize: 14,
  '&.MuiAlert-filledError': {
    borderLeft: `2px solid ${themeColors.redPrimary}`,
  },
  '&.MuiAlert-filledWarning': {
    borderLeft: `2px solid ${themeColors.yellowPrimary}`,
  },
  '&.MuiAlert-filledInfo': {
    borderLeft: `2px solid ${themeColors.purplePrimary}`,
  },
  '&.MuiAlert-filledSuccess': {
    borderLeft: `2px solid ${themeColors.greenPrimary}`,
  },
  '& .MuiAlert-icon': {
    marginTop: '3px',
  },
};
