import { AxiosError } from 'axios';
import React, { useLayoutEffect, useState } from 'react';

import { ErrorPage } from 'components/error-page';
import { useNotification } from 'notifications/snackbar/useNotification';

import { Api } from '../../api/api';
import { handleApiError } from '../../utils/handleApiError';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

const AppErrorHandler = (props: Props) => {
  const [errorStatus, setErrorStatus] = useState<number | undefined>(200);
  const sendNotification = useNotification({});

  useLayoutEffect(() => {
    let errorHasBeenIdentified = false;
    let errorTimeout: ReturnType<typeof setTimeout> | null = null;

    const onReject = (error: AxiosError) => {
      setErrorStatus(error?.response?.status);

      if (
        error?.response?.status !== 404 &&
        error?.response?.status !== 403 &&
        !errorHasBeenIdentified
      ) {
        handleApiError(sendNotification, error);
        errorHasBeenIdentified = true;
      }

      errorTimeout = setTimeout(() => {
        errorHasBeenIdentified = false;
      }, 3000);

      return Promise.reject(error);
    };

    Api.interceptors.response.use(undefined, onReject);

    return () => clearTimeout(errorTimeout as ReturnType<typeof setTimeout>);
    // eslint-disable-next-line
  }, []);

  if (errorStatus === 403) {
    return <ErrorPage type="Forbidden" />;
  }

  if (errorStatus === 404) {
    return <ErrorPage type="NotFound" />;
  }

  return props.children;
};

export default AppErrorHandler;
