import React from 'react';

import { Link, LinkProps, SxProps } from '@mui/material';
import { LinkTypeMap } from '@mui/material/Link/Link';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { TEST_ID } from 'components/constants';
import { convertSxToThemeType } from 'utils';

import { styles } from './styles';

export enum LinkType {
  Primary = 'primary',
  Footer = 'footer',
}

const linkPropsConfig = {
  [LinkType.Footer]: {
    target: '_blank',
    rel: 'noopener noreferrer',
    color: 'secondary',
    sx: () => ({}),
  },
  [LinkType.Primary]: {
    sx: styles,
  },
};

export interface ExternalLinkProps extends LinkProps {
  href: string;
  type: LinkType;
  icon?: React.ReactElement;
  isIconHoverAble?: boolean;
}

const ExternalLink: React.FC<ExternalLinkProps> = React.forwardRef(
  ({ children, href, type, icon, isIconHoverAble = true, ...rest }, ref) => {
    const { sx, ...props } = linkPropsConfig[type];

    return (
      <Link
        href={href}
        {...rest}
        {...props}
        ref={ref}
        sx={convertSxToThemeType([sx(isIconHoverAble), rest.sx as SxProps])}
        data-testid={TEST_ID.externalLink}
      >
        {children}
        {icon}
      </Link>
    );
  }
) as OverridableComponent<LinkTypeMap<ExternalLinkProps>>;

export { ExternalLink };
