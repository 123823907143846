import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/queryKeys';
import { resendActivation } from 'api/users';
import { getFullName } from 'features/users/utils';
import { NotificationMessage, useNotification } from 'notifications';

import { User } from '../types';

export const useResendActivation = (user: User) => {
  const cache = useQueryClient();
  const sendNotification = useNotification({});

  return useMutation<void, Record<string, unknown>>(() => resendActivation(user), {
    onSuccess: (data) => {
      cache.invalidateQueries([queryKeys.getUsers]);
      cache.invalidateQueries([queryKeys.getUser, user.id]);

      sendNotification({
        type: 'success',
        message: (
          <NotificationMessage
            id={`user.manage.activation.resend.message`}
            values={[{ key: 'username', value: getFullName(user) }]}
          />
        ),
      });
    },
  });
};
