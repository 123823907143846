import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { SelectProps } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

import { ContainerWithTitle } from 'components/elements/container-with-title';
import { SelectField } from 'components/form/fields/select-field';

import { TEST_ID } from './constants';
import { buildRolesOptions } from './utils';

import { RoleInfo } from '../../../../components/role-info/RoleInfo';

export const SelectRole: React.FC<
  SelectProps & {
    role: string;
    roles: string[];
    onChange: SelectInputProps['onChange'];
  }
> = ({ role, roles, onChange, ...props }) => {
  const intl = useIntl();
  const rolesOptions = useMemo(() => {
    return buildRolesOptions(roles, intl);
  }, [intl, roles]);

  return (
    <ContainerWithTitle
      title={intl.formatMessage({ id: 'user.manage.set.role.label' })}
      mt={0}
      mb={3}
      infoModalContent={<RoleInfo />}
    >
      <SelectField
        name="role"
        data-testid={TEST_ID.role}
        value={role}
        options={rolesOptions}
        onChange={onChange}
        {...props}
      />
    </ContainerWithTitle>
  );
};
