import React from 'react';
import { useIntl } from 'react-intl';

import { CircularProgress } from '@mui/material';

import { useResendActivation } from 'api/users/hooks';
import { User } from 'api/users/types';
import { ConfirmationDialog } from 'components/confirmation-dialog';
import { getFullName } from 'features/users/utils';

interface Props {
  triggerEl: React.ReactElement;
  user: User;
}

export const ResendActivationDialog: React.FC<Props> = ({ triggerEl, user }) => {
  const intl = useIntl();
  const { mutate, isLoading } = useResendActivation(user);
  const confirmResendHandler = () => {
    mutate();
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <ConfirmationDialog
      triggerEl={triggerEl}
      title={intl.formatMessage({ id: `user.manage.activation.resend.title` })}
      description={intl.formatMessage(
        { id: `user.manage.activation.resend.description` },
        { username: getFullName(user) }
      )}
      onConfirm={confirmResendHandler}
    />
  );
};
