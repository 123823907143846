import { Redirect } from 'react-router-dom';

import { appRoutes } from 'routes/appRoutes';

import { Props as RouteProps, Route } from './Route';

import { useAbility } from '../components/acl/AclProvider';
import { Actions, Permissions } from '../components/acl/constants';
import { AsyncBoundary } from '../components/async-boundary/AsyncBoundary';
import { useKeycloak } from '../utils/keycloak/KeycloakProvider';

export type PrivateRouteProps = RouteProps & {
  path: string;
  permission?: Permissions;
  action?: Actions;
};

const PrivateRoute = ({ permission, action, ...props }: PrivateRouteProps) => {
  const { authenticated } = useKeycloak();
  const ability = useAbility();

  if (authenticated) {
    const canManage = permission
      ? ability.can(action ?? Actions.Manage, permission)
      : true;

    if (canManage) {
      return (
        <AsyncBoundary>
          <Route {...props} />
        </AsyncBoundary>
      );
    }
    return <Redirect to={appRoutes.main()} />;
  }

  return (
    <Redirect
      to={{ pathname: appRoutes.login(), state: { referer: window.location.href } }}
    />
  );
};

export { PrivateRoute };
