import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ImportDealsLayout } from '../../components/import-deals-layout';
import { SectionTitle } from '../../components/section-title';

const ImportDeals: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <SectionTitle
        title={<FormattedMessage id="agency.importDeals.title" />}
        description={<FormattedMessage id="agency.importDeals.description" />}
        sx={{ marginBottom: '32px' }}
      />
      <ImportDealsLayout agencyId={id} />
    </>
  );
};

export { ImportDeals };
