import { SxProps } from '@mui/material';

import { theme } from 'theme';
import { themeColors } from 'theme/themeColors';

export const styles: {
  autoSelectOptionItem: SxProps;
  autoSelectOption: SxProps;
  autoSelectPopper: SxProps;
  popupIndicator: SxProps;
  inputField: SxProps;
} = {
  autoSelectOptionItem: {
    background: 'transparent',
    '&:hover': {
      fontWeight: 'bold',
    },
    width: '100%',
    padding: '15px 0',
    '& .MuiButtonBase-root': {
      padding: '0px 10px 0px 0px',
    },
  },
  autoSelectOption: {
    '&.MuiAutocomplete-option.Mui-focused, &.MuiAutocomplete-option[aria-selected="true"]':
      {
        background: 'transparent',
      },
    padding: 0,
  },
  autoSelectPopper: {
    '& 	.MuiAutocomplete-popper': {
      '& .MuiAutocomplete-paper': {
        margin: 0,
      },
    },
  },
  popupIndicator: {
    '& .MuiAutocomplete-popupIndicator': {
      color: themeColors.lightBlack,
      '&.MuiAutocomplete-popupIndicatorOpen': {
        transform: 'none',
      },
    },
    '& .MuiOutlinedInput-root > fieldset > legend': {
      display: 'none',
    },
  },
  inputField: {
    '& fieldset': {
      borderRadius: '4px',
    },
    '&:hover fieldset.MuiOutlinedInput-notchedOutline, &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline':
      {
        borderColor: theme.palette.primary.main,
      },
  },
};
