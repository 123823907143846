import { SxProps } from '@mui/material';

import { themeColors } from 'theme/themeColors';

export const styles: {
  pageBtn: SxProps;
  pages: SxProps;
  btnMargin: (isPrevious: boolean, smallNavButton: boolean) => SxProps;
  selected: SxProps;
} = {
  btnMargin: (isPrevious, smallNavButton) => ({
    ...(isPrevious
      ? {
          marginRight: '16px',
        }
      : {
          marginLeft: '16px',
        }),
    ...(smallNavButton
      ? {
          padding: '15px 11px',
        }
      : {}),
  }),
  pageBtn: {
    minWidth: '10px',
    width: 'fit-content',
    padding: '0 8px',
    '&:hover': {
      color: themeColors.purpleHover,
    },
  },
  pages: {
    fontFamily: 'Proxima Nova Semibold',
    fontSize: 16,
    color: themeColors.purplePrimary,
    fontWeight: 600,
    '&:hover': {
      color: themeColors.purpleHover,
    },
  },
  selected: {
    color: themeColors.black,
    cursor: 'auto',
    pointerEvents: 'none',
    padding: '0 8px',
  },
};
