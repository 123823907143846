import { useEffect, useRef } from 'react';

const DEFAULT_TITLE_PREFIX = 'PMP Portal';

const useDocumentTitle = (title: string, prevailOnUnmount = false) => {
  const defaultTitle = useRef(DEFAULT_TITLE_PREFIX);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export { DEFAULT_TITLE_PREFIX, useDocumentTitle };
