import React, { useMemo, useState } from 'react';

import { AnimatedSearchInput } from '../animated-search-input';

export const useSearch = (placeholder?: string) => {
  const [searchValue, setSearchValue] = useState('');
  const [cachedValue, setCachedValue] = useState('');
  const SearchInput = useMemo(
    () => (
      <AnimatedSearchInput
        setSearchValue={setSearchValue}
        setCachedValue={setCachedValue}
        placeholder={placeholder}
      />
    ),
    [setSearchValue, setCachedValue, placeholder]
  );

  return {
    SearchInput,
    searchValue,
    isSearchValuesEqual: searchValue === cachedValue,
    setSearchValue,
  };
};
