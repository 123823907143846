import React, { ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { PageItem } from 'components/data-grid/page-item/PageItem';

import { ITEMS_PER_PAGE } from './constants';
import { styles } from './styles';
import { getChildrenPerPage, sortBySelectedFn } from './utils';

import { ReactFCC } from '../../../../../react-util';

export const Listbox: ReactFCC = (props) => {
  const { children, ...rest } = props;
  const childrenCount = (children as ReactElement[]).length;
  const [page, setPage] = React.useState(1);
  const [renderedChildren, setRenderedChildren] = React.useState(
    getChildrenPerPage((children as ReactElement[]).sort(sortBySelectedFn), page)
  );

  const pageCount = Math.round((children as ReactElement[]).length / ITEMS_PER_PAGE) || 1;

  useEffect(() => {
    setRenderedChildren(getChildrenPerPage(children as ReactElement[], page));
  }, [children, page]);

  return (
    <Box>
      <ul {...rest} style={{ maxHeight: '100%' }}>
        {renderedChildren}
      </ul>
      <Box
        display="flex"
        {...rest}
        alignItems="center"
        justifyContent="space-between"
        padding="15px"
        sx={styles.pagination}
      >
        <Box>
          <Typography variant="body2" color="textPrimary">
            <FormattedMessage
              id="deal.targeting.dialog.search.numberOfSelected"
              values={{
                selectedCount: (children as ReactElement[]).filter(
                  (item) => item.props['aria-selected']
                ).length,
                allCount: childrenCount,
              }}
            />
          </Typography>
        </Box>
        <Box display="flex" alignSelf="flex-end" alignItems="center">
          <Pagination
            page={page}
            count={pageCount}
            variant="outlined"
            shape="rounded"
            color="primary"
            hidePrevButton={page === 1}
            renderItem={(params) => <PageItem params={params} smallNavButton={true} />}
            onChange={(event, data) => {
              setPage(data);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
