import { AbilityBuilder } from '@casl/ability';

import { User } from '../../../api/users/types';
import { Actions, Permissions } from '../constants';

export const defaultRole = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  can: AbilityBuilder<any>['can'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cannot: AbilityBuilder<any>['cannot'],
  user?: User
): void => {
  cannot(Actions.View, Permissions.Users);
  cannot(Actions.Create, Permissions.Users);

  cannot(Actions.View, Permissions.UserInfo);
  can(Actions.View, Permissions.UserInfo, { id: user?.id });
  cannot(Actions.Manage, Permissions.UserInfo);
  can(Actions.Manage, Permissions.UserInfo, { id: user?.id });

  can(Actions.View, Permissions.DealId);
  cannot(Actions.Manage, Permissions.DealId);

  cannot(Actions.View, Permissions.Agencies);
  cannot(Actions.Manage, Permissions.Agencies);
  cannot(Actions.Create, Permissions.Agencies);

  cannot(Actions.Manage, Permissions.UserAgencySettings);
};
