import { Ability } from '@casl/ability';

import { appRoutes } from 'routes/appRoutes';

import { FollowUsItem } from './types';

import { Actions, Permissions } from '../components/acl/constants';
import { MenuItem } from '../components/menu/types';

export const getMenuItems = (ability: Ability): MenuItem[] => {
  const menuItems: MenuItem[] = [
    {
      path: appRoutes.deals(),
      icon: 'home',
      title: 'Deals overview',
      exact: true,
    },
  ];

  if (ability.can(Actions.View, Permissions.Users)) {
    menuItems.push({
      path: appRoutes.users(),
      icon: 'users',
      title: 'Users',
    });
  }

  if (ability.can(Actions.View, Permissions.Agencies)) {
    menuItems.push({
      path: appRoutes.agencies(),
      icon: 'agency',
      title: 'Agencies',
    });
  }

  return menuItems;
};

export const FOLLOW_US_ITEMS: FollowUsItem[] = [
  {
    name: 'facebook',
    link: 'https://www.facebook.com/dpgmedianl',
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/dpgmedia_nl',
  },
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/dpg-media-nl/',
  },
  {
    name: 'instagram',
    link: 'https://www.instagram.com/dpgmedia_nl/',
  },
  {
    name: 'youtube',
    link: 'https://www.youtube.com/channel/UCWPQgZ1B3-qoG6AXmCI_DqQ?view_as=subscriber',
  },
];
