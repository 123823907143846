import React, { useContext } from 'react';

import { SxProps } from '@mui/material';
import { default as MuiDialogContent } from '@mui/material/DialogContent';

import { DialogContext } from './Dialog';
import { ModalVariants } from './types';

export interface Props {
  currentRef: React.MutableRefObject<HTMLDivElement | undefined>;
  sx?: SxProps;
  children: React.ReactNode;
}

const DialogContent: React.FC<Props> = ({ sx, currentRef, children }) => {
  const { mode } = useContext(DialogContext);

  return (
    <MuiDialogContent
      ref={currentRef}
      sx={{
        padding: ModalVariants.primary === mode ? '0 32px 24px' : '0 24px 24px',
        ...sx,
      }}
    >
      {children}
    </MuiDialogContent>
  );
};

export { DialogContent };
