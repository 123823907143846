import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';

import { Icon } from 'components/icon';

import { styles } from './styles';

import { Deal, State } from '../../../api/deals/types';
import { appRoutes } from '../../../routes/appRoutes';
import { getRole, Roles } from '../../../utils/keycloak/Keycloak';
import { Button } from '../../button';
import { DealDetailEditDialog } from '../../deal-details/DealDetailEditDialog';
import { Tooltip } from '../../tooltip/Tooltip';

type Props = {
  deal: Deal;
};

export const DealActions: React.FC<Props> = ({ deal }) => {
  const intl = useIntl();
  const canEditDeal = deal.state === State.Draft;
  const canEditDealInline =
    deal.state !== State.Draft && [Roles.DPGAdmin, Roles.DPGUser].includes(getRole());
  const canViewDeal = deal.state !== State.Draft;
  const canDuplicateDeal = !deal.external;

  return (
    <>
      {canViewDeal && (
        <Tooltip title={intl.formatMessage({ id: 'deals.actions.view' })} size="small">
          <IconButton
            sx={styles.item}
            component={Link}
            to={appRoutes.viewDeal({ id: deal.id })}
          >
            <Icon width={24} height={24} name="view" />
          </IconButton>
        </Tooltip>
      )}
      {canEditDeal && (
        <Tooltip title={intl.formatMessage({ id: 'deals.actions.edit' })} size="small">
          <IconButton
            sx={styles.item}
            component={Link}
            to={appRoutes.editDeals({}, { ids: deal.id })}
          >
            <Icon width={24} height={24} name="edit" />
          </IconButton>
        </Tooltip>
      )}
      {canEditDealInline && (
        <DealDetailEditDialog
          deal={deal}
          triggerEl={
            <IconButton sx={styles.item}>
              <Icon width={24} height={24} name="edit" />
            </IconButton>
          }
        />
      )}
      {canDuplicateDeal && (
        <Tooltip
          title={intl.formatMessage({ id: 'deals.actions.duplicate' })}
          size="small"
        >
          <IconButton
            sx={styles.item}
            component={Link}
            to={{
              pathname: appRoutes.createDeals(),
              state: { deals: [deal] },
            }}
          >
            <Icon width={24} height={24} name="duplicate" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
