import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { themeColors } from 'theme/themeColors';

interface Props {
  path: string;
}

export const InfoModalContent: React.FC<Props> = ({ path }) => {
  const pathArray = path.split('.');
  const pathParts = pathArray.slice(1);
  const type = pathArray[0];
  const label = pathParts[pathParts.length - 1];

  return (
    <>
      <Box borderBottom={`1px solid ${themeColors.grey30}`} mb={1} pb={1}>
        <Typography variant="h4">{label}</Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          <FormattedMessage id={`deal.targeting.dialog.category.${type}`} />
          {' | ' + pathParts.join(' | ')}
        </Typography>
      </Box>
    </>
  );
};
