import { themeColors } from 'theme/themeColors';

export const styles = {
  wrapper: {
    width: 710,
    backgroundColor: themeColors.white,
    padding: '1px 30px',
    paddingBottom: '30px',
    minHeight: 543,
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  validationText: {
    margin: 0,
    display: 'block',
  },
  checkbox: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
    float: 'left',
  },
  fullWidth: {
    width: '100%',
  },
  errorMessage: {
    color: '#e6463c',
    fontWeight: 400,
    fontFamily: 'Proxima Nova Bold',
    fontSize: '14px',
    lineHeight: 1.66,
    marginTop: '8px',
  },
};
