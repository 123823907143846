import { SxProps } from '@mui/system';

import { theme } from '../../theme';

export const styles: {
  containerLayout: SxProps;
} = {
  containerLayout: {
    margin: '0 auto',
    position: 'relative',
    padding: '48px 48px 160px',
    [theme.breakpoints.down('lg')]: {
      padding: '24px 24px 120px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '16px 16px 120px',
    },
  },
};
