import { theme } from '../../theme';

export const styles = {
  dealActionButton: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),

    '& svg.copy g': {
      fill: 'none',
    },
  },
  formActionButton: {
    marginRight: theme.spacing(3),
  },
  dealItem: {
    marginBottom: theme.spacing(1),
  },
};
