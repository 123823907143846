import { IntlShape } from 'react-intl';

import { Option } from '../../../../components/form/fields/select-field/SelectField';
import { RolesNameMap } from '../../../../utils/keycloak/Keycloak';

export const buildRolesOptions = (roles: Array<string>, intl: IntlShape) =>
  roles.reduce((options: Option[], role: string) => {
    options.push({
      value: role,
      label: intl.formatMessage({
        id: `role.${RolesNameMap[role].toLowerCase()}`,
      }),
    });

    return options;
  }, []);
