import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueries, UseQueryOptions } from 'react-query';
import { useHistory } from 'react-router-dom';

import { CircularProgress, Grid } from '@mui/material';

import { getDeal } from '../../api/deals';
import { Deal } from '../../api/deals/types';
import { queryKeys } from '../../api/queryKeys';
import { DealsForm } from '../../components/deals-form/DealsForm';
import { Icon } from '../../components/icon';
import { SectionTitle } from '../../components/section-title';
import { appRoutes } from '../../routes/appRoutes';
import useQueryParams from '../../utils/hooks/useQueryParams';

const EditDeals: React.FC = () => {
  const idsParam = useQueryParams().get('ids');
  const ids = useMemo(() => idsParam?.split(',').filter((i) => i) ?? [], [idsParam]);
  const queries = useQueries<Deal[]>(
    ids.map(
      (id) =>
        ({
          queryKey: [queryKeys.getDeal, id],
          queryFn: getDeal,
        }) as unknown as UseQueryOptions // TODO check better typing
    )
  );
  const isLoading = queries.some((q) => q.isLoading);
  const history = useHistory();
  const deals = useMemo(() => queries.map((q) => q.data!), [queries]);

  if (ids.length === 0) {
    history.push(appRoutes.deals());
  }

  return (
    <Grid container justifyContent="space-between" spacing={10}>
      <Grid item md={8}>
        <SectionTitle
          title={<FormattedMessage id="deals.edit.title" />}
          description={<FormattedMessage id="deals.edit.description" />}
          sx={{ marginBottom: '32px' }}
        />
        {isLoading ? (
          <CircularProgress
            color="error"
            sx={{
              marginLeft: '16px',
              marginBottom: '16px',
              width: '25px !important',
              height: '25px !important',
            }}
          />
        ) : (
          <DealsForm deals={deals} canAddItems={false} />
        )}
      </Grid>
      <Grid item xs={4} mt={12}>
        <Icon name="io-man" viewBox="0 0 800 1162" height={1000} />
      </Grid>
    </Grid>
  );
};

export { EditDeals };
