import { theme } from '../../theme';
import { themeColors } from '../../theme/themeColors';

export const styles = {
  title: {
    color: themeColors.greyPrimary,
    marginBottom: theme.spacing(2),
  },
  titleHint: {
    display: 'inline-flex',
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  textValue: {
    width: '100%',
    alignSelf: 'flex-end',
  },
  toggleField: {
    alignItems: 'flex-end',
  },
  loadingIndicator: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'middle',
  },
  actionButtons: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer',
  },
};
