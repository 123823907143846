import { SxProps } from '@mui/material';

import { themeColors } from 'theme/themeColors';

export const styles: { card: SxProps; title: SxProps; button: SxProps } = {
  card: {
    width: '100%',
    backgroundColor: themeColors.white,
    borderRadius: '0 4px 4px 0',
    padding: '14px',
    boxShadow: '0 4px 12px 2px rgb(0, 0, 0, 0.1)',

    '&.rootCard_success': {
      borderLeft: `2px solid ${themeColors.greenPrimary}`,
    },
    '&.rootCard_error': {
      borderLeft: `2px solid ${themeColors.redPrimary}`,
    },
    '&.rootCard_info': {
      borderLeft: `2px solid ${themeColors.purplePrimary}`,
    },
    '&.rootCard_warning': {
      borderLeft: `2px solid ${themeColors.yellowPrimary}`,
    },

    '& .icon': {
      fontSize: 22,
    },
    '& .icon_success': {
      color: themeColors.greenPrimary,
    },
    '& .icon_error': {
      color: themeColors.redPrimary,
    },
    '& .icon_info': {
      color: themeColors.purplePrimary,
    },
    '& .icon_warning': {
      color: themeColors.yellowPrimary,
    },
  },
  title: {
    fontSize: 18,
    lineHeight: '1.4',
    height: 22,
    marginBottom: '4px',
  },
  button: {
    padding: '0',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: 14,
    height: 22,
    '& .MuiButton-endIcon': {
      marginRight: '0px',
      marginLeft: '1px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};
