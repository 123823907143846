import React, { useMemo, useState } from 'react';

import { default as MuiPopover } from '@mui/material/Popover';

import { styles } from './styles';

interface Props {
  children: (closeModal: () => void) => React.ReactElement;
  triggerEl: React.ReactElement;
}

export const Popover: React.FC<Props> = ({ triggerEl, children }) => {
  const [open, setOpen] = useState(false);
  const [anchorElRef, setAnchorEl] = useState<Element | undefined>();

  const handleOpen = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleClose = (event: React.MouseEvent, reason: string) => {
    reason !== 'backdropClick' && closeModal();
  };

  const targetEl = useMemo(
    () =>
      React.cloneElement(triggerEl, {
        onClick: () => {
          triggerEl.props.onClick?.();
          handleOpen();
        },
        ref: (node: Element) => {
          setAnchorEl(node);
        },
      }),
    [triggerEl]
  );

  return (
    <>
      {targetEl}
      <MuiPopover
        open={open}
        anchorEl={anchorElRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={styles.paper}
        onClose={handleClose}
      >
        {children(closeModal)}
      </MuiPopover>
    </>
  );
};
