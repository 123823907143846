import React from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { styles } from 'components/deals-table-layout/styles';
import { styles as tableStyles } from 'components/table/common-styles';

import { UserActions } from './components/UserActions';
import { Props } from './types';

import { Agency } from '../../api/agencies/types';
import { getFullName } from '../../features/users/utils';
import { appRoutes } from '../../routes/appRoutes';
import { themeColors } from '../../theme/themeColors';
import { getUserRole, RolesNameMap } from '../../utils/keycloak/Keycloak';
import { Button } from '../button';
import { TableTitle } from '../deals-table-layout';
import { NoRowsOverlay } from '../deals-table-layout/components/NoRowsOverlay';
import { TableLayoutTitle } from '../deals-table-layout/components/TableLayoutTitle';
import { LoadingOverlay } from '../loading-overlay';
import { AscendingIcon } from '../sort-icon/ascending-icon/AscendingIcon';
import { DescendingIcon } from '../sort-icon/descending-icon/DescendingIcon';
import { UnsortedIcon } from '../sort-icon/unsorted-icon/UnsortedIcon';
import { StatusIcon } from '../status-label/status-icon/StatusIcon';
import { Pagination } from '../table/pagination/Pagination';

const UsersTableLayout: React.FC<Props> = ({
  data,
  isLoading,
  SearchInput,
  searchValue,
  page,
  pageSize,
  onPageSizeChange,
  onPageChange,
  sortModel,
  onSortModelChange,
}) => {
  const sxStyles = styles({
    notClickable: true,
    disableHeaderOverflow: false,
    selectAllCheckboxHidden: false,
  });
  const intl = useIntl();
  const columns: Array<GridColDef> = [
    {
      field: 'active',
      headerName: '',
      maxWidth: 60,
      renderCell: ({ row }) => (
        <StatusIcon
          sx={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          color={row.active ? themeColors.greenPrimary : themeColors.redPrimary}
        />
      ),
    },
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'users.overview.column.name' }),
      minWidth: 250,
      valueGetter: ({ row }) => getFullName(row),
      cellClassName: 'boldCell',
    },
    {
      field: 'email',
      headerName: intl.formatMessage({ id: 'users.overview.column.email' }),
      flex: 1,
    },
    {
      field: 'role',
      headerName: intl.formatMessage({ id: 'users.overview.column.role' }),
      minWidth: 200,
      valueGetter: ({ row }) =>
        intl.formatMessage({
          id: `role.${RolesNameMap[getUserRole(row)].toLowerCase()}`,
        }),
    },
    {
      field: 'agencies',
      headerName: intl.formatMessage({ id: 'users.overview.column.agency' }),
      minWidth: 200,
      valueFormatter: ({ value }) =>
        value?.length > 0 ? value.map((val: Agency) => val.name).join(', ') : '-',
    },
    {
      field: 'actions',
      headerName: intl.formatMessage({ id: 'users.overview.column.actions' }),
      minWidth: 120,
      type: 'actions',
      getActions: ({ row }) => [<UserActions user={row} />],
    },
  ];

  return (
    <>
      <TableLayoutTitle
        titleId="users.overview.title"
        descriptionId="users.overview.description"
      />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Button
            to={appRoutes.createUser()}
            endIconName="arrow-right"
            labelId="users.overview.button.new"
          />
        </Grid>
      </Grid>
      <Box mt={4} sx={tableStyles.grid}>
        <TableTitle
          totalId="users.overview.table.title"
          searchField={searchValue}
          total={data?.meta?.totalElements ?? 0}
        />
        <Grid item sx={tableStyles.searchHeader}>
          {SearchInput}
        </Grid>
        <DataGrid
          autoHeight
          rows={data?.users ?? []}
          columns={columns}
          loading={isLoading}
          sx={sxStyles.root}
          rowHeight={72}
          columnHeaderHeight={60}
          hideFooter={true}
          disableColumnMenu={true}
          slots={{
            columnSortedDescendingIcon: DescendingIcon,
            columnSortedAscendingIcon: AscendingIcon,
            columnUnsortedIcon: UnsortedIcon,
            loadingOverlay: LoadingOverlay,
            noRowsOverlay: () => (
              <NoRowsOverlay
                title="users.overview.noRows.title"
                description="users.overview.noRows.description"
              />
            ),
          }}
          rowCount={data?.meta.totalElements ?? 0}
          paginationModel={{ page: page - 1, pageSize }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'fullName', sort: 'desc' }],
            },
          }}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
        />
        <Pagination
          page={page}
          size={pageSize}
          itemsLength={data?.meta.totalElements ?? 0}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  );
};

export { UsersTableLayout };
