import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { styles } from './styles';

type Props = {
  count: number;
  showMore: boolean;
  clickHandler(): void;
};

const ShowAllButton: React.FC<Props> = ({ count, showMore, clickHandler }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const prevValueRef = useRef<number>();

  useEffect(() => {
    setIsHighlighted(true);
    const timeout = setTimeout(() => setIsHighlighted(false), 2000);
    prevValueRef.current = count;

    return () => clearTimeout(timeout);
  }, [count]);

  return (
    <Button sx={styles.showAllButton} onClick={clickHandler}>
      {showMore ? (
        <FormattedMessage
          id="showAll"
          values={{
            number: isHighlighted ? (
              <Typography sx={styles.updatedCounter}>{count}</Typography>
            ) : (
              count
            ),
          }}
        />
      ) : (
        <FormattedMessage id="showLess" />
      )}
    </Button>
  );
};

export { ShowAllButton };
