import { theme } from 'theme';

export const styles = {
  pagination: {
    '& li button': {
      borderColor: theme.palette.primary.main,
      '&[class*="disabled"]': {
        borderColor: '#a3acb2',
        '& svg': {
          fill: '#a3acb2',
        },
      },
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
    '& li:last-child button': {
      marginRight: 0,
    },
  },
};
