import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { ContentLayout } from 'components/content-layout';
import { FooterSmall } from 'components/footer-small';
import { Header } from 'components/header';

import { TEST_ID } from './constants';
import { styles } from './styles';

import { ReactFCC } from '../../react-util';

type Props = {
  showPb?: boolean;
  disablePaddings?: boolean;
  layoutType?: 'wide' | 'narrow';
};

const AppLayout: ReactFCC<Props> = ({
  showPb = false,
  disablePaddings = false,
  layoutType,
  children,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      sx={styles.mainContainer}
      data-testid={TEST_ID.appLayout}
      pb={showPb ? 12 : 0}
    >
      <Container
        disableGutters
        maxWidth={false}
        sx={styles.header}
        data-testid={TEST_ID.appHeader}
      >
        <Header />
      </Container>
      <Container
        disableGutters
        maxWidth={false}
        data-testid={TEST_ID.appContent}
        sx={styles.content}
      >
        <ContentLayout type={layoutType} disablePaddings={disablePaddings}>
          {children}
        </ContentLayout>
      </Container>
      {!disablePaddings && <FooterSmall />}
    </Box>
  );
};

export { AppLayout };
