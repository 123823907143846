import { appRoutes } from './appRoutes';

import { Login } from '../features/login/Login';
import { ActivateUser } from '../features/users/activate-user';
import { ValidateEmail } from '../features/users/validate-email';

export const publicRoutesConfig = [
  {
    path: appRoutes.login(),
    component: Login,
    title: 'Login',
  },
  {
    path: appRoutes.activate(),
    component: ActivateUser,
    title: 'Activate user',
  },
  {
    path: appRoutes.validateEmail(),
    component: ValidateEmail,
    title: 'Validate email',
  },
];
