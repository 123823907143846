import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  Tags,
  TagType,
} from 'components/form/fields/autocomplete-tags/components/tags/Tags';

import {
  Keyword,
  RestrictedRelation,
  TagValue,
  TargetingValue,
  TargetingValueItem,
} from '../../types';
import { InfoModalContent } from '../autocomplete/InfoModalContent';
import { cleanData } from '../browse/utils';
import { buildTargetingValue } from '../targeting-item/utils';

interface Props {
  value: TagValue[];
  relation: RestrictedRelation;
  browseValue: TargetingValueItem[];

  onChange(value: TargetingValue & { keywords?: Keyword[] }): void;
}

export const TagsAutocomplete: React.FC<Props> = ({
  value,
  relation,
  browseValue,
  onChange,
}) => {
  const intl = useIntl();

  const tagsItems = useMemo(
    () =>
      value.map((valueItem) => {
        return {
          ...valueItem,
          value: valueItem.id,
          infoContent: <InfoModalContent path={valueItem.pathLabels as string} />,
        };
      }) as TagType[],
    [value]
  );

  const onTagDelete = useCallback(
    (index: number) => {
      const newValue = buildTargetingValue(
        value[index].path as string,
        browseValue,
        relation
      );

      onChange(cleanData(newValue!));
    },
    [browseValue, onChange, relation, value]
  );

  return (
    <Tags
      isAllOptionSelected={false}
      selectAllLabel={intl.formatMessage({
        id: 'deal.targeting.dialog.browse.selectAll',
      })}
      tags={tagsItems}
      iconName={'tooltip'}
      onTagDelete={onTagDelete}
    />
  );
};
