export const styles = {
  select: {
    borderRadius: '3px',
    '& .MuiSelect-select': {
      fontSize: 16,
    },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    '& .MuiInputAdornment-root': {
      mr: 3,
    },
  },
};
