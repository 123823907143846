import { buildMappedFormFieldValues, isNoneItem } from './components/browse/utils';
import {
  DetailedTargeting,
  DetailedTargetingItem,
  DetailedTargetingKeys,
  Keyword,
  Relation,
  RestrictedRelation,
  SearchDetailedTargetingItem,
  Size,
  TagValue,
  TargetingValue,
  TargetingValueItem,
  TargetingValueItemExtended,
} from './types';

import { Targeting, TargetingItem } from '../../../api/deals/types';

export const getItem = <T extends { id: string; children?: T[] }>(
  names: string[],
  values?: T[]
): T => {
  const firstItem = names[0];
  const current = values?.find((i) => i.id === firstItem) as T;
  if (names.length <= 1) {
    return current;
  } else {
    return current && getItem(names.slice(1), current.children);
  }
};

export const hasExcluded = (data?: TargetingValueItem[]): boolean =>
  !!data?.some((i) => {
    if (i.relation === Relation.EXCLUDED) {
      return true;
    } else if (!i?.children?.length) {
      return false;
    }
    return hasExcluded(i.children);
  });

export const hasExcludedKeywords = (data?: Keyword[]): boolean => {
  if (!data) {
    return false;
  }
  return data.some((i) => i.relation === Relation.EXCLUDED);
};

export const getSelectedItems = (
  relation: Relation,
  data: TargetingValueItemExtended[]
): TagValue[] => {
  const items: TagValue[] = [];

  const getItems = (
    children: TargetingValueItemExtended[],
    category?: string,
    categoryLabel?: string
  ) =>
    children &&
    children.forEach((i) => {
      if (i.relation === relation) {
        items.push({
          ...i,
          path: `${category}.${i.id}`,
          pathLabels: `${categoryLabel}.${i.label}`,
        });
      } else if (i?.children?.length) {
        getItems(
          i.children,
          category ? `${category}.${i.id}` : i.id,
          categoryLabel ? `${categoryLabel}.${i.label}` : i.id
        );
      }
    });

  getItems(data);
  return items;
};

export const getLeafItemsCount = (rootNode: TargetingValueItem) => {
  const traverse = (acc: number, node: TargetingValueItem): number => {
    if (node.children) {
      if (node.children.every((c) => c.exclusive)) {
        return acc + 1;
      }

      return node.children.reduce(traverse, acc);
    }

    return acc + 1;
  };

  return traverse(0, rootNode);
};

export const getSelectedLeafItemsCount = (rootNode: TargetingValueItem) => {
  const traverse = (
    acc: TargetingValueItem[],
    node: TargetingValueItem
  ): TargetingValueItem[] => {
    if (node?.children) {
      return node.children.reduce(traverse, acc);
    }
    node.relation === Relation.INCLUDED && acc.push(node);
    return acc;
  };

  return traverse([], rootNode);
};

export const getTransformedItems = (
  data: DetailedTargeting
): SearchDetailedTargetingItem[] => {
  const targetingData: SearchDetailedTargetingItem[] = [];

  const getCategoryData = (
    rootCategory: string,
    categoryName: string,
    categoryKeyData: DetailedTargetingItem[] | undefined,
    categoryLabelName: string
  ) => {
    (categoryKeyData || [])
      .filter((item) => !isNoneItem(item))
      .forEach((item) => {
        targetingData.push({
          id: item.id,
          size: item.size,
          keyword: item.label,
          label: item.label,
          group: rootCategory,
          path: categoryName + '.' + item.id,
          pathLabels: categoryLabelName,
          leafItemsCount: getLeafItemsCount(item),
        });
        if (item.children) {
          getCategoryData(
            rootCategory,
            categoryName + '.' + item.id,
            item.children,
            categoryLabelName + '.' + item.label
          );
        }
      });
  };

  Object.keys(data).forEach((key) =>
    getCategoryData(key, key, data[key as DetailedTargetingKeys], key)
  );

  return targetingData;
};

export const buildFilterLabel = (arr: string[] | undefined): string[] => {
  if (!arr || !arr.length) {
    return [];
  }

  let result = [...arr];

  if (result.length > 3) {
    const length = result.length;
    result = result.slice(0, 3);

    return [result.join(', '), ` +${length - 3}`];
  }

  return [result.join(', ')];
};

export const sortBySizeFn = (a: Size, b: Size) => Number(b.size) - Number(a.size);

export const getOmittedParam = (param: RestrictedRelation) =>
  param === Relation.INCLUDED ? Relation.EXCLUDED : Relation.INCLUDED;

export const sizeFormatter = (value?: number, withLetter = true): string => {
  if (!value && value !== 0) {
    return '-';
  } else if (value > 999 && value < 1000000) {
    return (value / 1000).toFixed(0) + (withLetter ? 'K' : '');
  } else if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + (withLetter ? 'M' : '');
  }

  return value.toString();
};

export const simplifyToTargeting = (detailedTargeting: DetailedTargeting): Targeting => {
  const traverse = (items?: TargetingValueItem[]): TargetingItem[] | undefined => {
    return items?.map((i) => ({
      id: i.id,
      label: i.label,
      children: i.relation === Relation.INTERMEDIATE ? traverse(i.children) : undefined,
      xandrSegmentCode: i.xandrSegmentCode,
      xandrIdNl: i.xandrIdNl,
      xandrIdBe: i.xandrIdBe,
    }));
  };

  return Object.fromEntries(
    Object.entries(detailedTargeting).map(([cat, items]) => [cat, traverse(items)])
  );
};

export const extendToDetailedTargeting = (
  targeting?: Targeting | null
): DetailedTargeting => {
  const traverse = (items?: TargetingItem[]): DetailedTargetingItem[] | undefined => {
    return items?.map((i) => ({
      ...i,
      relation: i.children ? Relation.INTERMEDIATE : Relation.INCLUDED,
      children: traverse(i.children),
    }));
  };

  return Object.fromEntries(
    Object.entries(targeting ?? {}).map(([cat, items]) => [cat, traverse(items)])
  );
};

const getItemGroup = (item: TagValue) =>
  item.path ? item.path.split('.')[0] : item.group;

export const getFilterValue = (
  detailedTargeting: DetailedTargeting,
  value: TargetingValue
): {
  include: string[];
  exclude: string[];
} => {
  const formFieldMappedValues =
    buildMappedFormFieldValues(detailedTargeting, value) || [];
  const selectedIncludedValues = getSelectedTagsValues(
    formFieldMappedValues,
    value,
    Relation.INCLUDED
  ).map((item) => `${item?.label || item?.keyword} (${getItemGroup(item)})`);

  const selectedExcludedValues = getSelectedTagsValues(
    formFieldMappedValues,
    value,
    Relation.EXCLUDED
  ).map((item) => `${item?.label || item?.keyword} (${getItemGroup(item)})`);

  return {
    include: buildFilterLabel(selectedIncludedValues),
    exclude: buildFilterLabel(selectedExcludedValues),
  };
};

export const getSelectedTagsValues = (
  formattedData: TargetingValueItemExtended[],
  value: DetailedTargeting,
  relation: Relation
): TagValue[] => {
  if (!value) {
    return [];
  }

  return [...getSelectedItems(relation, formattedData)].sort(
    (a: { size?: number }, b: { size?: number }) =>
      Number(b?.size ?? 0) - Number(a?.size ?? 0)
  ) as TagValue[];
};
