import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { queryKeys } from 'api/queryKeys';
import { Icon } from 'components/icon';

import { UserInfo } from './components/user-info';
import { usePasswordChanged } from './usePasswordChanged';

import { getUser } from '../../../api/users';
import { LoadingOverlay } from '../../../components/loading-overlay';
import { getFullName } from '../utils';

export const ManageUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: user, isLoading: isUserLoading } = useQuery(
    [queryKeys.getUser, id],
    getUser
  );
  const isPasswordChanged = usePasswordChanged();

  if (isUserLoading) {
    return <LoadingOverlay />;
  }

  return (
    <Grid container>
      <Grid container justifyContent="space-between" columnSpacing={4}>
        <Grid item md={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h1">{getFullName(user!)}</Typography>
            </Grid>
            {isPasswordChanged && (
              <Grid item>
                <Alert variant="filled" severity="success">
                  <FormattedMessage id="user.manage.password_changed.message" />
                </Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md={6} sm={12} xs={12} zIndex={1}>
          <UserInfo user={user!} />
        </Grid>
        <Grid
          item
          container
          md={6}
          sm={12}
          xs={12}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Icon name="man" viewBox="0 0 650 480" />
        </Grid>
      </Grid>
    </Grid>
  );
};
