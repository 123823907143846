import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Icon } from 'components/icon';

import { themeColors } from '../../../theme/themeColors';

interface Props {
  title: string;
  description: string;
}

export const NoRowsOverlay: React.FC<Props> = ({ title, description }) => {
  return (
    <Box sx={{ position: 'relative', borderBottom: `2px solid ${themeColors.grey20}` }}>
      <Icon name="grid-man" sx={{ display: 'block' }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateY(-50%) translateX(-50%)',
        }}
      >
        <Typography variant="h3">
          <FormattedMessage id={title} />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id={description} />
        </Typography>
      </Box>
    </Box>
  );
};
