export enum Types {
  ExampleType = 'ExampleType',
}

export type InitialStateType = {
  isDesignModalOpened: boolean;
};
type ActionMap<M extends { [index: string]: boolean }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type AppPayload = {
  [Types.ExampleType]: boolean;
};

export type Actions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

export const appReducer = (state: InitialStateType, { payload, type }: Actions) => {
  switch (type) {
    default:
      return state;
  }
};
