import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { InfoModal } from 'components/info-modal';

import { styles } from './styles';

import { ReactFCC } from '../../../react-util';

interface Props {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  divide?: boolean;
  description?: React.ReactNode;
  mb?: number;
  mt?: number;
  ml?: number;
  testId?: string;
  infoModalContent?: ReactElement;
  isOptional?: boolean;
  textVariant?: 'h4' | 'h6';
  actions?: ReactElement;
}

const ContainerWithTitle: ReactFCC<Props> = ({
  children,
  title,
  subTitle,
  description,
  actions,
  testId,
  divide,
  mt = 4,
  mb = 4,
  ml,
  infoModalContent,
  isOptional,
  textVariant = 'h4',
  ...rest
}) => {
  return (
    <Box>
      <Box {...rest} mt={mt} mb={mb} ml={ml} data-testid={testId}>
        <Box display="flex" alignItems="center" sx={styles.wrapper}>
          <Typography variant={textVariant}>{title}</Typography>
          {subTitle && (
            <Box style={{ marginLeft: '10px' }}>
              <Typography variant="body1">{subTitle}</Typography>
            </Box>
          )}
          {isOptional && (
            <Typography variant="body1" color="textSecondary" sx={styles.optional}>
              <FormattedMessage id="optional" />
            </Typography>
          )}
          {infoModalContent && (
            <Box ml={1} sx={styles.infoModal}>
              <InfoModal>{infoModalContent}</InfoModal>
            </Box>
          )}
        </Box>
        <Box sx={styles.descriptionContainer}>
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
          {actions && <Box flexShrink={0}>{actions}</Box>}
        </Box>
        {children}
      </Box>
      {divide && <Divider />}
    </Box>
  );
};

export { ContainerWithTitle };
