import React, { MouseEventHandler, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, DialogTitle as MuiDialogTitle, SxProps, Typography } from '@mui/material';

import { DialogCloseIcon } from 'components/dialog2/components/dialog-close-icon/DialogCloseIcon';
import { InfoModal } from 'components/info-modal/InfoModal';
import { themeColors } from 'theme/themeColors';

import { DialogContext } from './Dialog';
import { ModalVariants, Reason } from './types';

interface Props {
  headerComponent?: React.ReactElement;
  headerLabel?: string;
  subHeaderLabel?: string;
  subHeaderLabelSx?: SxProps;
  infoComponent?: React.ReactElement;
  infoLabel?: string;
  testId?: string;
  topDivider?: boolean;
  showCloseButton?: boolean;

  handleClose?(event?: Record<string, unknown>, reason?: Reason): void;
}

const DialogTitle: React.FC<Props> = ({
  headerComponent,
  headerLabel,
  subHeaderLabel,
  subHeaderLabelSx,
  infoComponent,
  infoLabel,
  testId,
  topDivider = false,
  showCloseButton = true,
  handleClose,
}) => {
  const { mode } = useContext(DialogContext);

  return (
    <MuiDialogTitle
      sx={{
        padding: ModalVariants.primary === mode ? '32px 32px 18px' : '24px 24px 8px',
        zIndex: 1,
        '&:after': {
          content: '""',
          display: 'block',
          height: '8px',
          ...(topDivider && {
            position: 'relative',
            top: ModalVariants.primary === mode ? '18px' : '8px',
            margin: ModalVariants.primary === mode ? '0 -32px' : '0 -24px',
            boxShadow: `0 4px 6px 0 ${themeColors.dropShadow}`,
          }),
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display="flex" alignItems="center">
          {headerComponent}
          {headerLabel && (
            <Typography
              variant={ModalVariants.primary === mode ? 'h1' : 'h3'}
              color="textPrimary"
              data-testid="modalTitle"
            >
              <FormattedMessage id={headerLabel} />
            </Typography>
          )}
          {infoComponent}
          {infoLabel && (
            <Box ml={1}>
              <InfoModal>
                <Box width={372}>
                  <FormattedMessage id={infoLabel} />
                </Box>
              </InfoModal>
            </Box>
          )}
        </Box>

        {showCloseButton && (
          <DialogCloseIcon
            handleClose={handleClose as unknown as MouseEventHandler<HTMLButtonElement>}
          />
        )}
      </Box>

      {subHeaderLabel && (
        <Box mt={1}>
          <Typography variant="body1" color="textSecondary" sx={{ ...subHeaderLabelSx }}>
            <FormattedMessage id={subHeaderLabel} />
          </Typography>
        </Box>
      )}
    </MuiDialogTitle>
  );
};

export { DialogTitle };
