import React, { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Menu, MenuItem, MenuProps, SxProps } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';

import { Icon } from 'components/icon/Icon';
import { convertSxToThemeType } from 'utils';
import { DropdownItem } from 'utils/types';

import { StyledLink, styles } from './styles';
import { DropdownType } from './types';

interface Props extends Partial<MenuProps> {
  anchorElem: ReactElement;
  menuItems: DropdownItem[];
  themeType?: string;
  autoWidth?: boolean;
}

const Dropdown: React.FC<Props> = ({
  anchorElem: anchorElemProp,
  menuItems,
  themeType = DropdownType.Default,
  autoWidth = false,
  ...restMenuProps
}) => {
  const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null);
  const [anchorElemClassName, setAnchorElemClassName] = useState<string>('');
  const sxStyles = styles({ type: themeType });

  const handleClose = () => {
    setAnchorElem(null);
    anchorElem && (anchorElem.className = anchorElemClassName);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    !anchorElemClassName && setAnchorElemClassName(event.currentTarget.className);
    setAnchorElem(event.currentTarget);
    event.currentTarget.tagName === 'BUTTON' &&
      (event.currentTarget.className += ' open');
  };

  const expandableMenuStyles = {
    ...(!autoWidth ? { width: anchorElem?.offsetWidth ?? 0 } : {}),
  };

  const menuStyles = themeType === DropdownType.Expandable ? expandableMenuStyles : {};

  const getMenuItemNode = ({
    onClick,
    link,
    textId,
    sx = {},
    disabled = false,
    infoModal,
    customRenderer = null,
  }: DropdownItem) => {
    const text = <FormattedMessage id={textId} />;
    const isMenuItemDisabled = !infoModal && disabled;
    const isLinkDisabled = Boolean(infoModal) && disabled;

    const DefaultRenderer = link ? (
      <>
        <StyledLink
          to={link}
          className="menu-link"
          dropdowntype={themeType}
          disabled={isLinkDisabled}
        >
          {text}
        </StyledLink>
        {infoModal}
      </>
    ) : (
      <>
        {text}
        {infoModal}
      </>
    );

    return (
      <MenuItem
        key={textId}
        sx={convertSxToThemeType([
          sxStyles.menuItem({ disabled: isLinkDisabled }),
          sx as SxProps,
          !link ? { padding: '16.5px 15px' } : {},
        ])}
        disabled={isMenuItemDisabled}
        onClick={() => {
          if (disabled) {
            return;
          }

          onClick?.(handleClose);
        }}
      >
        {customRenderer || DefaultRenderer}
      </MenuItem>
    );
  };

  const getMenuItemsNode = (items: DropdownItem[]): React.ReactElement[] => {
    return items.map(
      ({
        textId,
        onClick,
        link,
        children,
        sx,
        disabled,
        infoModal,
        defaultExpanded,
        customRenderer,
      }) => {
        if (link && children) {
          throw Error('Only "link" or "children" can be provided.');
        }

        if (!children) {
          return getMenuItemNode({
            textId,
            onClick,
            link,
            sx,
            disabled,
            infoModal,
            customRenderer,
          });
        }

        return (
          <Accordion
            key={textId}
            sx={sxStyles.menuItemContainer}
            defaultExpanded={defaultExpanded}
          >
            <AccordionSummary
              expandIcon={<Icon width="24px" height="24px" name="panel-closed" />}
            >
              <Typography variant="h4">
                <FormattedMessage id={textId} />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MenuList>{getMenuItemsNode(children)}</MenuList>
            </AccordionDetails>
          </Accordion>
        );
      }
    );
  };

  return (
    <>
      {React.cloneElement(anchorElemProp, { onClick: handleOpen })}
      <Menu
        keepMounted
        {...restMenuProps}
        PaperProps={{
          style: menuStyles,
        }}
        open={Boolean(anchorElem)}
        anchorEl={anchorElem}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={sxStyles.list}
        onClose={handleClose}
      >
        {getMenuItemsNode(menuItems)}
      </Menu>
    </>
  );
};

export { Dropdown };
