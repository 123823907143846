import React from 'react';

import Box from '@mui/material/Box';

import { CardList } from 'components/card-list';

import { TEST_ID } from './constants';
import { useUserInfoConfig } from './useUserInfoConfig';

import { User } from '../../../../../api/users/types';

interface Props {
  user: User;
}

export const UserInfo: React.FC<Props> = ({ user }) => {
  const items = useUserInfoConfig(user);

  return (
    <Box data-testid={TEST_ID.userInfo}>
      <CardList items={items} />
    </Box>
  );
};
