import React from 'react';

import { Box, IconButton, Popover, SxProps, Typography } from '@mui/material';

import { styles } from './styles';

import { Icon } from '../icon';

export type Props = {
  text: string;
  active?: boolean;
  sx?: SxProps;
};

export const TextCopyPopover: React.FC<Props> = ({ text, sx, active = true }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleOpenInfoButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  };
  const handleCloseInfoButton = () => {
    setAnchorEl(null);
  };
  const isDealNamePopUpVisible = Boolean(anchorEl);

  return (
    <>
      <Typography sx={styles.textValueEllipsis}>
        {active && (
          <IconButton sx={styles.iconButton} onClick={handleOpenInfoButton}>
            <Icon width={24} height={24} name="tooltip" />
          </IconButton>
        )}
        {text}
      </Typography>
      <Popover
        open={isDealNamePopUpVisible}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={styles.popover}
        onClose={handleCloseInfoButton}
      >
        <Box sx={styles.infoMessage}>
          <Typography sx={sx}>
            {text}
            <IconButton onClick={() => navigator.clipboard.writeText(text ?? '')}>
              <Icon width={24} height={24} name="copy" />
            </IconButton>
          </Typography>
        </Box>
      </Popover>
    </>
  );
};
