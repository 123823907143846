export enum apiErrors {
  Forbidden = 'Forbidden',
  NotFound = 'NotFound',
  ServerError = 'ServerError',
}

export const ErrorHeaderTitle = {
  [apiErrors.Forbidden]: 'error.access_error.header.title',
  [apiErrors.NotFound]: 'error.notFound_error.header.title',
  [apiErrors.ServerError]: 'error.server_error.header.title',
};

export const ErrorSubTitle = {
  [apiErrors.Forbidden]: 'error.access_error.sub_header.title',
  [apiErrors.NotFound]: 'error.notFound_error.sub_header.title',
  [apiErrors.ServerError]: 'error.server_error.sub_header.title',
};

export const ErrorTitle = {
  [apiErrors.Forbidden]: 'error.access_error.title',
  [apiErrors.NotFound]: 'error.notFound_error.title',
  [apiErrors.ServerError]: 'error.server_error.title',
};

export const ErrorDescription = {
  [apiErrors.Forbidden]: 'error.access_error.description1',
  [apiErrors.NotFound]: 'error.notFound_error.description1',
  [apiErrors.ServerError]: 'error.server_error.description1',
};

export const ErrorSupDescription = {
  [apiErrors.Forbidden]: 'error.access_error.description2',
  [apiErrors.NotFound]: 'error.notFound_error.description2',
  [apiErrors.ServerError]: 'error.server_error.description2',
};
