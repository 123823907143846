import { SnackbarContent, useSnackbar } from 'notistack';
import React, { forwardRef, useCallback } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

import { styles } from './styles';

const CustomNotification = forwardRef<
  HTMLDivElement,
  {
    id: number | string;
    variant: 'success' | 'error' | 'warning' | 'info';
    title?: React.ReactElement;
    message?: React.ReactElement;
    button?: {
      label: string;
      clickHandler(id: number | string): void;
    };
  }
>((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <SnackbarContent
      ref={ref}
      style={{
        width: 400,
      }}
    >
      <Card sx={styles.card} className={`rootCard_${props.variant}`}>
        <Box display="flex">
          <Box display="flex" marginRight={2}>
            <Icon className={`icon_${props.variant} icon`}>
              {'success' === props.variant ? 'check_circle' : props.variant}
            </Icon>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            flexBasis="100%"
            justifyContent="center"
          >
            {props.title && (
              <Typography variant="subtitle2" sx={styles.title}>
                {props.title}
              </Typography>
            )}

            {props.message && (
              <Box display="flex" alignItems="end" paddingTop="1px">
                <Typography component="span" style={{ fontSize: 14 }}>
                  {props.message}
                </Typography>
              </Box>
            )}

            {props.button && (
              <Box mt={props.title ? 0.5 : 0}>
                <Button
                  disableRipple
                  variant="text"
                  color="error"
                  sx={styles.button}
                  data-testid={`${props.variant}-snackbar-notification-button`}
                  endIcon={<Icon style={{ fontSize: 20 }}>chevron_right</Icon>}
                  onClick={() => props.button?.clickHandler(props.id)}
                >
                  {props.button.label}
                </Button>
              </Box>
            )}
          </Box>

          <Box
            display="flex"
            ml={1}
            sx={{
              cursor: 'pointer',
            }}
            onClick={handleDismiss}
          >
            <Icon color="error" className="icon">
              close
            </Icon>
          </Box>
        </Box>
      </Card>
    </SnackbarContent>
  );
});

export { CustomNotification };
