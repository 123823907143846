import { AxiosError } from 'axios';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';

import { Box, CircularProgress, Typography } from '@mui/material';
import Link from '@mui/material/Link';

import { DealDetailEditDialog } from './DealDetailEditDialog';
import { styles } from './styles';
import { canAutomaticallyCreateDealId } from './util';

import { sendToXandr } from '../../api/deals';
import { Deal, State } from '../../api/deals/types';
import { queryKeys } from '../../api/queryKeys';
import { NotificationMessage, useNotification } from '../../notifications';
import { getRole, Roles } from '../../utils/keycloak/Keycloak';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { Button } from '../button';
import { InfoModal } from '../info-modal';
import { SectionContent } from '../section-content';
import { Tooltip } from '../tooltip/Tooltip';

export type Props = {
  deal: Deal;
};

export const DealDetailActions: React.FC<Props> = ({ deal }) => {
  const intl = useIntl();
  const ability = useAbility();
  const { mutate: onSendToXandr, isLoading: isSendToXandrLoading } = useSendToXandr();
  const canEditDeal = [Roles.DPGAdmin, Roles.DPGUser].includes(getRole());
  const canViewInXandr = canEditDeal && deal.dealId;
  const canSendToXandr =
    deal.state === State.Requested && ability.can(Actions.Create, Permissions.DealId);
  const canAutomaticallyCreate = canAutomaticallyCreateDealId(deal);

  if (!canSendToXandr && !canEditDeal) {
    return null;
  }

  return (
    <SectionContent size="halfScreen" sx={{ marginBottom: '8px', paddingBottom: '40px' }}>
      <Typography variant="h2" sx={styles.title}>
        <FormattedMessage id="deal.detail.manageActions.title" />
        <InfoModal infoIconSx={styles.titleHint}>
          <FormattedMessage id="deal.detail.manageActions.hint" />
        </InfoModal>
      </Typography>
      <Box sx={styles.actionButtons}>
        {canEditDeal && (
          <DealDetailEditDialog
            deal={deal}
            triggerEl={
              <Button endIconName="edit" labelId="deal.detail.manageActions.editDeal" />
            }
          />
        )}
        {canViewInXandr && (
          <Button
            endIconName="view"
            labelId="deal.detail.manageActions.viewInXandr"
            component={Link}
            href={`https://monetize.xandr.com/smw/line-items?filter=%7B%22settings%22:%7B%22deal%22:%5B%22${deal.dealId}%22%5D%7D%7D`}
            target="_blank"
          />
        )}
        {canSendToXandr && (
          <Tooltip
            title={
              canAutomaticallyCreate
                ? ''
                : intl.formatMessage({
                    id: 'deal.detail.manageActions.sendToXandr.disabledHint',
                  })
            }
          >
            <span>
              <Button
                endIconName="upload-cloud"
                labelId="deal.detail.manageActions.sendToXandr"
                disabled={!canAutomaticallyCreate || isSendToXandrLoading}
                onClick={() => onSendToXandr(deal.id)}
              />
              {isSendToXandrLoading && <CircularProgress sx={styles.loadingIndicator} />}
            </span>
          </Tooltip>
        )}
      </Box>
    </SectionContent>
  );
};

const useSendToXandr = () => {
  const cache = useQueryClient();
  const sendNotification = useNotification({});

  return useMutation<void, AxiosError, string>(sendToXandr, {
    onSuccess: () => {
      sendNotification({
        type: 'success',
        message: <NotificationMessage id="deal.detail.props.dealId.message" />,
      });
    },
    onSettled: (_, __, id) => {
      cache.invalidateQueries([queryKeys.getDeal, id]);
    },
  });
};
