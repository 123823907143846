import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { QueryFunctionContext } from 'react-query';

import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { default as MuiDialog } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Icon } from 'components/icon';
import { themeColors } from 'theme/themeColors';

import { styles } from './styles';

import { Agency } from '../../api/agencies/types';
import { queryKeys } from '../../api/queryKeys';
import { getUser } from '../../api/users';
import { User } from '../../api/users/types';
import { getFullName } from '../../features/users/utils';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { RowWithTitle } from '../elements/row-with-title';
import { Tooltip } from '../tooltip/Tooltip';

export interface DialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  agency: Agency;
}

async function getDealManagers(ids: string[]) {
  return await Promise.all(
    ids.map((id) => {
      return getUser({
        queryKey: [queryKeys.getUser, id],
      } as QueryFunctionContext<[string, string]>);
    })
  );
}

const ViewAgencyDialog: React.FC<DialogProps> = ({ open, setOpen, agency }) => {
  const intl = useIntl();
  const ability = useAbility();
  const canViewDealManagers = ability.can(Actions.View, Permissions.AgencyDealManagers);
  const [dealManagers, setDealManagers] = useState<User[]>([]);

  useEffect(() => {
    if (canViewDealManagers && agency.dealManagers) {
      getDealManagers(agency.dealManagers).then((agencyDealManagers) =>
        setDealManagers(agencyDealManagers)
      );
    }
  }, [agency]);

  return (
    <MuiDialog open={open} maxWidth={false}>
      <DialogContent>
        <Box>
          <Icon
            name="close"
            width={24}
            height={24}
            color={themeColors.purplePrimary}
            sx={styles.closeButton}
            onClick={() => setOpen(false)}
          />
        </Box>
        <Typography variant="h2" sx={styles.title}>
          <FormattedMessage id="agency.form.view.title" />
        </Typography>
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.view.name' })}
          mt={3}
          mb={3}
        >
          <Typography sx={styles.text}>{agency.name}</Typography>
        </RowWithTitle>
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.view.dealNameAlias' })}
          mt={3}
          mb={3}
        >
          <Typography sx={[styles.text, agency.dealNameAlias ? null : styles.mutedText]}>
            {agency.dealNameAlias ?? agency.name}
          </Typography>
        </RowWithTitle>
        {canViewDealManagers && (
          <RowWithTitle
            title={intl.formatMessage({ id: 'agency.view.dealManagers' })}
            mt={3}
            mb={3}
          >
            <Typography sx={styles.text}>
              {dealManagers.length > 0
                ? dealManagers.map((dealManager) => getFullName(dealManager)).join(', ')
                : ''}
            </Typography>
          </RowWithTitle>
        )}
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.view.salesForce' })}
          mt={3}
          mb={3}
        >
          <Typography sx={styles.text}>{agency.salesForceId}</Typography>
        </RowWithTitle>{' '}
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.view.buyers' })}
          mt={3}
          mb={3}
        >
          <Typography sx={styles.text}>
            {agency.buyers?.map((buyer, idx, arr) => (
              <>
                {`${buyer.dsp} ${buyer.seat}`}
                {idx < arr!.length - 1 && <br />}
              </>
            )) ?? '-'}
          </Typography>
        </RowWithTitle>{' '}
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.view.advertisers' })}
          mt={3}
          mb={3}
        >
          <Typography sx={styles.text}>
            {agency.advertisers?.map((a, idx, arr) => (
              <>
                {a.name}
                {a.isGambling && (
                  <Tooltip
                    title={intl.formatMessage({
                      id: `agency.form.advertisers.gamblingTooltip.enabled`,
                    })}
                    size="small"
                  >
                    <span>
                      <Icon
                        name="gambling"
                        width={16}
                        height={16}
                        color={themeColors.greyPrimary}
                        sx={{
                          margin: '0 4px',
                          verticalAlign: 'middle',
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {idx < arr.length - 1 && <br />}
              </>
            )) ?? '-'}
          </Typography>
        </RowWithTitle>{' '}
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.view.isPrio' })}
          mt={3}
          mb={3}
        >
          <Typography sx={styles.text}>
            <FormattedMessage
              id={agency.isPrio ? 'agency.view.isPrio.true' : 'agency.view.isPrio.false'}
            />
          </Typography>
        </RowWithTitle>
      </DialogContent>
    </MuiDialog>
  );
};

export { ViewAgencyDialog };
