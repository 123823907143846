export enum RootCategories {
  gender = 'gender',
  age = 'age',
  interest = 'interest',
  moments = 'moments',
  sociodemographic = 'sociodemographic',
  contextualTargeting = 'contextualTargeting',
}

export interface SearchDetailedTargetingItem extends DetailedTargetingItem {
  path: string;
  pathLabels: string;
  group: string;
  leafItemsCount: number;
  keyword?: string;
}

export type DetailedTargetingKeys = keyof typeof RootCategories;

export type DetailedTargeting = {
  [key in RootCategories]?: DetailedTargetingItem[];
};

export type DetailedTargetingItem = {
  id: string;
  label: string;
  size?: number;
  description?: string;
  exclusive?: boolean;
  children?: DetailedTargetingItem[];
  xandrSegmentCode?: string;
  xandrIdNl?: number;
  xandrIdBe?: number;
};

export type TargetingValue = {
  [key in RootCategories]?: TargetingValueItem[];
};

export type TargetingValueItem = Omit<DetailedTargetingItem, 'size' | 'children'> & {
  size?: number;
  relation?: Relation;
  description?: string;
  children?: TargetingValueItem[];
};

export type Keyword = {
  group: string;
  keyword: string;
  sizeNl?: number;
  sizeBe?: number;
  relation?: Relation;
};

export interface TargetingValueItemExtended extends TargetingValueItem {
  label: string;
  path?: string;
  pathLabels?: string;
  leafItemsCount?: number;
}

export interface TagValue extends TargetingValueItemExtended {
  group?: string;
  keyword?: string;
}

export type ItemProps = {
  name: string;
  disabled: boolean;
  relation?: string;
  config: DetailedTargetingItem;
  type: string;
  changeHandler(): void;
  onClick(): void;
};

export type DetailedTargetingSubmitValues = {
  detailedTargeting: TagValue[];
};

export enum Relation {
  INCLUDED = 'INCLUDED',
  EXCLUDED = 'EXCLUDED',
  INTERMEDIATE = 'INTERMEDIATE',
}

export type RestrictedRelation = Exclude<Relation, Relation.INTERMEDIATE>;

export type Size = { size?: number };
