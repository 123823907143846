import React from 'react';

import Box from '@mui/material/Box';

import { IconName } from 'components/icon';
import { InfoModal } from 'components/info-modal';
import { TextOverflowTooltip } from 'components/text-overflow-tooltip';

interface Props {
  infoContent?: React.ReactElement;
  label: string;
  iconName?: IconName;
}

export const TagLabel: React.FC<Props> = ({ infoContent, label, iconName }) => {
  const LabelText = <TextOverflowTooltip value={label} />;

  if (iconName && iconName === 'tooltip') {
    return (
      <Box component="div" display="flex">
        <Box mr={1}>{LabelText}</Box>
        <InfoModal>{infoContent}</InfoModal>
      </Box>
    );
  }

  return <Box component="span">{LabelText}</Box>;
};
