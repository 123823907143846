import { theme } from '../../theme';
import { themeColors } from '../../theme/themeColors';

export const styles = {
  fullWidth: {
    width: '100%',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),

    '& .MuiTextField-root': {
      marginRight: theme.spacing(1),
    },
  },
  title: {
    color: themeColors.greyPrimary,
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
  text: {
    fontSize: '18px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer',
  },
  deleteIcon: {
    width: '24px',
    height: '24px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  gamblingIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '16px',
  },
  padding: {
    paddingTop: '15px',
  },
  selectField: {
    flexBasis: '100%',
    width: '0%',
  },
};
