import React from 'react';

import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Dialog } from 'components/dialog';

import { TEST_ID } from './constants';
import { styles } from './styles';

import { Button } from '../button';

interface GeneralDialogProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  title: string;
  description: string | React.ReactNode;
  confirmId?: string;
  cancelId?: string;
}

interface Props extends GeneralDialogProps {
  sx?: SxProps;

  onDialogClose?(): void;
}

export interface DialogProps extends GeneralDialogProps {
  triggerEl: React.ReactElement;
}

export const Confirmation: React.FC<Props> = ({
  title,
  description,
  confirmId = 'confirmation.confirm.title',
  cancelId = 'confirmation.cancel.title',
  sx = {},
  onDialogClose,
  onCancel,
  onConfirm,
}) => {
  const onCancelHandler = () => {
    onCancel?.();
    onDialogClose?.();
  };

  const onConfirmHandler = () => {
    onConfirm?.();
    onDialogClose?.();
  };

  return (
    <Box sx={sx}>
      <Typography variant="h1">{title}</Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: '12px',
        }}
      >
        {description}
      </Typography>
      <Box sx={styles.actions}>
        <Button
          data-testid={TEST_ID.confirmButton}
          labelId={confirmId}
          type="submit"
          sx={{ marginRight: '24px', minWidth: 80 }}
          onClick={onConfirmHandler}
        />
        <Button
          variant="text"
          sx={{ minWidth: 70 }}
          labelId={cancelId}
          onClick={onCancelHandler}
        />
      </Box>
    </Box>
  );
};

export const ConfirmationDialog: React.FC<DialogProps> = ({ triggerEl, ...props }) => {
  return (
    <Dialog triggerEl={triggerEl} sx={styles.paper}>
      <Confirmation {...props} />
    </Dialog>
  );
};
