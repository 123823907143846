import { AbilityBuilder } from '@casl/ability';

import { Actions, Permissions } from '../constants';

export const dpgAdminRole = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  can: AbilityBuilder<any>['can'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cannot: AbilityBuilder<any>['cannot']
): void => {
  can(Actions.View, Permissions.Users);
  can(Actions.Create, Permissions.Users);

  can(Actions.View, Permissions.UserInfo);
  can(Actions.Manage, Permissions.UserInfo);

  can(Actions.Import, Permissions.Deal);

  can(Actions.View, Permissions.DealId);
  can(Actions.Manage, Permissions.DealId);
  can(Actions.Create, Permissions.DealId);

  can(Actions.Manage, Permissions.DealName);

  can(Actions.Manage, Permissions.Roles);

  can(Actions.Manage, Permissions.Activation);

  can(Actions.View, Permissions.Agencies);
  can(Actions.Manage, Permissions.Agencies);
  can(Actions.Create, Permissions.Agencies);

  can(Actions.View, Permissions.AgencyDealManagers);

  can(Actions.Manage, Permissions.UserAgencySettings);
};
