import React, { createContext, useReducer } from 'react';

import { appReducer, InitialStateType } from './reducer';

import { ReactFCC } from '../react-util';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Handlers {}

const initialState = {
  isDesignModalOpened: false,
};

const AppContext = createContext<{
  state: InitialStateType;
  handlers: Handlers | null;
}>({
  state: initialState,
  handlers: null,
});

const AppProvider: ReactFCC = ({ children }) => {
  const [state] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider
      value={{
        state,
        handlers: {},
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
