import React from 'react';
import { useIntl } from 'react-intl';

import { IndeterminateCheckBox } from '@mui/icons-material';
import { Box, Radio } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import { themeColors } from 'theme/themeColors';

import { styles } from './styles';

import { TEST_ID } from '../../constants';
import { ItemProps, Relation } from '../../types';
import { sizeFormatter } from '../../utils';

const LeafItem: React.FC<ItemProps & { selectOne?: boolean }> = ({
  name,
  type,
  config,
  disabled,
  relation,
  changeHandler,
  onClick,
  selectOne,
}) => {
  const intl = useIntl();
  const disabledMessage = intl.formatMessage({
    id: `deal.targeting.dialog.${type === Relation.INCLUDED ? 'excluded' : 'included'}`,
  });

  const formattedSize = sizeFormatter(config.size);
  const Control = selectOne ? (
    <Radio color="primary" size="small" name={name} />
  ) : (
    <Checkbox
      color="primary"
      size="small"
      name={name}
      indeterminateIcon={<IndeterminateCheckBox color="primary" />}
      indeterminate={relation === Relation.INTERMEDIATE ? true : undefined}
    />
  );

  return (
    <Box
      key={config.id}
      sx={styles.root}
      display="flex"
      alignItems="center"
      data-testid={TEST_ID.leafItem}
    >
      <FormControlLabel
        disabled={disabled}
        checked={!!relation}
        control={Control}
        label={
          <Box
            display="contents"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={onClick}
          >
            <Box>
              <Typography variant="body1">{config.label}</Typography>
            </Box>
            <Typography
              variant="body1"
              style={{
                marginLeft: 'auto',
                marginRight: 12,
                color: themeColors.greyPrimary,
              }}
            >
              {disabled ? disabledMessage : formattedSize}
            </Typography>
          </Box>
        }
        onChange={changeHandler}
      />
    </Box>
  );
};

export { LeafItem };
