import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Close, Search } from '@mui/icons-material';
import { AutocompleteChangeReason, Box, Paper, SxProps, TextField } from '@mui/material';
import AutocompleteMUI from '@mui/material/Autocomplete';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';

import { styles } from 'components/form/fields/autocomplete/styles';

import { isOptionMatched } from './utils';

import { TEST_ID } from '../../constants';
import {
  DetailedTargeting,
  DetailedTargetingItem,
  Keyword,
  RestrictedRelation,
  SearchDetailedTargetingItem,
  TagValue,
  TargetingValue,
  TargetingValueItem,
} from '../../types';
import { getOmittedParam, getSelectedTagsValues } from '../../utils';
import { AutocompleteOption } from '../autocomplete-option/AutocompleteOption';
import { cleanData } from '../browse/utils';
import { Listbox } from '../listbox/Listbox';
import { TagsAutocomplete } from '../tags-autocomplete/TagsAutocomplete';
import { buildTargetingValue } from '../targeting-item/utils';

interface Props {
  filterData: SearchDetailedTargetingItem[];
  formFieldMappedValues: TargetingValueItem[];
  relation: RestrictedRelation;
  formValue: DetailedTargeting & { keywords?: Keyword[] };
  isSearchActive?: boolean;
  inputRef: React.MutableRefObject<boolean>;
  browseValue: TargetingValueItem[];

  onChange(value: TargetingValue & { keywords?: Keyword[] }): void;

  setIsSearchActive(value: boolean): void;

  setScreen(screen: string[]): void;
}

export const Autocomplete: React.FC<Props> = ({
  filterData,
  formFieldMappedValues,
  relation,
  setScreen,
  formValue,
  onChange,
  setIsSearchActive,
  isSearchActive,
  inputRef,
  browseValue,
}) => {
  const intl = useIntl();

  const initialFieldValue = getSelectedTagsValues(
    formFieldMappedValues,
    formValue,
    relation
  );
  const [value, setValue] = React.useState<TagValue[]>(initialFieldValue);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState(filterData);
  const omittedParam = getOmittedParam(relation);
  const [rerenderFix, setRerenderFix] = React.useState<(() => void) | null>(null);

  // Autocomplete gets cleared when first switching to search because of an
  // uncontrolled input being re-rendered, so we need to re-set the value
  // TODO proper fix
  useEffect(() => {
    if (rerenderFix && isSearchActive) {
      rerenderFix();
      setRerenderFix(null);
    }
  }, [rerenderFix, isSearchActive]);

  useEffect(() => {
    setOptions(filterData);
  }, [filterData]);

  useEffect(() => {
    setValue(getSelectedTagsValues(formFieldMappedValues, formValue, relation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldMappedValues, formValue, relation]);

  const handleChange = useCallback(
    (newValue: TagValue[]) => {
      const addedItem = newValue[newValue.length - 1];
      setValue(newValue);
      // if item is IAB
      if (addedItem.path) {
        const newVal = buildTargetingValue(
          addedItem.path,
          formFieldMappedValues,
          relation
        ) as TargetingValueItem[];

        onChange({
          ...formValue,
          ...cleanData(newVal),
        });
      } else {
        onChange({
          ...formValue,
          keywords: [
            ...(formValue.keywords || []),
            {
              ...addedItem,
              relation,
            },
          ] as Keyword[],
        });
      }

      setScreen([]);
    },
    [formFieldMappedValues, formValue, onChange, relation, setScreen]
  );

  const isOptionDisabled = useCallback(
    (option: TagValue) => isOptionMatched(option, omittedParam, formValue),
    [formValue, omittedParam]
  );

  const isOptionSelected = useCallback(
    (option: TagValue) => isOptionMatched(option, relation, formValue),
    [formValue, relation]
  );

  const handleOpen = () => {
    inputRef!.current = true;
    setIsSearchActive(true);
  };

  const handleClose = () => {
    inputRef.current = false;
    setIsSearchActive(false);
  };

  const handleRenderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: TagValue,
      { inputValue, selected }: AutocompleteRenderOptionState
    ) => {
      const matches = match(option.keyword!, inputValue);
      const parts = parse(option.keyword!, matches);

      return (
        <li {...props} key={option.id}>
          <AutocompleteOption
            option={option}
            textParts={parts}
            disabled={isOptionDisabled(option)}
            omittedParam={omittedParam}
            selected={selected}
            value={value}
            formValue={formValue}
            browseValue={browseValue}
            onChange={onChange}
          />
        </li>
      );
    },
    [formValue, isOptionDisabled, omittedParam, onChange, value]
  );

  return (
    <>
      <TagsAutocomplete
        value={value}
        relation={relation}
        browseValue={formFieldMappedValues}
        onChange={onChange}
      />
      <AutocompleteMUI
        multiple
        disableClearable
        disablePortal
        data-testid={TEST_ID.autocomplete}
        noOptionsText={<FormattedMessage id="results.no_options" />}
        sx={
          {
            ...styles.autoSelectOption,
            ...styles.popupIndicator,
            ...styles.autoSelectPopper,
            '& .MuiOutlinedInput-notchedOutline': {
              top: 0,
            },
          } as SxProps
        }
        getOptionLabel={(option: TagValue) => option.keyword!}
        options={options}
        renderInput={(params) => (
          <Box>
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              placeholder={intl.formatMessage({
                id: 'deal.targeting.dialog.search.placeholder',
              })}
              sx={styles.inputField}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                params.inputProps.onChange?.(e);

                if (e.target.value.length && !isSearchActive) {
                  handleOpen();
                  setRerenderFix(() => () => params.inputProps.onChange?.(e));
                }
              }}
            />
          </Box>
        )}
        // todo: fix typing
        value={value as unknown as DetailedTargetingItem[]}
        inputValue={inputValue}
        isOptionEqualToValue={isOptionSelected}
        renderTags={() => null}
        renderOption={handleRenderOption}
        ListboxComponent={Listbox}
        PaperComponent={({ children, ...rest }) => (
          <Paper elevation={0} {...rest}>
            {inputRef.current ? children : <></>}
          </Paper>
        )}
        popupIcon={
          isSearchActive ? (
            <Close color="primary" viewBox="-6 -6 36 36" />
          ) : (
            <Search color="primary" />
          )
        }
        onBlurCapture={(event) => {
          event.stopPropagation();
          if (!inputValue.length) {
            handleClose();
          }
        }}
        onChange={(event, newValue, reason: AutocompleteChangeReason) => {
          if (reason !== 'removeOption') handleChange(newValue as TagValue[]);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onClose={handleClose}
      />
    </>
  );
};
