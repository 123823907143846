import React from 'react';

import Box from '@mui/material/Box';
import MuiPagination from '@mui/material/Pagination';

import { PageItem } from 'components/table/page-item/PageItem';
import { ShowPerPage } from 'components/table/show-per-page/ShowPerPage';

import { styles } from './styles';

interface Props {
  page: number;
  size: number;
  paginationType?: string;
  itemsLength?: number;

  onPageSizeChange(size: number): void;

  onPageChange(page: number): void;
}

export const Pagination: React.FC<Props> = ({
  page,
  onPageSizeChange,
  onPageChange,
  size,
  itemsLength,
}) => {
  const pageCount = itemsLength ? Math.ceil(itemsLength / size) : 1;

  return (
    <Box sx={styles.box}>
      {pageCount > 1 ? (
        <MuiPagination
          sx={styles.root}
          count={pageCount}
          hidePrevButton={page === 1}
          page={page}
          renderItem={(params) => <PageItem params={params} />}
          onChange={(_event, value) => {
            onPageChange(value);
          }}
        />
      ) : null}
      <Box sx={styles.dropdown}>
        <ShowPerPage pageSize={size} onPageSizeChange={onPageSizeChange} />
      </Box>
    </Box>
  );
};
