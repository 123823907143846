import React, { Suspense } from 'react';

import { ReactFCC } from '../../react-util';
import { LoadingOverlay } from '../loading-overlay';

interface Props {
  pendingFallback?: React.ReactElement;
}

export const AsyncBoundary: ReactFCC<Props> = ({
  children,
  pendingFallback = <LoadingOverlay />,
}) => {
  return <Suspense fallback={pendingFallback}>{children}</Suspense>;
};
